import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Input, Table } from 'reactstrap'
import {
  FacilityManagementPermissions,
  TFacility,
  USER_STATUSES,
} from '../../../sharedTypes'
import { usePermissions } from '../../../hooks/usePermissions'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import { getUserDisplayName } from '../../../helpers/user'
import { getAdmins } from '../../../helpers/api/admins'
import PrimaryConfirmationModal from '../../../Components/Modals/PrimaryConfirmationModal'
import { FormikProps } from 'formik'
import { IForm } from './FacilityInfo'
import _ from 'lodash'

interface FacilitySettingsProps {
  facility: TFacility
  form: FormikProps<IForm>
}

const FacilitySettings = ({
  facility: facilityProp,
  form,
}: FacilitySettingsProps) => {
  const [facility, setFacility] = useState(facilityProp)
  const [isEdit, setEdit] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const hasPermissionToEdit = usePermissions(
    FacilityManagementPermissions.EDIT_FACILITY,
  )

  useEffect(() => {
    setFacility(facilityProp)
    setEdit(false)
  }, [facilityProp])

  const info = useMemo(() => {
    return [
      {
        label: 'Mastered Manager',
        value: getUserDisplayName(facility.masteredManager),
        isEditable: true,
        formKey: 'masteredManager',
      },
      {
        label: 'HR Manager',
        value: getUserDisplayName(facility.HRManager),
        isEditable: true,
        formKey: 'HRManager',
      },
      {
        label: 'Clinical Scheduler',
        value: getUserDisplayName(facility.clinicalScheduler),
        isEditable: true,
        formKey: 'clinicalScheduler',
      },
      {
        label: 'Allow Skip to Test',
        value: facility.allowSkipToTest ? 'Yes' : 'No',
        isEditable: true,
        formKey: 'allowSkipToTest',
      },
    ]
  }, [facility])

  const fetchAdmins = (inputValue?: string) => {
    const params = {
      page: 1,
      limit: 10,
      status: USER_STATUSES.ACTIVE,
      key: inputValue,
    }

    return getAdmins({
      ...params,
      facilityIds: [facility?.id],
      permission: FacilityManagementPermissions.VIEW_FACILITY_MANAGEMENT,
    })
      .then(d => d.data)
      .then(({ admins }) => {
        return admins.map(admin => ({
          value: admin,
          label: getUserDisplayName(admin),
        }))
      })
      .catch(() => [])
  }

  const onCancel = () => {
    setEdit(false)
    form.resetForm()
  }
  const widthCondition = window.innerWidth > 1600

  return (
    <Card>
      <CardBody className='d-flex flex-column'>
        <div className='d-flex align-items-center mb-3'>
          <div className='flex-grow-1'>
            <p className='fs-16 mb-0 fw-light' style={{ color: '#7C8193' }}>
              Facility Settings
            </p>
          </div>
          <div className='flex-shrink-0 fs-12'>
            {hasPermissionToEdit && !isEdit && (
              <Button
                color='soft-primary'
                className='fs-12'
                onClick={() => setEdit(true)}
              >
                <i className={`ri-edit-box-line me-1 fs-12`}></i>
                Edit
              </Button>
            )}

            {isEdit && (
              <>
                <Button
                  color='soft-danger'
                  className='me-2 fs-12'
                  onClick={onCancel}
                >
                  <i className='ri-close-line me-1'></i>Cancel
                </Button>
                <Button
                  color='soft-success'
                  className='fs-12 border-0'
                  onClick={() => form.submitForm()}
                  disabled={!(form.isValid && form.dirty) || form.isSubmitting}
                >
                  <i className='ri-check-line me-1 fs-12'></i>Save
                </Button>
              </>
            )}
          </div>
        </div>
        <div className='table-responsive overflow-visible'>
          <Table
            className='table-borderless mb-0'
            style={{ tableLayout: 'fixed' }}
          >
            <tbody>
              {info.map((item, index) => (
                <tr
                  key={index}
                  style={{ height: widthCondition ? 39 : '100%' }}
                  className={`d-flex ${
                    widthCondition ? '' : isEdit ? 'flex-column' : ''
                  }`}
                >
                  <td
                    className={`ps-0 fs-14 fw-normal ${
                      item.isEditable ? 'text-nowrap' : ''
                    }`}
                    scope='row'
                    style={{
                      width: 150,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    title={item.label}
                  >
                    {item.label} :
                  </td>
                  <td
                    className={
                      'text-muted fw-light pe-0' +
                      (isEdit && item.isEditable ? ' p-0 align-middle' : '')
                    }
                  >
                    {isEdit && item.isEditable ? (
                      item.formKey !== 'allowSkipToTest' ? (
                        <AsyncSelectWithSearch
                          name={item.formKey}
                          id={item.formKey}
                          onChange={option => {
                            form.setFieldValue(item.formKey, option)
                          }}
                          onBlur={form.handleBlur}
                          value={
                            _.get(form.values, `[${item.formKey}].value`)
                              ? form.values[
                                  item.formKey as keyof typeof form.values
                                ]
                              : undefined
                          }
                          isMulti={false}
                          isClearable={true}
                          isSearchable={true}
                          placeholder={'Enter admin'}
                          defaultOptions
                          loadOptions={fetchAdmins}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                        />
                      ) : (
                        <div className='form-check form-switch'>
                          <Input
                            className='form-check-input'
                            type='checkbox'
                            role='switch'
                            id={'allowSkipToTest'}
                            checked={form.values.allowSkipToTest}
                            onChange={() => setShowModal(true)}
                          />
                        </div>
                      )
                    ) : (
                      item.value
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>

      <PrimaryConfirmationModal
        onConfirm={() => {
          form.setFieldValue('allowSkipToTest', !form.values.allowSkipToTest)
          setShowModal(false)
        }}
        className='company-switch-warning-modal'
        confirmButtonLabel='Yes'
        icon='ri-error-warning-line'
        isLoading={false}
        isOpen={showModal}
        title=''
        message={`Are you sure you want to ${
          form.values.allowSkipToTest ? 'turn off ' : 'enable'
        } the "Skip to Test" feature?`}
        onClose={() => {
          setShowModal(false)
        }}
      />
    </Card>
  )
}

export default FacilitySettings
