import { Dispatch, SetStateAction } from 'react'

export enum NewUsersStatuses {
  NOT_REGISTERED = 'notRegistered',
  NOT_STARTED_ONBOARDING = 'notStartedOnboarding',
  ONBOARDING_IN_PROGRESS = 'onboardingInProgress',
  ONBOARDING_COMPLETE = 'onboardingComplete',
  ONBOARDING_EXPIRED = 'onboardingExpired',
}

export enum Tabs {
  USERS_NOT_REGISTERED = 'Users Not Registered',
  IMPORT_ERRORS = 'Import Errors',
  FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION = 'Overdue Compliance Courses',
  USERS_WITH_EXPIRED_ONBOARDING = 'Onboarding Expired',
  EXPIRED_USERS = 'Expired Users',
  NO_COURSES = 'No Courses',
}

export const TabsTooltips = {
  [Tabs.USERS_NOT_REGISTERED]:
    'Users that have not Registered on the Mastered App',
  [Tabs.IMPORT_ERRORS]:
    'Outstanding user errors on import, sometimes preventing user account creation',
  [Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION]:
    'Users that have compliance courses more than 4 weeks overdue',
  [Tabs.USERS_WITH_EXPIRED_ONBOARDING]:
    ' Users who has not completed an onboarding course within the timeframe allotted at assignment',
  [Tabs.EXPIRED_USERS]:
    'Users created manually who were not matched with an imported user within 2 weeks.  These users will no longer be able to access the app',
  [Tabs.NO_COURSES]: ' Users that have registered but have no courses assigned',
}

export interface ITotalCounts {
  [Tabs.USERS_NOT_REGISTERED]: number
  [Tabs.IMPORT_ERRORS]: number
  [Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION]: number
  [Tabs.USERS_WITH_EXPIRED_ONBOARDING]: number
  [Tabs.EXPIRED_USERS]: number
  [Tabs.NO_COURSES]: number
}

export interface TabProps {
  setTotalCounts: Dispatch<SetStateAction<ITotalCounts>>
  includeAgency?: boolean
}
