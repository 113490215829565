import { useAppSelector } from '../redux'
import { COURSE_TYPES } from '../../sharedTypes'

export const useCourseTypes = () => {
  const user = useAppSelector(state => state.User.user)

  if (user && user.companyId) {
    return Object.values(COURSE_TYPES)
      .filter(t => !(user.companyId && t === COURSE_TYPES.CONTINUING_EDUCATION)) // Exclude if companyId exists
      .map((t: string) => ({
        value: t as string,
        label: t as string,
      }))
  }

  return Object.values(COURSE_TYPES).map((t: string) => ({
    value: t as string,
    label: t as string,
  }))
}
