import * as yup from 'yup'
import {
  OnlineCourseFormatsEnum,
  RecurringTypes,
  ScheduledCourseTypes,
  QuestionTypes,
  SurveyStatusTypes,
  COURSE_TYPES,
} from './sharedTypes'
import moment from 'moment'
import { CoursePackageFormatsEnum } from './sharedTypes/models/coursePackage'
import { KnowledgeCheckTypes } from './pages/Courses/OnlineCourses/Lessons/types'

export const digitalCourseSchema = yup.object().shape({
  completeDays: yup.number().required('Required'),
  quizAttempts: yup.number().required('Required'),
})

export const lessonSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  fileId: yup.number().nullable(),
})

export const knowledgeCheckSchema = yup.object().shape({
  type: yup.mixed().oneOf(Object.values(KnowledgeCheckTypes)).required(),
  timestamp: yup
    .string()
    .matches(
      /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
      'Timestamp must be in the format HH:mm:ss',
    )
    .required('Timestamp is required'),
  data: yup
    .object()
    .nullable()
    .when('type', (type, schema) => {
      if (type === KnowledgeCheckTypes.PAUSE) {
        return yup.object().shape({
          buttonText: yup.string().nullable(),
        })
      } else if (type === KnowledgeCheckTypes.MULTIPLE_CHOICE) {
        return yup.object().shape({
          question: yup
            .string()
            .max(200, 'must be up to 200 characters')
            .required('Question is required'),
          explanation: yup.string().nullable(),
          answers: yup
            .array()
            .of(
              yup.object().shape({
                answer: yup.string().trim().required('Answer is required'),
                correct: yup.boolean(),
              }),
            )
            .min(1, 'At least one answer is required')
            .required(),
        })
      } else if (type === KnowledgeCheckTypes.REFLECTION_POINT) {
        return yup.object().shape({
          description: yup.string().required('Description is required'),
          buttonText: yup.string().nullable(),
        })
      } else if (type === KnowledgeCheckTypes.TRUE_FALSE) {
        return yup.object().shape({
          question: yup
            .string()
            .max(200, 'must be up to 200 characters')
            .required('Question is required'),
          explanation: yup.string().nullable(),
          answer: yup.object().shape({
            correct: yup.boolean().required('Correct field is required'),
          }),
        })
      } else {
        return schema
      }
    }),
})

export const completeGeneralSchema = yup.object().shape({
  languages: yup.array().min(1, 'Must have at least 1 items'),
  states: yup.array(),
  duration: yup.number().required('Required'),
  level: yup
    .object()
    .nullable()
    .when('type', {
      is: (type: COURSE_TYPES) =>
        Array.isArray(type) &&
        type.length > 0 &&
        ![COURSE_TYPES.ANNUAL_EDUCATION, COURSE_TYPES.ONBOARDING].includes(
          type[0],
        ),
      then: yup.object().required('Required'),
      otherwise: yup.object().nullable(),
    }),
  instructorId: yup.object().nullable(),
  tagIds: yup.array(),
  expirationDate: yup.date(),
  effectiveDate: yup.date(),
  translations: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .max(100, 'must be at most 100 characters')
        .required('Required'),
      description: yup
        .string()
        .max(2000, 'must be at most 2000 characters')
        .required('Required'),
      // .nullable()
      objective: yup
        .string()
        .max(1000, 'must be at most 1000 characters')
        // .required('Required')
        .nullable(),
      notes: yup
        .string()
        .max(2000, 'must be at most 2000 characters')
        .nullable(),
      resources: yup
        .string()
        .max(2000, 'must be at most 2000 characters')
        .nullable(),
    }),
  ),
})

export const defaultCourseSchema = yup.object().shape({
  name: yup
    .string()
    .max(100, 'must be at most 100 characters')
    .required('Required'),
  type: yup.array(yup.mixed().oneOf(Object.values(COURSE_TYPES)).required()),
  formats: yup
    .array(yup.mixed().oneOf(Object.values(OnlineCourseFormatsEnum)))
    .ensure()
    .when('type', {
      is: (types: COURSE_TYPES[]) =>
        !types.includes(COURSE_TYPES.CONTINUING_EDUCATION),
      then: schema => schema.min(1, 'Must have at least 1 item'),
      otherwise: schema => schema.nullable(),
    }),
})

export const draftSchema = yup.object().shape({
  languages: yup.array().min(1, 'Must have at least 1 items'),
  states: yup.array(),
  duration: yup.string().required('Required'),
  level: yup.object().nullable(),
  instructorId: yup.object().nullable(),
  translations: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .max(100, 'must be at most 100 characters')
        .required('Required'),
      description: yup
        .string()
        .max(2000, 'must be at most 2000 characters')
        .nullable(),
      objective: yup
        .string()
        .max(1000, 'must be at most 1000 characters')
        .nullable(),
      notes: yup
        .string()
        .max(2000, 'must be at most 2000 characters')
        .nullable(),
      resources: yup
        .string()
        .max(2000, 'must be at most 2000 characters')
        .nullable(),
    }),
  ),
})

export const generalCompetencySchema = yup.object().shape({
  quizQuestionsNumber: yup.number().min(1).required('Required').nullable(),
  messages: yup.array().of(
    yup.object().shape({
      success: yup
        .string()
        .max(100, 'must be at most 100 characters')
        .required('Required'),
      fail: yup
        .string()
        .max(100, 'must be at most 100 characters')
        .nullable()
        .required('Required'),
      retake: yup
        .string()
        .max(100, 'must be at most 100 characters')
        .nullable()
        .required('Required'),
    }),
  ),
})

export const scheduledCourseSchema = yup.object().shape({
  type: yup.mixed().oneOf(Object.values(ScheduledCourseTypes)),
  recurring: yup.mixed().oneOf(Object.values(RecurringTypes)),
  location: yup.string().nullable(),
  facilityId: yup
    .number()
    .nullable(false)
    .typeError('Facility is required')
    .required('Facility is required'),
  facilitatorId: yup.number().nullable(),
  startDate: yup.date().required('Please select Schedule Date and Time'),
  recurringEnd: yup
    .date()
    .nullable()
    .when('recurring', {
      is: (val: RecurringTypes) => {
        return val == RecurringTypes.WEEKLY
      },
      then: schema => schema.required('Pease select Recurring End dates'),
      otherwise: schema => schema.optional(),
    }),
  startTime: yup.date().required('Please select Schedule Date and Time'),
  endTime: yup
    .date()
    .test(
      'min_end_time',
      'Start Time cannot be before End Time',
      function (endTime) {
        const { startTime } = this.parent
        const format = 'hh:mm:ss'
        const beforeTime = moment(startTime, format)
        const afterTime = moment(endTime, format)

        return beforeTime.isBefore(afterTime)
      },
    )
    .required('Please select Schedule Date and Time'),
})

const questionItemSchema = yup.object().shape({
  question: yup
    .string()
    .max(200, 'must be up to 200 characters')
    .required('Required'),
  answers: yup.array().of(
    yup.object({
      answer: yup.string().nullable().required('Required'),
      correct: yup.boolean(),
    }),
  ),
})

export const courseQuestionsSchema = yup.object().shape({
  type: yup.mixed().oneOf(Object.values(QuestionTypes)),
  questions: yup
    .array()
    .nullable()
    .when(['type'], (type, schema) => {
      if (type === QuestionTypes.MULTIPLE_CHOICE) {
        return schema.of(questionItemSchema)
      } else if (type === QuestionTypes.SHORT_ANSWER) {
        return schema.of(
          yup.object().shape({
            question: yup
              .string()
              .max(200, 'must be up to 200 characters')
              .required('Required'),
          }),
        )
      } else if (type === QuestionTypes.IMAGE_CHOICE) {
        return schema.of(
          yup.object().shape({
            question: yup.string().required('Required'),
            answers: yup.array().of(
              yup.object({
                attachmentId: yup.number().required('Required'),
                correct: yup.boolean(),
              }),
            ),
          }),
        )
      } else {
        return schema.of(
          yup.object().shape({
            question: yup
              .string()
              .max(200, 'must be up to 200 characters')
              .required('Required'),
            answer: yup.object({
              correct: yup.boolean(),
            }),
          }),
        )
      }
    }),
})

export const defaultCoursePackageSchema = yup.object().shape({
  name: yup
    .string()
    .max(50, 'must be up to 50 characters')
    .required('Please Enter Name'),
  description: yup.string().max(500, 'must be up to 500 characters'),
  type: yup
    .array()
    .of(yup.string().oneOf(Object.values(COURSE_TYPES)))
    .min(1, 'Please select type')
    .required('Please select type'),
  disciplineIds: yup.array().of(yup.number()).nullable(),
  format: yup
    .string()
    .oneOf(Object.values(CoursePackageFormatsEnum))
    .required(),
})

export const surveySchema = yup.object().shape({
  translations: yup.array().when('status', {
    is: (status: SurveyStatusTypes) =>
      [SurveyStatusTypes.SCHEDULED, SurveyStatusTypes.SENT].includes(status),
    then: schema =>
      schema.of(
        yup.object({
          name: yup
            .string()
            .required('Please Enter Name')
            .max(100, 'Title should be under 100 characters'),
          title: yup.string().required('Please Enter Notification Title'),
          description: yup
            .string()
            .required('Please Enter Description')
            .max(2000, 'Description should be under 2000 characters'),
          message: yup
            .string()
            .required('Please Enter Notification Message')
            .nullable(),
          languageId: yup.number().required(),
        }),
      ),
    otherwise: schema =>
      schema.of(
        yup.object({
          name: yup
            .string()
            .required('Please Enter Name')
            .max(100, 'Title should be under 100 characters'),
          title: yup.string().optional().nullable(),
          description: yup
            .string()
            .max(2000, 'Description should be under 2000 characters')
            .optional()
            .nullable(),
          message: yup.string().optional().nullable(),
          languageId: yup.number().required(),
        }),
      ),
  }),
  sendDate: yup
    .date()
    .nullable()
    .when('status', {
      is: (status: SurveyStatusTypes) => status === SurveyStatusTypes.SCHEDULED,
      then: schema =>
        schema
          .required('Please select Schedule Date and Time')
          .min(new Date(), 'Schedule Date and Time must be in the future')
          .typeError('Please select Schedule Date and Time'),
      otherwise: schema => schema.optional(),
    }),
  deadline: yup
    .date()
    .nullable()
    .when('status', {
      is: (status: SurveyStatusTypes) =>
        [SurveyStatusTypes.SCHEDULED, SurveyStatusTypes.SENT].includes(status),
      then: schema =>
        schema
          .required('Please select Deadline')
          .min(new Date(), 'Deadline must be in the future')
          .typeError('Please select Deadline'),
      otherwise: schema => schema.optional(),
    }),
  recipients: yup
    .array()
    .nullable()
    .when('status', {
      is: (status: SurveyStatusTypes) =>
        [SurveyStatusTypes.SCHEDULED, SurveyStatusTypes.SENT].includes(status),
      then: schema => schema.min(1, 'Please select at least one recipient.'),
      otherwise: schema => schema.optional(),
    }),
  questions: yup.array().of(
    yup.object({
      type: yup.mixed().oneOf(Object.values(QuestionTypes)),
      questions: yup.array().of(
        yup.object().shape({
          question: yup
            .string()
            .max(200, 'must be up to 200 characters')
            .required('Required'),
          // answers: yup.array().of(
          //   yup.object({
          //     answer: yup.string().nullable().required('Required'),
          //     correct: yup.boolean(),
          //   })
          // )
        }),
      ),
    }),
  ),
})

export const companiesSchema = yup.object().shape({
  name: yup.string().required('Please Enter Name'),
  address: yup.string().required('Please Enter Address'),
  city: yup.string().required('Please Enter City'),
  state: yup.object().required('Please Enter State'),
  notes: yup.string().nullable(),
  accountContact: yup.object({
    name: yup.string().required('Please Enter Name'),
    email: yup
      .string()
      .email('Invalid email format')
      .required('Please Enter Email'),
    phone: yup
      .string()
      .matches(/^\d{10}$/, 'Phone must be exactly 10 digits')
      .required('Please Enter phone'),
  }),
  billingContact: yup.object({
    name: yup.string().nullable(),
    email: yup.string().email('Invalid email format').nullable(),
    phone: yup
      .string()
      .matches(/^\d{10}$/, 'Phone must be exactly 10 digits')
      .nullable(),
  }),
})

const confirmationSchema = yup.object({
  scheduleOnboarding: yup.boolean(),
  onboardingDate: yup.date().when('scheduleOnboarding', {
    is: true,
    then: yup.date().required('Onboarding Date is required'),
    otherwise: yup.date().nullable(),
  }),
  onboardingTime: yup.date().when('scheduleOnboarding', {
    is: true,
    then: yup.date().required('Onboarding Time is required'),
    otherwise: yup.date().nullable(),
  }),
  confirmation: yup
    .object({
      scheduleFacilityTour: yup.boolean(),
      facilityTourScheduleId: yup
        .object({
          value: yup.number().nullable(),
          label: yup.string().nullable(),
        })
        .nullable(),
      facilityTourDate: yup.date().nullable(),
      facilityTourStartTime: yup.date().nullable(),
      facilityTourEndTime: yup.date().nullable(),
    })
    .test(
      'facility-tour-validation',
      'Select facility tour schedule or add a new one',
      function (_, context) {
        const {
          scheduleFacilityTour,
          facilityTourSchedule,
          facilityTourDate,
          facilityTourStartTime,
          facilityTourEndTime,
        } = context.parent
        if (scheduleFacilityTour) {
          const hasScheduleId = !!facilityTourSchedule?.value
          const hasDateAndTime =
            !!facilityTourDate &&
            !!facilityTourStartTime &&
            !!facilityTourEndTime
          if (!hasScheduleId && !hasDateAndTime) {
            return this.createError({
              path: `confirmation.scheduleFacilityTour`,
            })
          }
        }
        return true
      },
    ),
})

export const addNewUserSchema = yup.object({
  user: yup.object({
    email: yup
      .string()
      .email('Invalid email format')
      .required('Email is required'),
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    dob: yup
      .string()
      .required('Date of Birth is required')
      .test('is-valid-dob', 'Invalid date of birth', function (value) {
        if (!value) {
          return this.createError({ message: 'Date of Birth is required' })
        }

        const formatRegex = /^\d{2}\/\d{2}\/\d{4}$/
        if (!formatRegex.test(value)) {
          return this.createError({
            message: 'Date of Birth should have mm/dd/yyyy format',
          })
        }

        const date = moment(value, 'MM/DD/YYYY', true)

        if (!date.isValid()) {
          return this.createError({
            message: 'Date of Birth must be a valid date',
          })
        }

        if (date.isSameOrAfter(moment(), 'day')) {
          return this.createError({
            message: 'Date of Birth must be less than today',
          })
        }

        return true
      }),
    position: yup.object().required('Position is required'),
    facility: yup.object().required('Facility is required'),
    department: yup.object().required('Department is required'),
    phone: yup
      .string()
      .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
  }),
  packages: yup.object({
    expirationDate: yup.date().when('data', {
      is: (data: unknown[]) => Array.isArray(data) && data.length > 0,
      then: yup.date().required('Please choose expiration date'),
      otherwise: yup.date().nullable(),
    }),
  }),
  confirmation: confirmationSchema,
})

export const assignCoursesSchema = yup.object({
  confirmation: confirmationSchema,
})

export const courseTranslationsSchema = yup.object().shape({
  content: yup.object().shape({
    name: yup.object().shape({
      value: yup.string().max(100, 'must be at most 100 characters').nullable(),
      approved: yup.boolean(),
    }),
    description: yup.object().shape({
      value: yup
        .string()
        .max(2000, 'must be at most 2000 characters')
        .nullable(),
      approved: yup.boolean(),
    }),
    objective: yup.object().shape({
      value: yup
        .string()
        .max(1000, 'must be at most 1000 characters')
        .nullable(),
      approved: yup.boolean(),
    }),
  }),
})

export const lessonsTranslationsSchema = yup.object({
  lessons: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        rawTranslation: yup.object({
          title: yup
            .object({
              value: yup.string().nullable(),
              approved: yup.boolean().required(),
            })
            .required(),
        }),
        knowledgeChecks: yup
          .array()
          .of(
            yup.object({
              rawTranslation: yup
                .object({
                  approved: yup.boolean().required(),
                })
                .nullable(),
            }),
          )
          .optional(),
      }),
    )
    .required('Lessons are required'),
})

export const ceProviderSchema = yup.object({
  providerNumber: yup.string().required('Provider Number is required'),
  board: yup.string().required('Board is required'),
  credentials: yup.string().required('Credentials are required'),
  disciplines: yup.array().required(),
  states: yup.array().required(),
  signatoryName: yup
    .string()
    .matches(
      /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/,
      'Signatory First Name and Last Name are required',
    )
    .required('Signatory Name is required'),
  signatoryTitle: yup.string().required('Signatory Title is required'),
  signature: yup
    .mixed()
    .nullable()
    .test('valid-size', 'Max allowed size is 1mb', value => {
      if (!value) {
        return true
      }
      return value.size <= 1000000
    }),
  status: yup.string().required(),
})
