import { TInstructor } from '../models'
import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import {
  CalendarPermissions,
  CoursesPermissions,
  OrderType,
  SettingsPermissions,
} from '../index'

type InstructorPermissions =
  | SettingsPermissions.VIEW_INSTRUCTOR_LISTING
  | CalendarPermissions.VIEW_CALENDAR
  | CoursesPermissions.ADD_EDIT_COURSE

export namespace GetInstructorsDTO {
  export interface Request extends PaginationRequest {
    orderBy?: OrderType
    permission?: InstructorPermissions
  }

  export interface Response extends PaginatedBaseResponse {
    instructors: TInstructor[]
  }
}

export namespace CreateInstructorDTO {
  export interface Request extends Omit<TInstructor, 'photo'> {}
  export interface Response extends TInstructor {}
}

export type InstructorModalForm = {
  id: number
  name: string
  bio: string
  photo: string | Blob | undefined
  qualification: string
}

export type SelectOption = {
  value: string | number
  label: string
}
