import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import UsersTable, {
  AssignCourse,
  Departments,
  UserId,
  UserInfo,
} from '../../../Components/Common/UsersTable'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  GetUsersDTO,
  OrderType,
  TUser,
  USER_COURSE_STATUSES,
  USER_REGISTERED_STATUSES,
  USER_STATUSES,
  UserManagementPermissions,
  UsersSearchSortBy,
  UsersSortBy,
} from '../../../sharedTypes'
import { getUsers } from '../../../helpers/api/users'
import { handleError } from '../../../helpers/toast_helper'
import { TabProps, Tabs } from '../types'
import { useAppSelector } from '../../../hooks/redux'
import _ from 'lodash'
import moment from 'moment/moment'
import AssignCourseModal from '../../../Components/Modals/AssignCourseModal'
import { usePermissions } from '../../../hooks/usePermissions'

const Columns = [
  {
    title: 'ID',
    id: 'id',
    component: UserId,
    sortBy: UsersSortBy.ID,
    style: { width: '10%' },
  },
  {
    title: 'User',
    id: 'user',
    component: UserInfo,
    sortBy: UsersSortBy.USER,
    style: { width: '17%' },
  },
  {
    title: 'Facility',
    id: 'facility',
    sortBy: UsersSortBy.FACILITY_NAME,
    style: { width: '20%' },
    selector: 'facility.name',
  },
  {
    title: 'Department',
    id: 'department',
    component: Departments,
    style: { width: '20%' },
  },
  {
    title: 'Account Creation Date',
    id: 'account-creation-date',
    sortBy: UsersSortBy.CREATION_DATE,
    selector: 'createdAt',
    format: (value: string) => moment(value).format('MM/DD/YYYY'),
    style: { width: '20%', minWidth: 130 },
  },
  {
    title: 'Registration Date',
    id: 'sign-up-date',
    sortBy: UsersSortBy.REGISTRATION_DATE,
    selector: 'signupDate',
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
    style: { width: '20%' },
  },
]

const UsersWithNoCourses = ({ setTotalCounts, includeAgency }: TabProps) => {
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )
  const user = useAppSelector(state => state.User.user)

  const containerRef = useRef<HTMLDivElement>(null)
  const scrollPositionRef = useRef(0)

  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [assignModal, setAssignModal] = useState<TUser | null>(null)
  const [data, setData] = useState<GetUsersDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    users: [],
  })

  const [query, setQuery] = useState<GetUsersDTO.Request>({
    page: 1,
    limit: 30,
    facilityIds: [],
    departmentIds: [],
    positionIds: [],
    courseStatus: USER_COURSE_STATUSES.NO_COURSES,
    sortBy: UsersSortBy.ID,
    orderBy: OrderType.ASC,
    permission: UserManagementPermissions.VIEW_USER_LISTING,
    registeredStatuses: [USER_REGISTERED_STATUSES.Yes],
    status: [USER_STATUSES.ACTIVE, USER_STATUSES.UNVERIFIED],
  })

  const permissionToAssign = usePermissions(
    UserManagementPermissions.ASSIGN_COURSE_TO_USER,
  )

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      getUsers({ ...query, includeAgencyEmployees: !!includeAgency })
        .then(res => {
          setData({
            users:
              query.page === 1
                ? res.data.users
                : [...data.users, ...res.data.users],
            page: res.data.page,
            pages: res.data.pages,
            count: res.data.count,
          })
          if (query.page === 1) {
            setTotalCounts(prev => ({
              ...prev,
              [Tabs.NO_COURSES]: res.data.count,
            }))
          }
          afterScroll()
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  useEffect(() => {
    if (selectedGroupId && !_.isEqual([selectedGroupId], query.groupIds)) {
      setQuery(q => ({ ...q, groupIds: [selectedGroupId], facilityIds: [] }))
    }

    if (
      selectedFacilityId &&
      !_.isEqual([selectedFacilityId], query.facilityIds)
    ) {
      setQuery(q => ({ ...q, facilityIds: [selectedFacilityId], groupIds: [] }))
    }

    if (!selectedFacilityId && !selectedGroupId) {
      setQuery(q => ({ ...q, facilityIds: [], groupIds: [] }))
    }

    setFetchData(true)
  }, [selectedFacilityId, selectedGroupId, includeAgency])

  const handleSort = useCallback((column: UsersSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
      page: 1,
    }))
  }, [])

  const columns = useMemo(() => {
    let _columns = [...Columns]
    const userHasSingleFacility =
      user && user?.isFacilityAdmin && !user?.hasAccessToMultipleFacilities

    if (userHasSingleFacility || selectedFacilityId) {
      _columns = _columns.filter(column => column.title !== 'Facility')
    }

    if (permissionToAssign) {
      return [
        ..._columns,
        {
          title: '',
          id: 'action',
          component: AssignCourse,
          onClick: (user: TUser) => setAssignModal(user),
          style: { width: '12%' },
        },
      ]
    }

    return _columns
  }, [user, selectedFacilityId, permissionToAssign])

  const afterScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current
    }
  }

  const handleScroll = () => {
    if (containerRef.current) {
      scrollPositionRef.current = containerRef.current.scrollTop

      const { scrollTop, clientHeight, scrollHeight } = containerRef.current
      if (
        scrollHeight - (scrollTop + clientHeight) <= 1 &&
        query.page !== data.pages
      ) {
        // Load more data when reaching the bottom
        setQuery(prev => ({ ...prev, page: (query.page as number) + 1 }))
      }
    }
  }
  return (
    <>
      <NoResultTableWrapper
        isLoading={isLoading}
        isFiltering={false}
        pages={data.pages}
      >
        <div
          ref={containerRef}
          style={{ overflowY: 'auto' }}
          className='scroll-not-visible'
          id='dashboard-not-registered-users'
          onScroll={handleScroll}
        >
          <UsersTable
            columns={columns}
            users={data.users}
            sortedColumn={query.sortBy}
            page={data.page - 1}
            sortOrder={query.orderBy}
            totalPages={data.pages}
            onPageChanged={page => {
              setQuery(prev => ({ ...prev, page: ++page }))
            }}
            onLimitChange={limit => {
              setQuery(prev => ({ ...prev, limit }))
            }}
            itemsPerPage={query.limit ? query.limit : 10}
            limit={query.limit}
            handleSort={
              handleSort as (column: UsersSortBy | UsersSearchSortBy) => void
            }
            totalUsers={data.count}
            globalSearch={query.key || ''}
            showPagination={false}
            id='users-with-no-courses-table'
          />
        </div>
      </NoResultTableWrapper>
      {permissionToAssign && assignModal && (
        <AssignCourseModal
          isOpen={!!assignModal}
          onClose={() => setAssignModal(null)}
          onSubmit={() => {}}
          user={assignModal}
        />
      )}
    </>
  )
}

export default UsersWithNoCourses
