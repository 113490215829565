import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { Col, Row, CardBody } from 'reactstrap'
import { OnlineCourseFormatsEnum } from '../../../../sharedTypes'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import { handleError } from '../../../../helpers/toast_helper'
import { CourseLayout } from '../../../../Components/Course'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../../helpers/toast_helper'
import { CourseContext } from '../../../../context/CourseContext'
import { useShouldGoTo } from '../../../../hooks/course/useShouldGoTo'
import Dropzone from 'react-dropzone'
import Lessons from '../Lessons/index'
import {
  createLesson,
  deleteLesson,
  getLessons,
  getPresignedUrl,
  updateLessonsOrder,
} from '../../../../helpers/api/lessons'
import _ from 'lodash'
import { getMediaDetails } from '../../../../helpers/common'
import formatBytes from '../../../../utils/formatBytes'
import DeleteConfirmation from '../../../../Components/Modals/DeleteConfirmation'
import { ILesson } from '../Lessons/types'
import axios from 'axios'

const VideoCourse = ({ router }: WithRouterProps) => {
  const {
    course,
    setCourseId,
    onNavigate,
    setLoading,
    loading,
    onRemoveFormat,
    onBack,
    goNext,
    deleteFormatModal,
    tabs,
    setDeleteFormatModal,
    isPublished,
    setUploadedCourseLessonsData,
    uploadedCourseLessonsData,
    updateLessonProgress,
    updateLessonFiles,
  } = useContext(CourseContext)
  const { setShouldGoTo } = useShouldGoTo({ goNext, onBack })

  const [lessons, setLessons] = useState<ILesson[]>([])
  const [lessonToDelete, setLessonToDelete] = useState<ILesson | null>(null)

  const allowToDeleteDigitalSettings = useMemo(
    () =>
      !course?.originalCourse ||
      !course.originalCourse?.formats.includes(OnlineCourseFormatsEnum.VIDEO),
    [course],
  )

  const disableActions = useMemo(
    () =>
      uploadedCourseLessonsData.length
        ? uploadedCourseLessonsData.some(data => data.fileId === null)
        : false,
    [uploadedCourseLessonsData],
  )

  useEffect(() => {
    if (course) {
      fetchLessons()
    }
  }, [course])

  useEffect(() => {
    setCourseId(Number(router.params.courseId))
  }, [router.params.courseId])

  const uploadFileToS3 = async (
    url: string,
    file: File,
    lessonId: number,
    fileId: number,
  ) => {
    return axios.put(url, file, {
      headers: { 'Content-Type': file.type, Accept: 'application/json' },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100,
        )
        updateLessonProgress(lessonId, progress)

        if (progressEvent.loaded === progressEvent.total) {
          updateLessonFiles(lessonId, fileId)
        }
      },
    })
  }

  const handleUploadLessons = async (files: File[]) => {
    if (files.length && course) {
      setLoading(true)
      const lessonsData = await Promise.all(
        files.map(async (file, index) => {
          const formData = new FormData()
          formData.append('file', file)

          try {
            const lesson = await createLesson(course.id)
            return {
              file: Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
              }),
              isOpen: index === 0,
              progress: 0,
              title: '',
              order: null,
              knowledgeChecks: [],
              id: lesson.data.id,
            }
          } catch (error) {
            handleError(error)
            setLoading(false)
            return null
          }
        }),
      )

      const successfulLessonsData = lessonsData.filter(
        lesson => lesson !== null,
      )

      if (successfulLessonsData.length) {
        setLessons(prevLessons => [
          ...prevLessons,
          ...(successfulLessonsData as ILesson[]),
        ])

        const lessonFilesData = successfulLessonsData.map(lesson => ({
          id: lesson?.id as number,
          fileId: null,
        }))

        setUploadedCourseLessonsData(lessonFilesData)
        setLoading(false)
      }

      const promises = successfulLessonsData.map(lesson => {
        if (!lesson) {
          return Promise.resolve()
        }
        const { name: fileName, type: fileType, size: fileSize } = lesson.file
        return getPresignedUrl({
          fileName,
          fileType,
          fileSize,
        })
          .then(({ presignedUrl, fileId }) => {
            return uploadFileToS3(presignedUrl, lesson.file, lesson.id, fileId)
          })
          .catch(handleError)
      })

      Promise.all(promises)
        .then(() => {})
        .catch(handleError)
    }
  }

  const fetchLessons = () => {
    if (course?.id) {
      getLessons({ courseId: course.id })
        .then(data => {
          const lessonsData = data.lessons.map((lesson, i) => ({
            title: _.get(lesson, 'translations[0].content.title', ''),
            fileId: lesson.fileId,
            file: getMediaDetails(lesson.file),
            isOpen: i === 0,
            id: lesson.id,
            order: lesson.order,
            knowledgeChecks: lesson.knowledgeChecks,
          }))

          setLessons(lessonsData)
        })
        .catch(handleError)
    }
  }

  const onReOrder = useCallback(
    (result: any) => {
      if (!result.destination || !course?.id) {
        return
      }

      const lessonsData = [...lessons]

      const [reorderedItem] = lessonsData.splice(result.source.index, 1)

      lessonsData.splice(result.destination.index, 0, reorderedItem)

      lessonsData.forEach((lesson, index: number) => {
        lesson.order = index + 1
      })

      const updatedOrder = lessonsData.map(({ id, order }) => ({
        lessonId: id as number,
        order,
      }))

      updateLessonsOrder({
        data: updatedOrder,
      })
        .then(() => {
          fetchLessons()
        })
        .catch(handleError)
    },
    [lessons],
  )

  const handleDelete = useCallback(async () => {
    try {
      if (lessonToDelete?.id && course?.id) {
        await deleteLesson(lessonToDelete.id)
        toast(`Lesson has been successfully deleted!`, successToastOptions)
        setLessonToDelete(null)

        fetchLessons()
      }
    } catch (e) {
      handleError(e)
    }
  }, [lessons, lessonToDelete])

  return (
    <div>
      <CourseLayout
        backText='Back'
        leftSideBar={!!course?.digital?.file}
        showSaveAsDraft={!lessons.length}
        viewOnly={loading || disableActions}
        onNavigate={direction => {
          onNavigate(direction)
        }}
        router={router}
        tabs={tabs}
        onBack={() => {
          onBack()
        }}
        onRemoveFormat={onRemoveFormat}
        setDeleteFormatModal={setDeleteFormatModal}
        deleteFormatModal={deleteFormatModal}
        isDisabled={disableActions}
        course={course}
        onNext={() => {
          goNext()
        }}
        onSaveAsDraft={() => {
          setShouldGoTo({ direction: '/courses' })
        }}
        classes={{
          cardBody: 'digital-course-container',
        }}
      >
        <Row className='w-100'>
          <div className='d-flex justify-content-end'>
            <div className='w-auto'>
              {!isPublished && allowToDeleteDigitalSettings && (
                <span
                  className='text-danger cursor-pointer d-flex gap-2 justify-content-end'
                  onClick={() => {
                    setDeleteFormatModal({
                      title: 'Delete Video Settings',
                      message:
                        'Are you sure you want to delete Digital Settings?',
                      format: OnlineCourseFormatsEnum.VIDEO,
                    })
                  }}
                >
                  <b>Remove Video Settings</b>{' '}
                  <i className='bx bx-trash text-danger cursor-pointer	'></i>
                </span>
              )}
            </div>
          </div>

          <>
            {lessons.length ? (
              <Lessons
                courseId={course?.id as number}
                handleUploadLessons={handleUploadLessons}
                lessons={lessons}
                onReOrder={onReOrder}
                handleDelete={setLessonToDelete}
              />
            ) : (
              <>
                {!loading ? (
                  <Row className='mb-5'>
                    {!lessons.length && !course?.digital?.file && (
                      <Col className='rounded'>
                        <div style={{ height: 700 }}>
                          <label htmlFor='upload-video' className='form-label'>
                            Upload a Video
                          </label>
                          <Dropzone
                            multiple={true}
                            accept={{
                              'video/mp4': [],
                            }}
                            onDrop={acceptedFiles => {
                              handleUploadLessons(acceptedFiles)
                            }}
                          >
                            {({ getRootProps }) => (
                              <div
                                className='dropzone dz-clickable cursor-pointer h-100 d-flex align-items-center'
                                style={{ minHeight: '100%' }}
                              >
                                <div
                                  className='dz-message needsclick h-100 d0flex align-items-center justify-content-center'
                                  {...getRootProps()}
                                >
                                  <div className='h-100 d-flex flex-column justify-content-center'>
                                    <div className='mt-2'>
                                      <i className='display-7 text-muted ri-upload-cloud-2-fill fs-25' />
                                    </div>
                                    <p className='m-0 text-muted fw-light fs-14'>
                                      Drop file here or click to upload.
                                      <br></br>
                                      (mp4)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </Col>
                    )}
                  </Row>
                ) : (
                  <CardBody>
                    <div className='d-flex flex-column align-items-center py-5'>
                      <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Uploading...
                      </p>
                    </div>
                  </CardBody>
                )}
              </>
            )}
          </>

          {lessonToDelete && (
            <DeleteConfirmation
              isOpen={!!lessonToDelete}
              onClose={() => {
                setLessonToDelete(null)
              }}
              title='Delete Lesson'
              message={`Are you sure you want to delete lesson "${lessonToDelete?.title}"?`}
              onDelete={handleDelete}
            />
          )}
        </Row>
      </CourseLayout>
    </div>
  )
}
export default withRouter(VideoCourse)
