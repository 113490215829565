import React, { useMemo } from 'react'
import FilterTabs, { FilterTabsProps } from '../Common/FilterTabs'
import { CoursesTabs, CoursesTabsWithoutCE } from '../../sharedTypes'
import { v4 as uuidv4 } from 'uuid'
import useIsMasteredAdmin from '../../hooks/useIsMasteredAdmin'

const tabs = [
  {
    label: 'Courses',
    value: CoursesTabs.COURSES,
    info: 'All individual courses available to be assigned to users.',
  },
  {
    label: 'Packages',
    value: CoursesTabs.PACKAGES,
    info: 'A number of courses that can be assigned to users together.',
  },
  {
    label: 'CE Courses',
    value: CoursesTabs.CE_COURSES,
    info: 'Continuing Education Courses.',
  },
  {
    label: 'Training Days',
    value: CoursesTabs.TRAINING_DAYS,
    info: 'Live sessions taking place in one day that can be assigned to users together.',
  },
]

interface CoursesTabsProps
  extends Pick<FilterTabsProps<CoursesTabs>, 'extraTabData'> {
  activeTab: CoursesTabs | CoursesTabsWithoutCE
  onTabChange: (tab: CoursesTabs | CoursesTabsWithoutCE) => void
  forAssignment?: boolean
}

const CourseTabs = ({
  activeTab,
  onTabChange,
  extraTabData,
  forAssignment = false,
}: CoursesTabsProps) => {
  const isMasteredAdmin = useIsMasteredAdmin()

  const memoizedTabs = useMemo(() => {
    return tabs
      .filter(item =>
        forAssignment
          ? item.value !== CoursesTabs.CE_COURSES
          : isMasteredAdmin
          ? item.value !== CoursesTabs.TRAINING_DAYS
          : item.value !== CoursesTabs.CE_COURSES,
      )
      .map(item => ({
        ...item,
        id: uuidv4(),
      }))
  }, [isMasteredAdmin, forAssignment])

  return (
    <FilterTabs<CoursesTabs | CoursesTabsWithoutCE>
      tabs={memoizedTabs}
      navTab={activeTab}
      navToggle={_activeTab => {
        if (_activeTab && _activeTab !== activeTab) {
          onTabChange(_activeTab)
        }
      }}
      extraTabData={extraTabData}
    />
  )
}

export default CourseTabs
