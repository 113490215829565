import {
  FormikErrors,
  FormikHandlers,
  FormikHelpers,
  FormikTouched,
} from 'formik'
import { COURSE_TYPES, FilterOption } from '../../../sharedTypes'
import { TCoursePackage } from '../../../sharedTypes/models/coursePackage'
import { Dispatch, SetStateAction } from 'react'
import { ConfirmationScheduleDetails } from '../AssignCourseModal/types'

export interface IStep2Data {
  page: number
  data: TCoursePackage[]
}

export const initialPackagesData: IStep2Data = {
  page: 0,
  data: [],
}

export interface FormValues {
  user: {
    dob?: string
    firstName: string
    lastName: string
    position?: FilterOption
    facility?: FilterOption
    email: string
    phone: string
    department?: FilterOption
  }
  packages: {
    data?: TCoursePackage[]
    expirationDate?: string
  }
  confirmation: ConfirmationScheduleDetails
}

interface StepProps<T> {
  values: T
  touched: FormikTouched<T>
  errors: FormikErrors<T>
  setFieldValue: FormikHelpers<FormValues>['setFieldValue']
  setFieldTouched: FormikHelpers<FormValues>['setFieldTouched']
  onCancel: () => void
  onNext: () => void
  isValid: boolean
}

export interface Step1Props extends StepProps<FormValues['user']> {
  handleBlur: FormikHandlers['handleBlur']
  handleChange: FormikHandlers['handleChange']
  setFieldError: FormikHelpers<FormValues>['setFieldError']
}

export interface Step2Props extends StepProps<FormValues['packages']> {
  filters: Step2Filters
  setFilters: Dispatch<SetStateAction<Step2Filters>>
  isLoading: boolean
  packagesData: IStep2Data
}

export interface Step3Props
  extends Omit<
    StepProps<FormValues['confirmation']>,
    'touched' | 'setFieldTouched'
  > {
  isLoading: boolean
  facilityId: number
}

export interface Step1PassedProps {
  userData: FormValues['user']
}

export interface Step2Filters {
  limit: number
  key?: string
  disciplineIds?: number[]
  type?: COURSE_TYPES[]
}

export interface Step2PassedProps {
  packages: TCoursePackage[]
}

export interface Option {
  value: number
  label: string
}
