import BreadCrumb from '../../../Components/Common/BreadCrumb'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'reactstrap'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  ASSIGNMENT_STATUS,
  OrderType,
  ReportingPermissions,
} from '../../../sharedTypes'
import {
  CoursesAssignmentsSortBy,
  GetCoursesAssignmentsDTO,
} from '../../../sharedTypes/api/courseHistory'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { getCoursesAssignments } from '../../../helpers/api/assignments'
import { handleError } from '../../../helpers/toast_helper'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import { Pagination } from '../../../Components/Common/Pagination'
import UserCell from '../../../Components/Common/UserCellWithDetails'
import { onPrint } from '../../../helpers/common'
import _ from 'lodash'
import { useAppSelector } from '../../../hooks/redux'
import UserAssociations from '../../../Components/Common/UserAssociations'
import ItemsPerPageDropdown from '../../../Components/Common/ItemsPerPageDropdown'
import { EXPORT_TYPES, SelectedFilters } from './types'
import ExportExpiredCoursesReport from './ExportExpiredCoursesReport'
import Filters from '../CourseHistory/Filters'

const Columns = [
  {
    name: 'User',
    sortBy: CoursesAssignmentsSortBy.USER,
    style: { width: 220 },
  },
  {
    name: 'Department',
    style: { width: 245 },
  },
  {
    name: 'Agency',
    sortBy: CoursesAssignmentsSortBy.AGENCY_NAME,
    style: {
      width: window.innerWidth <= 1800 ? 170 : 245,
      display: window.innerWidth > 1700 ? 'table-cell' : 'none',
    },
  },
  {
    name: 'Code',
    sortBy: CoursesAssignmentsSortBy.CODE,
    style: { width: 'auto', minWidth: 100 },
  },
  {
    name: 'Package',
    sortBy: CoursesAssignmentsSortBy.PACKAGE,
  },
  {
    name: 'Course',
    sortBy: CoursesAssignmentsSortBy.COURSE,
  },
  {
    name: 'Due Date',
    sortBy: CoursesAssignmentsSortBy.DUE_DATE,
    style: { minWidth: 110 },
  },
  {
    name: 'Date Expired',
    sortBy: CoursesAssignmentsSortBy.EXPIRATION_DATE,
    style: { minWidth: 110 },
  },
]

const ExpiredCoursesReport = () => {
  document.title = 'Expired Courses Report | Mastered - Admin & Dashboard'

  const admin = useAppSelector(state => state.User.user)
  const [showFilters, setShowFilters] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [exportType, setExportType] = useState<EXPORT_TYPES | null>(null)
  const [fetchData, setFetchData] = useState(false)
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    positions: [],
    facilities: [],
    groups: [],
    departments: [],
    courses: [],
  })

  const [query, setQuery] = useState<GetCoursesAssignmentsDTO.Request>({
    page: 1,
    limit: 10,
    status: [ASSIGNMENT_STATUS.EXPIRED],
    permission: ReportingPermissions.VIEW_EXPIRED_COURSES_REPORT,
    facilityIds: [],
  })

  const [data, setData] = useState<GetCoursesAssignmentsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    assignments: [],
  })

  const isMounted = useRef(false)

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      const _query = _.omitBy(query, _.isNil)
      if (!_.isEmpty(_query.dueDate)) {
        _query.dueDate = [
          moment(_query.dueDate[0]).format('YYYY-MM-DD'),
          moment(_query.dueDate[1]).format('YYYY-MM-DD'),
        ]
      }
      if (!_.isEmpty(_query.expiredDate)) {
        _query.expiredDate = [
          moment(_query.expiredDate[0]).format('YYYY-MM-DD'),
          moment(_query.expiredDate[1]).format('YYYY-MM-DD'),
        ]
      }
      getCoursesAssignments(_query as GetCoursesAssignmentsDTO.Request)
        .then(d => d.data)
        .then(res => {
          setData(res)
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsLoading(false)
          setFetchData(false)
        })
    }
  }, [fetchData])

  useEffect(() => {
    if (isMounted.current) {
      setFetchData(true)
    } else {
      isMounted.current = true
    }
  }, [query])

  const handleSort = useCallback((column: CoursesAssignmentsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={'Expired Courses Report'}
            items={[
              {
                active: true,
                title: 'Reporting',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      <Dropdown
                        isOpen={exportDropdownOpen}
                        toggle={() => {
                          setExportDropdownOpen(prevState => !prevState)
                        }}
                      >
                        <DropdownToggle
                          className=' btn btn-secondary align-middle'
                          tag='button'
                        >
                          <i className='mdi mdi-file-export-outline me-1 fs-16'></i>
                          Export
                        </DropdownToggle>
                        <DropdownMenu end>
                          {Object.values(EXPORT_TYPES).map(type => (
                            <DropdownItem key={type}>
                              <span
                                className={`mx-2 ${
                                  type === EXPORT_TYPES.PDF
                                    ? 'text-green-500'
                                    : ''
                                }`}
                                onClick={() => setExportType(type)}
                              >
                                Export as {type}
                              </span>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => onPrint('expired-courses-section')}
                      >
                        <i className='ri-printer-line me-1 fs-16'></i>
                      </Button>
                    </div>
                  </div>

                  <Row
                    className={`filters-row gap-4 ${
                      showFilters ? 'my-3' : 'my-0'
                    } mx-0`}
                  >
                    <Filters
                      visible={showFilters}
                      setFilters={data => {
                        setQuery(prev => ({
                          ...prev,
                          facilityIds: data.facility,
                          agencyIds: data.agencies,
                          groupIds: data.group,
                          positionIds: data.position,
                          departmentIds: data.department,
                          courseIds: data.course,
                          dueDate: data.dueDate,
                          expiredDate: data.expiredDate,
                          page: 1,
                        }))
                        if (!fetchData) {
                          setFetchData(true)
                        }
                      }}
                      filterBy={{
                        status: false,
                        courseType: false,
                        dueDate: true,
                        expiredDate: true,
                      }}
                      setSelectedFilters={setSelectedFilters}
                    />
                  </Row>
                </CardHeader>

                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <div className='overflow-auto'>
                      <Table
                        className='align-middle mb-0'
                        id='expired-courses-section'
                      >
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {Columns.map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.name}
                              >
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex align-items-center justify-content-center'>
                                    <span className='header-name'>
                                      {column.name}
                                    </span>
                                    {!!column.sortBy && (
                                      <ColumnSortIcon<CoursesAssignmentsSortBy>
                                        sortOrder={query.orderBy}
                                        sortedColumn={query.sortBy}
                                        column={column.sortBy}
                                        handleSort={handleSort}
                                      />
                                    )}{' '}
                                  </div>
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.assignments.map((assignment, i) => (
                            <tr key={i} className='fs-14'>
                              <UserCell
                                user={assignment.user}
                                globalSearch={query.key || ''}
                                displayFacility={
                                  !admin?.isFacilityAdmin ||
                                  admin?.hasAccessToMultipleFacilities
                                }
                              />

                              <UserAssociations
                                data={assignment.user.departments || []}
                                key={query.key || ''}
                              />

                              <td className='agency-col'>
                                <span className='two-line-limit'>
                                  {assignment.user.agency
                                    ? assignment.user.agency.name
                                    : ''}
                                </span>
                              </td>

                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  className={'text-muted'}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={assignment.code}
                                />
                              </td>
                              <td>
                                <span className='text-muted'>
                                  {assignment.package ? (
                                    <Highlighter
                                      highlightClassName='text-highlight'
                                      searchWords={[query.key || '']}
                                      highlightTag={'span'}
                                      autoEscape={true}
                                      textToHighlight={assignment.package.code}
                                    />
                                  ) : (
                                    '-'
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className={'two-line-limit'}>
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={
                                      assignment.course?.translations[0].content
                                        .name ||
                                      assignment.package?.name ||
                                      '-'
                                    }
                                  />
                                </span>
                              </td>
                              <td>
                                <span className='text-muted fs-14'>
                                  {moment(assignment.dueDate).format(
                                    'MM/DD/YYYY',
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className='text-muted fs-14'>
                                  {assignment.expiredDate
                                    ? moment(assignment.expiredDate).format(
                                        'MM/DD/YYYY',
                                      )
                                    : ''}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className='mx-3 my-3'>
                      <ItemsPerPageDropdown
                        limit={query.limit ? query.limit : 10}
                        onChange={limit =>
                          setQuery(prev => ({ ...prev, limit: +limit.label }))
                        }
                      />
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                        limit={query.limit}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
        {exportType && (
          <ExportExpiredCoursesReport
            exportType={exportType}
            setExportType={setExportType}
            count={data.count}
            query={query}
            selectedFilters={selectedFilters}
          />
        )}
      </div>
    </Fragment>
  )
}

export default ExpiredCoursesReport
