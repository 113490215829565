import React from 'react'
import { Card, Col, Row, Label } from 'reactstrap'
import { Button } from 'reactstrap'
import Dropzone, { FileRejection, DropEvent } from 'react-dropzone'
import { Link } from 'react-router-dom'
import { TAttachment } from '../../sharedTypes/models'

export const SignatureUpload = ({
  onDrop,
  onDelete,
  isSubmitting,
  signature,
  error,
  isRequired = false,
}: {
  onDrop:
    | (<T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent,
      ) => void)
    | undefined
  onDelete: () => void
  error?: string
  isSubmitting: boolean
  signature: TAttachment | Blob | undefined
  isRequired?: boolean
}) => {
  return (
    <div>
      <Label htmlFor='basiInput' className='form-label'>
        Signature{isRequired ? '*' : ''}
      </Label>
      <p className='text-muted fw-normal mb-0'>
        To set up your eSignature, click the link below. After creating your
        eSignature, download the signature file to your computer and upload it
        below.
      </p>
      <a
        className='fw-normal text-purple mb-2 d-block'
        href='https://createmysignature.com'
        target='_blank'
        rel='noreferrer'
      >
        https://createmysignature.com
      </a>
      <Dropzone
        maxFiles={1}
        disabled={isSubmitting}
        multiple={false}
        accept={{ image: ['.png'] }}
        onDrop={onDrop}
      >
        {({ getRootProps }) => (
          <div className='dropzone dz-clickable cursor-pointer'>
            <div className='dz-message needsclick' {...getRootProps()}>
              <div className='mt-2'>
                <i className='display-7 text-muted ri-upload-cloud-2-fill' />
              </div>
              <p className='m-0'>
                Drop file here or click to upload.<br></br>
                (png) up to 1mb
              </p>
            </div>
          </div>
        )}
      </Dropzone>
      {error ? (
        <p style={{ color: '#F06548', fontSize: '0.875em' }}>
          {(error as string) || ''}
        </p>
      ) : null}
      {signature && (
        <ul className='list-unstyled mb-0' id='dropzone-preview'>
          <Card
            className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
            key={'-file'}
          >
            <div className='p-2'>
              <Row className='align-items-center'>
                <Col className='col-auto'>
                  <img
                    data-dz-thumbnail=''
                    height='80'
                    className='avatar-sm rounded bg-light'
                    alt={'name' in signature ? signature.name : ''}
                    src={
                      ('preview' in signature
                        ? signature.preview
                        : 'path' in signature
                        ? signature.path
                        : '') as string
                    }
                  />
                </Col>

                <Col>
                  <Link to='#' className='text-muted font-weight-bold'>
                    {'name' in signature ? signature.name : ''}
                  </Link>
                  <p className='mb-0'>
                    <strong>{signature.size} KB</strong>
                  </p>
                </Col>
                <Col className='d-flex justify-content-end align-items-end'>
                  <Button
                    disabled={isSubmitting}
                    color='danger'
                    onClick={onDelete}
                  >
                    {' '}
                    Delete{' '}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </ul>
      )}
    </div>
  )
}
