import Highlighter from 'react-highlight-words'
import { UncontrolledTooltip } from 'reactstrap'
import React, { useMemo } from 'react'
import { TDepartment, TFacility } from '../../sharedTypes'
import { TAgency } from '../../sharedTypes/models/agency'

const LIMIT = 1

interface UserAssociationsProps {
  data: TDepartment[] | TFacility[] | TAgency[]
  key: string
  className?: string
  type?: 'department' | 'facility' | 'agency'
}

const UserAssociations = ({
  data,
  key,
  className = '',
  type = 'department',
}: UserAssociationsProps) => {
  const propertyNames = useMemo(() => data.map(d => d.name), [data])

  return (
    <td
      className='text-break'
      style={{ whiteSpace: window.innerWidth > 1440 ? 'normal' : 'nowrap' }}
    >
      <span className='text-body'>
        <Highlighter
          highlightClassName='text-highlight'
          className={`${className} user-association`}
          searchWords={[key || '']}
          highlightTag={'span'}
          autoEscape={true}
          textToHighlight={data?.[0]?.name}
        />

        {propertyNames.length > LIMIT && (
          <>
            <UncontrolledTooltip placement='top' target={`viewMore${type}`}>
              {propertyNames.slice(LIMIT, propertyNames.length).join(', ')}
            </UncontrolledTooltip>

            <span className='ps-1 cursor-pointer' id={`viewMore${type}`}>
              +{propertyNames.length - LIMIT}
            </span>
          </>
        )}
      </span>
    </td>
  )
}

export default UserAssociations
