import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Collapse, Form, Modal, ModalBody } from 'reactstrap'
import { Formik, FormikProps } from 'formik'
import _ from 'lodash-es'
import {
  AssignCourseModalProps,
  AssignToOptions,
  CollapseHeaderProps,
  DigitalAssign,
  FormValues,
  IStep2Data,
  LiveAssign,
  Step2Filters,
} from './types'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import {
  COURSE_FILTER_FORMATS,
  COURSE_TYPES,
  CoursesPermissions,
  CoursesTabs,
  CoursesTabsWithoutCE,
  CreateAssignmentDTO,
  GetOnlineCoursesDTO,
  ONLINE_COURSE_STATUS,
  OnlineCourseItem,
  UserManagementPermissions,
  ValidateAssignmentDTO,
} from '../../../sharedTypes'
import {
  createAssignment,
  getOnlineCourses,
  validateAssignment,
} from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import {
  errorToastOptions,
  handleError,
  successToastOptions,
} from '../../../helpers/toast_helper'
import moment from 'moment'
import { getCoursePackages } from '../../../helpers/api/coursePackages'
import { GetCoursePackagesDTO } from '../../../sharedTypes/api/coursePackages'
import {
  CoursePackageFormatsEnum,
  CoursePackageStatus,
  TCoursePackage,
} from '../../../sharedTypes/models/coursePackage'
import { getAssignedCode } from '../../../helpers/course'
import Step1Passed from './Step1Passed'
import Step2Passed from './Step2Passed'
import Step3Passed from './Step3Passed'
import AssignConfirmation from '../PrimaryConfirmationModal'
import Confirmation from '../AddNewUserModal/Step3'
import { assignCoursesSchema } from '../../../schemas'

const initialValues: FormValues = {
  assignTo: {
    facilities: undefined,
    positions: undefined,
    departments: undefined,
    users: undefined,
  },
  enrollment: {
    courses: [],
    packages: [],
    trainingDays: [],
  },
  confirmation: {},
}

const initialAssignOptions = {
  digital: [],
  live: [],
  facilities: [],
  assignTo: [],
}

const initialCourseFilters: Step2Filters = {
  activeTab: CoursesTabsWithoutCE.COURSES,
  limit: 10,
}

const initialItemsToEnroll: IStep2Data = {
  [CoursesTabsWithoutCE.COURSES]: {
    page: 0,
    data: [],
  },
  [CoursesTabsWithoutCE.PACKAGES]: {
    page: 0,
    data: [],
  },
  [CoursesTabsWithoutCE.TRAINING_DAYS]: {
    page: 0,
    data: [],
  },
}

const AssignCourseModal = ({
  isOpen,
  onClose,
  onSubmit,
  user,
  selectedEntities,
}: AssignCourseModalProps) => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [courseFilters, setCourseFilters] = useState(initialCourseFilters)
  const [isLoading, setIsLoading] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [itemsToEnroll, setItemsToEnroll] = useState(initialItemsToEnroll)
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false)
  const [confirmationDetails, setConfirmationDetails] = useState<boolean>(false)
  const [assignOptions, setAssignOptions] =
    useState<AssignToOptions>(initialAssignOptions)
  const [allowSkipToTest, setAllowSkipToTest] = useState<boolean>(false)

  const fetchItemsToEnroll = (page?: number) => {
    const activeTab = courseFilters.activeTab
    let requestPromise
    if (activeTab === CoursesTabsWithoutCE.COURSES) {
      const query: GetOnlineCoursesDTO.Request = {
        page: page || itemsToEnroll[CoursesTabsWithoutCE.COURSES].page + 1,
        limit: courseFilters.limit,
        key: courseFilters.key,
        statuses: [ONLINE_COURSE_STATUS.PUBLISHED],
        notExpired: true,
      }
      if (!_.isEmpty(courseFilters.formats)) {
        query.formats = courseFilters.formats
      }
      if (!_.isEmpty(courseFilters.types)) {
        query.types = courseFilters.types
      }
      requestPromise = getOnlineCourses(query).then(res => ({
        data: res.courses,
        page: res.page,
      }))
    } else {
      const query: GetCoursePackagesDTO.Request = {
        page: page || itemsToEnroll[activeTab].page + 1,
        limit: courseFilters.limit,
        key: courseFilters.key,
        format:
          activeTab === CoursesTabsWithoutCE.PACKAGES
            ? CoursePackageFormatsEnum.PACKAGE
            : CoursePackageFormatsEnum.TRAINING_DAY,
        status: [CoursePackageStatus.PUBLISHED],
      }
      requestPromise = getCoursePackages(query).then(res => ({
        data: res.content,
        page: res.page,
      }))
    }

    if (requestPromise) {
      requestPromise
        .then(({ page, data }) => {
          setItemsToEnroll(prev => ({
            ...prev,
            [activeTab]: {
              data:
                page > 1 ? [...itemsToEnroll[activeTab].data, ...data] : data,
              page,
            },
          }))
        })
        .catch(() => {
          toast('Something went wrong', errorToastOptions)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      fetchItemsToEnroll(1)
    }
  }, [courseFilters, isOpen])

  const formikFormRef = useRef<FormikProps<FormValues>>(null)

  const _onClose = () => {
    formikFormRef.current?.resetForm()
    setActiveStep(0)
    setCourseFilters(initialCourseFilters)
    setIsLoading(false)
    setIsValidating(false)
    setItemsToEnroll(initialItemsToEnroll)
    setAssignOptions(initialAssignOptions)
    setOpenConfirmationModal(false)
    onClose()
  }

  const onStepBack = useCallback(() => {
    if (activeStep >= 1) {
      setActiveStep(activeStep - 1)
    }
  }, [activeStep])

  const onStepSubmit = useCallback(() => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1)
    }
  }, [activeStep])

  const permission = useMemo(() => {
    return user
      ? UserManagementPermissions.ASSIGN_COURSE_TO_USER
      : CoursesPermissions.ASSIGN_COURSE
  }, [user])

  const getDate = (
    item: OnlineCourseItem,
    type: 'digital' | 'live',
    key: 'dateAvailable' | 'dueDate',
    facilities: number[] = [],
  ) => {
    if (
      !_.isEqual(assignOptions['facilities'], facilities) &&
      type === 'live'
    ) {
      return undefined
    }

    const format = _.get(item, 'available_formats[0]')
    const row = _.find(
      assignOptions[type] as DigitalAssign[],
      assignItem =>
        assignItem.course.id === item.id && assignItem.format === format,
    )

    return _.get(row, key, undefined)
  }

  const getScheduleId = (item: OnlineCourseItem, facilities: number[]) => {
    if (!_.isEqual(assignOptions['facilities'], facilities)) {
      return undefined
    }

    const format = _.get(item, 'available_formats[0]')
    const row = _.find(
      assignOptions['live'],
      assignItem =>
        assignItem.course.id === item.id && assignItem.format === format,
    )

    return _.get(row, 'scheduleId', undefined)
  }

  const onStep2Submit = useCallback(() => {
    if (!formikFormRef.current?.values) {
      return
    }

    const {
      assignTo: { facilities, departments, positions, users },
      enrollment,
    } = formikFormRef.current.values

    const noFormat = enrollment[CoursesTabs.COURSES].find(c => !c.format)
    if (noFormat) {
      return toast('Select format for each selected course', errorToastOptions)
    }

    const params: ValidateAssignmentDTO.Request = {
      facilityIds: Array.isArray(facilities)
        ? facilities.map(item => item.value)
        : undefined,
      departmentIds: Array.isArray(departments)
        ? _.flatten(departments.map(item => item.value))
        : undefined,
      positionIds: Array.isArray(positions)
        ? _.flatten(positions.map(item => item.value))
        : undefined,
      userIds: Array.isArray(users) ? users.map(item => item.value) : undefined,
      [CoursesTabs.COURSES]: enrollment[CoursesTabs.COURSES].map(c => ({
        courseId: c.course.id,
        format: c.format as COURSE_FILTER_FORMATS,
      })),
      [CoursesTabs.PACKAGES]: enrollment[CoursesTabs.PACKAGES].map(p => p.id),
      [CoursesTabs.TRAINING_DAYS]: enrollment[CoursesTabs.TRAINING_DAYS].map(
        t => t.id,
      ),
    }

    setIsValidating(true)
    validateAssignment(params)
      .then(({ data }) => {
        setAssignOptions({
          assignTo: data.assignTo,
          facilities: data.facilities,
          digital: data.digital.map(item => ({
            course: item,
            format: item.available_formats[0],
            dateAvailable: getDate(item, 'digital', 'dateAvailable'),
            dueDate: getDate(item, 'digital', 'dueDate'),
            isValid: true,
          })),
          live: data.live.map(item => {
            const dueDate = getDate(
              item as OnlineCourseItem,
              'live',
              'dueDate',
              data.facilities,
            )
            const scheduleId = getScheduleId(
              item as OnlineCourseItem,
              data.facilities,
            )

            return {
              course: item,
              format: (item as OnlineCourseItem).available_formats[0],
              dateAvailable: undefined,
              dueDate,
              isValid: true,
              ...(scheduleId && { scheduleId }),
            }
          }),
        })
        setAllowSkipToTest(data.allowSkipToTest)
        setActiveStep(activeStep + 1)
        setConfirmationDetails(data.scheduleDetails)
      })
      .catch(reason => {
        setAssignOptions(initialAssignOptions)
        handleError(reason)
      })
      .finally(() => {
        setIsValidating(false)
      })
  }, [activeStep, formikFormRef])

  const onStep3Submit = useCallback(() => {
    let isError = false
    const _assignOptions = Object.assign({}, assignOptions)
    _assignOptions.digital.forEach((item, index) => {
      if (
        !(
          item.dateAvailable &&
          item.dueDate &&
          moment(item.dueDate).isAfter(moment(item.dateAvailable))
        )
      ) {
        isError = true
        _assignOptions.digital[index].isValid = false
      }
    })
    _assignOptions.live.forEach((item, index) => {
      if (!item.dueDate) {
        isError = true
        _assignOptions.live[index].isValid = false
      }
    })
    if (isError) {
      setAssignOptions(_assignOptions)
      toast('Some required fields need to be filled out', errorToastOptions)
      return
    }
    if (
      _assignOptions.digital.length === 0 &&
      _assignOptions.live.length === 0
    ) {
      toast('No selected courses to assign', errorToastOptions)
      return
    }
    setActiveStep(activeStep + 1)
  }, [assignOptions])

  //Step 4 submit
  const onAssign = () => {
    const itemsToAssign: CreateAssignmentDTO.Request['itemsToAssign'] = []

    assignOptions.digital.forEach(item => {
      itemsToAssign.push({
        courseId: item.course.id,
        packageId: item.course?.package?.id ?? undefined,
        format: item.format,
        dateAvailable: item.dateAvailable as Date,
        dueDate: moment(item.dueDate).format('YYYY-MM-DD'),
        code: getAssignedCode(item),
        allowSkipToTest: !!item.allowSkipToTest,
      })
    })

    assignOptions.live.forEach(item => {
      itemsToAssign.push({
        courseId:
          (item.course as TCoursePackage).format ===
          CoursePackageFormatsEnum.TRAINING_DAY
            ? null
            : item.course.id,
        packageId:
          (item.course as TCoursePackage).format ===
          CoursePackageFormatsEnum.TRAINING_DAY
            ? item.course.id
            : (item.course as OnlineCourseItem)?.package?.id,
        format: item.format,
        dateAvailable: moment().toDate(),
        dueDate: item.dueDate as Date,
        scheduleId: item.scheduleId,
        code: getAssignedCode(item),
      })
    })

    const request: CreateAssignmentDTO.Request = {
      userIds: assignOptions.assignTo,
      itemsToAssign,
      permission,
    }

    const confirmationData = formikFormRef.current?.values.confirmation
    if (confirmationData && _.keys(confirmationData).length) {
      request.confirmation = {}

      if (confirmationData.scheduleOnboarding) {
        request.confirmation = {
          onboardingDate: moment(confirmationData.onboardingDate)
            .set('hours', moment(confirmationData.onboardingTime).hours())
            .set('minute', moment(confirmationData.onboardingTime).minutes())
            .toDate(),
        }
      }

      if (confirmationData.scheduleFacilityTour) {
        if (confirmationData.facilityTourSchedule) {
          request.confirmation.facilityTourScheduleId =
            confirmationData.facilityTourSchedule.value
          request.confirmation.facilityTourDate =
            confirmationData.facilityTourDate
        } else {
          request.confirmation.facilityTourDate = moment(
            confirmationData.facilityTourDate,
          )
            .set(
              'hours',
              moment(confirmationData.facilityTourStartTime).hours(),
            )
            .set(
              'minute',
              moment(confirmationData.facilityTourStartTime).minutes(),
            )
            .toDate()
          request.confirmation.facilityTourStartTime =
            confirmationData.facilityTourStartTime
          request.confirmation.facilityTourEndTime =
            confirmationData.facilityTourEndTime
        }
      }
    }

    setIsLoading(true)

    createAssignment(request)
      .then(() => {
        toast('Assignments have been successfully sent', successToastOptions)
        _onClose()
        onSubmit()
      })
      .catch(handleError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onDeleteItem = (item: DigitalAssign | LiveAssign) => {
    setAssignOptions(prev => ({
      ...prev,
      digital: prev.digital.filter(course => !_.isEqual(item, course)),
      live: prev.live.filter(course => !_.isEqual(item, course)),
    }))
  }

  const onSetDate = (
    type: 'live' | 'digital',
    item: DigitalAssign | LiveAssign,
  ) => {
    setAssignOptions(prev => ({
      ...prev,
      [type]: prev[type].map(course => {
        if (item.course.id === course.course.id) {
          return item
        }
        return course
      }),
    }))
  }

  const onUpdateAllDigitalCourses = (
    digitalCourseProperty: keyof DigitalAssign,
    value: string | boolean,
  ) => {
    setAssignOptions(prev => ({
      ...prev,
      ['digital']: prev['digital'].map(course => {
        return {
          ...course,
          [digitalCourseProperty]: value,
        }
      }),
      ['settings']: {
        ...prev['settings'],
        [digitalCourseProperty]: value,
      },
    }))
  }

  const showSteps = useMemo(
    () => ({
      step1: activeStep === 0 && !user,
      step2: (activeStep === 1 && !user) || (activeStep === 0 && user),
      step3: (activeStep === 2 && !user) || (activeStep === 1 && user),
      step4: (activeStep === 3 && !user) || (activeStep === 2 && user),
    }),
    [activeStep, user],
  )

  const showConfirmationDetails = useMemo(() => {
    return !!(
      assignOptions.digital?.find(
        item =>
          item.course &&
          item.course.package &&
          item.course.package.type?.includes(COURSE_TYPES.ONBOARDING),
      ) && confirmationDetails
    )
  }, [assignOptions, confirmationDetails])

  const formInitialValues = useMemo(() => {
    if (user) {
      return {
        ...initialValues,
        assignTo: {
          ...initialValues.assignTo,
          users: [
            { value: user.id, label: `${user.firstName} ${user.lastName}` },
          ],
        },
      }
    }
    if (selectedEntities) {
      const preSelectedEntities = _.cloneDeep(selectedEntities)

      const packageCourseIds = [
        ...preSelectedEntities[CoursesTabs.PACKAGES].flatMap(
          packageItem => packageItem.courses?.map(course => course.id),
        ),
        ...preSelectedEntities[CoursesTabs.TRAINING_DAYS].flatMap(
          trainingDayItem => trainingDayItem.courses?.map(course => course.id),
        ),
      ]

      const selectedCourses = preSelectedEntities[CoursesTabs.COURSES].filter(
        course => !_.includes(packageCourseIds, course.id),
      )

      const assignTo = { ...initialValues.assignTo }

      if (preSelectedEntities?.users) {
        assignTo.users = preSelectedEntities.users.map(u => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`,
        }))
      }

      return {
        ...initialValues,
        assignTo,
        enrollment: {
          ...initialValues.enrollment,
          [CoursesTabs.COURSES]: selectedCourses.map(course => ({
            course,
            format: undefined,
          })),
          [CoursesTabs.PACKAGES]: preSelectedEntities[CoursesTabs.PACKAGES],
          [CoursesTabs.TRAINING_DAYS]:
            preSelectedEntities[CoursesTabs.TRAINING_DAYS],
        },
      }
    }

    return initialValues
  }, [user, selectedEntities, itemsToEnroll])

  const handleLastStep = () => {
    if (user) {
      onAssign()
    } else {
      setOpenConfirmationModal(true) // show confirmation only for bulk assignments
    }
  }

  const assignConfirmationText = useMemo(() => {
    const usersCount = assignOptions.assignTo.length

    const coursesCount = _.filter(
      [...assignOptions.digital, ...assignOptions.live],
      item => item.course.id !== null,
    ).length

    const trainingDaysCount = _.filter(
      assignOptions.live,
      item => item.course.id === null,
    ).length
    const trainingDaysText =
      trainingDaysCount > 0
        ? ` and ${trainingDaysCount}
    training day${trainingDaysCount > 1 ? 's' : ''}`
        : ''

    return `Are you sure you want to assign ${coursesCount} course${
      coursesCount > 1 ? 's' : ''
    }
    ${trainingDaysText} to ${usersCount} user${usersCount > 1 ? 's' : ''}?`
  }, [assignOptions.assignTo.length])

  return (
    <Modal isOpen={isOpen} toggle={_onClose} centered size={'xl'}>
      <ModalBody className='p-0'>
        <div className='px-3 py-2 border-bottom'>
          <div className='hstack w-100 flex-1 align-items-center justify-content-end gap-5'>
            <h5 className='flex-1 fw-light m-0'>
              Assign Courses{' '}
              {`${user ? `for ${user.firstName} ${user.lastName}` : ''}`}
            </h5>
            <div
              className='flex-1 position-relative align-items-center justify-content-end'
              style={{ maxWidth: 250 }}
            >
              <div
                className='hstack justify-content-between'
                style={{ zIndex: 100 }}
              ></div>
            </div>
            <i
              className='ri-close-line fs-24 cursor-pointer'
              onClick={_onClose}
            ></i>
          </div>
        </div>
        <Formik<FormValues>
          enableReinitialize={false}
          initialValues={formInitialValues}
          validationSchema={assignCoursesSchema}
          onSubmit={() => {}}
          innerRef={formikFormRef}
        >
          {({ values, handleBlur, setFieldValue, errors, isValid }) => (
            <Form className='position-relative assign-courses-form'>
              {!user && (
                <div className='assign-step rounded mx-3 my-2 px-3 py-2 bg-light'>
                  <CollapseHeader
                    label={'Select Users'}
                    icon={'bx bx-user-plus'}
                    stepPassed={!showSteps.step1}
                    changeActiveStep={() => setActiveStep(0)}
                  />
                  {showSteps.step1 ? (
                    <Collapse isOpen={true}>
                      <Step1
                        values={values.assignTo}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        onCancel={_onClose}
                        onNext={onStepSubmit}
                        permission={permission}
                      />
                    </Collapse>
                  ) : (
                    <Step1Passed assignTo={values.assignTo} />
                  )}
                </div>
              )}

              <div className='assign-step rounded mx-3 my-2 bg-light p-3'>
                <CollapseHeader
                  label={'Select Courses / Course Packages'}
                  icon={'ri-file-copy-line'}
                  stepPassed={!!showSteps.step3 || !!showSteps.step4}
                  changeActiveStep={() => setActiveStep(!user ? 1 : 0)}
                />
                <Collapse isOpen={!showSteps.step1}>
                  {showSteps.step2 ? (
                    <Step2
                      values={values.enrollment}
                      filters={courseFilters}
                      setFilters={setCourseFilters}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      onCancel={onStepBack}
                      onNext={onStep2Submit}
                      isLoading={isLoading}
                      isValidating={isValidating}
                      itemsToEnroll={itemsToEnroll}
                      hideBackButton={!!user}
                    />
                  ) : (
                    <Step2Passed enrollment={values.enrollment} />
                  )}
                </Collapse>
              </div>

              <div className='assign-step rounded mx-3 my-2 bg-light p-3'>
                <CollapseHeader
                  label={'Dates and Times'}
                  icon={'ri-calendar-2-line'}
                  stepPassed={!!showSteps.step4}
                  changeActiveStep={() => setActiveStep(!user ? 2 : 1)}
                />
                <Collapse isOpen={!showSteps.step1 && !showSteps.step2}>
                  {showSteps.step3 ? (
                    <Step3
                      onCancel={onStepBack}
                      onNext={onStep3Submit}
                      assignOptions={assignOptions}
                      onDelete={onDeleteItem}
                      onSetDate={onSetDate}
                      onUpdateAllDigitalCourses={onUpdateAllDigitalCourses}
                      showSkipToTest={allowSkipToTest}
                    />
                  ) : (
                    <Step3Passed assignOptions={assignOptions} />
                  )}
                </Collapse>
              </div>

              <div className='assign-step rounded mx-3 my-2 bg-light p-3'>
                <CollapseHeader
                  label={'Confirmation'}
                  icon={'bx bx-badge-check'}
                  stepPassed={false}
                  changeActiveStep={() => setActiveStep(4)}
                />
                <Collapse isOpen={!!showSteps.step4}>
                  {showConfirmationDetails ? (
                    <Confirmation
                      values={values.confirmation}
                      facilityId={
                        user
                          ? (user.facilityId as number)
                          : assignOptions.facilities[0]
                      }
                      errors={errors.confirmation || {}}
                      setFieldValue={setFieldValue}
                      isValid={isValid}
                      isLoading={isLoading}
                      onNext={handleLastStep}
                      onCancel={onStepBack}
                    />
                  ) : (
                    <Step4
                      onCancel={onStepBack}
                      onNext={handleLastStep}
                      isLoading={isLoading}
                    />
                  )}
                </Collapse>
              </div>
            </Form>
          )}
        </Formik>

        <AssignConfirmation
          onConfirm={onAssign}
          onClose={() => setOpenConfirmationModal(false)}
          isOpen={openConfirmationModal}
          message={assignConfirmationText}
          title={''}
          confirmButtonLabel={'Yes, Save and Send'}
          cancelLabel={'No, Go back'}
          icon={'ri-error-warning-line'}
          isLoading={isLoading}
        />
      </ModalBody>
    </Modal>
  )
}

const CollapseHeader = ({
  label,
  icon,
  stepPassed,
  changeActiveStep,
}: CollapseHeaderProps) => {
  return (
    <div className='d-flex align-items-center fs-20 gap-2 justify-content-between'>
      <div className='d-flex align-items-center fs-20 gap-2'>
        <div className='avatar-xs flex-shrink-0'>
          <span
            className={`avatar-title rounded-circle assign-course-step-icon
        ${stepPassed ? 'selected' : ''} `}
          >
            {stepPassed ? (
              <i className='bx bx-check text-green-500'></i>
            ) : (
              <i className={`${icon} text-primary`}></i>
            )}
          </span>
        </div>
        <p className={`fw-semibold m-0 ${stepPassed ? 'text-green-500' : ''}`}>
          {label}
        </p>
      </div>

      {stepPassed && (
        <div className='flex-shrink-0 fs-12'>
          <Button
            color='soft-primary'
            className='fs-12 p-1 fw-medium'
            onClick={changeActiveStep}
          >
            <i className='ri-edit-box-line me-1'></i>Edit
          </Button>
        </div>
      )}
    </div>
  )
}
export default AssignCourseModal
