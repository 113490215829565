import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  Row,
  Spinner,
} from 'reactstrap'
import React, { useEffect, useState } from 'react'
import defaultCourseCover from '../../../assets/images/course-default-cover.png'
import _ from 'lodash'
import ShowMore from '../../Common/ShowMore'
import CourseTags from '../../Course/CourseTags'
import {
  COURSE_TYPES,
  OnlineCourseFormatsEnum,
  OnlineCourseItem,
  UserManagementPermissions,
} from '../../../sharedTypes'
import {
  BadgeDarkColorByStatus,
  formattedDuration,
} from '../../../helpers/course'
import moment from 'moment'
import { getOnlineCourse } from '../../../helpers/api_helper'
import { handleError } from '../../../helpers/toast_helper'

interface CoursePreviewModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  courseId: number
}

const CoursePreviewModal = ({
  isOpen,
  onClose,
  courseId,
}: CoursePreviewModalProps) => {
  const [loading, setLoading] = useState(true)
  const [course, setCourse] = useState<OnlineCourseItem | null>(null)

  useEffect(() => {
    setLoading(true)
    getOnlineCourse(courseId, {
      permission: UserManagementPermissions.VIEW_USER_COURSE_HISTORY,
    })
      .then(courseData => {
        setCourse(courseData)
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      className='course-preview-modal'
      centered
    >
      <ModalHeader toggle={onClose}></ModalHeader>
      <ModalBody className='p-4'>
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : course ? (
          <div>
            <div className='d-flex flex-column gap-2'>
              <div className='course-cover position-relative'>
                <img
                  className='card-img-top img-fluid rounded w-100 h-100'
                  src={course.cover?.path ?? defaultCourseCover}
                  alt='NO IMAGE'
                  style={{
                    aspectRatio: 1,
                    objectFit: 'cover',
                  }}
                />
              </div>
              <div className='d-flex justify-content-between w-100'>
                <span className='text-muted fs-14'>{course.code}</span>
              </div>

              <div className='fs-24 text-body fw-medium'>
                {_.get(course, 'translations[0].content.name', '-')}
              </div>
              <div className='text-muted fs-16 course-description'>
                <ShowMore
                  content={_.get(
                    course,
                    'translations[0].content.description',
                    '-',
                  )}
                />
              </div>
              <div className='d-flex gap-2 flex-wrap'>
                {course.type?.map((type, index) => (
                  <span
                    key={index}
                    className={`badge badge-outline-info fs-12 fw-normal text-capitalize`}
                  >
                    {type}
                  </span>
                ))}
                <CourseTags tags={course.tags} />
              </div>

              <Row>
                {!(course.formats || []).includes(
                  OnlineCourseFormatsEnum.COMPETENCY,
                ) && (
                  <Col>
                    <div>
                      <div className='fw-medium text-body fs-16'>
                        Duration :
                      </div>
                      <div className='text-muted fs-16'>
                        {formattedDuration(course.duration)}
                      </div>
                    </div>
                  </Col>
                )}
                <Col>
                  <div>
                    <div className='fw-medium text-body fs-16 form-label'>
                      Status
                    </div>
                    <div className='text-muted fs-12'>
                      <span
                        className={`badge badge-${
                          BadgeDarkColorByStatus[course.status]
                        } fs-12 fw-medium text-capitalize`}
                      >
                        {course.status}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div>
                <div className='fw-medium text-body fs-16 form-label'>
                  Date Published :
                </div>
                <div className='text-muted fs-16'>
                  {course.publishedDate
                    ? moment(course.publishedDate).format('MM/DD/YYYY')
                    : '-'}
                </div>
              </div>

              {course.expirationDate && (
                <div>
                  <div className='fw-medium text-body fs-16 form-label'>
                    Expiration Date :
                  </div>
                  <div className='text-muted fs-16'>
                    {moment(course.expirationDate).format('MM/DD/YYYY')}
                  </div>
                </div>
              )}
              {!course.type?.includes(COURSE_TYPES.CONTINUING_EDUCATION) && (
                <div>
                  <div className='fw-medium text-body fs-16 form-label'>
                    Formats Available :
                  </div>
                  <div className='text-muted fs-16'>
                    {course.available_formats?.join(', ')}
                  </div>
                </div>
              )}
              {course.positions?.length && (
                <div>
                  <div className='fw-medium text-body fs-16 form-label'>
                    Positions :
                  </div>
                  <div className='text-muted fs-16'>
                    {course.positions?.join(', ')}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  )
}

export default CoursePreviewModal
