import React, { useState } from 'react'
import { Col, Container, Input, Label, Row } from 'reactstrap'

import BreadCrumb from '../../Components/Common/BreadCrumb'
import ScheduleBreakDown from './UsersOnboarding/ScheduleBreakDown'
import NewUsers from './UsersOnboarding/NewUsers'
import Statistics from './Statistics/Statistics'
import Notifications from './Notifications'
import MainTabs from './Statistics/MainTabs'
import { useAppSelector } from '../../hooks/redux'

const DashboardAnalytics = () => {
  document.title = 'Dashboard | Mastered - Admin & Dashboard'

  const { user } = useAppSelector(state => ({
    user: state.User.user,
  }))
  const [includeAgencyUsers, setIncludeAgencyUsers] = useState<boolean>(true)

  return (
    <div className='page-content'>
      <Container fluid>
        <div className='d-flex justify-content-between align-items-center'>
          <BreadCrumb title='Dashboard' items={[{ title: 'Dashboard' }]} />
          <div className='form-check form-switch mb-2'>
            <Input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id={'includeAgencyUsers'}
              checked={includeAgencyUsers}
              onChange={e => {
                setIncludeAgencyUsers(e.target.checked)
              }}
            />
            <Label className='form-check-label' htmlFor={'includeAgencyUsers'}>
              Include Agency
            </Label>
          </div>
        </div>

        <Row>
          <Col sm={6} xl={5} xxl={4}>
            <ScheduleBreakDown includeAgency={includeAgencyUsers} />
            <NewUsers includeAgency={includeAgencyUsers} />
          </Col>
          <Col sm={6} md={6} xl={7} xxl={user?.facilityId ? 5 : 8}>
            <Statistics includeAgency={includeAgencyUsers} />
            <MainTabs user={user} includeAgency={includeAgencyUsers} />
          </Col>

          {user?.facilityId && (
            <Col xxl={3}>
              <Notifications />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default DashboardAnalytics
