import React, { useCallback, useState, Dispatch, SetStateAction } from 'react'
import { BottomNavigationProps, BottomNavigation } from './BottomNavigation'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import { GeneralInfo } from './GeneralInfo'
import {
  OnlineCourseItem,
  OnlineCourseFormatsEnum,
  ONLINE_COURSE_STATUS,
} from '../../sharedTypes'
import { TabsHeader } from '../../Components/Course/TabsHeader'
import { Col, Row, Card, CardBody, Container } from 'reactstrap'
import { WithRouterProps } from '../../Components/Common/withRouter'
import UnsavedDataModal from '../../Components/Course/UnsavedDataModal'
import { DeleteFormatState } from '../../context/CourseContext'
import DeleteRoleModal from '../../Components/Modals/DeleteConfirmation'
import { CourseTabs } from './TabsHeader'
import { IGoTo } from '../../hooks/course/useShouldGoTo'
import { ENGLISH_LANGUAGE_CODE } from '../../helpers/common'

interface CourseLayoutProps
  extends Omit<BottomNavigationProps, 'onSubmit' | 'onBack' | 'status'>,
    WithRouterProps {
  children: React.ReactElement
  course: OnlineCourseItem | null
  tabs: CourseTabs
  leftSideBar?: boolean
  loading?: boolean
  viewOnly?: boolean
  classes?: {
    cardBody?: string
  }
  hasUnsavedData?: boolean
  isDisabled?: boolean
  onPublish?: () => void
  onNext: () => void
  onBack: () => void
  onNavigate: (path: string) => void
  deleteFormatModal?: DeleteFormatState | null
  setDeleteFormatModal?: Dispatch<SetStateAction<DeleteFormatState | null>>
  onRemoveFormat?: (format: OnlineCourseFormatsEnum) => void
  onSaveAsDraft: (props: IGoTo) => void
}

export const CourseLayout = (props: CourseLayoutProps) => {
  const [direction, setDirection] = useState<IGoTo['direction']>(undefined)

  const onBeforeAction = useCallback(
    (action: IGoTo['direction']) => {
      if (props.hasUnsavedData) {
        setDirection(action)
      } else {
        switch (action) {
          case 'prev':
            props.onBack()
            break
          case 'next':
            props.onNext()
            break
          default:
            props.onNavigate(action as string)
        }
      }
    },
    [props],
  )

  const onConfirm = useCallback(() => {
    props.onSaveAsDraft({ direction })
  }, [direction])

  const onClose = useCallback(() => {
    switch (direction) {
      case 'prev':
        props.onBack()
        break
      case 'next':
        props.onNext()
        break
      default:
        props.onNavigate(direction as string)
    }
  }, [direction, props])

  const name =
    props.course?.translations?.find(
      item => item?.language?.code === ENGLISH_LANGUAGE_CODE,
    )?.content?.name ?? ''

  const title = `${name}  Course ${props.course?.code ?? ''}`

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={title}
            items={[
              {
                title: 'Courses',
                linkTo: '/courses',
              },
              {
                title: 'Online Course Categories',
                active: true,
              },
            ]}
          />
          <Row>
            {props.leftSideBar && (
              <Col sm='3'>
                <GeneralInfo
                  course={props.course as any}
                  router={props.router}
                />
              </Col>
            )}
            <Col sm={props.leftSideBar ? '9' : '12'}>
              <Card
                className='m-0 h-100'
                style={{ display: 'block', paddingBottom: '20px' }}
              >
                <TabsHeader
                  tabs={props.tabs}
                  disabled={!!props.isDisabled}
                  deletable={props.course?.deletable || false}
                  courseId={props.course?.id}
                  onNavigate={onBeforeAction}
                  status={props.course?.status}
                  hasRevision={!!props.course?.revisionId}
                />
                {props.loading && (
                  <CardBody className='h-100 d-flex justify-content-center align-items-center'>
                    <h2>Loading...</h2>
                  </CardBody>
                )}
                {!props.loading && (
                  <CardBody
                    className={`h-100 d-flex justify-content-center ${
                      props?.classes?.cardBody || ''
                    }`}
                  >
                    {props.children}
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col
              sm={props.leftSideBar ? '9' : '12'}
              className={
                props.leftSideBar
                  ? 'offset-sm-3 sticky-row'
                  : 'sticky-row w-100'
              }
            >
              <BottomNavigation
                allowPublish={props.allowPublish}
                viewOnly={props.viewOnly}
                backText={props.backText}
                hideNext={props.hideNext}
                showSaveAsDraft={props.showSaveAsDraft}
                onBack={() => onBeforeAction('prev')}
                onPublish={props.onPublish}
                onSaveAsDraft={props.onSaveAsDraft}
                onSubmit={() => onBeforeAction('next')}
                status={props.course?.status as ONLINE_COURSE_STATUS}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <UnsavedDataModal
        isOpen={!!direction}
        onClose={onClose}
        onConfirm={onConfirm}
      />
      <DeleteRoleModal
        title={props?.deleteFormatModal?.title || ''}
        message={props?.deleteFormatModal?.message || ''}
        isOpen={!!props.deleteFormatModal}
        onClose={() => {
          if (props?.setDeleteFormatModal) {
            props?.setDeleteFormatModal(null)
          }
        }}
        onDelete={() => {
          if (props.onRemoveFormat && props.deleteFormatModal) {
            props.onRemoveFormat(props.deleteFormatModal?.format)
          }
        }}
      />
    </React.Fragment>
  )
}
