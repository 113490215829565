import BreadCrumb from '../../../Components/Common/BreadCrumb'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'reactstrap'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  CompaniesSortBy,
  COMPANY_STATUSES,
  GetCompaniesDTO,
  OrderType,
  ReportingPermissions,
} from '../../../sharedTypes'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { handleError } from '../../../helpers/toast_helper'
import { onPrint } from '../../../helpers/common'
import { EXPORT_TYPES } from '../CourseHistory/types'
import _ from 'lodash'
import moment from 'moment/moment'
import { getCompanies } from '../../../helpers/api/company'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import Highlighter from 'react-highlight-words'
import { BadgeColorByStatus } from '../../CompanyManagement/types'
import { Pagination } from '../../../Components/Common/Pagination'
import { Link } from 'react-router-dom'
import Filters from './Filters'
import ExportCompanyReport from './ExportCompanyReport'

const Columns = [
  {
    name: 'Company ID',
    sortBy: CompaniesSortBy.ID,
    style: { width: 140 },
  },
  {
    name: 'Company Name',
    sortBy: CompaniesSortBy.NAME,
    style: { width: 220 },
  },
  {
    name: 'Status (Active/Inactive)',
    sortBy: CompaniesSortBy.STATUS,
  },
  {
    name: 'Activation Date',
    sortBy: CompaniesSortBy.ACTIVATION_DATE,
  },
  {
    name: 'Number of \n Active Facilities',
    sortBy: CompaniesSortBy.NUM_OF_FACILITIES,
    style: { minWidth: 140 },
  },
  {
    name: 'Number of \n Active Users',
    sortBy: CompaniesSortBy.NUM_OF_USERS,
    style: { minWidth: 135 },
  },
  {
    name: 'Number of \n Registered Users',
    sortBy: CompaniesSortBy.NUM_OF_REGISTERED_USERS,
    style: { minWidth: 145 },
  },
  {
    name: 'Number of \n UnRegistered Users',
    sortBy: CompaniesSortBy.NUM_OF_NOT_REGISTERED_USERS,
    style: { minWidth: 170 },
  },
]

const CompanyReport = () => {
  document.title = 'Company Report | Mastered - Admin & Dashboard'

  const [showFilters, setShowFilters] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [exportDropdownOpen, setExportDropdownOpen] = useState(false)
  const [exportType, setExportType] = useState<EXPORT_TYPES | null>(null)

  const [query, setQuery] = useState<GetCompaniesDTO.Request>({
    page: 1,
    limit: 10,
    sortBy: CompaniesSortBy.NAME,
    status: [COMPANY_STATUSES.ACTIVE],
    orderBy: OrderType.ASC,
    permission: ReportingPermissions.VIEW_COMPANY_REPORT,
  })

  const [data, setData] = useState<GetCompaniesDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    companies: [],
  })

  useEffect(() => {
    setIsLoading(true)
    const _query = _.omitBy(query, _.isNil)
    if (!_.isEmpty(_query.activationDate)) {
      _query.activationDate = [
        moment(_query.activationDate[0]).format('YYYY-MM-DD'),
        moment(_query.activationDate[1]).format('YYYY-MM-DD'),
      ]
    }

    getCompanies(_query as GetCompaniesDTO.Request)
      .then(res => {
        setData(res)
      })
      .catch(e => {
        handleError(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  const handleSort = useCallback((column: CompaniesSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={'Company Report'}
            items={[
              {
                active: true,
                title: 'Reporting',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key || ''}
                    />

                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      <Dropdown
                        isOpen={exportDropdownOpen}
                        toggle={() => {
                          setExportDropdownOpen(prevState => !prevState)
                        }}
                      >
                        <DropdownToggle
                          className=' btn btn-secondary align-middle'
                          tag='button'
                        >
                          <i className='mdi mdi-file-export-outline me-1 fs-16'></i>
                          Export
                        </DropdownToggle>
                        <DropdownMenu end>
                          {Object.values(EXPORT_TYPES).map(type => (
                            <DropdownItem key={type}>
                              <span
                                className={`mx-2 ${
                                  type === EXPORT_TYPES.PDF
                                    ? 'text-green-500'
                                    : ''
                                }`}
                                onClick={() => setExportType(type)}
                              >
                                Export as {type}
                              </span>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => onPrint('company-report-section')}
                      >
                        <i className='ri-printer-line me-1 fs-16'></i>
                      </Button>
                    </div>
                  </div>

                  <Filters
                    showFilters={showFilters}
                    setFilters={setQuery}
                    query={query}
                  />
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <div className='overflow-auto'>
                      <Table
                        className='align-middle mb-0'
                        id='company-report-section'
                      >
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {Columns.map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.name}
                              >
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex align-items-center justify-content-center'>
                                    <span className='header-name'>
                                      {column.name}
                                    </span>
                                    {!!column.sortBy && (
                                      <ColumnSortIcon<CompaniesSortBy>
                                        sortOrder={query.orderBy}
                                        sortedColumn={query.sortBy}
                                        column={column.sortBy}
                                        handleSort={handleSort}
                                      />
                                    )}{' '}
                                  </div>
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.companies.map((company, i) => (
                            <tr key={i} className='fs-14'>
                              <td>
                                <Link
                                  to={`/company-manage/${company.id}`}
                                  target='_blank'
                                >
                                  {company.id}
                                </Link>
                              </td>
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  className={'text-muted'}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={company.name}
                                />
                              </td>
                              <td>
                                <span
                                  className={`badge badge-soft-${
                                    BadgeColorByStatus[company.status]
                                  } fs-12 fw-normal text-capitalize`}
                                >
                                  {company.status}
                                </span>
                              </td>
                              <td>
                                {company.activationDate
                                  ? moment(company.activationDate).format(
                                      'MM/DD/YYYY',
                                    )
                                  : '-'}
                              </td>
                              <td>{company.facilitiesCount}</td>
                              <td>{company.usersCount}</td>
                              <td>{company.registeredUsersCount}</td>
                              <td>{company.notRegisteredUsersCount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className='mx-3 my-3'>
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={(page: number) => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
        {exportType && (
          <ExportCompanyReport
            exportType={exportType}
            setExportType={setExportType}
            count={data.count}
            query={query}
          />
        )}
      </div>
    </Fragment>
  )
}

export default CompanyReport
