import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import moment from 'moment/moment'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { Pagination } from '../../../Components/Common/Pagination'
import {
  GetPushNotificationsDTO,
  PushNotificationsSortBy,
} from '../../../sharedTypes/api/pushNotifications'
import {
  deletePushNotification,
  duplicatePushNotification,
  getPushNotifications,
  sendPushNotification,
} from '../../../helpers/api/pushNotifications'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import { CommunicationPermissions, OrderType } from '../../../sharedTypes'
import { SearchInput } from '../../../Components/Common/SearchInput'
import Highlighter from 'react-highlight-words'
import { getUserDisplayName } from '../../../helpers/user'
import { getStatusColor } from '../../../helpers/common'
import {
  errorToastOptions,
  successToastOptions,
} from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import PushNotificationModal from '../../../Components/Modals/PushNotificationModal'
import FilterTabs from './Tabs'
import ViewNotificationModal from '../../../Components/Modals/ViewNotificationModal'
import {
  StatusTypes,
  TPushNotification,
} from '../../../sharedTypes/models/pushNotifications'
import SendNotificationModal from '../../../Components/Modals/PrimaryConfirmationModal'
import { usePermissions } from '../../../hooks/usePermissions'
import {
  IPushNotificationsPermissions,
  PushNotificationDropDown,
} from '../../../Components/ActionDropDown'
import ItemsPerPageDropdown from '../../../Components/Common/ItemsPerPageDropdown'
import PreviewNotificationModal from '../../../Components/Modals/PreviewNotificationModal'

const Columns = [
  {
    name: 'Title',
    sortBy: PushNotificationsSortBy.TITLE,
  },
  {
    name: 'Message',
    sortBy: PushNotificationsSortBy.MESSAGE,
    style: { width: 200 },
  },
  {
    name: 'Recipients',
    sortBy: PushNotificationsSortBy.RECIPIENTS,
  },
  {
    name: 'Clicks',
    sortBy: PushNotificationsSortBy.CLICKS_COUNT,
  },
  {
    name: 'Created by',
    sortBy: PushNotificationsSortBy.USER,
  },
  {
    name: 'Status',
    sortBy: PushNotificationsSortBy.STATUS,
  },
  {
    name: 'Send date',
    sortBy: PushNotificationsSortBy.SEND_DATE,
  },
  {
    name: 'Actions',
    style: { width: 135, textAlign: 'center' } as any,
  },
]

type SelectedNotificationType =
  | 'view'
  | 'delete'
  | 'edit'
  | 'duplicate'
  | 'send'

interface SelectedNotification {
  id: number | null
  type: SelectedNotificationType
}

const PushNotifications = () => {
  document.title = 'Push Notifications | Mastered - Admin & Dashboard'

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [previewNotification, setPreviewNotification] =
    useState<TPushNotification | null>(null)
  const [selectedNotification, setSelectedNotification] =
    useState<SelectedNotification>({
      id: null,
      type: 'view',
    })

  const permissions: IPushNotificationsPermissions = {
    add: usePermissions(CommunicationPermissions.CREATE_CLONE_NOTIFICATIONS),
    edit: usePermissions(CommunicationPermissions.EDIT_NOTIFICATIONS),
    delete: usePermissions(CommunicationPermissions.DELETE_NOTIFICATIONS),
    view: usePermissions(CommunicationPermissions.VIEW_NOTIFICATIONS),
    send: usePermissions(CommunicationPermissions.SEND_SCHEDULE_NOTIFICATIONS),
  }
  const hasPermissions = useMemo(
    () => Object.values(permissions).some(value => value),
    [],
  )

  const [query, setQuery] = useState<GetPushNotificationsDTO.Request>({
    page: 1,
    status: undefined,
    limit: 10,
  })
  const [data, setData] = useState<GetPushNotificationsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    pushNotifications: [],
  })

  useEffect(() => {
    getPushNotificationsData()
  }, [query])

  const getPushNotificationsData = () => {
    setIsLoading(true)
    getPushNotifications(query)
      .then(res => {
        setData(res.data)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSort = useCallback((column: PushNotificationsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onDelete = useCallback(async () => {
    try {
      await deletePushNotification(selectedNotification.id as number)
      setQuery(prev => ({ ...prev, page: 1 }))
      setSelectedNotification({ ...selectedNotification, id: null })
      toast('Success - Notification successfully deleted', successToastOptions)
    } catch (e) {
      toast('Something went wrong', errorToastOptions)
    }
  }, [data.page, selectedNotification.id])

  const handleManageNotificationSubmit = () => {
    handleClose()
    setQuery(q => ({ ...q, page: 1 }))
  }

  const handleClose = () => {
    setSelectedNotification({ type: 'view', id: null })
  }

  const handleDuplicate = async (id: number) => {
    try {
      const data = await duplicatePushNotification(id)
      setQuery(prev => ({ ...prev, page: 1 }))
      setSelectedNotification({ type: 'edit', id: data.id })

      toast('Success - Notification has been duplicated!', successToastOptions)
    } catch (e) {
      toast('Something went wrong', errorToastOptions)
    }
  }

  const handleSend = async () => {
    try {
      await sendPushNotification(Number(selectedNotification.id))
      setSelectedNotification({ ...selectedNotification, id: null })

      toast('Success - Notification has been sent!', successToastOptions)
    } catch (e) {
      toast('Something went wrong', errorToastOptions)
    }
  }

  const getRecipientPercent = (readCount: number, recipientsCount: number) => {
    if (recipientsCount) {
      return Math.round((readCount / recipientsCount) * 100)
    }
    return 0
  }

  const columns = useMemo(() => {
    if (Object.values(permissions).every(value => !value)) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [permissions])

  const message = useMemo(() => {
    const title = data.pushNotifications.find(
      notification => notification.id === selectedNotification.id,
    )?.translations[0].title

    return (
      <div>
        Are you sure you want to send <br />
        {title ? `"${title}"` : ''} notification?
      </div>
    )
  }, [data.pushNotifications, selectedNotification.id])

  const handleRowClick = (id: number, status: StatusTypes) => {
    if (permissions.edit && status !== StatusTypes.SENT) {
      setSelectedNotification({
        id,
        type: 'edit',
      })
    }
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Push Notifications'
          items={[
            {
              title: 'Settings',
              linkTo: '/',
            },
            {
              title: 'Push Notifications',
              active: true,
            },
          ]}
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className='d-flex justify-content-between align-items-center gap-3 flex-wrap'>
                  <SearchInput
                    style={{ maxWidth: 400 }}
                    onChange={key => {
                      setQuery(prev => ({ ...prev, key, page: 1 }))
                    }}
                    value={query.key}
                  />
                  <FilterTabs
                    navTab={query.status}
                    navToggle={status => {
                      setQuery(prev => ({ ...prev, status, page: 1 }))
                    }}
                  />
                  {permissions.add && (
                    <Button
                      color={'primary'}
                      onClick={() =>
                        setSelectedNotification({ type: 'edit', id: null })
                      }
                      className='btn btn-primary align-middle d-flex align-items-center'
                    >
                      <i className='ri-add-line me-1 fs-16'></i>Add New
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card className='m-0 p-0'>
          <NoResultTableWrapper
            isLoading={isLoading}
            isFiltering={false}
            pages={data.pages}
            className={'py-0'}
          >
            <div className='table-card mt-0'>
              <div className='overflow-x-auto'>
                <Table className='align-middle table-nowrap mb-0 push-notifications-table'>
                  <thead className='table-light'>
                    <tr className='text-muted fs-14'>
                      {columns.map(column => (
                        <th
                          scope='col'
                          className='align-middle'
                          style={column.style ?? {}}
                          key={column.name}
                        >
                          {column.name}
                          {!!column.sortBy && (
                            <ColumnSortIcon<PushNotificationsSortBy>
                              sortOrder={query.orderBy}
                              sortedColumn={query.sortBy}
                              column={column.sortBy}
                              handleSort={handleSort}
                            />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.pushNotifications.map((notification, i) => (
                      <tr
                        key={i}
                        className='fs-14'
                        onClick={() => {
                          handleRowClick(notification.id, notification.status)
                        }}
                      >
                        <td>
                          <Highlighter
                            highlightClassName='text-highlight'
                            searchWords={[query.key || '']}
                            highlightTag={'span'}
                            autoEscape={true}
                            textToHighlight={
                              notification.translations[0]?.title
                            }
                          />
                        </td>
                        <td>
                          <Highlighter
                            highlightClassName='text-highlight'
                            searchWords={[query.key || '']}
                            highlightTag={'span'}
                            autoEscape={true}
                            textToHighlight={
                              notification.translations[0]?.message
                            }
                            className='message'
                          />
                        </td>
                        <td>
                          <span>
                            {notification.status === StatusTypes.SENT
                              ? notification.recipientsCount
                              : '-'}
                          </span>
                        </td>
                        <td>
                          <span>
                            {notification.status === StatusTypes.SENT
                              ? `${notification?.readCount} (${getRecipientPercent(
                                  notification.readCount ?? 0,
                                  notification.recipientsCount ?? 0,
                                )}%)`
                              : '-'}
                          </span>
                        </td>
                        <td>
                          <Highlighter
                            highlightClassName='text-highlight'
                            searchWords={[query.key || '']}
                            highlightTag={'span'}
                            autoEscape={true}
                            textToHighlight={getUserDisplayName(
                              notification.createdBy,
                            )}
                          />
                        </td>
                        <td>
                          <span
                            className={`${getStatusColor(
                              notification.status,
                            )} badge text-capitalize`}
                          >
                            {notification.status}
                          </span>
                        </td>
                        <td>
                          {notification.status === StatusTypes.SENT
                            ? moment(notification.updatedAt).format(
                                'MM/DD/YYYY, HH:mm',
                              )
                            : '-'}
                        </td>
                        {hasPermissions && (
                          <td>
                            <div className='d-flex justify-content-center'>
                              <span className='d-flex gap-2 text-muted justify-content-end'>
                                <PushNotificationDropDown
                                  permissions={permissions}
                                  notification={notification}
                                  onDelete={() => {
                                    setSelectedNotification({
                                      id: notification.id,
                                      type: 'delete',
                                    })
                                  }}
                                  onSend={() => {
                                    setSelectedNotification({
                                      id: notification.id,
                                      type: 'send',
                                    })
                                  }}
                                  onEdit={() => {
                                    setSelectedNotification({
                                      id: notification.id,
                                      type: 'edit',
                                    })
                                  }}
                                  onDuplicate={() => {
                                    handleDuplicate(notification.id)
                                  }}
                                  onView={() => {
                                    setSelectedNotification({
                                      id: notification.id,
                                      type: 'view',
                                    })
                                  }}
                                  onPreview={notification => {
                                    setPreviewNotification(notification)
                                  }}
                                />
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className='my-3 pt-3'>
              <ItemsPerPageDropdown
                limit={query.limit ? query.limit : 10}
                onChange={limit =>
                  setQuery(prev => ({ ...prev, limit: +limit.label }))
                }
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={data.pages}
                totalRecords={data.count}
                setPage={page => {
                  setQuery(prev => ({ ...prev, page: ++page }))
                }}
                limit={query.limit}
              />
            </div>
          </NoResultTableWrapper>
        </Card>

        <DeleteConfirmation
          isOpen={
            !!(
              selectedNotification.id && selectedNotification.type === 'delete'
            )
          }
          title='Delete notification?'
          message={`Are you sure you want to delete "${data.pushNotifications.find(
            notification => notification.id === selectedNotification.id,
          )?.translations[0].title}" notification?`}
          onDelete={onDelete}
          onClose={() => {
            setSelectedNotification({ ...selectedNotification, id: null })
          }}
        />

        <PushNotificationModal
          id={selectedNotification.id}
          isOpen={selectedNotification.type === 'edit'}
          onClose={handleClose}
          onSubmit={handleManageNotificationSubmit}
        />
        {selectedNotification.id && selectedNotification.type === 'view' && (
          <ViewNotificationModal
            onClose={() =>
              setSelectedNotification({ ...selectedNotification, id: null })
            }
            isOpen={
              !!(
                selectedNotification.id && selectedNotification.type === 'view'
              )
            }
            id={selectedNotification.id}
          />
        )}

        <SendNotificationModal
          onConfirm={handleSend}
          onClose={() =>
            setSelectedNotification({ ...selectedNotification, id: null })
          }
          isOpen={
            !!(selectedNotification.id && selectedNotification.type === 'send')
          }
          message={message}
          title={'Send notification?'}
          confirmButtonLabel={'Send'}
        />

        {previewNotification && (
          <PreviewNotificationModal
            onClose={() => setPreviewNotification(null)}
            isOpen={!!previewNotification}
            notification={previewNotification}
          />
        )}
      </Container>
    </div>
  )
}

export default PushNotifications
