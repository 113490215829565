import { DOCUMENT_TYPES } from '../../pages/Authentication/UserProfile/DocumentModal'
import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import { StatesEnum } from '../lib/locations'

export enum DocumentSortBy {
  TYPE = 'type',
  LICENSE_TYPE = 'licenseType',
  EXPIRY_DATE = 'expiryDate',
  DOCUMENT_ID = 'documentId',
  ORGANIZATION = 'organization',
  VALID_DATE = 'validDate',
  STATE = 'state',
  SOURCE = 'source',
}

export type DocumentFile = {
  attachmentId?: number
  preview: string
  formattedSize: string
  name: string
  type?: string
  blob?: string
}

interface ExtendedFile extends File, Omit<DocumentFile, 'name' | 'type'> {}

export enum SOURCE_TYPES {
  HOSTED_IMPORT = 'Hosted Import',
  MASTERED_APP = 'Mastered App',
  MASTERED_ADMIN = 'Mastered Admin',
}

export type IDocumentItem = {
  id?: number
  userId: number
  type: string
  licenseType: string
  url: string
  documentId: string
  disciplineIds: number[]
  state: keyof typeof StatesEnum
  source: SOURCE_TYPES
  organization: string
  validDate: Date
  expiryDate: Date
}

export namespace CreateDocumentDto {
  export interface Request extends DocumentModalSubmitedValues {}
  export interface Response extends IDocumentItem {}
}

export namespace UploadDocumentDto {
  export interface Response {
    expirationDate: string | null
    issueDate: string | null
    documentType: DOCUMENT_TYPES
    documentId: string | null
    organization: string | null
    state: string | null
  }
}

export namespace GetDocumentsDTO {
  export interface Request extends PaginationRequest {
    sortBy?: DocumentSortBy
  }

  export interface Response extends PaginatedBaseResponse {
    documents: IDocumentItem[]
  }
}

export namespace GetLicenseTypesDto {
  export interface Response {
    licenseTypes: string[]
  }
}

export type DocumentModalSubmitedValues = {
  id?: number
  userId: number
  type: DOCUMENT_TYPES
  licenseType?: {
    label: string
    value: string
  }
  document: Blob
  documentId: string
  disciplineIds: number[]
  organization: string
  state?:
    | {
        label: StatesEnum
        value: keyof typeof StatesEnum
      }
    | undefined
  validDate: Date
  expiryDate: Date
}

export type DocumentModalInitialValues = {
  id?: number
  userId: number
  type: string
  licenseType?: {
    label: string
    value: string
  }
  document?: ExtendedFile | DocumentFile
  disciplineIds: number[]
  documentId: string
  state:
    | {
        label: StatesEnum
        value: keyof typeof StatesEnum
      }
    | undefined
  organization: string | undefined
  validDate: Date | undefined
  expiryDate: Date | undefined
}
