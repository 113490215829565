import { Col, Row } from 'reactstrap'
import React, { Dispatch, SetStateAction } from 'react'
import {
  COURSE_FILTER_FORMATS,
  COURSE_TYPES,
  CourseFiltersFormatsOptions,
  CourseAssignTypesOptions,
  FilterOption,
} from '../../../sharedTypes'
import _ from 'lodash'
import MultiSelect from '../../../Components/Common/MultiSelect'
import {
  AVG_DAYS_TO_COMPLETE,
  AVG_TEST_SCORE,
  AVG_USER_RATING,
  avgDaysToComplete,
  avgTestScore,
  avgUserRating,
  RETAKE_COURSE,
  RETAKE_TEST,
  retakeCourse,
  retakeTest,
} from './types'
import { GetCourseReportDTO } from '../../../sharedTypes/api/courseReport'

interface ReportFiltersProps {
  showFilters: boolean
  setFilters: Dispatch<SetStateAction<GetCourseReportDTO.Request>>
  query: GetCourseReportDTO.Request
}

const ReportFilters = ({
  showFilters,
  setFilters,
  query,
}: ReportFiltersProps) => {
  return (
    <>
      {showFilters && (
        <Row className=' mx-0 gap-4'>
          <Col className='mt-0 p-0 filter-col'>
            <div>
              <label htmlFor='type' className='form-label'>
                Filter by Type
              </label>

              <MultiSelect
                name='type'
                id='type'
                isMulti
                options={CourseAssignTypesOptions}
                isSearchable={true}
                placeholder='Select type'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    courseType: _.isEmpty(option)
                      ? []
                      : (_.map(option, 'value') as COURSE_TYPES[]),
                    page: 1,
                  }))
                }}
                defaultValue={CourseAssignTypesOptions.filter(score =>
                  (query.courseType || []).includes(score.value),
                )}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0 filter-col'>
            <div>
              <label htmlFor='format' className='form-label'>
                Filter by Format
              </label>

              <MultiSelect
                name='format'
                id='format'
                isMulti
                options={CourseFiltersFormatsOptions}
                isSearchable={true}
                placeholder='Select format'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    courseFormat: _.isEmpty(option)
                      ? []
                      : (_.map(option, 'value') as COURSE_FILTER_FORMATS[]),
                    page: 1,
                  }))
                }}
                defaultValue={CourseFiltersFormatsOptions.filter(format =>
                  (query.courseFormat || []).includes(format.value),
                )}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0 filter-col'>
            <label htmlFor='avgTestScore' className='form-label'>
              Avg. Test Score
            </label>

            <MultiSelect
              name='avgTestScore'
              id='avgTestScore'
              isMulti
              options={avgTestScore}
              isSearchable={true}
              placeholder='Select option'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setFilters(prev => ({
                  ...prev,
                  avgTestScore: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as AVG_TEST_SCORE[]),
                  page: 1,
                }))
              }}
              defaultValue={avgTestScore.filter(score =>
                (query.avgTestScore || []).includes(score.value),
              )}
            />
          </Col>
          <Col className='mt-0 p-0 filter-col'>
            <label htmlFor='coursesInProgress' className='form-label'>
              Avg. Days Taken to Complete
            </label>

            <MultiSelect
              name='avgDaysToComplete'
              id='avgDaysToComplete'
              isMulti
              options={avgDaysToComplete}
              isSearchable={true}
              placeholder='Select option'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setFilters(prev => ({
                  ...prev,
                  avgDaysToComplete: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as AVG_DAYS_TO_COMPLETE[]),
                  page: 1,
                }))
              }}
              defaultValue={avgDaysToComplete.filter(score =>
                (query.avgDaysToComplete || []).includes(score.value),
              )}
            />
          </Col>
          <Col className='mt-0 p-0 filter-col'>
            <label htmlFor='retakeTest' className='form-label'>
              Retake Test
            </label>

            <MultiSelect
              name='retakeTest'
              id='retakeTest'
              isMulti
              options={retakeTest}
              isSearchable={true}
              placeholder='Select option'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setFilters(prev => ({
                  ...prev,
                  retakeTest: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as RETAKE_TEST[]),
                  page: 1,
                }))
              }}
              defaultValue={retakeTest.filter(score =>
                (query.retakeTest || []).includes(score.value),
              )}
            />
          </Col>

          <Col className='mt-0 p-0 filter-col'>
            <label htmlFor='coursesOverdue' className='form-label'>
              Retake Course
            </label>

            <MultiSelect
              name='retakeCourse'
              id='retakeCourse'
              isMulti
              options={retakeCourse}
              isSearchable={true}
              placeholder='Select option'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setFilters(prev => ({
                  ...prev,
                  retakeCourse: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as RETAKE_COURSE[]),
                  page: 1,
                }))
              }}
              defaultValue={retakeCourse.filter(score =>
                (query.retakeCourse || []).includes(score.value),
              )}
            />
          </Col>

          <Col className='mt-0 p-0 filter-col'>
            <label htmlFor='coursesOverdue' className='form-label'>
              Avg. User Rating
            </label>

            <MultiSelect
              name='avgUserRating'
              id='avgUserRating'
              isMulti
              options={avgUserRating}
              isSearchable={true}
              placeholder='Select option'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setFilters(prev => ({
                  ...prev,
                  avgUserRating: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as AVG_USER_RATING[]),
                  page: 1,
                }))
              }}
              defaultValue={avgUserRating.filter(score =>
                (query.avgUserRating || []).includes(score.value),
              )}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default ReportFilters
