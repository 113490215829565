import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalProps,
  Row,
  Spinner,
} from 'reactstrap'
import { useFormik } from 'formik'
import Flatpickr from 'react-flatpickr'
import { AsyncSelectWithSearch } from '../../Components/Common/SelectWithSearch'
import moment from 'moment'
import { scheduledCourseSchema } from '../../schemas'
import {
  CoursesPermissions,
  GetAdminsDTO,
  RecurringTypes,
  ScheduledCourseInitialValues,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
} from '../../sharedTypes'
import {
  getAdmins,
  getFacilities,
  postScheduledCourses,
  putScheduledCourses,
} from '../../helpers/api_helper'
import { handleError } from '../../helpers/toast_helper'

interface ScheduledCourseModalProps {
  onClose: (refetch?: boolean) => void
  isOpen: ModalProps['isOpen']
  entityId: number
  initialValues: ScheduledCourseInitialValues | null
  modalTitle: string
  webinarTitle: string
  entityType: ScheduledEntitiesTypes
  onRefetch?: () => void
}

const ScheduledCourseModal = ({
  entityId,
  onClose,
  onRefetch,
  isOpen,
  initialValues,
  modalTitle,
  webinarTitle,
  entityType,
}: ScheduledCourseModalProps) => {
  const datePickerRef = useRef<Flatpickr>(null)
  const recurringPickerRef = useRef<Flatpickr>(null)
  const [facilities, setFacilities] = useState<any>([])
  const [saveAndCreate, setSaveAndCreate] = useState<boolean>(false)
  const [users, setUsers] = useState<any>([])

  const isCourseType = entityType === ScheduledEntitiesTypes.COURSE

  const onSubmit = useCallback(
    async (values: ScheduledCourseInitialValues, { resetForm }: any) => {
      try {
        const payload = {
          ...values,
          startDate: moment(values.startDate)
            .set('hours', moment(values.startTime).hours())
            .set('minute', moment(values.startTime).minutes()),
          startTime: values.startTime,
          endTime: values.endTime,
          entityType,
        }
        if (values.id) {
          await putScheduledCourses(values.id, entityId, payload)
        } else {
          await postScheduledCourses(entityId, payload)
        }

        resetForm()

        if (!saveAndCreate) {
          onClose(true)
        } else if (onRefetch) {
          onRefetch()
        }
      } catch (e) {
        handleError(e)
      }
    },
    [entityId, saveAndCreate],
  )

  const permission = useMemo(() => {
    if (entityType === ScheduledEntitiesTypes.TRAINING_DAY) {
      return initialValues?.id
        ? CoursesPermissions.EDIT_PACKAGE_SCHEDULE
        : CoursesPermissions.ADD_PACKAGE_SCHEDULE
    }

    return initialValues?.id
      ? CoursesPermissions.EDIT_COURSE_SCHEDULE
      : CoursesPermissions.ADD_COURSE_SCHEDULE
  }, [entityType, initialValues])

  const fetchUsers = (inputValue?: string) => {
    const params: GetAdminsDTO.Request = {
      page: 1,
      limit: 10,
      key: inputValue,
      permission,
    }

    return getAdmins(params)
      .then(d => d.data)
      .then(({ admins }) => {
        const list = admins.map(u => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`,
        }))

        setUsers(list)

        return list
      })
  }

  const fetchFacilities = useCallback((inputValue?: string) => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }
    return getFacilities(params)
      .then(d => d.data)
      .then(({ facilities }) => {
        const list = facilities.map(facility => ({
          value: facility.id,
          label: facility.name,
        }))
        setFacilities(list)
        return list
      })
      .catch(() => [])
  }, [])

  const form = useFormik<ScheduledCourseInitialValues>({
    enableReinitialize: true,
    initialValues: initialValues || {
      type: ScheduledCourseTypes.IN_PERSON,
      recurring: RecurringTypes.ONE_TIME,
      facilityId: null,
      location: '',
      facilitatorId: null,
      startDate: undefined,
      startTime: undefined,
      endTime: undefined,
      recurringEnd: undefined,
    },
    validationSchema: scheduledCourseSchema,
    onSubmit,
  })

  const facilitatorValue = useMemo(
    () => users.find((f: any) => f.value === form.values.facilitatorId) || null,
    [form.values],
  )

  const facilityValue = useMemo(
    () =>
      facilities.find((f: any) => f.value === form.values.facilityId) || null,
    [form.values],
  )

  const onBeforeSubmit = useCallback(
    (saveAndCreate: boolean) => {
      setSaveAndCreate(saveAndCreate)
      form.handleSubmit()
    },
    [form],
  )

  return (
    <Modal style={{ minWidth: 750 }} isOpen={isOpen} toggle={() => {}} centered>
      <ModalBody className='modal-body schedule-modal'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{modalTitle}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={() => onClose()}
          ></i>
        </div>
        <Row className='mb-3'>
          {isCourseType && (
            <Col col='4'>
              <div>
                <label htmlFor='bio' className='form-label'>
                  Type
                </label>
                <div className='d-flex flex-column'>
                  <div className='form-check'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      name='webinar'
                      checked={
                        form.values.type === ScheduledCourseTypes.IN_PERSON
                      }
                      onChange={() => {
                        form.setFieldValue(
                          'type',
                          ScheduledCourseTypes.IN_PERSON,
                        )
                      }}
                      id='in_person'
                    />
                    <Label className='form-check-label' htmlFor='in_person'>
                      In Person
                    </Label>
                  </div>
                  <div className='form-check'>
                    <Input
                      className='form-check-input'
                      type='radio'
                      name='webinar'
                      checked={
                        form.values.type === ScheduledCourseTypes.WEBINAR
                      }
                      onChange={() => {
                        form.setFieldValue('type', ScheduledCourseTypes.WEBINAR)
                      }}
                      defaultChecked
                      id='webinar'
                    />
                    <Label className='form-check-label' htmlFor='webinar'>
                      {webinarTitle}
                    </Label>
                  </div>
                </div>
              </div>
            </Col>
          )}

          <Col sm={`${isCourseType ? '4' : '6'}`}>
            <label className='form-label'>Facility</label>
            <AsyncSelectWithSearch
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  borderRadius: '4px 0px 0px 4px',
                  minHeight: 39,
                  borderColor:
                    form.touched.facilityId && form.errors.facilityId
                      ? '#f06548 !important'
                      : undefined,
                }),
              }}
              placeholder='Select facility'
              className='select2-container is-invalid'
              name='facilityId'
              id='facilityId'
              onChange={option =>
                form.setFieldValue('facilityId', option?.value)
              }
              onBlur={() => {
                form.setFieldTouched('facilityId', true)
              }}
              loadOptions={fetchFacilities}
              value={facilityValue}
              isMulti={false}
              isClearable={true}
              error={
                form.touched.facilityId ? form.errors.facilityId : undefined
              }
              isSearchable={true}
              defaultOptions
            />
            {form.touched.facilityId && form.errors.facilityId ? (
              <FormFeedback type='invalid'>
                {form.errors.facilityId as string}
              </FormFeedback>
            ) : null}
          </Col>

          <Col sm={`${isCourseType ? '4' : '6'}`}>
            <div>
              <label htmlFor='title' className='form-label'>
                Location Details
              </label>
              <Input
                className='form-control'
                id='location'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                name='location'
                placeholder='Location'
                value={form.values.location}
                invalid={!!(form.touched.location && form.errors.location)}
              />
              {form.touched.location && form.errors.location ? (
                <FormFeedback type='invalid'>
                  {form.errors.location}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={4}>
            <div>
              <label htmlFor='date' className='form-label'>
                Date
              </label>
              <div className='form-icon right is-invalid'>
                <Flatpickr
                  className={`form-control form-control-icon ${
                    form.touched.startDate && form.errors.startDate
                      ? ' is-invalid'
                      : ''
                  }`}
                  ref={datePickerRef}
                  id='date'
                  name='startDate'
                  placeholder='mm-dd-yyyy'
                  onChange={option => {
                    form.setFieldValue('startDate', option[0])
                  }}
                  value={form.values.startDate}
                  options={{
                    dateFormat: 'MM-DD-YYYY',
                    formatDate: date => moment(date).format('MM/DD/YYYY'),
                    minDate: Date.now(),
                    allowInvalidPreload: true,
                  }}
                />
                {form.values.startDate && (
                  <i
                    className='ri-close-line fs-16 text-danger'
                    onClick={() => {
                      datePickerRef.current?.flatpickr.clear()
                    }}
                  ></i>
                )}
                <i className='ri-calendar-event-line fs-20'></i>
              </div>
              {form.touched.startDate && form.errors.startDate ? (
                <FormFeedback type='invalid'>
                  {form.errors.startDate}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col md={4}>
            <div>
              <label htmlFor='startTime' className='form-label'>
                Start Time
              </label>
              <div className='form-icon right is-invalid'>
                <Flatpickr
                  className={`form-control form-control-icon ${
                    form.touched.startTime && form.errors.startTime
                      ? ' is-invalid'
                      : ''
                  }`}
                  id='startTime'
                  name='startTime'
                  placeholder='--:--'
                  onChange={option => {
                    form.setFieldValue('startTime', option[0])
                  }}
                  onBlur={() => {
                    form.setFieldTouched('startTime', true)
                  }}
                  value={form.values.startTime}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:i K',
                    minTime: moment(form.values.startDate).isSame(
                      moment().startOf('day'),
                    )
                      ? Date.now()
                      : undefined,
                    allowInvalidPreload: true,
                  }}
                />
                <i className='ri-time-line fs-20'></i>
              </div>
              {form.touched.startTime && form.errors.startTime ? (
                <FormFeedback type='invalid'>
                  {form.errors.startTime}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col md={4}>
            <div>
              <label htmlFor='date' className='form-label'>
                End Time
              </label>
              <div className='form-icon right is-invalid'>
                <Flatpickr
                  className={`form-control form-control-icon ${
                    form.touched.endTime && form.errors.endTime
                      ? ' is-invalid'
                      : ''
                  }`}
                  id='endTime'
                  name='endTime'
                  placeholder='--:--'
                  onChange={option => {
                    form.setFieldValue('endTime', option[0])
                  }}
                  onBlur={() => {
                    form.setFieldTouched('endTime', true)
                  }}
                  value={form.values.endTime}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:i K',
                    minTime: moment(form.values.startDate).isSame(
                      moment().startOf('day'),
                    )
                      ? Date.now()
                      : undefined,
                    allowInvalidPreload: true,
                  }}
                />
                <i className='ri-time-line fs-20'></i>
              </div>
              {form.touched.endTime && form.errors.endTime ? (
                <FormFeedback type='invalid'>
                  {form.errors.endTime}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm='4'>
            <label className='form-label'>Facilitator</label>
            <div>
              <AsyncSelectWithSearch
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    borderRadius: '4px 0px 0px 4px',
                    minHeight: 39,
                    borderColor:
                      form.touched.facilitatorId && form.errors.facilitatorId
                        ? '#f06548 !important'
                        : undefined,
                  }),
                }}
                placeholder='Select facilitator'
                className='select2-container is-invalid'
                name='facilitatorId'
                id='facilitatorId'
                onChange={option => {
                  form.setFieldValue('facilitatorId', option?.value)
                }}
                onBlur={() => {
                  form.setFieldTouched('facilitatorId', true)
                }}
                value={facilitatorValue}
                loadOptions={fetchUsers}
                error={
                  form.touched.facilitatorId
                    ? form.errors.facilitatorId
                    : undefined
                }
                isMulti={false}
                isClearable={true}
                isSearchable={true}
                defaultOptions
              />
              {form.touched.facilitatorId && form.errors.facilitatorId ? (
                <FormFeedback type='invalid'>
                  {form.errors.facilitatorId as string}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col col='4'>
            <div>
              <label htmlFor='bio' className='form-label'>
                Repeats
              </label>
              <div className='d-flex gap-3'>
                <div className='form-check mb-2'>
                  <Input
                    className='form-check-input'
                    type='radio'
                    name='certificateRequired'
                    checked={form.values.recurring === RecurringTypes.ONE_TIME}
                    onChange={() => {
                      form.setFieldValue('recurring', RecurringTypes.ONE_TIME)
                    }}
                    id='oneTime'
                  />
                  <Label className='form-check-label' htmlFor='oneTime'>
                    One Time
                  </Label>
                </div>
                <div className='form-check'>
                  <Input
                    className='form-check-input'
                    type='radio'
                    name='certificateRequired'
                    checked={form.values.recurring === RecurringTypes.WEEKLY}
                    onChange={() => {
                      form.setFieldValue('recurring', RecurringTypes.WEEKLY)
                    }}
                    defaultChecked
                    id='weekly'
                  />
                  <Label className='form-check-label' htmlFor='weekly'>
                    Weekly
                  </Label>
                </div>
              </div>
            </div>
          </Col>

          {form.values.recurring === RecurringTypes.WEEKLY && (
            <Col md={4}>
              <label htmlFor='date' className='form-label'>
                End Recurring
              </label>
              <div className='form-icon right is-invalid'>
                <Flatpickr
                  className={`form-control form-control-icon ${
                    form.touched.recurringEnd && form.errors.recurringEnd
                      ? ' is-invalid'
                      : ''
                  }`}
                  ref={recurringPickerRef}
                  id='date'
                  name='recurringEnd'
                  placeholder='mm-dd-yyyy, --:--'
                  onChange={option => {
                    form.setFieldValue('recurringEnd', option[0])
                  }}
                  onBlur={() => {
                    form.setFieldTouched('recurringEnd', true)
                  }}
                  value={form.values.recurringEnd}
                  options={{
                    dateFormat: 'MM-DD-YYYY',
                    formatDate: date => moment(date).format('MM/DD/YYYY'),
                    minDate: Date.now(),
                    allowInvalidPreload: true,
                  }}
                />
                {form.values.recurringEnd && (
                  <i
                    className='ri-close-line fs-16 text-danger'
                    onClick={() => {
                      recurringPickerRef.current?.flatpickr.clear()
                    }}
                  ></i>
                )}
                <i className='ri-calendar-event-line fs-20'></i>
              </div>
              {form.touched.recurringEnd && form.errors.recurringEnd ? (
                <FormFeedback type='invalid'>
                  {form.errors.recurringEnd}
                </FormFeedback>
              ) : null}
            </Col>
          )}
        </Row>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-primary'
            onClick={() => onClose()}
            active={false}
            disabled={form.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            type='button'
            className='center'
            onClick={() => {
              onBeforeSubmit(false)
            }}
          >
            {form.isSubmitting ? <Spinner size={'sm'} /> : 'Save'}
          </Button>
          {!form.values.id && (
            <Button
              color={'primary'}
              className='btn btn-primary align-middle'
              onClick={() => {
                onBeforeSubmit(true)
              }}
            >
              {form.isSubmitting ? (
                <Spinner size={'sm'} />
              ) : (
                <>
                  <i className='ri-add-line me-1 fs-16'></i>Save and Add Another
                  Schedule
                </>
              )}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ScheduledCourseModal
