import React, { createContext, useContext, useState } from 'react'
import {
  GetTranscriptDTO,
  PrintTranscriptModalValues,
  TUser,
} from '../sharedTypes'
import { Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { switchToPortal } from '../helpers/company'
import useIsMasteredAdmin from '../hooks/useIsMasteredAdmin'
import { handleError } from '../helpers/toast_helper'
import {
  deactivateUserById,
  getUserAssignments,
  getUserAssignmentsTranscript,
} from '../helpers/api_helper'
import { toast } from 'react-toastify'
import { FormikHelpers } from 'formik'
import { generateFileName } from '../helpers/getExportFileName'

interface UserContextType {
  user: TUser | undefined
  setUser: React.Dispatch<React.SetStateAction<TUser | undefined>>
  printTranscript: (
    values: PrintTranscriptModalValues,
    form: FormikHelpers<PrintTranscriptModalValues>,
  ) => void
  showPrintTranscript: boolean | undefined
  handleUserGet: (data: TUser) => void
  onDeactivate: () => void
  setShowPrintTranscript: React.Dispatch<React.SetStateAction<boolean>>
  handlePrintTranscript: () => void
  setUserHasAnyCompletedAssignments: React.Dispatch<
    React.SetStateAction<boolean>
  >
  userHasAnyCompletedAssignments: boolean | undefined
}

const UserContext = createContext<UserContextType | undefined>({
  user: undefined,
  showPrintTranscript: undefined,
  userHasAnyCompletedAssignments: undefined,
  setUser: () => {},
  handleUserGet: () => {},
  printTranscript: () => {},
  onDeactivate: () => {},
  setShowPrintTranscript: () => {},
  handlePrintTranscript: () => {},
  setUserHasAnyCompletedAssignments: () => {},
})

export const UserContextProvider = () => {
  const [user, setUser] = useState<TUser | undefined>(undefined)
  const dispatch = useAppDispatch()
  const isMasteredAdmin = useIsMasteredAdmin()
  const admin = useAppSelector(state => state.User.user)
  const [showPrintTranscript, setShowPrintTranscript] = useState(false)
  const [userHasAnyCompletedAssignments, setUserHasAnyCompletedAssignments] =
    useState<boolean>(false)

  const printTranscript = async (
    values: PrintTranscriptModalValues,
    form: FormikHelpers<PrintTranscriptModalValues>,
  ) => {
    try {
      if (user) {
        let dto: GetTranscriptDTO.Request
        if (values.rangeType === 'dateRange') {
          dto = {
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
          }
        } else {
          dto = {}
        }

        const transcript = await getUserAssignmentsTranscript(user.id, dto)

        form.setSubmitting(false)
        form.resetForm()
        setShowPrintTranscript(false)

        const pdfBlob = new Blob([new Uint8Array(transcript.data.data)], {
          type: 'application/pdf',
        })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        const link = document.createElement('a')
        link.href = pdfUrl
        link.download = `${generateFileName('Transcript')}.pdf`
        link.click()
      }
    } catch (e) {
      handleError(e)
      form.setSubmitting(false)
    }
  }

  const handlePrintTranscript = async () => {
    if (user) {
      const query = {
        userId: user.id,
        page: 1,
        limit: 1,
        status: undefined,
      }
      await getUserAssignments(query)
        .then(res => {
          setUserHasAnyCompletedAssignments(
            res.data.userHasAnyCompletedAssignments,
          )
          if (res.data.userHasAnyCompletedAssignments) {
            setShowPrintTranscript(true)
          } else {
            toast(
              'Unable to generate transcript. The user does not have any completed courses.',
              { type: 'error' },
            )
          }
        })
        .catch(handleError)
    }
  }

  const handleUserGet = (data: TUser) => {
    if (isMasteredAdmin && admin && data.companyId) {
      switchToPortal(
        admin,
        data.companyId,
        dispatch,
        `/user-listing/profile/${data.id}`,
      ).catch(handleError)
    } else {
      setUser(data)
    }
  }

  const onDeactivate = () => {
    if (!user) {
      return
    }
    deactivateUserById(user?.id)
      .then(res => {
        toast(
          user.deactivated
            ? 'Successfully activated'
            : 'Successfully deactivated',
          { type: 'success' },
        )
        setUser(res.data)
      })
      .catch(handleError)
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        handleUserGet,
        onDeactivate,
        printTranscript,
        showPrintTranscript,
        handlePrintTranscript,
        setUserHasAnyCompletedAssignments,
        userHasAnyCompletedAssignments,
        setShowPrintTranscript,
      }}
    >
      <Outlet />
    </UserContext.Provider>
  )
}

export const useUser = (): UserContextType => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserContextProvider')
  }
  return context
}
