import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Button, Card, Col, FormFeedback, Row } from 'reactstrap'
import InstructorModal from '../../../../Components/Modals/InstructorModal'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {
  DocumentFile,
  SettingsPermissions,
  TAttachment,
  TInstructor,
} from '../../../../sharedTypes'
import { AsyncSelectWithSearch } from '../../../../Components/Common/SelectWithSearch'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import { CourseLayout } from '../../../../Components/Course'
import { CourseContext } from '../../../../context/CourseContext'
import { getUserDisplayName } from '../../../../helpers/user'
import CourseCodes from './CourseCodes'
import _ from 'lodash'
import { usePermissions } from '../../../../hooks/usePermissions'
import useIsMasteredAdmin from '../../../../hooks/useIsMasteredAdmin'
import Dropzone from 'react-dropzone'
import formatBytes from '../../../../utils/formatBytes'
import { Link } from 'react-router-dom'
import {
  deleteCEFiles,
  getCEDetails,
  uploadCEFiles,
} from '../../../../helpers/api_helper'
import { toast } from 'react-toastify'
import {
  handleError,
  successToastOptions,
} from '../../../../helpers/toast_helper'
import { getMediaDetails } from '../../../../helpers/common'
import AudioPlayer from './AudioPlayer'

interface GenerateStepProps extends WithRouterProps {}

const ContinuingEducation = ({ router }: GenerateStepProps) => {
  const {
    course,
    setCourseId,
    generalStep,
    goNext,
    onBack,
    onNavigate,
    tabs,
    setIsValidCECourse,
    onPublish,
  } = useContext(CourseContext)
  const {
    form,
    selectedLanguage,
    fetchInstructors,
    translationError,
    hasUnsavedData,
    onPreHandleSubmit,
    onDiscardChanges,
  } = generalStep

  const [showCreateInstructorModal, setCreateInstructorModal] =
    useState<boolean>(false)
  const [reload, setReload] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const [pdfFile, setPdfFile] = useState<Omit<DocumentFile, 'type'> | null>()
  const [audioFile, setAudioFile] = useState<Omit<
    DocumentFile,
    'type'
  > | null>()

  const permissionToAddInstructor = usePermissions(
    SettingsPermissions.ADD_INSTRUCTOR,
  )
  const isMasteredAdmin = useIsMasteredAdmin()

  useEffect(() => {
    setCourseId(Number(router.params.courseId))
    if (router.params.courseId) {
      getCEDetails(+router.params.courseId)
        .then(data => {
          if (data.pdfFileId) {
            setPdfFile(getMediaDetails(data.pdfFile as TAttachment))
          }
          if (data.audioFile) {
            setAudioFile(getMediaDetails(data.audioFile as TAttachment))
          }
        })
        .catch(handleError)
    }
  }, [router.params.courseId])

  const onCreateInstructor = (instructor: TInstructor) => {
    form.setFieldValue('instructor', {
      label: getUserDisplayName(instructor),
      value: instructor.id,
    })
    setCreateInstructorModal(false)
    setReload(prev => prev + 1)
  }

  const selectedLanguageIndex = useMemo(
    () =>
      form.values.translations.findIndex(
        t => selectedLanguage === t.languageId,
      ) || 0,
    [form.values.translations],
  )

  const handleUploadedFile = async (file: File, type: 'audio' | 'pdf') => {
    try {
      if (course) {
        const isPdf = type === 'pdf'
        if (!isPdf) {
          setIsUploading(true)
        }
        const formData = new FormData()
        formData.append(isPdf ? 'pdfFile' : 'audioFile', file)

        await uploadCEFiles(course.id, formData)
        const uploadedFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
        if (isPdf) {
          setPdfFile(uploadedFile)
        } else {
          setAudioFile(uploadedFile)
          setIsUploading(false)
        }
      }
    } catch (e) {
      handleError(e)
      setIsUploading(false)
    }
  }

  const onDeleteFile = async (type: 'pdf' | 'audio') => {
    try {
      setIsLoading(true)
      const isPdf = type === 'pdf'
      const property = isPdf ? 'pdfFileId' : 'audioFileId'
      await deleteCEFiles(course?.id as number, {
        [property]: true,
      })

      if (isPdf) {
        setPdfFile(null)
      } else {
        setAudioFile(null)
      }
      setIsLoading(false)
      toast(
        `Success - ${isPdf ? 'PDF ' : 'Audio '} file deleted successfully`,
        successToastOptions,
      )
    } catch (e) {
      handleError(e)
      setIsLoading(false)
    }
  }

  return (
    <CourseLayout
      tabs={tabs}
      showSaveAsDraft
      backText='Cancel'
      onNavigate={direction => {
        if (!hasUnsavedData) {
          onDiscardChanges()
          onNavigate(direction)
        } else {
          onPreHandleSubmit({ direction })
        }
      }}
      hasUnsavedData={false}
      router={router}
      course={course}
      onBack={onBack}
      onNext={() => {
        if (!hasUnsavedData) {
          onDiscardChanges()
          goNext()
          setIsValidCECourse(!!(pdfFile || audioFile))
        } else {
          onPreHandleSubmit({ direction: 'next' })
        }
      }}
      onSaveAsDraft={() => {
        onPreHandleSubmit({ direction: 'courses' })
      }}
    >
      <>
        <div className='w-100'>
          <Col>
            <Row style={{ rowGap: 15 }}>
              <Col className='gap-3 d-flex flex-column'>
                <div>
                  <label className='form-label'>Instructor</label>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <AsyncSelectWithSearch
                        styles={{
                          control: baseStyles => ({
                            ...baseStyles,
                            borderRadius: '4px 0px 0px 4px',
                            minHeight: 39,
                            borderColor:
                              form.touched.instructorId &&
                              form.errors.instructorId
                                ? '#f06548 !important'
                                : undefined,
                          }),
                        }}
                        placeholder='Select instructor'
                        className='select2-container is-invalid'
                        name='instructorId'
                        id='instructorId'
                        key={reload}
                        onChange={option =>
                          form.setFieldValue('instructorId', option)
                        }
                        onBlur={() => {
                          form.setFieldTouched('instructorId', true)
                        }}
                        value={form.values.instructorId}
                        isMulti={false}
                        isClearable={true}
                        isSearchable={true}
                        defaultOptions
                        loadOptions={fetchInstructors}
                        error={
                          form.touched.instructorId
                            ? form.errors.instructorId
                            : undefined
                        }
                      />
                    </div>
                    {permissionToAddInstructor && isMasteredAdmin && (
                      <div>
                        <div
                          className='btn btn-light w-auto text-nowrap border-1'
                          style={{
                            borderColor: '#CED4DA',
                            borderLeft: 0,
                            borderRadius: '0px 4px 4px 0px',
                          }}
                          onClick={() => setCreateInstructorModal(true)}
                        >
                          Add instructor
                        </div>
                      </div>
                    )}
                  </div>
                  {form.touched.instructorId && form.errors.instructorId ? (
                    <FormFeedback type='invalid'>
                      {form.errors.instructorId}
                    </FormFeedback>
                  ) : null}
                </div>
                <div>
                  <label htmlFor='disclosures' className='form-label'>
                    Disclosures
                  </label>
                  <div className='is-invalid'>
                    {' '}
                    <CKEditor
                      editor={ClassicEditor}
                      data={_.get(
                        form.values,
                        `translations[${selectedLanguageIndex}].disclosures`,
                        '',
                      )}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData()
                        form.setFieldValue(
                          `translations[${selectedLanguageIndex}].disclosures`,
                          data,
                        )
                      }}
                      onBlur={() =>
                        form.handleBlur(
                          `translations[${selectedLanguageIndex}].disclosures`,
                        )
                      }
                    />
                  </div>
                  {translationError(selectedLanguageIndex, 'disclosures')}
                </div>
              </Col>
              <Col className='gap-3 d-flex flex-column'>
                <div>
                  <label className='form-label'>PDF file</label>
                  <Dropzone
                    maxFiles={1}
                    disabled={form.isSubmitting}
                    multiple={false}
                    accept={{
                      'application/pdf': [],
                    }}
                    onDrop={acceptedFiles => {
                      handleUploadedFile(acceptedFiles[0], 'pdf')
                    }}
                  >
                    {({ getRootProps }) => (
                      <div className='dropzone dz-clickable cursor-pointer'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}
                        >
                          <div className='mt-2'>
                            <i className='display-7 text-muted ri-upload-cloud-2-fill fs-25' />
                          </div>
                          <p className='m-0 text-muted fw-light fs-14'>
                            Drop file here or click to upload<br></br>
                            (pdf)
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {pdfFile && (
                    <>
                      <ul className='list-unstyled mb-2' id='dropzone-preview'>
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={'-file'}
                        >
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <div className='avatar-sm flex-shrink-0'>
                                  <span className='avatar-title rounded fs-3 image-container'>
                                    <i className='ri-file-list-3-line'></i>
                                  </span>
                                </div>
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'
                                >
                                  {pdfFile?.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{pdfFile.formattedSize}</strong>
                                </p>
                              </Col>

                              <Col className='d-flex justify-content-end align-items-end gap-3'>
                                <Link to={pdfFile.preview} target={'_blank'}>
                                  <Button color='soft-primary'>Preview</Button>
                                </Link>
                                <Button
                                  color='danger'
                                  disabled={isLoading}
                                  onClick={() => onDeleteFile('pdf')}
                                >
                                  Delete
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </ul>
                    </>
                  )}
                </div>
                <div>
                  <label className='form-label'>Audio file</label>
                  {isUploading ? (
                    <div className='d-flex flex-column align-items-center py-5'>
                      <p className='fs-20 fw-medium'>Uploading...</p>
                    </div>
                  ) : (
                    <Dropzone
                      maxFiles={1}
                      disabled={form.isSubmitting}
                      multiple={false}
                      accept={{
                        'audio/mpeg': [],
                      }}
                      onDrop={acceptedFiles => {
                        handleUploadedFile(acceptedFiles[0], 'audio')
                      }}
                    >
                      {({ getRootProps }) => (
                        <div className='dropzone dz-clickable cursor-pointer'>
                          <div
                            className='dz-message needsclick'
                            {...getRootProps()}
                          >
                            <div className='mt-2'>
                              <i className='display-7 text-muted ri-upload-cloud-2-fill fs-25' />
                            </div>
                            <p className='m-0 text-muted fw-light fs-14'>
                              Drop file here or click to upload<br></br>
                              (mp3)
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}

                  {audioFile && (
                    <>
                      <ul className='list-unstyled mb-2' id='dropzone-preview'>
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={'-file'}
                        >
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <div className='avatar-sm flex-shrink-0'>
                                  <span className='avatar-title rounded text-light-purple'>
                                    MP3
                                  </span>
                                </div>
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'
                                >
                                  {audioFile?.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{audioFile.formattedSize}</strong>
                                </p>
                              </Col>

                              <Col className='d-flex justify-content-end align-items-end gap-3'>
                                <Button
                                  color='danger'
                                  disabled={isLoading}
                                  onClick={() => onDeleteFile('audio')}
                                >
                                  Delete
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <AudioPlayer file={audioFile as DocumentFile} />
                          </div>
                        </Card>
                      </ul>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          {router.params.courseId && (
            <CourseCodes courseId={+router.params.courseId} />
          )}
        </div>
        <InstructorModal
          title={'New Instructor'}
          isOpen={showCreateInstructorModal}
          initialValues={null}
          onSubmit={onCreateInstructor}
          onClose={() => {
            setCreateInstructorModal(false)
          }}
        />
      </>
    </CourseLayout>
  )
}
export default withRouter(ContinuingEducation)
