import Filters from '../../UserManagment/Filters'
import { Col, Row } from 'reactstrap'
import React, { Dispatch, SetStateAction } from 'react'
import { GetUserReportDTO } from '../../../sharedTypes/api/userReport'
import { FilterOption } from '../../../sharedTypes'
import _ from 'lodash'
import MultiSelect from '../../../Components/Common/MultiSelect'
import {
  COURSES_COMPLETED,
  COURSES_IN_PROGRESS,
  COURSES_NOT_STARTED,
  COURSES_OVERDUE,
  coursesCompleted,
  coursesInProgress,
  coursesNotStarted,
  coursesOverdue,
  FILTERS,
  SelectedFilters,
} from './types'
import { useNavigate } from 'react-router-dom'
import { useUrlParams } from '../../../utils/urlParamsParser'
import moment from 'moment/moment'

interface ReportFiltersProps {
  showFilters: boolean
  setQuery: Dispatch<SetStateAction<GetUserReportDTO.Request>>
  query: GetUserReportDTO.Request
  setSelectedFilters: (filters: SelectedFilters) => void
  setFetchData: Dispatch<SetStateAction<boolean>>
}

const ReportFilters = ({
  showFilters,
  setQuery,
  query,
  setSelectedFilters,
  setFetchData,
}: ReportFiltersProps) => {
  const navigate = useNavigate()
  const { updateUrlParams, getDataUrlParamsAsArray } = useUrlParams()
  const signupDate = getDataUrlParamsAsArray(FILTERS.REGISTRATION_DATE)
  const hireDate = getDataUrlParamsAsArray(FILTERS.HIRE_DATE)

  return (
    <>
      <Row
        className={`filters-row user-report-filter-row gap-4 ${
          showFilters ? 'my-3' : 'my-0'
        } mx-0`}
      >
        <Filters
          visible={showFilters}
          setFilters={data => {
            setQuery(prev => ({
              ...prev,
              groupIds: data.groups,
              facilityIds: data.facility,
              agencyIds: data.agencies,
              departmentIds: data.department,
              positionIds: data.position,
              status: data.status,
              hireDate: data.hireDate,
              signupDate: data.signupDate,
              registeredStatuses: data.registeredStatuses,
              creationDate: data.creationDate,
              page: 1,
            }))
            setFetchData(true)
          }}
          filterBy={{
            facilities: true,
            departments: true,
            positions: true,
            agencies: true,
            groups: false,
            status: true,
            registered: true,
            hireDate: true,
            signupDate: true,
            creationDate: false,
          }}
          setSelectedFilters={setSelectedFilters}
          initialValues={{
            signupDate,
            hireDate,
          }}
        />
      </Row>
      {showFilters && (
        <Row className='mx-0 gap-4 filters-row user-report-filter-row'>
          <Col className='mt-0 p-0 filter-col'>
            <label htmlFor='coursesNotStarted' className='form-label'>
              Courses Not Started
            </label>

            <MultiSelect
              name='coursesNotStarted'
              id='coursesNotStarted'
              isMulti
              options={coursesNotStarted}
              isSearchable={true}
              placeholder='Select amount'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  coursesNotStarted: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSES_NOT_STARTED[]),
                }))
                navigate(
                  `?${updateUrlParams(
                    FILTERS.COURSES_NOT_STARTED,
                    _.map(option, 'value').toString(),
                  )}`,
                )
              }}
              defaultValue={coursesNotStarted.filter(course =>
                (query.coursesNotStarted || []).includes(course.value),
              )}
            />
          </Col>

          <Col className='mt-0 p-0 filter-col'>
            <label htmlFor='coursesInProgress' className='form-label'>
              Courses in Progress
            </label>

            <MultiSelect
              name='coursesInProgress'
              id='coursesInProgress'
              isMulti
              options={coursesInProgress}
              isSearchable={true}
              placeholder='Select amount'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  coursesInProgress: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSES_IN_PROGRESS[]),
                }))
                navigate(
                  `?${updateUrlParams(
                    FILTERS.COURSES_IN_PROGRESS,
                    _.map(option, 'value').toString(),
                  )}`,
                )
              }}
              defaultValue={coursesInProgress.filter(course =>
                (query.coursesInProgress || []).includes(course.value),
              )}
            />
          </Col>
          <Col className='mt-0 p-0 filter-col'>
            <label htmlFor='coursesCompleted' className='form-label'>
              Courses Completed
            </label>

            <MultiSelect
              name='coursesCompleted'
              id='coursesCompleted'
              isMulti
              options={coursesCompleted}
              isSearchable={true}
              placeholder='Select amount'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  coursesCompleted: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSES_COMPLETED[]),
                }))
                navigate(
                  `?${updateUrlParams(
                    FILTERS.COURSES_COMPLETED,
                    _.map(option, 'value').toString(),
                  )}`,
                )
              }}
              defaultValue={coursesCompleted.filter(course =>
                (query.coursesCompleted || []).includes(course.value),
              )}
            />
          </Col>

          <Col className='mt-0 p-0 filter-col'>
            <label htmlFor='coursesOverdue' className='form-label'>
              Courses Overdue
            </label>

            <MultiSelect
              name='coursesOverdue'
              id='coursesOverdue'
              isMulti
              options={coursesOverdue}
              isSearchable={true}
              placeholder='Select amount'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  coursesOverdue: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSES_OVERDUE[]),
                }))
                navigate(
                  `?${updateUrlParams(
                    FILTERS.COURSES_OVERDUE,
                    _.map(option, 'value').toString(),
                  )}`,
                )
              }}
              defaultValue={coursesOverdue.filter(course =>
                (query.coursesOverdue || []).includes(course.value),
              )}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default ReportFilters
