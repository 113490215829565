import React, { useEffect, useState } from 'react'
import { Card } from 'reactstrap'

import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import { deactivateUserById, getUserById } from '../../../helpers/api/users'
import { TUser } from '../../../sharedTypes'
import DeactivateModal from '../../../Components/Modals/DeactivateModal'
import { successToastOptions, handleError } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import axios from 'axios'
import { UserProfileLayout } from '../../../Components/Common/UserProfileLayout'
import DocumentsTable from '../../Authentication/UserProfile/DocumentsTable'

type UserProfileProps = WithRouterProps

const Documents = ({ router }: UserProfileProps) => {
  document.title = 'User profile | Mastered - Admin & Dashboard'

  const [user, setUser] = useState<TUser | undefined>()
  const [showDeactivate, setShowDeactivate] = useState(false)

  useEffect(() => {
    if (
      !router.params.userId ||
      Number.isNaN(+router.params.userId) ||
      typeof +router.params.userId !== 'number'
    ) {
      return router.navigate('/404')
    }

    getUserById(parseInt(router.params.userId))
      .then(res => {
        setUser(res.data)
      })
      .catch(error => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          router.navigate('/404')
        }
      })
  }, [router.params.userId])

  const onDeactivate = () => {
    if (!user) {
      return
    }
    deactivateUserById(user?.id)
      .then(res => {
        toast(
          user.deactivated
            ? 'Successfully activated'
            : 'Successfully deactivated',
          successToastOptions,
        )
        setUser(res.data)
      })
      .catch(handleError)
      .finally(() => {
        setShowDeactivate(false)
      })
  }

  return (
    <React.Fragment>
      {user && (
        <UserProfileLayout
          user={user}
          onDeactivate={() => setShowDeactivate(true)}
        >
          <Card className='m-0 p-0 table-card'>
            <DocumentsTable userId={user.id} />
          </Card>
        </UserProfileLayout>
      )}
      {user && (
        <DeactivateModal
          onClose={() => setShowDeactivate(v => !v)}
          isOpen={showDeactivate}
          user={user}
          onDeactivate={onDeactivate}
          isActivate={user.deactivated}
        />
      )}
    </React.Fragment>
  )
}

export default withRouter(Documents)
