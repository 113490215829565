import React from 'react'
import { ScheduledEntitiesTypes } from '../../../../sharedTypes'
import { Button, Col, Row } from 'reactstrap'
import ScheduledCourseModal from '../../../../Components/Course/ScheduledCourseModal'
import withRouter from '../../../../Components/Common/withRouter'
import { SearchInput } from '../../../../Components/Common/SearchInput'
import DeleteConfirmation from '../../../../Components/Modals/DeleteConfirmation'
import ScheduledEntitiesList from './ScheduledCoursesList'
import { IUseScheduled } from '../../../../hooks/course/useScheduled'

interface IScheduledCourse extends IUseScheduled {
  courseId: number
  viewOnly?: boolean
  isPublishing?: boolean
}

const ScheduledCourse = (props: IScheduledCourse) => {
  const {
    query,
    data,
    onEdit,
    isLoading,
    handleSort,
    setQuery,
    onCreate,
    setDeleteId,
    deleteId,
    onDelete,
    courseId,
    showModal,
    onModalClose,
    onRefetch,
    isPublishing,
  } = props

  return (
    <Row>
      <DeleteConfirmation
        isOpen={!!deleteId}
        title='Delete Scheduled Course'
        message={`Are you sure you want to delete ?`}
        onDelete={onDelete}
        onClose={() => {
          setDeleteId(null)
        }}
      />
      <ScheduledCourseModal
        entityId={courseId}
        isOpen={!!showModal}
        initialValues={showModal}
        onClose={onModalClose}
        onRefetch={onRefetch}
        modalTitle={'Schedule Course'}
        webinarTitle={'Virtual Conference'}
        entityType={ScheduledEntitiesTypes.COURSE}
      />
      <Col sm='12'>
        <div className='hstack gap-3 px-3 mx-n3 justify-content-between mb-4'>
          <SearchInput
            style={{ maxWidth: 400 }}
            onChange={key => {
              setQuery(prev => ({ ...prev, key, page: 1 }))
            }}
            value={query.key || ''}
          />
          <div className='d-flex flex-shrink-0 gap-3'>
            <Button
              color={'primary'}
              className='btn btn-primary align-middle'
              onClick={() => onCreate()}
            >
              <i className='ri-add-line me-1 fs-16'></i>Add New Schedule
            </Button>
          </div>
        </div>
      </Col>
      <Col sm='12'>
        <ScheduledEntitiesList
          data={data}
          query={query}
          isPublishing={isPublishing}
          setQuery={setQuery}
          onEdit={onEdit}
          onDelete={(id: number) => setDeleteId(id)}
          isLoading={isLoading}
          handleSort={handleSort}
          entityType={ScheduledEntitiesTypes.COURSE}
          noDataErrorMessage={
            data.count === 0 && isPublishing
              ? 'At least one schedule is required'
              : 'Sorry, no schedules found'
          }
        />
      </Col>
    </Row>
  )
}

export default withRouter(ScheduledCourse)
