import {
  Col,
  Modal,
  ModalBody,
  ModalProps,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap'
import { TLanguage, AssignToOptions } from '../../sharedTypes'
import {
  StatusTypes,
  TPushNotificationData,
} from '../../sharedTypes/models/pushNotifications'
import React, { useEffect, useState } from 'react'
import { getLanguages } from '../../helpers/api_helper'
import classnames from 'classnames'
import { getUserDisplayName } from '../../helpers/user'
import { getPushNotificationById } from '../../helpers/api/pushNotifications'
import moment from 'moment'
import { copyToClipboard } from '../../utils/clipboard'
import { ENGLISH_LANGUAGE_CODE } from '../../helpers/common'
import { handleError } from '../../helpers/toast_helper'

interface ViewNotificationProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  id: number
}

const ViewNotificationModal = ({
  onClose,
  isOpen,
  id,
}: ViewNotificationProps) => {
  const [languages, setLanguages] = useState<TLanguage[] | []>([])
  const [recipientsData, setRecipientsData] = useState<string[] | []>([])
  const [activeTab, setActiveTab] = useState('en')
  const [data, setData] = useState<TPushNotificationData>()
  const RECIPIENTS_SHOW_LIMIT = 3

  useEffect(() => {
    if (isOpen) {
      getLanguages()
        .then(data => {
          const _data = data.filter(item => item.code === ENGLISH_LANGUAGE_CODE)
          setLanguages(_data)
        })
        .catch(handleError)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && id) {
      getPushNotificationById(+id).then(res => {
        setData(res.data)
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && data?.recipients.length) {
      setRecipientsData(
        data.recipients.map((entity: any) =>
          'name' in entity ? entity.name : getUserDisplayName(entity) || '',
        ),
      )
    }
  }, [data?.recipients])

  const tabChange = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      centered
      className='push-notification-modal'
    >
      {data?.notification ? (
        <ModalBody className='modal-body'>
          <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
            <h5 className='fw-light'>
              {data?.notification.translations[activeTab === 'en' ? 0 : 1]
                ?.title || '-'}
            </h5>
            <i
              className='ri-close-line fs-24 cursor-pointer'
              onClick={onClose}
            ></i>
          </div>
          <div className='vstack gap-3'>
            <Row>
              <Col md={12}>
                <Nav className='nav-tabs-custom rounded' role='tablist'>
                  {languages.map((language, index) => (
                    <NavItem key={index}>
                      <NavLink
                        to='#'
                        className={classnames({
                          active: activeTab === language.code,
                        })}
                        onClick={() => {
                          tabChange(language.code)
                        }}
                      >
                        {language.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {languages.map((language, index: number) => (
                    <TabPane tabId={language.code} key={index}>
                      <div className='vstack gap-4 mt-3'>
                        <div>
                          <span className='fw-medium'>Message</span>
                          <div className='hstack'>
                            <span className='text-muted'>
                              {data.notification.translations[index]?.message ||
                                '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  ))}
                </TabContent>

                <div className='vstack gap-4 mt-3'>
                  <div>
                    <span className='fw-medium'>Created by</span>
                    <div className='hstack'>
                      <span className='text-muted'>
                        {getUserDisplayName(data.notification.createdBy)}
                      </span>
                    </div>
                  </div>

                  <div>
                    <span className='fw-medium'>Send To</span>
                    <div className='hstack'>
                      <span className='text-muted'>
                        {AssignToOptions.find(
                          o => o.value === data.notification.sendTo,
                        )?.label || ''}
                      </span>
                    </div>
                  </div>

                  <div>
                    <span className='fw-medium'>
                      {AssignToOptions.find(
                        o => o.value === data.notification.sendTo,
                      )?.label || ''}
                    </span>
                    <div className='hstack'>
                      <span className='text-muted'>
                        {recipientsData
                          .slice(0, RECIPIENTS_SHOW_LIMIT)
                          .join(' , ')}

                        {recipientsData.length > RECIPIENTS_SHOW_LIMIT && (
                          <>
                            <UncontrolledTooltip
                              placement='top'
                              target={'viewMore'}
                            >
                              {recipientsData
                                .slice(
                                  RECIPIENTS_SHOW_LIMIT,
                                  recipientsData.length,
                                )
                                .join(' , ')}
                            </UncontrolledTooltip>

                            <span
                              className='text-primary cursor-pointer ps-1'
                              id={'viewMore'}
                            >
                              +{recipientsData.length - RECIPIENTS_SHOW_LIMIT}
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between align-items-center'>
                    <div>
                      <span className='fw-medium'>Link</span>
                      <div className='hstack'>
                        <span className='text-muted'>
                          {data.notification.link || '-'}
                        </span>
                      </div>
                    </div>
                    {data.notification.link && (
                      <div>
                        <UncontrolledTooltip
                          placement='top'
                          target={'copyLink'}
                        >
                          Copy Link
                        </UncontrolledTooltip>
                        <i
                          className='ri-file-copy-line cursor-pointer fs-5 text-primary'
                          id={'copyLink'}
                          onClick={() => {
                            copyToClipboard(data.notification.link || '')
                          }}
                        ></i>
                      </div>
                    )}
                  </div>
                  <Row>
                    <Col md={6}>
                      <span className='fw-medium'>Send Date</span>
                      <div className='hstack'>
                        <span className='text-muted'>
                          {data.notification.status === StatusTypes.SENT
                            ? moment(data.notification.updatedAt).format(
                                'MM/DD/YYYY',
                              )
                            : '-'}
                        </span>
                      </div>
                    </Col>
                    <Col md={6}>
                      <span className='fw-medium'>Send Time</span>
                      <div className='hstack'>
                        <span className='text-muted'>
                          {data.notification.status === StatusTypes.SENT
                            ? moment(data.notification.updatedAt).format(
                                'HH:mm',
                              )
                            : '-'}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
      ) : (
        <div className='d-flex justify-content-center'>
          <Spinner className='m-5' />
        </div>
      )}
    </Modal>
  )
}

export default ViewNotificationModal
