import React, { useState } from 'react'

const ShowMore = ({
  content,
  length = 100,
}: {
  content: string
  length?: number
}) => {
  const [showMore, setShowMore] = useState(false)
  return (
    <p>
      {' '}
      <span
        style={{ color: '#7C8193', wordBreak: 'break-word' }}
        dangerouslySetInnerHTML={{
          __html: showMore ? content : `${content.substring(0, length)}`,
        }}
      ></span>
      {content.length > length && (
        <span
          className='cursor-pointer m-1'
          style={{ color: '#0039C7', fontWeight: '500' }}
          onClick={() => {
            setShowMore(p => !p)
          }}
        >
          {showMore ? 'Show less' : 'Show more'}
        </span>
      )}
    </p>
  )
}

export default ShowMore
