import React, { Fragment, useCallback, useState } from 'react'
import { Container, Row, Col, Card, Input, FormFeedback } from 'reactstrap'
import BooksAndStethoscope from '../../../assets/images/svg/Books_and_stethoscope.svg'
import { useFormik } from 'formik'
import { defaultCoursePackageSchema } from '../../../schemas'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { CoursePackageFormatsEnum } from '../../../sharedTypes/models/coursePackage'
import WithRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import { createPackage } from '../../../helpers/api/coursePackages'
import { handleError } from '../../../helpers/toast_helper'
import { BottomNavigation } from './BottomNavigation'
import CoursePackageFormat from './PackageFormat'
import MultiSelect from '../../../Components/Common/MultiSelect'
import { COURSE_TYPES, CourseAssignTypesOptions } from '../../../sharedTypes'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import _ from 'lodash'
import { CoursePosition } from '../../../sharedTypes/api/coursePositions'
import { getCoursePositions } from '../../../helpers/api/coursePositions'

export interface IForm {
  name: string
  description: string
  disciplineIds: number[]
  disciplines?: string[]
  type: COURSE_TYPES[]
  format: CoursePackageFormatsEnum
}

const GeneralCoursePackage = ({ router }: WithRouterProps) => {
  document.title =
    'Create Package / Training Day | Mastered - Admin & Dashboard'

  const [saveAsDraft, setSaveAsDraft] = useState<boolean>(false)
  const [disciplineList, setDisciplineList] = useState<CoursePosition[]>([])

  const fetchDisciplines = () => {
    return getCoursePositions()
      .then(res => {
        setDisciplineList(res.coursePositions)
        return res.coursePositions.map(position => ({
          value: position.id,
          label: position.name,
        }))
      })
      .catch(() => [])
  }

  const onSubmit = useCallback(
    async (values: IForm) => {
      try {
        const createdPackage = await createPackage({ ...values })

        const redirectPath = saveAsDraft
          ? '/courses'
          : `/courses/packages/manage/${createdPackage.id}`
        router.navigate(redirectPath)
      } catch (e) {
        handleError(e)
      }
    },
    [saveAsDraft],
  )

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      type: [],
      disciplineIds: [],
      format: CoursePackageFormatsEnum.PACKAGE,
    },
    validationSchema: defaultCoursePackageSchema,
    onSubmit,
  })

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Create New Course Package'
            items={[
              {
                title: 'Courses',
                linkTo: '/courses',
              },
              {
                title: 'Course Packages',
                linkTo: '/courses',
              },
              {
                title: 'Create New Course Package',
                active: true,
              },
            ]}
          />
          <Row>
            <Col sm='12'>
              <Card className='py-5'>
                <section className='d-flex align-items-center gap-2 flex-column mb-2'>
                  <img
                    src={BooksAndStethoscope}
                    className='img-fluid mx-auto d-flex'
                    alt=''
                  />
                  <h3>Create a package!</h3>
                </section>

                <Row
                  className='gap-3 w-100 accent-blue-500 m-auto mb-3'
                  style={{
                    background: '#F6F9FE',
                    maxWidth: 456,
                    borderRadius: 8,
                    padding: 10,
                  }}
                >
                  <Col sm='12'>
                    <div>
                      <label htmlFor='title' className='form-label'>
                        Package Name*
                      </label>
                      <Input
                        className='form-control'
                        id='title'
                        name={`name`}
                        placeholder='Name this package'
                        type='text'
                        invalid={!!form.errors.name}
                        value={form.values.name}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                      />
                      {form.touched.name && form.errors.name ? (
                        <FormFeedback type='invalid'>
                          {form.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm='12'>
                    <div>
                      <label htmlFor='title' className='form-label'>
                        Description
                      </label>
                      <Input
                        className='form-control'
                        id='title'
                        name={`description`}
                        type='textarea'
                        rows={3}
                        invalid={!!form.errors.description}
                        value={form.values.description}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                      />
                      {form.touched.description && form.errors.description ? (
                        <FormFeedback type='invalid'>
                          {form.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col sm='12'>
                    <div>
                      <label className='form-label'>Type*</label>
                      <div className='d-flex'>
                        <div className='flex-grow-1'>
                          <MultiSelect
                            name='type'
                            id='type'
                            placeholder='Select a Type'
                            options={CourseAssignTypesOptions}
                            onChange={(selectedOptions: any[]) => {
                              const selectedValues = selectedOptions.map(
                                item => item.value,
                              )
                              form.setFieldValue('type', selectedValues)
                            }}
                            isMulti
                            onBlur={form.handleBlur}
                            isClearable={false}
                            isSearchable
                          />
                          {form.touched.type && form.errors.type ? (
                            <FormFeedback type='invalid' className='d-block'>
                              {form.errors.type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col>
                    <div>
                      <label htmlFor='tutorials' className='form-label'>
                        Disciplines
                      </label>
                      <AsyncSelectWithSearch
                        name='disciplines'
                        id='disciplines'
                        isMulti={true}
                        isClearable={false}
                        loadOptions={fetchDisciplines}
                        isSearchable={false}
                        placeholder='Select disciplines'
                        defaultOptions
                        onChange={option => {
                          form.setFieldValue(
                            'disciplineIds',
                            _.isEmpty(option) ? [] : _.map(option, 'value'),
                          )
                        }}
                        onBlur={form.handleBlur}
                        value={form.values.disciplineIds.map(id => ({
                          value: id,
                          label:
                            disciplineList.find(p => p.id === id)?.name || '',
                        }))}
                        className='select2-container is-invalid'
                        classNamePrefix='select2-selection form-select'
                      />
                    </div>
                  </Col>
                </Row>
                <Row
                  className='w-100 accent-blue-500 m-auto text-center  mb-3'
                  style={{
                    background: '#F6F9FE',
                    maxWidth: 456,
                    borderRadius: 8,
                    padding: 10,
                    border:
                      form.touched.format && form.errors.format
                        ? '1px solid #f06548'
                        : undefined,
                  }}
                >
                  <label className='mb-2'>
                    What format(s) would you like to offer?
                  </label>
                  <Col
                    sm='12'
                    className='d-flex flex-column gap-2 course-packages-formats'
                  >
                    <CoursePackageFormat
                      label={'Course Package'}
                      description={
                        'A group of courses assigned to the users as individual courses'
                      }
                      id={CoursePackageFormatsEnum.PACKAGE}
                      checked={
                        form.values.format === CoursePackageFormatsEnum.PACKAGE
                      }
                      value={CoursePackageFormatsEnum.PACKAGE}
                      onChange={form.handleChange}
                    />
                    <CoursePackageFormat
                      label={'Training Day'}
                      description={
                        'A group of courses assigned to the users as a group to be scheduled together and taken on one day'
                      }
                      id={CoursePackageFormatsEnum.TRAINING_DAY}
                      checked={
                        form.values.format ===
                        CoursePackageFormatsEnum.TRAINING_DAY
                      }
                      onChange={form.handleChange}
                      value={CoursePackageFormatsEnum.TRAINING_DAY}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <BottomNavigation
              backText={'Cancel'}
              isFirstStep={true}
              onBack={() => router.navigate('/courses')}
              onSaveAsDraft={() => {
                form.handleSubmit()
                setSaveAsDraft(true)
              }}
              onSubmit={() => {
                form.handleSubmit()
                setSaveAsDraft(false)
              }}
            />
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
export default WithRouter(GeneralCoursePackage)
