import { StatesEnum } from '../lib/locations'
import { CE_PROVIDERS_STATUSES } from '../lib/types'
import { TAttachment } from '../models'
import { OnlineProviderItem } from '../models/ceProvider'
import { PaginatedBaseResponse, PaginationRequest } from './pagination'

export type ProvidersInitialValues = {
  id?: number
  providerNumber: number
  board: string
  disciplines: string[]
  states: string[]
  signatoryName: string
  credentials: string
  signatoryTitle: string
  signature: TAttachment | Blob | undefined
  status: CE_PROVIDERS_STATUSES
}

export enum CEProviderSortBy {
  PROVIDER_NUMBER = 'providerNumber',
  BOARD = 'board',
  DISCIPLINE = 'disciplines',
  STATES = 'states',
  SIGNATORY_NAME = 'signatoryName',
  SIGNATURE = 'signature',
  STATUS = 'status',
}

export namespace GetProvidersDTO {
  export interface Request extends PaginationRequest {
    status?: CE_PROVIDERS_STATUSES[]
    disciplines?: string[]
    states?: StatesEnum[]
    sortBy?: CEProviderSortBy
  }

  export interface Response extends PaginatedBaseResponse {
    ceProviders: OnlineProviderItem[]
  }
}

export namespace GetBoardsDTO {
  export interface Response {
    id: number
    board: string
    disciplines: string[]
  }
}

export type ProvidersModalSubmitedValues = ProvidersInitialValues
