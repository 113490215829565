import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, ModalProps } from 'reactstrap'
import MediaTableView from '../../../pages/Courses/MediaLibrary/TableView'
import {
  deleteAttachment,
  getAttachments,
} from '../../../helpers/api/attachments'
import {
  AttachmentsSortBy,
  AttachmentsTypesEnum,
  GetAttachmentsDTO,
  OrderType,
  TAttachment,
  AttachmentsMimeTypeEnum,
  CoursesPermissions,
} from '../../../sharedTypes'
import { Pagination } from '../../Common/Pagination'
import NoResultTableWrapper from '../../Common/NoResultTableWrapper'
import { SearchInput } from '../../Common/SearchInput'
import FileDetailsModal from '../FileDetailsModal'
import DeleteConfirmation from '../DeleteConfirmation'
import { toast } from 'react-toastify'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import { Nav, NavItem, NavLink, Label, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import MediaUploader from './MediaUploader'
import { usePermissions } from '../../../hooks/usePermissions'

interface SelectFromMediaLibraryProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  handleUploadSelectedMedia: (attachment: TAttachment) => void
  type: AttachmentsTypesEnum[]
  mimetype?: AttachmentsMimeTypeEnum[]
}

const initialQuery = {
  page: 1,
  limit: 10,
}
const SelectFromMediaLibraryModal = ({
  onClose,
  isOpen,
  handleUploadSelectedMedia,
  type,
  mimetype = [],
}: SelectFromMediaLibraryProps) => {
  const viewMediaLibrary = usePermissions(CoursesPermissions.VIEW_MEDIA_LIBRARY)

  const [activeTab, setActiveTab] = useState<number>(viewMediaLibrary ? 0 : 1)
  const [data, setData] = useState<GetAttachmentsDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    attachments: [],
  })

  const [query, setQuery] = useState<GetAttachmentsDTO.Request>(initialQuery)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const isFiltering = useMemo<boolean>(() => !!query.key, [query])
  const [deleteMedia, setDeleteMedia] = useState<TAttachment | null>(null)
  const [showDetailsMedia, setShowDetailsMedia] = useState<TAttachment | null>(
    null,
  )

  useEffect(() => {
    if (isOpen && viewMediaLibrary) {
      fetchData(query)
    }
  }, [query, isOpen])

  const fetchData = useCallback(
    (q: GetAttachmentsDTO.Request) => {
      setIsLoading(true)
      return getAttachments({ ...q, type, mimetype })
        .then(res => {
          setData(res.data)
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false)
        })
    },
    [type, mimetype],
  )

  const handleSort = useCallback((column: AttachmentsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onRowClick = (selectedRowId: number) => {
    setSelectedRowId(selectedRowId)
  }

  const handleClose = () => {
    setSelectedRowId(null)
    onClose()
  }

  const onUploadedEvent = useCallback(
    (newMedia: TAttachment) => {
      onClose()
      handleUploadSelectedMedia(newMedia)
    },
    [initialQuery, type, mimetype],
  )

  const onDeleteMedia = useCallback(() => {
    if (deleteMedia) {
      deleteAttachment(deleteMedia.id)
        .then(() => {
          toast(
            'Success - Media file successfully deleted',
            successToastOptions,
          )
          setDeleteMedia(null)
          setShowDetailsMedia(null)
          setQuery(q => ({ ...q }))
        })
        .catch(handleError)
    }
  }, [deleteMedia])

  const onSubmitEditMedia = (media: TAttachment) => {
    setShowDetailsMedia(media)
    setData(prev => ({
      ...prev,
      attachments: prev.attachments.map(a => {
        if (a.id === media.id) {
          return media
        }
        return a
      }),
    }))
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={handleClose}
        centered
        style={{ maxWidth: 1020 }}
        className='media-library-modal'
      >
        <ModalHeader toggle={handleClose}>
          <div className='d-flex w-100 align-items-center gap-4'>
            <h4 className='d-inline-flex '>Select Media</h4>
            <Nav
              tabs
              className='nav justify-content-start mx-0 nav-tabs-custom rounded card-header-tabs border-bottom-1 mb-1'
            >
              {viewMediaLibrary && (
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    disabled={isLoading}
                    className={classnames({
                      active: activeTab === 0,
                      'pe-none': isLoading,
                    })}
                    onClick={() => {
                      setActiveTab(0)
                    }}
                  >
                    <div className='gap-2 d-flex'>
                      <Label
                        className='form-check-label'
                        chekc
                        htmlFor='flexSwitchCheckDefault'
                      >
                        Media Library
                      </Label>
                    </div>
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: activeTab === 1,
                    'pe-none': isLoading,
                  })}
                  onClick={() => {
                    setActiveTab(1)
                  }}
                >
                  <div className='gap-2 d-flex'>
                    <Label
                      className='form-check-label'
                      htmlFor='flexSwitchCheckDefault'
                    >
                      Upload File
                    </Label>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </ModalHeader>
        <ModalBody className='p-4'>
          <TabContent activeTab={activeTab}>
            {viewMediaLibrary && (
              <TabPane tabId={0} key={0}>
                <SearchInput
                  style={{ maxWidth: 400, marginBottom: 10 }}
                  onChange={key => {
                    setQuery(prev => ({ ...prev, key, page: 1 }))
                  }}
                  value={query.key}
                />
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={isFiltering}
                  pages={data.pages}
                >
                  <MediaTableView
                    onShow={setShowDetailsMedia}
                    isFromModal={true}
                    attachments={data.attachments}
                    query={query}
                    handleSort={handleSort}
                    setDeleteMedia={() => {}}
                    selectedRowId={selectedRowId}
                    onRowClick={onRowClick}
                  />
                  <div className='mx-3 mt-3'>
                    <Pagination
                      currentPage={data.page - 1}
                      totalPages={data.pages}
                      totalRecords={data.count}
                      setPage={page => {
                        setQuery(prev => ({ ...prev, page: ++page }))
                      }}
                    />
                  </div>
                  <div className='mx-3 mt-3 d-flex justify-content-between'>
                    <Button color='danger' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      color='success'
                      onClick={() => {
                        const selectedAttachment = data.attachments.find(
                          attachment => attachment.id === selectedRowId,
                        )
                        if (selectedAttachment) {
                          handleUploadSelectedMedia(selectedAttachment)
                        }
                        handleClose()
                      }}
                    >
                      Select
                    </Button>
                  </div>
                </NoResultTableWrapper>
              </TabPane>
            )}
            <TabPane tabId={1} key={1}>
              <MediaUploader
                mimetypes={mimetype}
                onUploadedEvent={onUploadedEvent}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
      <DeleteConfirmation
        isOpen={!!deleteMedia}
        onClose={() => {
          setDeleteMedia(null)
        }}
        title='Delete Media'
        message={`Are you sure you want to delete media "${deleteMedia?.name}"?`}
        onDelete={onDeleteMedia}
      />
      {showDetailsMedia && (
        <FileDetailsModal
          onClose={() => setShowDetailsMedia(null)}
          isOpen={true}
          onSubmit={onSubmitEditMedia}
          attachment={showDetailsMedia}
          setDeleteMedia={setDeleteMedia}
        />
      )}
    </>
  )
}

export default SelectFromMediaLibraryModal
