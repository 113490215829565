import React, { useEffect, useState } from 'react'
import MultiSelect from './MultiSelect'
import { COURSE_TYPES } from '../../sharedTypes'
import { FormFeedback } from 'reactstrap'
import { FormikProps } from 'formik'
import { IForm } from '../../hooks/course/generalStep'
import { IDefaultForm } from '../../pages/Courses/OnlineCourses/Manage/Default'

type Option = {
  value: string
  label: string
}

interface CourseTypeMultiSelectProps {
  courseTypeOptions: Option[]
  initialDisabled?: COURSE_TYPES[]
  form: FormikProps<IForm> | FormikProps<IDefaultForm>
}

const CourseTypeMultiSelect = ({
  courseTypeOptions,
  form,
  initialDisabled = [],
}: CourseTypeMultiSelectProps) => {
  const [disabled, setDisabled] = useState<COURSE_TYPES[]>(initialDisabled)
  const notCEType = [
    COURSE_TYPES.ANNUAL_EDUCATION,
    COURSE_TYPES.ONBOARDING,
    COURSE_TYPES.ENHANCED_LEARNING,
  ]

  const updateDisabledOptions = (selectedValues: COURSE_TYPES[]) => {
    if (selectedValues.includes(COURSE_TYPES.CONTINUING_EDUCATION)) {
      setDisabled(notCEType)
    } else if (selectedValues.some(value => notCEType.includes(value))) {
      setDisabled([COURSE_TYPES.CONTINUING_EDUCATION])
    } else {
      setDisabled([])
    }
  }

  useEffect(() => {
    updateDisabledOptions(form.values.type)
  }, [form.values.type])

  const handleTypeChange = (
    selectedOptions: { value: COURSE_TYPES; label: COURSE_TYPES }[],
  ) => {
    const selectedValues = selectedOptions.map(item => item.value)
    form.setFieldValue('type', selectedValues)
  }

  return (
    <>
      <MultiSelect
        name='type'
        id='type'
        placeholder='Select a Type'
        value={courseTypeOptions.filter(option =>
          form.values.type.includes(option.value as COURSE_TYPES),
        )}
        options={courseTypeOptions}
        onChange={(
          selectedOptions: {
            value: COURSE_TYPES
            label: COURSE_TYPES
          }[],
        ) => handleTypeChange(selectedOptions)}
        isMulti
        isClearable={false}
        isOptionDisabled={(option: {
          value: COURSE_TYPES
          label: COURSE_TYPES
        }) => disabled.includes(option.label)}
        isSearchable
      />
      {form.touched.type && form.errors.type ? (
        <FormFeedback type='invalid'>{form.errors.type}</FormFeedback>
      ) : null}
    </>
  )
}

export default CourseTypeMultiSelect
