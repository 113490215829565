import React from 'react'
import { Card, CardBody, Col, Input, Row } from 'reactstrap'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import { CoursesGridViewProps } from './types'
import defaultCourseCover from '../../../assets/images/course-default-cover.png'
import {
  COURSE_TYPES,
  ONLINE_COURSE_STATUS,
  OnlineCourseFormatsEnum,
} from '../../../sharedTypes'
import {
  BadgeDarkColorByStatus,
  formattedDuration,
  getTranslationIcon,
  isFacilityTourCourse,
} from '../../../helpers/course'
import { GridModeActions } from './GridModeActions'

const CoursesGridView = ({
  courses,
  query,
  handleDelete,
  handleClone,
  onSelect,
  selectedEntities,
  permissions,
  hasPermissionToActions,
  setCourseToPreview,
  courseToPreview,
}: Omit<CoursesGridViewProps, 'handleSort'>) => {
  return (
    <Row className='d-flex flex-wrap position-relative'>
      {courses.map((course, index) => (
        <Col
          md={6}
          lg={4}
          xl={3}
          className='course-card position-relative'
          key={index}
        >
          <Card
            style={{ height: '95%' }}
            className={`cursor-pointer ${
              courseToPreview ? 'course-preview-open' : ''
            } ${courseToPreview?.id === course.id ? ' active' : ''}`}
            onClick={() => {
              setCourseToPreview(course)
            }}
          >
            {!course.expirationDate &&
              !isFacilityTourCourse(course) &&
              !course.type?.includes(COURSE_TYPES.CONTINUING_EDUCATION) && (
                <div
                  className='position-absolute select-course'
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <Input
                    className='form-check-input cursor-pointer m-0'
                    type='checkbox'
                    name={`selectCourse${course.id}`}
                    id={`selectCourse${course.id}`}
                    onChange={() => {
                      onSelect(course)
                    }}
                    disabled={[
                      ONLINE_COURSE_STATUS.DRAFT,
                      ONLINE_COURSE_STATUS.INACTIVE,
                    ].includes(course.status)}
                    checked={!!_.find(selectedEntities, { id: course.id })}
                  />
                </div>
              )}
            <div className='delete-action position-absolute d-flex align-items-start'>
              <div className='d-flex gap-2'>
                <span className='badge text-light-purple fs-12 fw-medium course-duration'>
                  {!(course.formats || []).includes(
                    OnlineCourseFormatsEnum.COMPETENCY,
                  ) && formattedDuration(course.duration)}
                </span>
                {hasPermissionToActions(course.companyId) && (
                  <span
                    className={`badge badge-${
                      BadgeDarkColorByStatus[course.status]
                    } fs-12 fw-medium text-capitalize`}
                  >
                    {course.status}
                  </span>
                )}
              </div>
            </div>
            <div className='course-cover'>
              <img
                className='card-img-top img-fluid rounded w-100 h-100'
                src={course.cover?.path ?? defaultCourseCover}
                alt='NO IMAGE'
                style={{
                  aspectRatio: 1,
                  objectFit: 'cover',
                }}
              />
            </div>

            <CardBody className='d-flex'>
              <Col className='d-flex flex-column align-items-start justify-content-center gap-2'>
                <div className='d-flex justify-content-between w-100'>
                  <div className='d-flex align-items-center gap-3'>
                    <span className='text-muted fs-14'>
                      <Highlighter
                        highlightClassName='text-highlight'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={course.code}
                      />
                    </span>
                    <span>
                      <img
                        className=''
                        src={getTranslationIcon(course.translationStatus)}
                        alt='NO IMAGE'
                        style={{
                          aspectRatio: 1,
                          objectFit: 'cover',
                        }}
                      />
                    </span>
                  </div>

                  {hasPermissionToActions(course.companyId) && (
                    <GridModeActions
                      course={course}
                      permissions={permissions}
                      onClone={handleClone}
                      onDelete={handleDelete}
                    />
                  )}
                </div>

                <Highlighter
                  highlightClassName='text-highlight'
                  searchWords={[query.key || '']}
                  highlightTag={'span'}
                  autoEscape={true}
                  textToHighlight={_.get(
                    course,
                    'translations[0].content.name',
                    '-',
                  )}
                />
                <div className='d-flex gap-2 flex-wrap'>
                  {course.type?.map((type, index) => (
                    <span
                      key={index}
                      className={`badge badge-outline-info fs-12 fw-normal text-capitalize`}
                    >
                      {type}
                    </span>
                  ))}

                  {/*<span*/}
                  {/*  className={`badge badge-soft-info fs-12 fw-normal text-capitalize`}*/}
                  {/*>*/}
                  {/*  {'Infusion Therapy'}*/}
                  {/*</span>*/}
                </div>

                <Row className='w-100'>
                  {!course.type?.includes(
                    COURSE_TYPES.CONTINUING_EDUCATION,
                  ) && (
                    <>
                      <Col>
                        <span className='fw-medium text-body fs-14'>
                          Formats Available:{' '}
                        </span>
                      </Col>
                      <Col className='pe-0'>
                        <span className='text-muted fs-14'>
                          {course.available_formats?.join(', ')}
                        </span>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default CoursesGridView
