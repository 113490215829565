//ATTACHMENTS
import {
  GetAttachmentsDTO,
  TAttachment,
  UpdateAttachmentsDTO,
} from '../../sharedTypes'
import { ATTACHMENTS } from '../url_helper'
import { API } from '../api_helper'

export const postNewAttachment = (data: FormData) => {
  const REQUEST_TIMEOUT = 1800000 // 30 minutes to timeout request

  return API.post<TAttachment>(ATTACHMENTS, data, {
    timeout: REQUEST_TIMEOUT,
  }).then(d => d.data)
}

export const getAttachments = (params: GetAttachmentsDTO.Request) => {
  return API.get<GetAttachmentsDTO.Response>(ATTACHMENTS, { params })
}

export const getAttachmentMentions = (id: number) => {
  return API.get<TAttachment>(ATTACHMENTS + `/${id}`)
}

export const deleteAttachment = (id: number) => {
  return API.delete(ATTACHMENTS + `/${id}`)
}

export const updateAttachment = (
  id: number,
  data: UpdateAttachmentsDTO.Request,
) => {
  return API.patch<UpdateAttachmentsDTO.Response>(ATTACHMENTS + `/${id}`, data)
}
