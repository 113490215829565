import { PermissionSet, TRole } from '../models'
import { SettingsPermissions, UserManagementPermissions } from '../lib/types'

export enum GetRolesPermissions {
  VIEW_ADMIN_LISTING = UserManagementPermissions.VIEW_ADMIN_LISTING,
  ADD_NEW_ADMIN = UserManagementPermissions.ADD_NEW_ADMIN,
  EDIT_ADMIN = UserManagementPermissions.EDIT_ADMIN,
  VIEW_ROLES_AND_PERMISSIONS = SettingsPermissions.VIEW_ROLES_AND_PERMISSIONS,
  GRAND_ADMIN_PORTAL_ACCESS = UserManagementPermissions.GRAND_ADMIN_PORTAL_ACCESS,
}

export namespace GetRolesDTO {
  export interface Request {
    permission: GetRolesPermissions
    createAdmin?: boolean
  }
  export type Response = [TRole]
}

export namespace CreateRoleDTO {
  export interface Request {
    name: string
    permissionSet: PermissionSet
  }
  export type Response = TRole
}

export namespace UpdateRoleOrdersDTO {
  export interface Request {
    order: number[]
  }
}

export namespace AssignRoleToUsersDTO {
  export interface Request {
    users: number[]
  }
}
