import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'
import axios from 'axios'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import FacilityInfo from './FacilityInfo'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import {
  activateFacility,
  deactivateFacility,
  getFacilityById,
} from '../../../helpers/api_helper'
import {
  FacilityManagementPermissions,
  TFacility,
  TFacilityStatus,
} from '../../../sharedTypes'
import FacilityEmployees from './FacilityEmployees'
import ActivationModal from '../../../Components/Modals/PrimaryConfirmationModal'
import DeactivationModal from '../../../Components/Modals/DeleteConfirmation'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../helpers/toast_helper'
import { ReactComponent as FlashLightOff } from '../../../assets/images/svg/flashOff.svg'
import { usePermissions } from '../../../hooks/usePermissions'

type FacilityPageProps = WithRouterProps

const FacilityPage = ({ router }: FacilityPageProps) => {
  document.title = 'Facility | Mastered - Admin & Dashboard'

  const [facility, setFacility] = useState<TFacility | undefined>()
  const [isOpenActivationModal, setIsOpenConfirmationModal] =
    useState<boolean>(false)

  const hasPermissionToViewEmployees = usePermissions(
    FacilityManagementPermissions.VIEW_FACILITY_EMPLOYEES,
  )

  const fetchFacility = (facilityId: number) => {
    getFacilityById(facilityId)
      .then(res => {
        setFacility(res.data)
      })
      .catch(error => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          router.navigate('/404')
        }
      })
  }

  useEffect(() => {
    if (
      !router.params.facilityId ||
      Number.isNaN(+router.params.facilityId) ||
      typeof +router.params.facilityId !== 'number'
    ) {
      return router.navigate('/404')
    }
    fetchFacility(+router.params.facilityId)
  }, [router.params.userId])

  if (!facility) {
    return <></>
  }

  const handleFacilityActivation = () => {
    const action =
      facility.status === TFacilityStatus.INACTIVE
        ? activateFacility(facility.id)
        : deactivateFacility(facility.id)

    return action.then(() => {
      facility.status === TFacilityStatus.INACTIVE
        ? toast(
            `Facility ${facility.name} successfully activated`,
            successToastOptions,
          )
        : toast(
            `Facility ${facility.name} successfully deactivated`,
            successToastOptions,
          )
      setIsOpenConfirmationModal(false)

      setFacility({
        ...facility,
        status:
          facility.status === TFacilityStatus.ACTIVE
            ? TFacilityStatus.INACTIVE
            : TFacilityStatus.ACTIVE,
      })
    })
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={facility.name}
            items={[
              {
                linkTo: '/facility-management',
                title: 'Facility Management',
              },
              {
                active: true,
                title: facility.name,
              },
            ]}
          />
          <Row>
            <FacilityInfo
              facility={facility}
              onToggleActivation={() => setIsOpenConfirmationModal(true)}
              onEditSuccess={fetchFacility}
            />
          </Row>
        </Container>
      </div>

      <ActivationModal
        onConfirm={handleFacilityActivation}
        onClose={() => setIsOpenConfirmationModal(false)}
        isOpen={
          isOpenActivationModal && facility.status === TFacilityStatus.INACTIVE
        }
        message={`Please note: Activating the facility will send an email to
        all staff members instructing them to download the app.`}
        title={`Activate ${facility.name ?? ''} ?`}
        confirmButtonLabel={'Activate'}
        icon={'ri-flashlight-line'}
      />

      <DeactivationModal
        isOpen={
          isOpenActivationModal && facility.status === TFacilityStatus.ACTIVE
        }
        title={`Deactivate ${facility.name ?? ''} ?`}
        message={`Warning: Users will no longer be able to login to the app.`}
        confirmLabel={'Deactivate'}
        onDelete={handleFacilityActivation}
        onClose={() => setIsOpenConfirmationModal(false)}
        customImage={<FlashLightOff className='flash-light-off-danger' />}
      />
    </React.Fragment>
  )
}

export default withRouter(FacilityPage)
