import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import _ from 'lodash'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  GetUsersDTO,
  OrderType,
  TUser,
  USER_VERIFICATION_STATUS,
  UserManagementPermissions,
  UsersSortBy,
} from '../../../sharedTypes'
import {
  IColumn,
  UserId,
  UserInfo,
  Departments,
} from '../../../Components/Common/UsersTable'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { getUsers } from '../../../helpers/api/users'
import moment from 'moment/moment'
import { useAppSelector } from '../../../hooks/redux'
import { Pagination } from '../../../Components/Common/Pagination'
import ItemsPerPageDropdown from '../../../Components/Common/ItemsPerPageDropdown'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import Highlighter from 'react-highlight-words'
import UserCell from '../../../Components/Common/UserItemCell'
import { usePermissions } from '../../../hooks/usePermissions'
import FilterTabs from './Tabs'
import { useNavigate } from 'react-router-dom'
import AddNewUserModal from '../../../Components/Modals/AddNewUserModal'
import { handleError } from '../../../helpers/toast_helper'
import EditUserModal from '../../../Components/Modals/EditUserModal'
import Filters from '../Filters'

const Columns: IColumn[] = [
  {
    title: 'ID',
    id: 'id',
    component: UserId,
    sortBy: UsersSortBy.ID,
    style: { width: 70 },
  },
  {
    title: 'User',
    id: 'user',
    component: UserInfo,
    sortBy: UsersSortBy.USER,
    style: { width: 220 },
  },
  {
    title: 'Facility',
    id: 'facility',
    sortBy: UsersSortBy.FACILITY_NAME,
    selector: 'facility.name',
  },
  {
    title: 'Department',
    id: 'department',
    component: Departments,
  },
  {
    title: 'Position',
    id: 'position',
    sortBy: UsersSortBy.POSITION_NAME,
    selector: 'position.name',
  },
  {
    title: 'Account Creation Date',
    id: 'account-creation-date',
    sortBy: UsersSortBy.CREATION_DATE,
    selector: 'hireDate',
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    title: 'Verification Date',
    id: 'verification-date',
    sortBy: UsersSortBy.VERIFICATION_DATE,
    selector: 'hireDate',
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    title: 'Verified',
    id: 'verified',
    style: { width: 100 },
    sortBy: UsersSortBy.VERIFICATION_STATUS,
  },
  {
    title: 'Actions',
    id: 'Actions',
  },
]

const UserCreationLog = () => {
  document.title = 'User Creation Log | Mastered - Admin & Dashboard'

  const user = useAppSelector(state => state.User.user)

  const [isLoading, setIsLoading] = useState(false)
  const [showUserCreationModal, setShowUserCreationModal] = useState(false)
  const [showUserEditModal, setShowUserEditModal] = useState<null | TUser>(null)
  const [showFilters, setShowFilters] = useState(false)
  const [fetchData, setFetchData] = useState(false)

  const navigate = useNavigate()

  const permissions = {
    addNewUser: usePermissions(UserManagementPermissions.ADD_NEW_USER),
    matchUserAccount: usePermissions(
      UserManagementPermissions.MATCH_USER_ACCOUNT,
    ),
    editUser: usePermissions(UserManagementPermissions.EDIT_USER),
  }

  const [data, setData] = useState<GetUsersDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    users: [],
  })

  const [query, setQuery] = useState<GetUsersDTO.Request>({
    page: 1,
    limit: 10,
    facilityIds: [],
    departmentIds: [],
    positionIds: [],
    courseStatus: undefined,
    sortBy: UsersSortBy.ID,
    orderBy: OrderType.DESC,
    permission: UserManagementPermissions.VIEW_USER_CREATION_LOG,
    registeredStatuses: undefined,
    userCreationLog: true,
    verificationStatus: undefined,
  })

  const isFiltering = useMemo<boolean>(
    () =>
      _.size(query.positionIds) > 0 ||
      _.size(query.departmentIds) > 0 ||
      _.size(query.facilityIds) > 0 ||
      !!query.courseStatus ||
      !!query.status ||
      !!query.key,
    [query],
  )

  const handleSort = useCallback((column: UsersSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const hasPermissionToViewProfile = usePermissions(
    UserManagementPermissions.VIEW_USER_PROFILE,
  )

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      const _query = _.omitBy(query, _.isNil)
      if (!_.isEmpty(_query.creationDate)) {
        _query.creationDate = [
          moment(_query.creationDate[0]).format('YYYY-MM-DD'),
          moment(_query.creationDate[1]).format('YYYY-MM-DD'),
        ]
      }

      if (!_.isEmpty(_query.verificationDate)) {
        _query.verificationDate = [
          moment(_query.verificationDate[0]).format('YYYY-MM-DD'),
          moment(_query.verificationDate[1]).format('YYYY-MM-DD'),
        ]
      }

      getUsers(_query)
        .then(d => d.data)
        .then(res => {
          setData(res)
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  const columns = useMemo(() => {
    let _columns = [...Columns]
    if (user && user?.isFacilityAdmin && !user?.hasAccessToMultipleFacilities) {
      _columns = _columns.filter(column => column.title !== 'Facility')
    }

    if (!permissions.editUser && !permissions.matchUserAccount) {
      _columns = _columns.filter(column => column.title !== 'Actions')
    }

    return _columns
  }, [user, permissions.matchUserAccount])

  const canEditUser = (user: TUser) => {
    if (
      [
        USER_VERIFICATION_STATUS.VERIFIED,
        USER_VERIFICATION_STATUS.PENDING_MATCHING,
      ].includes(user.verificationStatus)
    ) {
      return false
    }

    return !(
      user.tempUserData &&
      user.verificationStatus === USER_VERIFICATION_STATUS.EXPIRED
    )
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='User Creation Log'
            items={[
              {
                linkTo: '/',
                title: 'User Management',
              },
              {
                active: true,
                title: 'User Creation Log',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <FilterTabs
                      navTab={query.verificationStatus}
                      navToggle={verificationStatus => {
                        setQuery(prev => ({
                          ...prev,
                          verificationStatus,
                          page: 1,
                        }))
                      }}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      {permissions.addNewUser && (
                        <Button
                          color={'primary'}
                          className='btn btn-soft-primary align-middle'
                          onClick={() => setShowUserCreationModal(true)}
                        >
                          <i className='ri-add-line me-1 fs-16'></i>Add User
                        </Button>
                      )}
                    </div>
                  </div>
                  <Row
                    className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-0`}
                  >
                    <Filters
                      visible={showFilters}
                      updateParamsInUrl={false}
                      setFilters={data => {
                        setQuery(prev => ({
                          ...prev,
                          facilityIds: data.facility,
                          creationDate: data.creationDate,
                          verificationDate: data.verificationDate,
                          page: 1,
                        }))

                        if (!fetchData) {
                          setFetchData(true)
                        }
                      }}
                      filterBy={{
                        facilities: true,
                        departments: false,
                        positions: false,
                        agencies: false,
                        groups: false,
                        status: false,
                        registered: false,
                        creationDate: true,
                        verificationDate: true,
                      }}
                    />
                  </Row>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={isFiltering}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <div className='overflow-x-auto'>
                      <Table
                        className='align-middle mb-0'
                        id={'users-creation-table'}
                      >
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {columns.map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.id}
                              >
                                {!!column.tooltip && (
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={'user-table-column' + column.id}
                                    style={{ minWidth: 300 }}
                                    autohide={false}
                                  >
                                    {column.tooltip}
                                  </UncontrolledTooltip>
                                )}
                                <div
                                  className='d-flex align-items-center gap-1'
                                  id={'user-table-column' + column.id}
                                >
                                  <span>{column.title}</span>
                                  {!!column.sortBy && (
                                    <ColumnSortIcon<UsersSortBy>
                                      sortOrder={query.orderBy}
                                      sortedColumn={query.sortBy}
                                      column={column.sortBy}
                                      handleSort={handleSort}
                                    />
                                  )}
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.users.map(user => (
                            <tr key={user.id} className='fs-14'>
                              <td
                                scope='row'
                                onClick={e => {
                                  e.stopPropagation()
                                  if (hasPermissionToViewProfile) {
                                    window.open(
                                      `/user-listing/profile/${user.id}`,
                                      '_blank',
                                    )
                                  }
                                }}
                                className='fs-14 cursor-pointer'
                              >
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  className='text-primary'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={user.id.toString()}
                                />
                              </td>
                              <UserCell user={user} globalSearch={query.key} />
                              {columns.find(
                                column => column.title === 'Facility',
                              ) && (
                                <td scope='row'>
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={user.facility?.name || ''}
                                  />
                                </td>
                              )}
                              <td scope='row'>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={
                                    user.departments?.length
                                      ? user.departments[0]?.name
                                      : ''
                                  }
                                />
                              </td>
                              <td scope='row'>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={user.position?.name || ''}
                                />
                              </td>
                              <td scope='row'>
                                <span
                                  className={`badge badge-soft-${
                                    moment().diff(
                                      moment(user.createdAt),
                                      'days',
                                    ) > 6 &&
                                    user.verificationStatus !==
                                      USER_VERIFICATION_STATUS.VERIFIED
                                      ? 'danger'
                                      : 'grey'
                                  } fs-12 fw-normal text-capitalize`}
                                >
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={
                                      moment(user.createdAt).format(
                                        'MM/DD/YYYY',
                                      ) || ''
                                    }
                                  />
                                </span>
                              </td>
                              <td scope='row'>
                                {user.verificationDate ? (
                                  <span
                                    className={`badge badge-soft-grey
                                  fs-12 fw-normal text-capitalize`}
                                  >
                                    <Highlighter
                                      highlightClassName='text-highlight'
                                      searchWords={[query.key || '']}
                                      highlightTag={'span'}
                                      autoEscape={true}
                                      textToHighlight={
                                        user.verificationDate
                                          ? moment(
                                              user.verificationDate,
                                            ).format('MM/DD/YYYY')
                                          : ''
                                      }
                                    />
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td scope='row'>
                                <span
                                  className={`badge badge-soft-${
                                    user.verificationStatus ===
                                    USER_VERIFICATION_STATUS.EXPIRED
                                      ? 'danger'
                                      : 'grey'
                                  } fs-12 fw-normal text-capitalize`}
                                >
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={
                                      user.verificationStatus || ''
                                    }
                                  />
                                </span>
                              </td>
                              {(permissions.editUser ||
                                permissions.matchUserAccount) && (
                                <td scope='row'>
                                  <div className='d-flex gap-2 align-items-center'>
                                    {permissions.editUser &&
                                      canEditUser(user) && (
                                        <i
                                          className={
                                            'ri-pencil-line cursor-pointer'
                                          }
                                          onClick={() => {
                                            setShowUserEditModal(user)
                                          }}
                                        />
                                      )}
                                    {permissions.matchUserAccount &&
                                      [
                                        USER_VERIFICATION_STATUS.PENDING_MATCHING,
                                        USER_VERIFICATION_STATUS.EXPIRED,
                                      ].includes(user.verificationStatus) && (
                                        <i
                                          className={
                                            'ri-external-link-line text-muted cursor-pointer'
                                          }
                                          onClick={() => {
                                            navigate('/match-user-account', {
                                              state: { user },
                                            })
                                          }}
                                        />
                                      )}
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className='mx-3 my-3'>
                      <ItemsPerPageDropdown
                        limit={query.limit ? query.limit : 10}
                        onChange={limit => {
                          setQuery(prev => ({
                            ...prev,
                            limit: limit ? +limit.value : 10,
                          }))
                        }}
                      />
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        limit={query.limit}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {showUserCreationModal && (
        <AddNewUserModal
          isOpen={showUserCreationModal}
          onClose={() => setShowUserCreationModal(false)}
          onAdd={() => {
            setQuery(prev => ({ ...prev, page: 1 }))
          }}
        />
      )}
      {showUserEditModal && (
        <EditUserModal
          isOpen={!!showUserEditModal}
          onClose={() => setShowUserEditModal(null)}
          user={showUserEditModal}
          onEdit={() => {
            setQuery(prev => ({ ...prev, page: 1 }))
          }}
        />
      )}
    </React.Fragment>
  )
}

export default UserCreationLog
