import {
  Button,
  Card,
  CardBody,
  CardHeader,
  TabContent,
  TabPane,
} from 'reactstrap'
import React, { useMemo, useRef, useState } from 'react'
import UsersNotRegistered from './UsersNotRegistered'
import ImportErrors from './ImportErrors'
import { Tabs, TabsTooltips } from '../types'
import AnnualEducationOverdue from './AnnualEducationOverdue'
import { TUser, UserManagementPermissions } from '../../../sharedTypes'
import { usePermissions } from '../../../hooks/usePermissions'
import UsersWithOnboardingExpired from './UsersWithOnboardingExpired'
import ExpiredUsers from './ExpiredUsers'
import UsersWithNoCourses from './UsersWithNoCourses'
import DetailsInfoModal from '../../../Components/Modals/DetailsInfoModal'

interface MainTabsProps {
  user: TUser | null
  includeAgency: boolean
}

const MainTabs = ({ user, includeAgency }: MainTabsProps) => {
  const permissions = {
    viewUserListing: usePermissions(
      UserManagementPermissions.VIEW_USER_LISTING,
    ),
    viewImportErrors: usePermissions(UserManagementPermissions.VIEW_IMPORT_LOG),
  }

  const defaultActiveTab = useMemo(() => {
    if (permissions.viewUserListing) {
      return Tabs.USERS_NOT_REGISTERED
    }

    if (permissions.viewImportErrors) {
      return Tabs.IMPORT_ERRORS
    }

    return Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION
  }, [permissions])

  const [activeTab, setActiveTab] = useState(defaultActiveTab)

  const [totalCounts, setTotalCounts] = useState({
    [Tabs.USERS_NOT_REGISTERED]: 0,
    [Tabs.IMPORT_ERRORS]: 0,
    [Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION]: 0,
    [Tabs.USERS_WITH_EXPIRED_ONBOARDING]: 0,
    [Tabs.EXPIRED_USERS]: 0,
    [Tabs.NO_COURSES]: 0,
  })

  const containerRef = useRef<HTMLDivElement>(null)

  const tabsData = useMemo(() => {
    const allowedTabs = []
    if (permissions.viewUserListing) {
      allowedTabs.push(Tabs.USERS_NOT_REGISTERED)
    }

    if (permissions.viewImportErrors) {
      allowedTabs.push(Tabs.IMPORT_ERRORS)
    }

    if (permissions.viewUserListing) {
      allowedTabs.push(Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION)
      allowedTabs.push(Tabs.USERS_WITH_EXPIRED_ONBOARDING)
      allowedTabs.push(Tabs.EXPIRED_USERS)
      allowedTabs.push(Tabs.NO_COURSES)
    }

    return allowedTabs
  }, [permissions])

  const tabChange = (tab: Tabs) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <>
      <Card>
        <CardHeader className='p-2 pb-1'>
          <div
            className='d-flex gap-2 slide-tabs-container'
            id='slide-tabs-container'
            ref={containerRef}
          >
            {Object.values(tabsData).map((tab, index) => (
              <div key={index} className='d-flex flex-grow-1 flex-wrap'>
                <Button
                  color={'primary'}
                  className={`${
                    activeTab === tab
                      ? 'btn btn-primary'
                      : 'btn-light border-dark'
                  } align-middle cursor-pointer w-100 d-flex align-items-center justify-content-center`}
                  onClick={() => {
                    tabChange(tab)
                  }}
                >
                  {tab} ({totalCounts[tab]})
                  <DetailsInfoModal
                    id={`tab-info-${index}`}
                    name={'tab-info'}
                    info={TabsTooltips[tab]}
                    onHover={true}
                  />
                </Button>
              </div>
            ))}
          </div>
        </CardHeader>
        <CardBody className='p-0'>
          <TabContent activeTab={activeTab}>
            {permissions.viewUserListing && (
              <TabPane tabId={Tabs.USERS_NOT_REGISTERED}>
                <UsersNotRegistered
                  setTotalCounts={setTotalCounts}
                  includeAgency={includeAgency}
                />
              </TabPane>
            )}
            {permissions.viewImportErrors && (
              <TabPane tabId={Tabs.IMPORT_ERRORS}>
                <ImportErrors
                  setTotalCounts={setTotalCounts}
                  includeAgency={includeAgency}
                />
              </TabPane>
            )}

            {permissions.viewUserListing && (
              <>
                <TabPane tabId={Tabs.FOUR_WEEKS_OVERDUE_ON_ANNUAL_EDUCATION}>
                  <AnnualEducationOverdue
                    setTotalCounts={setTotalCounts}
                    includeAgency={includeAgency}
                  />
                </TabPane>
                <TabPane tabId={Tabs.USERS_WITH_EXPIRED_ONBOARDING}>
                  <UsersWithOnboardingExpired
                    setTotalCounts={setTotalCounts}
                    includeAgency={includeAgency}
                  />
                </TabPane>
                <TabPane tabId={Tabs.EXPIRED_USERS}>
                  <ExpiredUsers
                    setTotalCounts={setTotalCounts}
                    includeAgency={includeAgency}
                  />
                </TabPane>
                <TabPane tabId={Tabs.NO_COURSES}>
                  <UsersWithNoCourses
                    setTotalCounts={setTotalCounts}
                    includeAgency={includeAgency}
                  />
                </TabPane>
              </>
            )}
          </TabContent>
        </CardBody>
      </Card>
    </>
  )
}

export default MainTabs
