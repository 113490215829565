import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Button, Col, Row, Table } from 'reactstrap'
import { Pagination } from '../../../../Components/Common/Pagination'
import {
  CourseCodesItem,
  CourseCodesSortBy,
  GetCourseCodeDTO,
  OrderType,
} from '../../../../sharedTypes'
import withRouter from '../../../../Components/Common/withRouter'
import { CourseContext } from '../../../../context/CourseContext'
import CourseCodeModal, {
  courseCodeInitialValues,
} from '../../../../Components/Modals/CoursesModal/CourseCodeModal'
import {
  deleteCourseCode,
  getCourseCodes,
} from '../../../../helpers/api_helper'
import NoResultTableWrapper from '../../../../Components/Common/NoResultTableWrapper'
import Highlighter from 'react-highlight-words'
import DeleteCourseCodeModal from '../../../../Components/Modals/DeleteConfirmation'
import { toast } from 'react-toastify'
import {
  handleError,
  successToastOptions,
} from '../../../../helpers/toast_helper'
import ColumnSortIcon from '../../../../Components/Common/ColumnSortIcon'
import emptyCourseCodes from '../../../../assets/images/pen.png'

const Columns = [
  {
    name: 'Course Tracking Number',
    style: { width: '15%' },
    sortBy: CourseCodesSortBy.COURSE_CODE,
  },
  {
    name: 'Board',
    style: { width: '25%' },
    sortBy: CourseCodesSortBy.BOARD,
  },
  {
    name: 'Disciplines',
    selector: 'facility.name',
    style: { width: '50%' },
    sortBy: CourseCodesSortBy.DISCIPLINES,
  },
  {
    name: 'Actions',
    selector: 'agency.name',
  },
]

interface CourseCodesProps {
  courseId: number
}

const CourseCodes = ({ courseId }: CourseCodesProps) => {
  const { course } = useContext(CourseContext)
  const [showCreateCourseCodeModal, setCreateCourseCodeModal] =
    useState<boolean>(false)
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState(false)
  const [edit, setEdit] = useState<courseCodeInitialValues | null>(null)
  const [itemToDelete, setItemToDelete] = useState<CourseCodesItem | null>(null)

  const [data, setData] = useState<GetCourseCodeDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    courseCodes: [],
  })

  const [query, setQuery] = useState<GetCourseCodeDTO.Request>({
    page: 1,
    limit: 10,
    key: '',
    sortBy: CourseCodesSortBy.COURSE_CODE,
    orderBy: OrderType.ASC,
  })

  const handleSort = useCallback((column: CourseCodesSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  useEffect(() => {
    if (course) {
      setIsLoading(true)
      getCourseCodes(course.id, query)
        .then(res => {
          setData(res)
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, course])

  const onCourseDelete = () => {
    if (itemToDelete) {
      deleteCourseCode(itemToDelete.id)
        .then(() => {
          toast(
            'Course tracking number deleted successfully.',
            successToastOptions,
          )
          setQuery(prev => ({ ...prev, page: 1 }))
        })
        .catch(handleError)
      setDeleteModal(false)
    }
  }

  return (
    <>
      <div className='w-100'>
        <Col className='pt-3 mt-3'>
          <Row className='pb-4'>
            <Col sm={9}>
              <p className='fs-20 text-muted'>Course Tracking Numbers</p>
            </Col>
            <Col>
              <div className='d-flex justify-content-end'>
                <Button
                  id='add-course-code'
                  color='primary'
                  onClick={() => setCreateCourseCodeModal(true)}
                >
                  <i className='ri-add-line me-1 fs-16'></i>Add
                </Button>
              </div>
            </Col>
          </Row>
          <div className='table-card'>
            <div className='overflow-x-auto'>
              <NoResultTableWrapper
                isLoading={isLoading}
                className='p-0'
                pages={data.pages}
                noDataErrorMessageImage={emptyCourseCodes}
                noDataErrorMessage='No Course Tracking Numbers yet'
              >
                <Table className='align-middle mb-0'>
                  <thead className='table-light'>
                    <tr className='text-muted fs-14'>
                      {Columns.map(column => (
                        <th
                          scope='col'
                          className='align-middle'
                          style={column.style}
                          key={column.name}
                        >
                          <div className='d-flex align-items-center gap-1'>
                            <span>{column.name}</span>
                            {!!column.sortBy && (
                              <ColumnSortIcon
                                sortOrder={query.orderBy}
                                sortedColumn={query.sortBy}
                                column={column.sortBy}
                                handleSort={handleSort}
                              />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.courseCodes.map(code => (
                      <tr key={code.id}>
                        <td>{code.courseCode}</td>
                        <td>
                          <Highlighter
                            highlightClassName='text-highlight'
                            searchWords={['']}
                            highlightTag={'span'}
                            autoEscape={true}
                            textToHighlight={code.provider.board}
                          />
                        </td>
                        <td>
                          <Highlighter
                            highlightClassName='text-highlight'
                            searchWords={['']}
                            highlightTag={'span'}
                            autoEscape={true}
                            textToHighlight={code.disciplines.join(', ')}
                          />
                        </td>
                        <td>
                          <span className='d-flex gap-2'>
                            <i
                              onClick={() => {
                                setCreateCourseCodeModal(true)
                                setEdit({
                                  id: code.id,
                                  courseCode: code.courseCode,
                                  providerId: code.providerId,
                                  disciplines: code.disciplines,
                                })
                              }}
                              className='bx bx-edit-alt cursor-pointer'
                            ></i>
                            <i
                              onClick={() => {
                                setItemToDelete(code)
                                setDeleteModal(true)
                              }}
                              className={`bx bx-trash text-danger cursor-pointer`}
                            ></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className='mx-3 mt-3'>
                  <Pagination
                    currentPage={data.page - 1}
                    totalPages={data.pages}
                    totalRecords={data.count}
                    limit={query.limit ? query.limit : 10}
                    setPage={(page: number) => {
                      setQuery(prev => ({ ...prev, page: ++page }))
                    }}
                  />
                </div>
              </NoResultTableWrapper>
            </div>
          </div>
        </Col>
      </div>

      <CourseCodeModal
        title={
          edit ? 'Edit Course Tracking Number' : 'Add Course Tracking Number'
        }
        isOpen={showCreateCourseCodeModal}
        onClose={() => {
          setEdit(null)
          setCreateCourseCodeModal(false)
        }}
        edit={!!edit}
        onSubmit={() => {
          setQuery(prev => ({ ...prev, page: 1 }))
        }}
        initialValues={edit ? edit : null}
        courseId={courseId}
      />
      {itemToDelete && (
        <DeleteCourseCodeModal
          isOpen={showDeleteModal}
          onClose={() => {
            setDeleteModal(false)
          }}
          title={'Delete Course Tracking Number'}
          onDelete={onCourseDelete}
          message={`Are you sure you want to delete course tracking number ${itemToDelete.courseCode} ?`}
        />
      )}
    </>
  )
}
export default withRouter(CourseCodes)
