import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import _ from 'lodash'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  OrderType,
  SearchUsersDTO,
  UsersSearchSortBy,
  UsersSortBy,
} from '../../../sharedTypes'
import UsersTable, {
  IColumn,
  UserInfo,
  UserRegistered,
  Departments,
  ViewPortal,
  UserStatus,
  UserId,
  UserAgency,
  UserCompany,
} from '../../../Components/Common/UsersTable'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { searchUsers } from '../../../helpers/api/users'
import SearchImg from '../../../assets/images/search.png'
import NoResults from '../../../assets/images/no-results-user-search.png'
import { handleError } from '../../../helpers/toast_helper'

const Columns: IColumn[] = [
  {
    title: 'ID',
    id: 'user-search-id',
    component: UserId,
    sortBy: UsersSearchSortBy.ID,
    style: { width: 70 },
  },
  {
    title: 'User',
    id: 'user',
    component: UserInfo,
    sortBy: UsersSearchSortBy.USER,
    style: { width: 220 },
  },
  {
    title: 'Company',
    id: 'company',
    component: UserCompany,
    sortBy: UsersSearchSortBy.COMPANY_NAME,
  },
  {
    title: 'Facility',
    id: 'facility',
    sortBy: UsersSearchSortBy.FACILITY_NAME,
    selector: 'facility.name',
  },
  {
    title: 'Agency',
    id: 'agency',
    component: UserAgency,
    sortBy: UsersSortBy.AGENCY_NAME,
    style: {
      width: window.innerWidth <= 1800 ? 170 : 245,
      display: window.innerWidth > 1700 ? 'table-cell' : 'none',
    },
  },
  {
    title: 'Department',
    id: 'department',
    component: Departments,
  },
  {
    title: 'Position',
    id: 'position',
    sortBy: UsersSearchSortBy.POSITION_NAME,
    selector: 'position.name',
  },
  {
    component: UserStatus,
    title: 'Active',
    id: 'active',
    style: { width: 100 },
    sortBy: UsersSearchSortBy.STATUS,
  },
  {
    component: UserRegistered,
    title: 'Registered',
    id: 'registered',
    style: { width: 100 },
    sortBy: UsersSearchSortBy.REGISTERED,
  },
  {
    component: ViewPortal,
    title: 'View',
    id: 'viewPortal',
    style: { width: window.innerWidth > 1600 ? 100 : 60 },
  },
]

const UserSearchNoResults = ({ searchValue }: { searchValue: string }) => {
  return (
    <CardBody className='no-results-user-search'>
      <div className='d-flex flex-column align-items-center py-5'>
        <img src={NoResults} alt='No results' className='mb-2' />
        <p className='fw-medium fs-20'>No Results found</p>
        <p className='text-muted text-center'>
          No users found for the request “{searchValue}”. Please try
          <br />a different name, email or phone.
        </p>
      </div>
    </CardBody>
  )
}

const UsersSearch = () => {
  document.title = 'User Search | Mastered - Admin & Dashboard'

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<SearchUsersDTO.Response>({
    count: 0,
    pages: 0,
    page: 1,
    users: [],
  })

  const [query, setQuery] = useState<SearchUsersDTO.Request>({
    page: 1,
    limit: 10,
    sortBy: UsersSearchSortBy.ID,
    orderBy: OrderType.ASC,
    key: '',
  })

  const handleSort = useCallback((column: UsersSearchSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const noResults = useMemo(
    () => query.key !== '' && !data.users.length && data.page === 1,
    [query, data],
  )

  const isSearched = useMemo(() => query.key !== '', [query.key])

  useEffect(() => {
    if (query.key !== '') {
      setIsLoading(true)
      searchUsers(_.omitBy(query, _.isNil) as SearchUsersDTO.Request)
        .then(d => d.data)
        .then(res => {
          setData(res)
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query])

  useEffect(() => {
    if (query.key === '' && data.users.length) {
      setData({
        count: 0,
        pages: 0,
        page: 1,
        users: [],
      })
    }
  }, [query.key, data.users])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='User Search'
            items={[
              {
                active: true,
                title: 'User Search',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader
                  className={`${!isSearched ? 'initial-user-search' : ''}`}
                >
                  <div className='d-flex flex-column align-items-center'>
                    {!isSearched && (
                      <img src={SearchImg} className='mb-5' alt='search-pic' />
                    )}
                    <p style={{ fontSize: 20, fontWeight: 600 }}>User Search</p>
                    <div className='users-search p-3 rounded'>
                      <SearchInput
                        style={{ width: 400 }}
                        placeholder='Enter a name, email address or phone of a user'
                        onChange={key => {
                          setQuery(prev => ({ ...prev, key, page: 1 }))
                        }}
                        value={query.key}
                      />
                    </div>
                  </div>
                </CardHeader>
                {isSearched && (
                  <>
                    {noResults ? (
                      <UserSearchNoResults searchValue={query.key} />
                    ) : (
                      <NoResultTableWrapper
                        isLoading={isLoading}
                        pages={data.pages}
                      >
                        <UsersTable
                          columns={Columns}
                          users={data.users}
                          sortedColumn={query.sortBy}
                          page={data.page - 1}
                          sortOrder={query.orderBy}
                          totalPages={data.pages}
                          onPageChanged={page => {
                            setQuery(prev => ({ ...prev, page: ++page }))
                          }}
                          onLimitChange={limit => {
                            setQuery(prev => ({ ...prev, limit }))
                          }}
                          limit={query.limit}
                          itemsPerPage={query.limit ? query.limit : 10}
                          handleSort={
                            handleSort as (
                              column: UsersSortBy | UsersSearchSortBy,
                            ) => void
                          }
                          totalUsers={data.count}
                          globalSearch={query.key || ''}
                          highlightSearchKey={false}
                        />
                      </NoResultTableWrapper>
                    )}
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UsersSearch
