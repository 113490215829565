import React, { useMemo } from 'react'
import { Button, Col } from 'reactstrap'
import _ from 'lodash-es'

import { Step2Props } from './types'
import Filters from './Filters'
import CoursesTable from '../../Course/CoursesTable'
import { CoursesTableProps } from '../../Course/CoursesTable/types'
import {
  CoursesTabs,
  CoursesTabsWithoutCE,
  OnlineCourseItem,
} from '../../../sharedTypes'
import { TCoursePackage } from '../../../sharedTypes/models/coursePackage'
import { toast } from 'react-toastify'
import { warningToastOptions } from '../../../helpers/toast_helper'

const AssignCourseStep2 = ({
  setFieldValue,
  values,
  onCancel,
  onNext,
  filters,
  setFilters,
  isLoading,
  itemsToEnroll,
  isValidating,
  hideBackButton,
}: Step2Props) => {
  const onSelect: CoursesTableProps['setSelected'] = (
    element,
    format,
  ): void => {
    const newValues = [...values[filters.activeTab]]
    const index = newValues.findIndex(item =>
      'course' in item ? item.course.id === element.id : item.id === element.id,
    )
    //already selected (need uncheck)
    if (index >= 0) {
      if (format && 'course' in newValues[index]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        newValues[index].format = format
        setFieldValue(`enrollment.${filters.activeTab}`, newValues)
        return
      }
      newValues.splice(index, 1)
      setFieldValue(`enrollment.${filters.activeTab}`, newValues)
      return
    }
    if (format) {
      newValues.push(
        'available_formats' in element ? { course: element, format } : element,
      )
    } else {
      if (filters.activeTab !== CoursesTabsWithoutCE.COURSES) {
        const packageCourseIds = (element as TCoursePackage).courses?.map(
          course => course.id,
        )
        const alreadySelectedCourses = values[CoursesTabs.COURSES].filter(
          courseData => _.includes(packageCourseIds, courseData.course.id),
        )

        const packageText =
          filters.activeTab === CoursesTabsWithoutCE.PACKAGES
            ? 'package '
            : 'training day ' + element.code
        alreadySelectedCourses.forEach(selectedCourseData => {
          toast(
            `Course ${selectedCourseData.course.code} in ${packageText} was already selected.
          Duplicate will be removed.`,
            warningToastOptions,
          )
        })

        const deselectedCourses = values[CoursesTabs.COURSES].filter(
          courseData => !_.includes(packageCourseIds, courseData.course.id),
        )

        setFieldValue(`enrollment.${CoursesTabs.COURSES}`, [
          ...deselectedCourses,
        ])
      }
      newValues.push(
        'available_formats' in element
          ? {
              course: element,
              format:
                element.available_formats.length === 1
                  ? element.available_formats[0]
                  : undefined,
            }
          : element,
      )
    }
    setFieldValue(`enrollment.${filters.activeTab}`, newValues)
  }

  const data = useMemo(() => {
    const selected = values[filters.activeTab].map(item => {
      return 'course' in item ? item.course : item
    })
    const _data = itemsToEnroll[filters.activeTab].data as (
      | OnlineCourseItem
      | TCoursePackage
    )[]

    const selectedNotInList = _.differenceBy(selected, _data, 'id') // not in the list

    return [...selectedNotInList, ..._data] as CoursesTableProps['data']
  }, [filters.activeTab, itemsToEnroll, values])

  return (
    <>
      <Filters
        filters={filters}
        onFiltersChange={setFilters}
        selectedCourses={values}
      />
      <CoursesTable
        selected={values}
        setSelected={onSelect}
        isLoading={isLoading}
        dataType={filters.activeTab}
        data={data}
        searchKey={filters.key}
      />
      <div className='row g-3 pt-4'>
        <Col lg={12}>
          <div
            className={`hstack gap-2 ${
              hideBackButton ? 'justify-content-end' : 'justify-content-between'
            }`}
          >
            {!hideBackButton && (
              <Button
                className='btn-soft-primary align-middle'
                color='secondary'
                onClick={onCancel}
                disabled={isValidating}
              >
                Back
              </Button>
            )}
            <Button
              className='align-middle'
              color='primary'
              onClick={onNext}
              disabled={isValidating}
            >
              Next
            </Button>
          </div>
        </Col>
      </div>
    </>
  )
}

export default AssignCourseStep2
