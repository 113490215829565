import React from 'react'
import { Label, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { filter, get, map } from 'lodash'
export enum UserTabItemEnum {
  HISTORY = 'HISTORY',
  // SKILLS_CHECKLISTS = 'SKILLS_CHECKLISTS',
  DOCUMENT = 'DOCUMENT',
}

export interface UserProfileTabItem {
  path: string
  label: string
  active: boolean
  count?: number
  error: boolean
}

export interface UserProfileTabs {
  [UserTabItemEnum.HISTORY]: UserProfileTabItem
  [UserTabItemEnum.DOCUMENT]: UserProfileTabItem
}

export const getStepBase = (
  path: string,
  tabs: UserProfileTabs,
  mode: 'prev' | 'next',
): string | null => {
  const filtered = map(tabs, t => t).filter(s => s.active)
  const currentStep = filtered.findIndex(s => path.includes(s.path))
  return get(
    filtered,
    `[${mode === 'prev' ? currentStep - 1 : currentStep + 1}].path`,
    null,
  )
}

export interface TabsHeaderProps {
  tabs: UserProfileTabs
  userId: number
}

export const TabsHeader = ({ tabs, userId }: TabsHeaderProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <>
      <Nav
        tabs
        className='nav justify-content-start mx-0 nav-tabs-custom rounded card-header-tabs border-bottom-1 mb-1'
      >
        {map(
          filter(
            map(tabs, v => v),
            { active: true },
          ),
          s => (
            <NavItem key={s.path}>
              <NavLink
                style={{
                  cursor: 'pointer',
                  color: s.error
                    ? '#F25959'
                    : !location.pathname.includes(s.path)
                    ? '#6C7393'
                    : '#0039C7',
                }}
                className={classnames({
                  active: location.pathname.includes(s.path),
                })}
                onClick={() => {
                  navigate(`${s.path}/${userId}`)
                }}
              >
                <div className='gap-2 d-flex'>
                  <Label
                    className='form-check-label'
                    chekc
                    htmlFor='flexSwitchCheckDefault'
                  >
                    {s.label}
                  </Label>
                  {Number.isInteger(s.count) && (
                    <span className='badge badge-soft-grey fs-12 fw-normal text-capitalize'>
                      {s.count}
                    </span>
                  )}
                </div>
              </NavLink>
            </NavItem>
          ),
        )}
      </Nav>
    </>
  )
}
