import { Button } from 'reactstrap'
import React, { useEffect, useMemo, useState } from 'react'
import NoResults from '../../../../assets/images/no-result.png'
import { Bar } from 'react-chartjs-2'
import { getSurveyStatistics } from '../../../../helpers/api/surveys'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  SurveyStatisticsItem,
  GetSurveyStatisticsDTO,
} from '../../../../sharedTypes'
import { getAveragePercent } from '../../../../utils/users'
import NoResultTableWrapper from '../../../../Components/Common/NoResultTableWrapper'
import { useAppSelector } from '../../../../hooks/redux'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import ReactDOMServer from 'react-dom/server'
import logoDark from '../../../../assets/images/logo-dark.png'
import { omit } from 'lodash'

interface OverviewProps {
  surveyId: number | undefined
}

interface PDFAppendedComponentProps
  extends Omit<GetSurveyStatisticsDTO.Response, 'data'> {}

const PDFAppendedComponent = (props: PDFAppendedComponentProps) => {
  return (
    <div>
      <section
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <span style={{ fontWeight: 700, fontSize: '24px' }}>{props.name}</span>
        <span>
          <img style={{ height: 18 }} src={logoDark}></img>
        </span>
      </section>
      <p className='text-muted mb-3'>{props.description}</p>
      <p className='text-muted mb-2'>
        <span>Date Sent :</span> {props.dateSend}
      </p>
      <p className='text-muted mb-2'>
        <span>Report Date :</span> {props.reportDate}
      </p>
      <p className='text-muted mb-2'>
        <span>Number of completed surveys:</span> {props.completedSurveys}
      </p>
      <p className='text-muted mb-4'>
        <span>Completion rate:</span> {props.completionRate}%
      </p>
    </div>
  )
}

const Overview = ({ surveyId }: OverviewProps) => {
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )

  const barChartColor = ['#85A8FF', '#D3D0FA']
  const [surveyStatisticsResponse, setSurveyStatisticsResponse] =
    useState<GetSurveyStatisticsDTO.Response>({
      dateSend: '',
      name: '',
      reportDate: '',
      description: '',
      data: [],
      completedSurveys: 0,
      completionRate: 0,
    })

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const hasData = useMemo(
    () =>
      surveyStatisticsResponse.data.reduce(
        (accumulator: number, currentValue) =>
          accumulator +
          currentValue.data.reduce((a: number, c) => a + c.amount, 0),
        0,
      ),
    [surveyStatisticsResponse, isLoading],
  )

  const options = {
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'end',
        align: 'end',
        clamp: true,
        offset: 4,
        font: {
          size: 14,
        },
        formatter: function (
          value: number,
          context: { dataset: { data: [] } },
        ) {
          const totalAnsweredUsers = context.dataset.data.reduce(
            (a, b) => +a + +b,
            0,
          )
          return `${value} (${getAveragePercent(value, totalAnsweredUsers)}%)`
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true, // Show grid lines
          color: 'rgba(0,0,0,0.1)', // Set the color of the grid lines
          lineWidth: 1, // Set the width of the grid lines
          borderDash: [5, 5], // Create dashed grid lines (optional)
          drawOnChartArea: true, // Draw grid lines on the chart area
          drawTicks: true, // Draw grid lines at the tick marks
        },
      },
    },
    y: {
      grid: {
        display: true, // Show grid lines
        color: 'rgba(0,0,0,0.1)', // Set the color of the grid lines
        lineWidth: 1, // Set the width of the grid lines
        borderDash: [5, 5], // Create dashed grid lines (optional)
        drawOnChartArea: true, // Draw grid lines on the chart area
        drawTicks: true, // Draw grid lines at the tick marks
      },

      yAxes: [
        {
          ticks: {
            reverse: false,
            fontSize: 16,
            stepSize: 10,
            min: 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 16,
            fontColor: '#212529',
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  }

  useEffect(() => {
    const facilityId = selectedFacilityId ?? undefined
    const groupId = selectedGroupId ?? undefined
    if (surveyId) {
      getSurveyStatistics(surveyId, {
        facilityId,
        groupId,
      })
        .then(setSurveyStatisticsResponse)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [surveyId, selectedFacilityId, selectedGroupId])

  const sanitizeFileName = (name: string) => {
    return name.replace(/[<>:"/\\|?*]/g, '').trim()
  }

  const generatePdf = async () => {
    const blockToPrint = document.getElementById('divToPrint') as HTMLElement

    html2canvas(blockToPrint, {
      onclone: clonedDoc => {
        const header = clonedDoc.getElementById('pdf-header') as HTMLElement

        const reactComponentHtml = ReactDOMServer.renderToString(
          <PDFAppendedComponent
            {...omit(surveyStatisticsResponse, ['data'])}
          />,
        )

        const extraElement = document.createElement('div')
        extraElement.innerHTML = reactComponentHtml

        header.appendChild(extraElement)
      },
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      const imgProps = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)

      pdf.save(
        surveyStatisticsResponse.name
          ? `${sanitizeFileName(surveyStatisticsResponse.name)}.pdf`
          : 'survey-results.pdf',
      )
    })
  }

  const getData = (item: SurveyStatisticsItem) => {
    const labels = item.data.map(d => d.name)
    const data = item.data.map(d => d.amount)
    return {
      labels: labels,
      datasets: [
        {
          backgroundColor: barChartColor[0],
          borderColor: barChartColor[0],
          borderWidth: 1,
          hoverBackgroundColor: barChartColor[1],
          hoverBorderColor: barChartColor[1],
          data: data,
        },
      ],
    }
  }

  return (
    <NoResultTableWrapper
      noDataErrorMessage={'There are no responses to this survey yet.'}
      noDataErrorMessageImage={NoResults}
      isLoading={isLoading}
      isFiltering={false}
      pages={hasData ? 1 : 0}
      className='overview-answers-card'
    >
      <div className='mt-3 p-3'>
        <Button onClick={() => generatePdf()}>Export</Button>
      </div>

      <div className='mt-3 p-4' id='divToPrint'>
        <div id='pdf-header'></div>
        {surveyStatisticsResponse.data.map((questionData, index: any) => (
          <div key={index} className='mb-2'>
            <p className='w-medium fs-16 bg-light p-2'>
              {questionData.question}
            </p>
            <div className='px-4 text-muted pb-2'>
              <div>Num of</div>
              <div>responses</div>
            </div>
            <div className='ms-3'>
              <Bar
                width={1000}
                height={260}
                data={getData(questionData)}
                options={options}
                plugins={[ChartDataLabels]}
              />
            </div>
          </div>
        ))}
      </div>
    </NoResultTableWrapper>
  )
}

export default Overview
