import React, { useCallback } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  FormFeedback,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { useFormik } from 'formik'
import Select, { MultiValue } from 'react-select'
import classnames from 'classnames'

import { FormikHelpers } from 'formik'
import { Spinner } from 'reactstrap'
import { StatesEnum } from '../../../sharedTypes'
import {
  ProvidersInitialValues,
  ProvidersModalSubmitedValues,
} from '../../../sharedTypes/api/providers'
import { SignatureUpload } from '../../../Components/Common/SignatureUpload'
import { CE_PROVIDERS_STATUSES } from '../../../sharedTypes'
import handleAcceptedFile from '../../../utils/handleAcceptedFile'
import { OnlineProviderItem } from '../../../sharedTypes/models/ceProvider'
import { CoursePosition } from '../../../sharedTypes/api/coursePositions'
import { ceProviderSchema } from '../../../schemas'

interface ProviderModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (
    values: ProvidersModalSubmitedValues,
    formikHelpers: FormikHelpers<ProvidersModalSubmitedValues>,
  ) => void
  title: string
  initialValues?: OnlineProviderItem | ProvidersInitialValues | null
  disciplines: CoursePosition[]
}

const CEProviderModal = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  initialValues,
  disciplines,
}: ProviderModalProps) => {
  const form = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: undefined,
      providerNumber: 0,
      board: '',
      disciplines: [],
      states: [],
      signatoryName: '',
      credentials: '',
      signatoryTitle: '',
      signature: undefined,
      status: '',
    },
    validationSchema: ceProviderSchema,
    onSubmit: (values, formik) => {
      onSubmit(
        values as ProvidersModalSubmitedValues,
        formik as FormikHelpers<ProvidersModalSubmitedValues>,
      )
    },
  })

  const handleStateChange = (
    value: MultiValue<{ value: string; label: string }>,
  ) => {
    const allStates = Object.values(StatesEnum)
    const isAllSelected = value.some(item => item.value === 'all')
    const uniqueValues = Array.from(new Set(value.map(item => item.label)))
    form.setFieldValue('states', isAllSelected ? allStates : uniqueValues)
  }

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  return (
    <Modal
      isOpen={isOpen}
      toggle={beforeClose}
      centered
      style={{ maxWidth: 900 }}
    >
      <ModalBody
        className='modal-body'
        style={{ maxHeight: '85vh', overflowY: 'auto' }}
      >
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>

        <div className='vstack gap-3'>
          <Row>
            <Col>
              <label htmlFor='providerNumber' className='form-label'>
                Provider Number*
              </label>
              <Input
                name='providerNumber'
                className='form-control'
                id='providerNumber'
                disabled={form.isSubmitting}
                placeholder='000 000'
                type='text'
                onChange={newValue => {
                  form.setFieldValue('providerNumber', newValue.target.value)
                }}
                onBlur={form.handleBlur}
                value={form.values.providerNumber || ''}
                invalid={
                  !!(form.touched.providerNumber && form.errors.providerNumber)
                }
              />
              {form.touched.providerNumber && form.errors.providerNumber ? (
                <FormFeedback type='invalid'>
                  {form.errors.providerNumber}
                </FormFeedback>
              ) : null}
            </Col>
            <Col>
              <label htmlFor='board' className='form-label'>
                Board*
              </label>
              <Input
                name='board'
                className='form-control'
                id='board'
                disabled={form.isSubmitting}
                placeholder='Enter board name'
                type='text'
                onBlur={form.handleBlur}
                value={form.values.board || ''}
                onChange={newValue => {
                  form.setFieldValue('board', newValue.target.value)
                }}
                invalid={!!(form.touched.board && form.errors.board)}
              />
              {form.touched.board && form.errors.board ? (
                <FormFeedback type='invalid'>{form.errors.board}</FormFeedback>
              ) : null}
            </Col>
          </Row>
          <div className='provider-disciplines'>
            <label htmlFor='disciplines' className='form-label'>
              Discipline
            </label>
            <Select
              name='disciplines'
              id='disciplines'
              isMulti
              isSearchable
              onChange={value =>
                form.setFieldValue(
                  'disciplines',
                  value.map(item => item.label),
                )
              }
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  borderRadius: '0px 4px 4px 0px',
                  minHeight: 39,
                  zIndex: 2,
                }),
              }}
              placeholder='Select discipline'
              value={form.values.disciplines.map(item => ({
                value: item,
                label: item,
              }))}
              options={disciplines.map(item => {
                return { value: item.name, label: item.name }
              })}
              className='select2-container'
              classNamePrefix='select2-selection form-select__wrap'
            />
          </div>
          <div>
            <label htmlFor='states' className='form-label'>
              State
            </label>
            <Select
              isSearchable
              onChange={value => handleStateChange(value)}
              name='states'
              id='states'
              isMulti
              placeholder='Select state'
              onBlur={form.handleBlur}
              value={form.values.states.map(item => ({
                value: item,
                label: item,
              }))}
              defaultValue={[
                { value: 'all', label: 'All' },
                ...Object.entries(StatesEnum).map(([key, value]) => ({
                  value: key,
                  label: value,
                })),
              ].filter(item => initialValues?.states.includes(item.label))}
              options={[
                { value: 'all', label: 'All' },
                ...Object.entries(StatesEnum).map(([key, value]) => ({
                  value: key,
                  label: value,
                })),
              ]}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  borderRadius: '0px 4px 4px 0px',
                  minHeight: 39,
                }),
              }}
              isClearable
              className='select2-container'
              classNamePrefix='select2-selection form-select__wrap'
            />
            {form.touched.states && form.errors.states ? (
              <FormFeedback type='invalid'>{form.errors.states}</FormFeedback>
            ) : null}
          </div>
          <SignatureUpload
            error={form.errors.signature}
            isRequired={false}
            signature={form.values.signature}
            isSubmitting={form.isSubmitting}
            onDrop={acceptedFiles => {
              handleAcceptedFile(acceptedFiles, 'signature', form.setFieldValue)
            }}
            onDelete={() => {
              form.setFieldValue('signature', null)
            }}
          ></SignatureUpload>
          <Row>
            <Col>
              <label htmlFor='signatoryName' className='form-label'>
                Signatory Name*
              </label>
              <Input
                name='signatoryName'
                className='form-control'
                id='signatoryName'
                disabled={form.isSubmitting}
                placeholder='Enter signatory name'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.signatoryName || ''}
                invalid={
                  !!(form.touched.signatoryName && form.errors.signatoryName)
                }
              />

              {form.touched.signatoryName && form.errors.signatoryName ? (
                <FormFeedback type='invalid'>
                  {form.errors.signatoryName}
                </FormFeedback>
              ) : null}
            </Col>
            <Col>
              <label htmlFor='credentials' className='form-label'>
                Credentials*
              </label>
              <Input
                name='credentials'
                className='form-control'
                id='credentials'
                disabled={form.isSubmitting}
                placeholder='Enter credentials'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.credentials || ''}
                invalid={
                  !!(form.touched.credentials && form.errors.credentials)
                }
              />

              {form.touched.signatoryTitle && form.errors.signatoryTitle ? (
                <FormFeedback type='invalid'>
                  {form.errors.signatoryTitle}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className="d-flex v-stack flex-row justify-content-end w-['100%']">
            <Col>
              <label htmlFor='signatoryTitle' className='form-label'>
                Signatory Title*
              </label>
              <Input
                name='signatoryTitle'
                className='form-control'
                id='signatoryTitle'
                disabled={form.isSubmitting}
                placeholder='Enter signatory title'
                type='text'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.signatoryTitle || ''}
                invalid={
                  !!(form.touched.signatoryTitle && form.errors.signatoryTitle)
                }
              />

              {form.touched.signatoryTitle && form.errors.signatoryTitle ? (
                <FormFeedback type='invalid'>
                  {form.errors.signatoryTitle}
                </FormFeedback>
              ) : null}
            </Col>
            <Col className='d-flex align-items-end'>
              <Nav className='nav-customs-bordered flex-1'>
                {Object.values(CE_PROVIDERS_STATUSES).map(tab => (
                  <NavItem key={tab} className='flex-1'>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={
                        classnames({
                          active: form.values.status === tab,
                        }) + ' text-capitalize'
                      }
                      onClick={() => {
                        form.setFieldValue('status', tab)
                      }}
                    >
                      {tab}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
          </Row>
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-primary'
            onClick={beforeClose}
            active={false}
            disabled={form.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            color='success'
            type='button'
            onClick={() => form.handleSubmit()}
            disabled={!form.dirty || !form.isValid || form.isSubmitting}
          >
            {form.isSubmitting ? (
              <Spinner size={'sm'} />
            ) : (
              `${initialValues?.id ? 'Update' : 'Submit'}`
            )}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CEProviderModal
