import React, { useState } from 'react'
import { Input } from 'reactstrap'

interface AdvancedSearchFiltersProps {
  onChange: (filters: string[]) => void
}
const AdvancedSearchFilters = ({ onChange }: AdvancedSearchFiltersProps) => {
  const [filters, setFilters] = useState([
    { name: 'Objectives', active: false, value: 'objective' },
    { name: 'Resources', active: false, value: 'resources' },
    { name: 'Notes', active: false, value: 'notes' },
    {
      name: 'Competency Test Questions & Answers',
      active: false,
      value: 'questionsAnswers',
    },
  ])

  const handleFilterChange = (index: number, checked: boolean) => {
    const newFilters = [...filters]
    newFilters[index].active = checked
    setFilters(newFilters)
    const activeFilters = filters
      .filter(filter => filter.active)
      .map(filter => filter.value)
    onChange(activeFilters)
  }

  return (
    <div className={'d-flex align-items-center gap-3'}>
      {filters.map(
        (filter: { name: string; active: boolean }, index: number) => (
          <div key={index} className='d-flex align-items-center gap-2 '>
            <Input
              className='form-check-input cursor-pointer m-0'
              type='checkbox'
              name={`addFilter`}
              id={`add-filter-${index}`}
              checked={filter.active}
              onChange={e => handleFilterChange(index, e.target.checked)}
            />
            <label className={'mb-0'} htmlFor={filter.name}>
              {filter.name}
            </label>
          </div>
        ),
      )}
    </div>
  )
}

export default AdvancedSearchFilters
