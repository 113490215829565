import React, { useEffect } from 'react'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import { getUserById } from '../../../helpers/api/users'
import axios from 'axios'
import CourseHistory from './CourseHistory'
import { UserProfileLayout } from '../../../Components/Common/UserProfileLayout'
import { useUser } from '../../../context/UserContext'
import { useNavigate } from 'react-router-dom'

type UserProfileProps = WithRouterProps

const UserProfile = ({ router }: UserProfileProps) => {
  document.title = 'User profile | Mastered - Admin & Dashboard'

  const { user, handleUserGet, onDeactivate } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !router.params.userId ||
      Number.isNaN(+router.params.userId) ||
      typeof +router.params.userId !== 'number'
    ) {
      return router.navigate('/404')
    }

    getUserById(+router.params.userId)
      .then(res => {
        handleUserGet(res.data)
      })
      .catch(error => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          navigate('/404')
        }
      })
  }, [])

  return (
    <React.Fragment>
      {user && (
        <UserProfileLayout user={user} onDeactivate={onDeactivate}>
          <CourseHistory user={user} />
        </UserProfileLayout>
      )}
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
