import React from 'react'
import { Button, Col, Modal, ModalBody, ModalProps, Row } from 'reactstrap'
import MicIcon from '../../assets/images/mic.png'
import { TPushNotification } from '../../sharedTypes/models/pushNotifications'
import { ENGLISH_LANGUAGE_CODE } from '../../helpers/common'
import _ from 'lodash'

interface PreviewNotificationModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  notification: TPushNotification
}

const PreviewNotificationModal = ({
  onClose,
  isOpen,
  notification,
}: PreviewNotificationModalProps) => {
  const englishTranslation = notification.translations?.find(
    item => _.get(item, 'language.code') === ENGLISH_LANGUAGE_CODE,
  )

  const title = _.get(englishTranslation, 'title', ' ')
  const message = _.get(englishTranslation, 'message', ' ')

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      centered
      className='preview-survey-modal'
    >
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-2 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Preview</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onClose}
          ></i>
        </div>
        <div className='vstack gap-3'>
          <Row>
            <Col md={12}>
              <div
                className='rounded d-flex align-items-center justify-content-between p-2 gap-3'
                style={{ background: '#F5F9FF' }}
              >
                <div
                  className='bg-white rounded-circle p-2'
                  style={{ width: 50, height: 50, minWidth: 50 }}
                >
                  <img
                    src={MicIcon}
                    alt='NO IMAGE'
                    style={{ maxWidth: '100%' }}
                  />
                </div>
                <div className='d-flex flex-column flex-grow-1'>
                  <div className='d-flex justify-content-between'>
                    <div className='fw-seibold fs-16 mb-2'>{title}</div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <span className='fs-14 text-muted'>{message}</span>
                    <div className='d-flex align-items-end'>
                      <i className='ri-arrow-right-s-line align-middle text-primary'></i>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button color='primary' onClick={onClose}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PreviewNotificationModal
