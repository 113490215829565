import React, { useEffect, useRef, useState } from 'react'
import { Col } from 'reactstrap'
import _ from 'lodash'

import {
  COURSE_TRANSLATIONS_STATUS,
  CoursesTabs,
  CourseTypesFiltersFormatsOptions,
  FilterOption,
  ONLINE_COURSE_STATUS,
  CoursesPermissions,
  StatesOptions,
} from '../../../sharedTypes'
import MultiSelect from '../../../Components/Common/MultiSelect'
import { FiltersProps } from './types'
import { CoursePackageStatus } from '../../../sharedTypes/models/coursePackage'
import Flatpickr from 'react-flatpickr'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import { FTagSortBy, GetFTagsDTO } from '../../../sharedTypes/api/ftags'
import { getFTags } from '../../../helpers/api_helper'
import { useCourseTypes } from '../../../hooks/course/useCourseTypes'

const Filters: <FilterObject>(
  props: FiltersProps<FilterObject>,
) => React.JSX.Element | null = ({ visible, setFilters, activeTab }) => {
  if (!visible) {
    return null
  }

  const datePickerRef = useRef<Flatpickr>(null)
  const [selectedStatus, setSelectedStatus] = useState<FilterOption[]>([])
  const [selectedTranslationStatus, setSelectedTranslationStatus] = useState<
    FilterOption[]
  >([])

  const courseTypeOptions = useCourseTypes()

  useEffect(() => {
    setSelectedStatus([])
  }, [activeTab])

  const fetchFTags = (inputValue?: string) => {
    const params: GetFTagsDTO.Request = {
      page: 1,
      limit: 20,
      key: inputValue,
      permission: CoursesPermissions.VIEW_COURSE_LISTING,
      sortBy: FTagSortBy.CODE,
      withCourses: true,
    }

    return getFTags(params)
      .then(res => {
        return res.ftags.map(ftag => ({
          value: ftag.id,
          label: ftag.code + ' - ' + ftag.name,
        }))
      })
      .catch(() => [])
  }

  return (
    <>
      {activeTab !== CoursesTabs.PACKAGES ? (
        <>
          {activeTab === CoursesTabs.COURSES && (
            <Col className='mt-0 p-0'>
              <div>
                <label htmlFor='format' className='form-label'>
                  Filter by Format
                </label>

                <MultiSelect
                  name='format'
                  id='format'
                  isMulti
                  options={CourseTypesFiltersFormatsOptions}
                  isSearchable={true}
                  placeholder='Select format'
                  isClearable={false}
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(option: FilterOption[]) => {
                    setFilters(prev => ({
                      ...prev,
                      courseFormats: _.isEmpty(option)
                        ? []
                        : _.map(option, 'value'),
                    }))
                  }}
                />
              </div>
            </Col>
          )}
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='courseStatus' className='form-label'>
                Filter by Status
              </label>

              <MultiSelect
                name='status'
                id='courseStatus'
                isMulti
                options={Object.values(ONLINE_COURSE_STATUS).map(value => ({
                  value: value,
                  label: _.capitalize(value),
                }))}
                isSearchable={true}
                placeholder='Select status'
                isClearable={true}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={selectedStatus}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    statuses: _.isEmpty(option) ? [] : _.map(option, 'value'),
                  }))
                  setSelectedStatus(option)
                }}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='ftag' className='form-label'>
                Filter by FTag
              </label>

              <AsyncSelectWithSearch
                name='ftag'
                id='ftag'
                isMulti={false}
                isClearable={true}
                isSearchable={true}
                placeholder='Select FTag'
                defaultOptions
                onChange={(option: FilterOption) => {
                  setFilters(prev => ({
                    ...prev,
                    ftags: option ? option.value : undefined,
                  }))
                }}
                loadOptions={fetchFTags}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  option: base => ({ ...base, cursor: 'pointer' }),
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </Col>
          {activeTab === CoursesTabs.COURSES && (
            <Col className='mt-0 p-0'>
              <div>
                <label htmlFor='type' className='form-label'>
                  Filter by Type
                </label>

                <MultiSelect
                  name='type'
                  id='type'
                  isMulti
                  options={courseTypeOptions}
                  isSearchable={true}
                  placeholder='Select type'
                  isClearable={true}
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(option: FilterOption[]) => {
                    setFilters(prev => ({
                      ...prev,
                      types: _.isEmpty(option) ? [] : _.map(option, 'value'),
                    }))
                  }}
                />
              </div>
            </Col>
          )}
          <Col className='mt-0 p-0'>
            <div style={{ minWidth: 175 }}>
              <label htmlFor='courseStatus' className='form-label'>
                Filter by Translation Status
              </label>

              <MultiSelect
                name='status'
                id='courseStatus'
                isMulti
                options={Object.values(COURSE_TRANSLATIONS_STATUS).map(
                  value => ({
                    value: value,
                    label: _.startCase(value),
                  }),
                )}
                isSearchable={true}
                placeholder='Select status'
                isClearable={true}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={selectedTranslationStatus}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    translationStatus: _.isEmpty(option)
                      ? []
                      : _.map(option, 'value'),
                  }))
                  setSelectedTranslationStatus(option)
                }}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='validStates' className='form-label'>
                Filter by Valid States
              </label>

              <MultiSelect
                name='validState'
                id='type'
                isMulti
                options={StatesOptions}
                isSearchable={true}
                placeholder='Select valid state'
                isClearable={true}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    states: _.isEmpty(option) ? [] : _.map(option, 'value'),
                  }))
                }}
              />
            </div>
          </Col>
        </>
      ) : (
        <>
          <Col className='mt-0 p-0' sm={3}>
            <div>
              <label htmlFor='packageStatus' className='form-label'>
                Filter by Status
              </label>

              <MultiSelect
                name='packageStatus'
                id='packageStatus'
                isMulti
                options={Object.values(CoursePackageStatus).map(value => ({
                  value: value,
                  label: _.capitalize(value),
                }))}
                isSearchable={true}
                placeholder='Select status'
                isClearable={true}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={selectedStatus}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    statuses: _.isEmpty(option) ? [] : _.map(option, 'value'),
                  }))
                  setSelectedStatus(option)
                }}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0' sm={3}>
            <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
              <div className='w-100 filter-by-date'>
                <label htmlFor='type' className='form-label'>
                  Filter by Publish Date
                </label>

                <div className='form-icon right'>
                  <Flatpickr
                    className='form-control'
                    placeholder='--/--/---- - --/--/---'
                    onChange={dates => {
                      if (dates.length === 2) {
                        setFilters(prev => ({
                          ...prev,
                          publishedDate: dates,
                        }))
                      }
                    }}
                    ref={datePickerRef}
                    options={{
                      mode: 'range',
                      dateFormat: 'd-m-Y',
                      locale: {
                        rangeSeparator: ' - ',
                      },
                    }}
                  />
                  <i
                    className='ri-close-line fs-16 text-danger'
                    onClick={() => {
                      datePickerRef.current?.flatpickr.clear()
                      setFilters(prev => ({
                        ...prev,
                        publishedDate: [],
                      }))
                    }}
                  ></i>
                  <i
                    className='ri-calendar-2-line fs-20 text-primary'
                    onClick={() => {
                      datePickerRef.current?.flatpickr.open()
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </Col>
        </>
      )}
    </>
  )
}

export default Filters
