import {
  Button,
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
} from 'reactstrap'
import React, { useState } from 'react'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import { AsyncSelectWithSearch } from '../../Common/SelectWithSearch'
import {
  getBoards,
  postCourseCode,
  updateCourseCode,
} from '../../../helpers/api_helper'
import { GetBoardsDTO } from '../../../sharedTypes/api/providers'
import MultiSelect from '../../Common/MultiSelect'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'

interface CourseCodeModalProps {
  onClose: () => void
  onSubmit: () => void
  edit: boolean
  isOpen: ModalProps['isOpen']
  title: string
  courseId: number
  initialValues?: courseCodeInitialValues | null
}

export type courseCodeInitialValues = {
  id?: number
  courseCode: string
  providerId: number
  disciplines: string[]
}

const CourseCodeModal = ({
  onClose,
  isOpen,
  title,
  initialValues,
  courseId,
  onSubmit,
  edit,
}: CourseCodeModalProps) => {
  const [boards, setBoards] = useState<GetBoardsDTO.Response[]>([])

  const form = useFormik<courseCodeInitialValues>({
    enableReinitialize: true,
    initialValues: initialValues || {
      courseCode: '',
      providerId: -1,
      disciplines: [],
    },
    validationSchema: Yup.object({
      courseCode: Yup.string()
        .trim()
        .required('Course Tracking Number is required'),
      providerId: Yup.number().required('Board is required'),
      disciplines: Yup.array()
        .min(1, 'At least one credential is required')
        .required(),
    }),
    onSubmit: values => _onSubmit(values),
  })

  const _onSubmit = async (values: courseCodeInitialValues) => {
    try {
      form.setSubmitting(true)
      const payload = {
        courseCode: values.courseCode,
        providerId: values.providerId,
        disciplines: values.disciplines,
      }
      if (edit) {
        await updateCourseCode(values.id as number, payload)
        toast(
          'Success - Course tracking number updated successfully',
          successToastOptions,
        )
      } else {
        await postCourseCode(courseId, payload)
        toast(
          'Success - Course tracking number added successfully',
          successToastOptions,
        )
      }
      form.setSubmitting(false)
      form.resetForm()
      onSubmit()
      onClose()
    } catch (e) {
      form.setSubmitting(false)
      handleError(e)
    }
  }

  const fetchBoards = () => {
    return getBoards().then(res => {
      setBoards(res)
      return Array.from(
        new Set(res.map(item => ({ label: item.board, value: item.id }))),
      )
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      className='media-library-modal'
      centered
    >
      <ModalHeader toggle={onClose}>
        <h5 className='fw-normal text-muted'>{title}</h5>
      </ModalHeader>
      <ModalBody className='p-4'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            users: [],
          }}
          onSubmit={() => {}}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} action='#'>
              <div className='vstack gap-3'>
                <div>
                  <label htmlFor='courseCode' className='form-label'>
                    Course Tracking Number
                  </label>
                  <Input
                    name='courseCode'
                    className='form-control'
                    id='name'
                    placeholder='Enter course tracking number'
                    type='text'
                    onChange={form.handleChange}
                    disabled={form.isSubmitting}
                    onBlur={form.handleBlur}
                    value={form.values.courseCode || ''}
                    invalid={
                      !!(form.touched.courseCode && form.errors.courseCode)
                    }
                  />
                  {form.touched.courseCode && form.errors.courseCode ? (
                    <FormFeedback type='invalid'>
                      {form.errors.courseCode}
                    </FormFeedback>
                  ) : null}
                </div>
                <div>
                  <label htmlFor='board' className='form-label'>
                    Board
                  </label>
                  <AsyncSelectWithSearch
                    onChange={option => {
                      form.setFieldValue('providerId', option.value)
                      form.setFieldValue('disciplines', [])
                    }}
                    loadOptions={fetchBoards}
                    name='board'
                    placeholder='Select Disciplines'
                    id='board'
                    isMulti={false}
                    value={{
                      value: form.values.providerId,
                      label: boards.find(
                        item => item.id === form.values.providerId,
                      )?.board,
                    }}
                    defaultOptions
                    onBlur={() => {
                      form.setFieldTouched('providerId', true)
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: '0px 4px 4px 0px',
                        minHeight: 39,
                      }),
                    }}
                  />
                  {form.touched.providerId && form.errors.providerId ? (
                    <FormFeedback type='invalid'>
                      {form.errors.providerId}
                    </FormFeedback>
                  ) : null}
                </div>
                <div>
                  <label htmlFor='disciplines' className='form-label'>
                    Disciplines
                  </label>
                  <MultiSelect
                    onChange={(option: { value: string; label: string }[]) => {
                      form.setFieldValue(
                        'disciplines',
                        option.map(item => item.value),
                      )
                    }}
                    onBlur={() => {
                      form.setFieldTouched('disciplines', true)
                    }}
                    value={form.values.disciplines.map(item => ({
                      value: item,
                      label: item,
                    }))}
                    options={Array.from(
                      new Set(
                        boards
                          .filter(item => item.id === form.values.providerId)
                          .flatMap(item => item.disciplines),
                      ),
                    ).map(discipline => ({
                      value: discipline,
                      label: discipline,
                    }))}
                    name='disciplines'
                    isMulti
                    id='disciplines'
                    styles={{
                      control: (baseStyles: any) => ({
                        ...baseStyles,
                        borderRadius: '0px 4px 4px 0px',
                        minHeight: 39,
                      }),
                    }}
                    className='select2-container'
                    classNamePrefix='select2-selection form-select__wrap'
                  />
                  {form.touched.disciplines && form.errors.disciplines ? (
                    <FormFeedback type='invalid'>
                      {form.errors.disciplines}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className='hstack gap-3 justify-content-end mt-4'>
                <Button
                  className='btn-soft-primary align-middle'
                  color='secondary'
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className='align-middle'
                  color='primary'
                  type='submit'
                  onClick={() =>
                    form.validateForm().finally(() => {
                      return form.submitForm()
                    })
                  }
                  disabled={!(form.dirty && form.isValid) || form.isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default CourseCodeModal
