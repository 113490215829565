// MyContext.jsx
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  getOnlineCourse,
  posOnlineCourseFormat,
  publishOnlineCourse,
  removeCourseFormat,
} from '../helpers/api_helper'
import {
  COURSE_TYPES,
  CoursesPermissions,
  OnlineCourseFormatsEnum,
  OnlineCourseItem,
  QuestionableTypes,
  ScheduledEntitiesTypes,
} from '../sharedTypes'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { IUseGeneralStep, useGeneralStep } from '../hooks/course/generalStep'
import { IUseQuestions, useQuestions } from '../hooks/course/useQuestions'
import { IUseScheduled, useScheduled } from '../hooks/course/useScheduled'
import {
  IUseCompetencyStep,
  useCompetencyStep,
} from '../hooks/course/useCompetencyStep'
import {
  CourseTabItemEnum,
  CourseTabs,
  getNextStep,
  getPrevStep,
} from '../Components/Course/TabsHeader'
import { toast } from 'react-toastify'
import { errorToastOptions, successToastOptions } from '../helpers/toast_helper'
import { setNestedObjectValues } from 'formik'
import WarningModal from '../Components/Modals/WarningModal'
import {
  LessonData,
  LessonItemData,
} from '../pages/Courses/OnlineCourses/Lessons/types'

export interface DeleteFormatState {
  title: string
  message: string
  format: OnlineCourseFormatsEnum
}

const tabList: CourseTabs = {
  [CourseTabItemEnum.GENERAL]: {
    path: '/courses/general',
    label: 'General Course Information',
    active: true,
    error: false,
  },
  [CourseTabItemEnum.DIGITAL]: {
    path: '/courses/digital',
    label: 'Digital Settings',
    active: false,
    error: false,
  },
  [CourseTabItemEnum.VIDEO]: {
    path: '/courses/video',
    label: 'Video Settings',
    active: false,
    error: false,
  },
  [CourseTabItemEnum.LIVE]: {
    path: '/courses/live',
    label: 'Live Settings',
    active: false,
    error: false,
  },
  [CourseTabItemEnum.KNOWLEDGE_CHECK]: {
    path: '/courses/knowledge',
    label: 'Knowledge Check',
    active: false,
    count: 0,
    error: false,
  },
  [CourseTabItemEnum.CONTINUING_EDUCATION]: {
    path: '/courses/continuing-education',
    label: 'Continuing Education',
    active: false,
    error: false,
  },
  [CourseTabItemEnum.COMPETENCY]: {
    path: '/courses/competency',
    label: 'Competency Test',
    active: true,
    count: 0,
    error: false,
  },
}

interface ICourceContext {
  generalStep: IUseGeneralStep
  competencyStep: IUseCompetencyStep
  course: null | OnlineCourseItem
  courceId: null | number
  loading: boolean
  isPublishing: boolean
  deleteFormatModal: null | DeleteFormatState
  setLoading: Dispatch<SetStateAction<boolean>>
  setCourseId: Dispatch<SetStateAction<number | null>>
  setCourse: Dispatch<SetStateAction<OnlineCourseItem | null>>
  setDeleteFormatModal: Dispatch<SetStateAction<DeleteFormatState | null>>
  onAddFormat: (format: OnlineCourseFormatsEnum) => void
  onRemoveFormat: (format: OnlineCourseFormatsEnum) => void
  goNext: () => void
  onBack: () => void
  onNavigate: (path: string) => void
  onPublish: () => void
  knowledgeQuestions: IUseQuestions
  competencyQuestions: IUseQuestions
  scheduled: IUseScheduled
  tabs: CourseTabs
  isPublished: boolean
  setUploadedCourseLessonsData: Dispatch<SetStateAction<LessonData[]>>
  uploadedCourseLessonsData: LessonData[]
  updateLessonProgress: (lessonId: number, progress: number) => void
  lessonProgress: LessonItemData
  lessonFiles: LessonItemData
  updateLessonFiles: (lessonId: number, fileId: number) => void
  setIsValidCECourse: Dispatch<SetStateAction<boolean>>
}

export const CourseContext = createContext<ICourceContext>({
  course: null,
  courceId: null,
  loading: false,
  setLoading: () => {},
  setCourseId: () => {},
  setCourse: () => {},
  goNext: () => {},
  onBack: () => {},
  setUploadedCourseLessonsData: () => {},
  uploadedCourseLessonsData: [],
} as any)

export const CourseContextProvider = () => {
  const navigate = useNavigate()
  const [isPublishing, setIsPublishing] = useState(false)
  const [isValidCECourse, setIsValidCECourse] = useState<boolean>(false)
  const [uploadedCourseLessonsData, setUploadedCourseLessonsData] = useState<
    LessonData[]
  >([])
  const [lessonProgress, setLessonProgress] = useState<LessonItemData>({})
  const [lessonFiles, setLessonFiles] = useState<LessonItemData>({})
  const location = useLocation()
  const [deleteFormatModal, setDeleteFormatModal] =
    useState<null | DeleteFormatState>(null)
  const [loading, setLoading] = useState(false)
  const [course, setCourse] = useState<OnlineCourseItem | null>(null)
  const [courceId, setCourseId] = useState<number | null>(null)
  const [showTabsErrors, setShowTabsErrors] = useState<boolean>(false)
  const [showPublishingErrorModal, setShowPublishingErrorModal] =
    useState(false)
  const isPublished = useMemo(() => !!course?.publishedDate, [course])
  const knowledgeQuestions = useQuestions(
    course?.id as number,
    QuestionableTypes.KNOWLEDGE_CHECK,
    (course?.formats || []).includes(OnlineCourseFormatsEnum.KNOWLEDGE_CHECK),
  )

  const scheduled = useScheduled(
    course?.id as number,
    ScheduledEntitiesTypes.COURSE,
    (course?.formats || []).includes(OnlineCourseFormatsEnum.LIVE),
  )

  const competencyQuestions = useQuestions(
    courceId as number,
    QuestionableTypes.COMPETENCY_ONLY,
    true,
  )

  const goNext = useCallback(() => {
    const path = getNextStep(location.pathname, tabs)
    navigate(`${path}/${course?.id}`)
  }, [course, location])

  const onBack = useCallback(() => {
    const path = getPrevStep(location.pathname, tabs)
    navigate(path ? `${path}/${course?.id}` : '/courses')
  }, [course, location])

  const onNavigate = useCallback((path: string) => {
    navigate(path)
  }, [])

  const updateLessonProgress = (lessonId: number, progress: number) => {
    setLessonProgress(prevProgress => ({
      ...prevProgress,
      [lessonId]: progress,
    }))
  }

  const updateLessonFiles = (lessonId: number, fileId: number) => {
    setLessonFiles(lessonFiles => ({
      ...lessonFiles,
      [lessonId]: fileId,
    }))
  }

  const generalStep = useGeneralStep({
    isPublishing,
    course,
    setCourse,
    goNext,
    onBack,
  })

  const competencyStep = useCompetencyStep({ course, onBack })

  const tabs = useMemo(() => {
    const formats = course?.formats || []

    const activeKnowledgeCheck = formats.includes(
      OnlineCourseFormatsEnum.DIGITAL,
    )

    if (showTabsErrors) {
      tabList[CourseTabItemEnum.GENERAL].error = !generalStep.form.isValid
      tabList[CourseTabItemEnum.DIGITAL].error = !course?.digital?.articulateId
      tabList[CourseTabItemEnum.VIDEO].error = !course?.hasLessons
      tabList[CourseTabItemEnum.LIVE].error = scheduled.data.count === 0
      tabList[CourseTabItemEnum.KNOWLEDGE_CHECK].error = !(
        knowledgeQuestions.data.count > 0 && activeKnowledgeCheck
      ) as boolean
      tabList[CourseTabItemEnum.COMPETENCY].error =
        competencyQuestions.data.count === 0 ||
        !competencyStep.form.isValid ||
        competencyStep.form.values.quizQuestionsNumber >
          competencyQuestions.data.count

      if (course?.type.includes(COURSE_TYPES.CONTINUING_EDUCATION)) {
        tabList[CourseTabItemEnum.CONTINUING_EDUCATION].error = !isValidCECourse
      }
    } else {
      tabList[CourseTabItemEnum.GENERAL].error = false
      tabList[CourseTabItemEnum.DIGITAL].error = false
      tabList[CourseTabItemEnum.VIDEO].error = false
      tabList[CourseTabItemEnum.KNOWLEDGE_CHECK].error = false
      tabList[CourseTabItemEnum.COMPETENCY].error = false
      tabList[CourseTabItemEnum.LIVE].error = false
      tabList[CourseTabItemEnum.CONTINUING_EDUCATION].error = false
    }

    tabList[CourseTabItemEnum.KNOWLEDGE_CHECK].active = activeKnowledgeCheck
    tabList[CourseTabItemEnum.KNOWLEDGE_CHECK].count =
      knowledgeQuestions.data.count
    tabList[CourseTabItemEnum.DIGITAL].active = formats.includes(
      OnlineCourseFormatsEnum.DIGITAL,
    )
    tabList[CourseTabItemEnum.VIDEO].active = formats.includes(
      OnlineCourseFormatsEnum.VIDEO,
    )
    tabList[CourseTabItemEnum.LIVE].active = formats.includes(
      OnlineCourseFormatsEnum.LIVE,
    )
    tabList[CourseTabItemEnum.KNOWLEDGE_CHECK].active = formats.includes(
      OnlineCourseFormatsEnum.KNOWLEDGE_CHECK,
    )

    tabList[CourseTabItemEnum.COMPETENCY].count = competencyQuestions.data.count
    tabList[CourseTabItemEnum.CONTINUING_EDUCATION].active =
      generalStep.form.values.type.includes(COURSE_TYPES.CONTINUING_EDUCATION)

    return tabList
  }, [
    course,
    knowledgeQuestions.data.count,
    competencyQuestions.data.count,
    scheduled.data.count,
    isPublishing,
    generalStep.form,
    showTabsErrors,
    isValidCECourse,
  ])

  const onPublish = useCallback(async () => {
    setIsPublishing(true)
    if (!competencyStep.form.isValid) {
      competencyStep.form.handleSubmit()
    } else {
      if (competencyStep.hasUnsavedData) {
        await competencyStep.onHandleUpdate(competencyStep.form.values, true)
      }
      try {
        const result = await publishOnlineCourse(courceId as number)
        setCourse(result)
        setIsPublishing(false)
        toast('Success - Course successfully published', successToastOptions)
        navigate(`/courses`)
      } catch (e) {
        setShowPublishingErrorModal(true)
        setShowTabsErrors(true)
        const generalsErrors = await generalStep.form.validateForm()
        const competencyErrors = await competencyStep.form.validateForm()

        if (tabList[CourseTabItemEnum.CONTINUING_EDUCATION].error) {
          toast(
            'Please provide audio or pdf file in order to publish the course',
            errorToastOptions,
          )
        }

        if (Object.keys(generalsErrors).length === 0) {
        } else {
          generalStep.form.setTouched(
            setNestedObjectValues(generalsErrors, true),
          )
        }

        if (!Object.keys(competencyErrors).length) {
          competencyStep.form.setTouched(
            setNestedObjectValues(competencyErrors, true),
          )
        }
      }
    }
  }, [courceId, generalStep.form, competencyStep.form])

  useEffect(() => {
    if (courceId) {
      fetchCourse()
    }
  }, [courceId])

  const fetchCourse = useCallback(() => {
    setLoading(true)
    getOnlineCourse(Number(courceId), {
      permission: CoursesPermissions.ADD_EDIT_COURSE,
    })
      .then(c => {
        setCourse(c)
      })
      .catch(e => {
        if (e.response.status === 404) {
          navigate('/404')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [courceId])

  const onAddFormat = useCallback(
    async (format: OnlineCourseFormatsEnum) => {
      try {
        const course = await posOnlineCourseFormat(Number(courceId), { format })
        setCourse(course)
        navigate(`/courses/${format.toLowerCase()}/${courceId}`)
      } catch (e) {}
    },
    [courceId],
  )

  const onRemoveFormat = useCallback(
    async (format: OnlineCourseFormatsEnum) => {
      try {
        await removeCourseFormat(Number(courceId), {
          format,
        })
        toast(
          `${format} Course has been deleted successfully`,
          successToastOptions,
        )
        fetchCourse()
        navigate(`/courses/general/${courceId}`)
      } catch (e) {}

      setDeleteFormatModal(null)
    },
    [courceId],
  )

  return (
    <CourseContext.Provider
      value={{
        generalStep,
        isPublishing,
        course,
        setCourse,
        courceId,
        setCourseId,
        onAddFormat,
        onRemoveFormat,
        loading,
        setLoading,
        goNext,
        onBack,
        onNavigate,
        deleteFormatModal,
        setDeleteFormatModal,
        onPublish,
        knowledgeQuestions,
        competencyQuestions,
        competencyStep,
        tabs,
        scheduled,
        isPublished,
        setUploadedCourseLessonsData,
        uploadedCourseLessonsData,
        lessonProgress,
        updateLessonProgress,
        updateLessonFiles,
        lessonFiles,
        setIsValidCECourse,
      }}
    >
      <WarningModal
        isOpen={showPublishingErrorModal}
        icon='ri-error-warning-line'
        title='Required Data Missing'
        message={
          'All required data must be completed before publishing the course.'
        }
        onClose={() => {
          setShowPublishingErrorModal(false)
        }}
      />
      <Outlet />
    </CourseContext.Provider>
  )
}
