import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Col } from 'reactstrap'
import _ from 'lodash'

import {
  ASSIGNMENT_STATUS,
  COURSE_TYPES,
  ONLINE_COURSE_STATUS,
  OnlineCourseItem,
  SKIP_TO_TEST,
  TDepartment,
  TGrouped,
} from '../../../sharedTypes'
import {
  getAgencies,
  getDepartments,
  getOnlineCourses,
  getPositions,
} from '../../../helpers/api_helper'
import MultiSelect from '../../../Components/Common/MultiSelect'
import { FilterOption } from '../../../sharedTypes'
import { handleError } from '../../../helpers/toast_helper'
import Flatpickr from 'react-flatpickr'
import { FILTERS, SelectedFilters } from './types'
import { formatRange } from '../../UserManagment/Filters'
import { useAppSelector } from '../../../hooks/redux'
import { Option } from '../../../helpers/facility'
import FacilitiesMultiSelectDropdown from '../../../Components/Common/FacilitiesMultiSelectDropdown'
import { useNavigate } from 'react-router-dom'
import { useUrlParams } from '../../../utils/urlParamsParser'
import { useCourseTypes } from '../../../hooks/course/useCourseTypes'

export interface IFilters {
  facility: number[]
  agencies: number[]
  group: number[]
  position: number[]
  department: number[]
  course: number[]
  completedDate: Date[]
  status: ASSIGNMENT_STATUS[]
  courseTypes: COURSE_TYPES[]
  dueDate?: Date[]
  expiredDate?: Date[]
  skipToTest?: SKIP_TO_TEST[]
}

interface FiltersProps {
  setFilters: (filter: IFilters) => void
  visible: boolean
  setSelectedFilters: (filters: SelectedFilters) => void
  filterBy: {
    status: boolean
    courseType: boolean
    dueDate?: boolean
    expiredDate?: boolean
    completedDate?: boolean
    skipToTest?: boolean
  }
}

const AssignmentOptions = Object.keys(ASSIGNMENT_STATUS)
  .filter(
    key =>
      ASSIGNMENT_STATUS[key as keyof typeof ASSIGNMENT_STATUS] !==
      ASSIGNMENT_STATUS.EXPIRED,
  )
  .map(key => ({
    value: ASSIGNMENT_STATUS[key as keyof typeof ASSIGNMENT_STATUS],
    label: ASSIGNMENT_STATUS[key as keyof typeof ASSIGNMENT_STATUS],
  }))

const Filters = ({
  visible,
  setFilters,
  setSelectedFilters,
  filterBy,
}: FiltersProps) => {
  const { groupOptions, selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )
  const { user } = useAppSelector(state => ({
    user: state.User.user,
  }))
  const {
    updateUrlParams,
    getUrlParamsAsArray,
    getDataUrlParamsAsArray,
    getFacilityUrlParamsAsArray,
  } = useUrlParams()

  const filteredFacility = getFacilityUrlParamsAsArray(FILTERS.FACILITY)

  const selectedValue = useMemo(() => {
    if (filteredFacility && filteredFacility.length) {
      return _.isEqual(filteredFacility, ['All'])
        ? []
        : groupOptions.filter(item => {
            return (
              filteredFacility.includes(item.label) &&
              item.value.split(':')[0] === 'facility'
            )
          })
    }
    if (user?.groupId && !selectedFacilityId && !selectedGroupId) {
      return groupOptions.filter(
        option => option.value === `group:${user?.groupId}`,
      )
    }

    if (!selectedFacilityId && !selectedGroupId) {
      return groupOptions.filter(option => option.level === 0)
    }

    if (selectedFacilityId) {
      return groupOptions.filter(
        option => option.value === `facility:${selectedFacilityId}`,
      )
    }

    return groupOptions.filter(
      option => option.value === `group:${selectedGroupId}`,
    )
  }, [selectedFacilityId, selectedGroupId, groupOptions, user])

  const navigate = useNavigate()
  const [positions, setPositions] = useState<TGrouped[]>([])
  const [departments, setDepartments] = useState<TGrouped[]>([])
  const [courses, setCourses] = useState<TGrouped[]>([])

  const [selectedOptions, setSelectedOptions] =
    useState<Option[]>(selectedValue)
  const [selectedPositions, setSelectedPositions] = useState<string[]>([])
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([])
  const [selectedCourses, setSelectedCourses] = useState<string[]>([])
  const [selectedAgencies, setSelectedAgencies] = useState<string[]>([])
  const [completedDates, setCompletedDates] = useState<Date[]>([])
  const [selectedCourseTypes, setSelectedCourseTypes] = useState<
    COURSE_TYPES[]
  >([])
  const [selectedSkipToTestOptions, setSelectedSkipToTestOptions] = useState<
    SKIP_TO_TEST[]
  >([])
  const datePickerRef = useRef<Flatpickr>(null)
  const expiredDatePickerRef = useRef<Flatpickr>(null)
  const [selectedStatuses, setSelectedStatuses] = useState<ASSIGNMENT_STATUS[]>(
    [],
  )
  const [agencyOptions, setAgencyOptions] = useState<Option[]>([])
  const [getFilters, setGetFilters] = useState<boolean>(false)
  const [dueDates, setDueDates] = useState<Date[]>([])
  const [expiredDates, setExpiredDates] = useState<Date[]>([])

  const courseTypeOptions = useCourseTypes()

  useEffect(() => {
    Promise.all([
      getPositions(),
      getDepartments({}),
      getAgencies({}),
      getOnlineCourses({
        page: 1,
        limit: 500,
        statuses: [ONLINE_COURSE_STATUS.PUBLISHED],
        excludeCE: true,
      }),
    ])
      .then(([positionsRes, departmentsRes, agenciesRes, onlineCoursesRes]) => {
        const groupedPositions: TGrouped[] = _.map(
          _.groupBy(positionsRes.data.positions, 'name'),
          (group: TDepartment[], key) => {
            return {
              name: key,
              values: group.map(g => g.id),
            }
          },
        )
        setPositions(groupedPositions)

        const groupedDepartments: TGrouped[] = _.map(
          _.groupBy(departmentsRes.data.departments, 'name'),
          (group: TDepartment[], key) => {
            return {
              name: key,
              values: group.map(g => g.id),
            }
          },
        )
        setDepartments(groupedDepartments)

        setAgencyOptions([
          {
            id: -2,
            label: 'In House',
            value: '2',
            level: 0,
            hasChildren: false,
            disabled: false,
            companyId: user?.companyId as number,
          },
          ...agenciesRes.data.agencies.map(agency => ({
            id: agency.id,
            label: agency.name,
            value: agency.name,
            level: 1,
            hasChildren: false,
            disabled: true,
            companyId: user?.companyId as number,
          })),
        ])

        const groupedCourses: TGrouped[] = _.map(
          _.groupBy(onlineCoursesRes.courses, 'translations[0].content.name'),
          (group: OnlineCourseItem[], key) => {
            return {
              name: key,
              values: group.map(g => g.id),
            }
          },
        )
        setCourses(groupedCourses)
        updateFilters()
      })
      .catch(e => {
        handleError(e)
      })
  }, [])

  const updateFilters = () => {
    setSelectedPositions(getUrlParamsAsArray(FILTERS.POSITION))
    setSelectedCourses(getUrlParamsAsArray(FILTERS.COURSE))
    setSelectedDepartments(getUrlParamsAsArray(FILTERS.DEPARTMENT))
    setCompletedDates(getDataUrlParamsAsArray(FILTERS.COMPLETED_DATE))
    setSelectedStatuses(getUrlParamsAsArray(FILTERS.STATUS))
    setSelectedSkipToTestOptions(getUrlParamsAsArray(FILTERS.SKIP_TO_TEST))
    setSelectedAgencies(getUrlParamsAsArray(FILTERS.AGENCY))
    setSelectedCourseTypes(getUrlParamsAsArray(FILTERS.TYPE))
    setDueDates(getDataUrlParamsAsArray(FILTERS.DUE_DATE))
    setExpiredDates(getDataUrlParamsAsArray(FILTERS.EXPIRE_DATE))
    setGetFilters(true)
  }

  const handleStatusChange = (options: FilterOption[]) => {
    const statuses = options.map(option => option.label) as ASSIGNMENT_STATUS[]
    setSelectedStatuses(statuses)
    navigate(`?${updateUrlParams('status', statuses.toString())}`)
  }

  const handleDataChange = (
    dates: Date[],
    type: FILTERS.COMPLETED_DATE | FILTERS.DUE_DATE | FILTERS.EXPIRE_DATE,
  ) => {
    if (dates.length === 2) {
      if (type === FILTERS.COMPLETED_DATE) {
        setCompletedDates(dates)
      } else if (type === FILTERS.DUE_DATE) {
        setDueDates(dates)
      } else {
        setExpiredDates(dates)
      }
    }

    const dateValue =
      dates.length === 2
        ? `${dates[0].toISOString().split('T')[0]} ${
            dates[1].toISOString().split('T')[0]
          }`
        : ''
    navigate(`?${updateUrlParams(type, dateValue)}`)
  }

  const onSelectGroupItem = (
    name:
      | 'department'
      | 'position'
      | 'course'
      | 'agency'
      | 'type'
      | 'skipToTest',
    groupNames: string[],
  ) => {
    switch (name) {
      case 'position':
        setSelectedPositions(groupNames)
        navigate(`?${updateUrlParams(FILTERS.POSITION, groupNames.toString())}`)
        break
      case 'department':
        setSelectedDepartments(groupNames)
        navigate(
          `?${updateUrlParams(FILTERS.DEPARTMENT, groupNames.toString())}`,
        )
        break
      case 'course':
        setSelectedCourses(groupNames)
        navigate(`?${updateUrlParams(FILTERS.COURSE, groupNames.toString())}`)
        break
      case 'agency':
        setSelectedAgencies(groupNames)
        navigate(`?${updateUrlParams(FILTERS.AGENCY, groupNames.toString())}`)
        break
      case 'type':
        setSelectedCourseTypes(groupNames as COURSE_TYPES[])
        navigate(`?${updateUrlParams(FILTERS.TYPE, groupNames.toString())}`)
        break
      case 'skipToTest':
        setSelectedSkipToTestOptions(groupNames as SKIP_TO_TEST[])
        navigate(
          `?${updateUrlParams(FILTERS.SKIP_TO_TEST, groupNames.toString())}`,
        )
        break
    }
  }

  const updateFilterSelections = () => {
    const _selectedOptions = selectedOptions.map(option => {
      const [type, id] = option.value.split(':')
      return { type, id: +id }
    })

    setFilters({
      position: _.flatten(
        positions
          .filter(p => selectedPositions.includes(p.name))
          .map(p => p.values),
      ),
      department: _.flatten(
        departments
          .filter(d => selectedDepartments.includes(d.name))
          .map(d => d.values),
      ),
      course: _.flatten(
        courses
          .filter(c => selectedCourses.includes(c.name))
          .map(c => c.values),
      ),
      agencies: _.flatten(
        agencyOptions
          .filter(c => selectedAgencies.includes(c.label))
          .map(c => c.id as number)
          .filter(option => option && option !== -1),
      ),
      completedDate: completedDates,
      status: selectedStatuses,
      courseTypes: selectedCourseTypes,
      facility: _selectedOptions
        .filter(option => option.type === 'facility')
        .map(option => option.id),
      group: _selectedOptions
        .filter(option => option.type === 'group')
        .map(option => option.id),
      dueDate: dueDates,
      expiredDate: expiredDates,
      skipToTest: selectedSkipToTestOptions,
    })

    setSelectedFilters({
      positions: selectedPositions,
      departments: selectedDepartments,
      courses: selectedCourses,
    })
  }

  useEffect(() => {
    if (getFilters) {
      updateFilterSelections()
    }
  }, [
    selectedCourses,
    selectedOptions,
    getFilters,
    selectedPositions,
    selectedDepartments,
    selectedStatuses,
    completedDates,
    selectedCourseTypes,
    selectedSkipToTestOptions,
    selectedAgencies,
    dueDates,
    expiredDates,
  ])

  if (!visible) {
    return null
  }

  return (
    <>
      {(!user?.isFacilityAdmin || user?.hasAccessToMultipleFacilities) && (
        <Col className='filter-col mt-0 p-0'>
          <FacilitiesMultiSelectDropdown
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            updateUrlParams={updateUrlParams}
          />
        </Col>
      )}

      {!user?.agencyId && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='position' className='form-label'>
              Filter by Agency
            </label>

            <MultiSelect
              name='agency'
              id='agency'
              isMulti
              options={agencyOptions}
              isSearchable={true}
              placeholder='Select agency'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                onSelectGroupItem(
                  'agency',
                  selectedOptions.map(option => option.label) as string[],
                )
              }}
              defaultValue={agencyOptions
                .filter(obj => selectedAgencies.includes(obj.label))
                .map(obj => ({
                  value: obj.value,
                  label: obj.label,
                }))}
            />
          </div>
        </Col>
      )}

      {positions.length > 0 && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='position' className='form-label'>
              Filter by Position
            </label>

            <MultiSelect
              name='position'
              id='position'
              isMulti
              options={positions.map(obj => ({
                value: obj.values,
                label: obj.name,
              }))}
              isSearchable={true}
              placeholder='Select position'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                onSelectGroupItem(
                  'position',
                  selectedOptions.map(option => option.label) as string[],
                )
              }}
              defaultValue={positions
                .filter(obj => selectedPositions.includes(obj.name))
                .map(obj => ({
                  value: obj.values,
                  label: obj.name,
                }))}
            />
          </div>
        </Col>
      )}

      {departments.length > 0 && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='department' className='form-label'>
              Filter by Department
            </label>

            <MultiSelect
              name='department'
              id='department'
              isMulti
              options={departments.map(obj => ({
                value: obj.values,
                label: obj.name,
              }))}
              isSearchable={true}
              placeholder='Select department'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                onSelectGroupItem(
                  'department',
                  selectedOptions.map(option => option.label) as string[],
                )
              }}
              defaultValue={departments
                .filter(obj => selectedDepartments.includes(obj.name))
                .map(obj => ({
                  value: obj.values,
                  label: obj.name,
                }))}
            />
          </div>
        </Col>
      )}

      {courses.length > 0 && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='position' className='form-label'>
              Filter by Course
            </label>

            <MultiSelect
              name='course'
              id='course'
              isMulti
              options={courses.map(obj => ({
                value: obj.values,
                label: obj.name,
              }))}
              isSearchable={true}
              placeholder='Select course'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                onSelectGroupItem(
                  'course',
                  selectedOptions.map(option => option.label) as string[],
                )
              }}
              defaultValue={courses
                .filter(obj => selectedCourses.includes(obj.name))
                .map(obj => ({
                  value: obj.values,
                  label: obj.name,
                }))}
            />
          </div>
        </Col>
      )}

      {filterBy.completedDate && (
        <Col className='filter-col mt-0 p-0'>
          <div className='w-100 filter-by-date'>
            <label htmlFor='type' className='form-label'>
              Filter by Date Completed
            </label>

            <div className='form-icon right'>
              <Flatpickr
                className='form-control fs-11'
                placeholder='--/--/---- - --/--/---'
                onChange={dates =>
                  handleDataChange(dates, FILTERS.COMPLETED_DATE)
                }
                ref={datePickerRef}
                value={completedDates}
                options={{
                  mode: 'range',
                  dateFormat: 'm/d/Y',
                  locale: {
                    rangeSeparator: ' - ',
                  },
                  formatDate: formatRange(completedDates || []),
                }}
              />
              <i
                className='ri-calendar-2-line fs-20 text-primary input-button'
                onClick={() => {
                  datePickerRef.current?.flatpickr.open()
                }}
              ></i>
              {completedDates.length ? (
                <i
                  className='ri-close-line fs-16 text-danger'
                  onClick={() => {
                    datePickerRef.current?.flatpickr.clear()
                    setCompletedDates([])
                  }}
                ></i>
              ) : null}
            </div>
          </div>
        </Col>
      )}

      {filterBy.status && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='type' className='form-label'>
              Course status
            </label>

            <MultiSelect
              name='courseStatus'
              id='courseStatus'
              isMulti
              options={AssignmentOptions}
              isSearchable={true}
              placeholder='Select status'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={handleStatusChange}
              defaultValue={Object.values(ASSIGNMENT_STATUS)
                .filter(courseStatus => selectedStatuses.includes(courseStatus))
                .map(courseStatus => ({
                  value: courseStatus,
                  label: courseStatus,
                }))}
            />
          </div>
        </Col>
      )}

      {filterBy.skipToTest && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='skipToTest' className='form-label'>
              Skip to Test
            </label>

            <MultiSelect
              name='skipToTest'
              id='skipToTest'
              isMulti
              options={Object.values(SKIP_TO_TEST).map(option => ({
                value: option,
                label: _.capitalize(option),
              }))}
              isSearchable={true}
              placeholder='Select option'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                onSelectGroupItem(
                  'skipToTest',
                  _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as SKIP_TO_TEST[]),
                )
                setSelectedSkipToTestOptions(
                  _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as SKIP_TO_TEST[]),
                )
              }}
              defaultValue={selectedSkipToTestOptions.map(type => ({
                value: type,
                label: _.capitalize(type),
              }))}
            />
          </div>
        </Col>
      )}

      {filterBy.courseType && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='type' className='form-label'>
              Filter by Type
            </label>

            <MultiSelect
              name='type'
              id='type'
              isMulti
              options={courseTypeOptions}
              isSearchable={true}
              placeholder='Select type'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                onSelectGroupItem(
                  'type',
                  _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSE_TYPES[]),
                )
                setSelectedCourseTypes(
                  _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSE_TYPES[]),
                )
              }}
              defaultValue={selectedCourseTypes.map(type => ({
                value: type,
                label: type,
              }))}
            />
          </div>
        </Col>
      )}

      {filterBy.dueDate && (
        <Col className='mt-0 p-0 filter-col '>
          <div className='w-100 filter-by-date'>
            <label htmlFor='type' className='form-label'>
              Filter by Due Date
            </label>

            <div className='form-icon right'>
              <Flatpickr
                className='form-control fs-11'
                placeholder='--/--/---- - --/--/---'
                onChange={dates => handleDataChange(dates, FILTERS.DUE_DATE)}
                ref={datePickerRef}
                value={dueDates}
                options={{
                  mode: 'range',
                  dateFormat: 'm/d/Y',
                  locale: {
                    rangeSeparator: ' - ',
                  },
                  formatDate: formatRange(dueDates || []),
                }}
              />
              <i
                className='ri-calendar-2-line fs-20 text-primary input-button'
                onClick={() => {
                  datePickerRef.current?.flatpickr.open()
                }}
              ></i>
              {dueDates.length ? (
                <i
                  className='ri-close-line fs-16 text-danger'
                  onClick={() => {
                    datePickerRef.current?.flatpickr.clear()
                    setDueDates([])
                  }}
                ></i>
              ) : null}
            </div>
          </div>
        </Col>
      )}

      {filterBy.expiredDate && (
        <Col className='mt-0 p-0 filter-col '>
          <div className='w-100 filter-by-date'>
            <label htmlFor='type' className='form-label'>
              Filter by Date Expired
            </label>

            <div className='form-icon right'>
              <Flatpickr
                className='form-control fs-11'
                placeholder='--/--/---- - --/--/---'
                onChange={dates => handleDataChange(dates, FILTERS.EXPIRE_DATE)}
                ref={expiredDatePickerRef}
                value={expiredDates}
                options={{
                  mode: 'range',
                  dateFormat: 'm/d/Y',
                  locale: {
                    rangeSeparator: ' - ',
                  },
                  formatDate: formatRange(expiredDates || []),
                }}
              />
              <i
                className='ri-calendar-2-line fs-20 text-primary input-button'
                onClick={() => {
                  expiredDatePickerRef.current?.flatpickr.open()
                }}
              ></i>
              {expiredDates.length ? (
                <i
                  className='ri-close-line fs-16 text-danger'
                  onClick={() => {
                    expiredDatePickerRef.current?.flatpickr.clear()
                    setExpiredDates([])
                  }}
                ></i>
              ) : null}
            </div>
          </div>
        </Col>
      )}
    </>
  )
}

export default Filters
