import React, { useCallback, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { formattedDurationTime } from '../../../../helpers/course'
import { DocumentFile } from '../../../../sharedTypes'

interface VideoPlayerProps {
  file: DocumentFile
  onPlayedChange?: (played: string) => void
}

const AudioPlayer = ({ file }: VideoPlayerProps) => {
  const playerRef = useRef<ReactPlayer | null>(null)
  const [playing, setPlaying] = useState(false)
  const [played, setPlayed] = useState(0)
  const [duration, setDuration] = useState(0)

  const handlePlayPause = useCallback(() => {
    setPlaying(prev => !prev)
  }, [])

  const audioUrl = useMemo(() => file.preview, [file])

  const seekForward = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10)
    }
  }, [])

  const seekBackward = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
    }
  }, [])

  return (
    <div className='px-2'>
      <ReactPlayer
        className=''
        url={audioUrl}
        ref={playerRef}
        playing={playing}
        onProgress={({ played }) => {
          setPlayed(played)
        }}
        controls={false}
        width='100%'
        height='0'
        onDuration={setDuration}
      />
      <div className='d-flex gap-3 align-items-center fs-4 mt-2 w-100'>
        <div onClick={seekBackward}>
          <i className='ri-rewind-line cursor-pointer'></i>
        </div>
        <div onClick={handlePlayPause}>
          {playing ? (
            <i className='ri-pause-line cursor-pointer' />
          ) : (
            <i className='ri-play-line cursor-pointer' />
          )}
        </div>
        <div onClick={seekForward}>
          <i className='ri-speed-line cursor-pointer'></i>
        </div>

        <div className='w-100'>
          <input
            className='w-100'
            id='progress'
            type='range'
            min='0'
            max='1'
            step='0.01'
            value={played}
            onChange={e => {
              const newValue = parseFloat(e.target.value)
              setPlayed(newValue)
              playerRef.current?.seekTo(newValue)
            }}
          />
        </div>
        {playerRef.current && (
          <div className='d-flex fs-13'>
            <span className='text-muted'>
              {formattedDurationTime(playerRef.current.getDuration() * played)}
            </span>
            <span className='text-muted'> / </span>
            <span className='text-muted'>
              {formattedDurationTime(playerRef.current.getDuration())}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

AudioPlayer.displayName = 'AudioPlayer'

export default AudioPlayer
