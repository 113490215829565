import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Progress,
  TabContent,
  Table,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap'
import { SearchInput } from '../../../Components/Common/SearchInput'
import FilterTabs from '../../../Components/Common/FilterTabs'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import {
  ASSIGNMENT_STATUS,
  CalendarPermissions,
  COURSE_TYPES,
  IDefaultCourseSettings,
  OnlineCourseItem,
  OrderType,
  TAssignment,
  TAttachment,
  TUser,
  UpdateAssignmentDTO,
  UploadWrittenTestDTO,
  USER_STATUSES,
  UserManagementPermissions,
} from '../../../sharedTypes'
import moment from 'moment'
import { Pagination } from '../../../Components/Common/Pagination'
import {
  deleteUserAssignment,
  getUserAssignments,
  overrideUserAssignmentScore,
  updateUserAssignment,
} from '../../../helpers/api/assignments'
import {
  AssignmentsSortBy,
  GetAssignmentsDTO,
} from '../../../sharedTypes/api/courseHistory'
import { BadgeColorByStatus } from '../../../helpers/course_history'
import { BadgeColorByAllFormats } from '../../../helpers/course'
import { Link } from 'react-router-dom'
import { getDefaultCourseSettings } from '../../../helpers/api_helper'
import Highlighter from 'react-highlight-words'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { toast } from 'react-toastify'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import OverrideConfirmationModal from '../../../Components/Modals/OverrideConfirmationModal'
import AssignCourseModal from '../../../Components/Modals/AssignCourseModal'
import { usePermissions } from '../../../hooks/usePermissions'
import EditAssignmentModal from '../../../Components/Modals/EditAssignmentModal'
import { FormikHelpers } from 'formik'
import ActionsDropdownMenu from './ActionsDropdownMenu'
import PrintTranscriptModal from '../../../Components/Modals/PrintTranscriptModal'
import { getAssignmentStatus } from '../../../helpers/assignments'
import UploadWrittenTest from '../../../Components/Modals/Assignments/UploadWrittenTest'
import TestPreview from '../../../Components/Modals/Assignments/TestPreview'
import { ASSIGNMENT_ACTIONS } from '../../Calendar/FacilitatorView/types'
import CompetencyTestPreviewModal from '../../../Components/Modals/CourseQuestions/PreviewModal'
import _ from 'lodash'
import { useUser } from '../../../context/UserContext'
import CourseOverrideConfirmationModal from '../../../Components/Modals/CourseOverrideConfirmationModal'
import { ReactComponent as SkipToTestIcon } from '../../../assets/images/svg/skip-to-test.svg'
import { ReactComponent as ProgressIcon } from '../../../assets/images/svg/in-progress.svg'
import CoursePreviewModal from '../../../Components/Modals/CoursesModal/CoursePreviewModal'
import classnames from 'classnames'

const tabs = [
  { label: 'All', value: undefined },
  { label: 'Not Started', value: ASSIGNMENT_STATUS.ASSIGNED },
  { label: 'In Progress', value: ASSIGNMENT_STATUS.IN_PROGRESS },
  { label: 'Test Pending', value: ASSIGNMENT_STATUS.TEST_PENDING },
  { label: 'Completed', value: ASSIGNMENT_STATUS.COMPLETED },
]

export enum COURSE_HISTORY_TABS {
  COMPLIANCE = 'Compliance',
  CONTINUING_EDUCATION = 'Continuing Education',
}

const courseHistoryTabs = [
  { label: 'Compliance', value: COURSE_HISTORY_TABS.COMPLIANCE },
  {
    label: 'Continuing Education',
    value: COURSE_HISTORY_TABS.CONTINUING_EDUCATION,
  },
]

const Columns = [
  {
    name: 'Code',
    id: 'code',
    sortBy: AssignmentsSortBy.CODE,
    style: { width: window.innerWidth > 1450 ? 140 : 240 },
  },
  {
    name: 'Package',
    id: 'package',
    sortBy: AssignmentsSortBy.PACKAGE,
    style: {
      flex: 1,
      display: window.innerWidth > 1500 ? 'table-cell' : 'none',
    },
  },
  {
    name: 'Course',
    id: 'course',
    sortBy: AssignmentsSortBy.COURSE,
    style: { width: 200 },
  },
  {
    name: 'Format',
    id: 'format',
    sortBy: AssignmentsSortBy.FORMAT,
  },
  {
    id: 'progress',
    name: window.innerWidth > 1500 ? 'Progress' : <ProgressIcon />,
    tooltip: window.innerWidth > 1500 ? undefined : 'Progress',
    sortBy: AssignmentsSortBy.PROGRESS,
    style: {
      width: 24,
      whiteSpace: 'pre-line',
      minWidth: 24,
    } as CSSProperties,
  },
  {
    name: 'Test Score',
    id: 'test-score',
    sortBy: AssignmentsSortBy.TEST_SCORE,
    style: {
      width: 170,
    } as CSSProperties,
  },
  {
    name: 'Due Date',
    id: 'due-date',
    sortBy: AssignmentsSortBy.DUE_DATE,
  },
  {
    name: 'Status',
    id: 'status',
    sortBy: AssignmentsSortBy.STATUS,
  },
  {
    id: 'skip-to-test',
    name: <SkipToTestIcon />,
    tooltip: 'Skip to Test',
    sortBy: AssignmentsSortBy.SKIP_TO_TEST,
    style: {
      width: 24,
      whiteSpace: 'pre-line',
      minWidth: 24,
    } as CSSProperties,
  },
  {
    name: 'Actions',
    id: 'actions',
    style: { width: 64, textAlign: 'center' } as CSSProperties,
  },
]

interface SelectedAssignment {
  id: number | null
  type: ASSIGNMENT_ACTIONS
}

const CourseHistory = ({ user }: { user: TUser }) => {
  const { id: userId, firstName, lastName } = user
  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState<COURSE_HISTORY_TABS>(
    COURSE_HISTORY_TABS.COMPLIANCE,
  )
  const [passingScore, setPassingScore] = useState<number>(0)
  const {
    handlePrintTranscript,
    printTranscript,
    showPrintTranscript,
    setShowPrintTranscript,
  } = useUser()
  const [showAssign, setShowAssign] = useState(false)
  const [courseToPreview, setCourseToPreview] =
    useState<OnlineCourseItem | null>(null)
  const [selectedAssignment, setSelectedAssignment] =
    useState<SelectedAssignment>({
      id: null,
      type: ASSIGNMENT_ACTIONS.VIEW,
    })

  const [query, setQuery] = useState<GetAssignmentsDTO.Request>({
    userId,
    page: 1,
    limit: 10,
    status: undefined,
    sortBy: AssignmentsSortBy.DUE_DATE,
    orderBy: OrderType.ASC,
  })

  const [data, setData] = useState<GetAssignmentsDTO.Response>({
    count: 0,
    pages: 0,
    page: 0,
    assignments: [],
    userHasAnyCompletedAssignments: false,
  })

  const permissions = {
    viewCertificate: usePermissions(UserManagementPermissions.VIEW_CERTIFICATE),
    overrideScore: usePermissions(CalendarPermissions.OVERRIDE_TEST_SCORE),
    courseOverride: usePermissions(UserManagementPermissions.COURSE_OVERRIDE),
    assign: usePermissions(UserManagementPermissions.ASSIGN_COURSE_TO_USER),
    printTranscript: usePermissions(UserManagementPermissions.PRINT_TRANSCRIPT),
    viewCourseHistory: usePermissions(
      UserManagementPermissions.VIEW_USER_COURSE_HISTORY,
    ),
  }

  useEffect(() => {
    if (permissions.viewCourseHistory) {
      getDefaultCourseSettings({
        permission: UserManagementPermissions.VIEW_USER_COURSE_HISTORY,
      }).then((settings: IDefaultCourseSettings) => {
        setPassingScore(settings.passingScore)
      })
    }
  }, [])

  useEffect(() => {
    if (permissions.viewCourseHistory) {
      setIsLoading(true)
      getUserAssignments(query)
        .then(res => {
          setData(res.data)
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [query])

  const isCETabActive = useMemo(() => {
    return activeTab === COURSE_HISTORY_TABS.CONTINUING_EDUCATION
  }, [activeTab])

  const columns = useMemo(() => {
    if (isCETabActive) {
      return Columns.filter(
        column =>
          ![
            'code',
            'package',
            'format',
            'progress',
            'due-date',
            'skip-to-test',
          ].includes(column.id),
      ).map(column =>
        column.id === 'course'
          ? { ...column, style: { ...column.style, width: 'auto' } }
          : column,
      )
    }

    return Columns
  }, [isCETabActive])

  const handleSort = useCallback((column: AssignmentsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const getPage = () => {
    if (data.assignments.length <= 1 && data.page > 1) {
      return data.page - 1
    } else {
      return data.page
    }
  }

  const tabChange = (tab: COURSE_HISTORY_TABS) => {
    if (activeTab !== tab) {
      setActiveTab(tab)

      setQuery(prev => ({
        ...prev,
        excludeCE: tab !== COURSE_HISTORY_TABS.CONTINUING_EDUCATION,
        courseType:
          tab === COURSE_HISTORY_TABS.CONTINUING_EDUCATION
            ? COURSE_TYPES.CONTINUING_EDUCATION
            : undefined,
        page: 1,
      }))
    }
  }

  const onDelete = useCallback(async () => {
    try {
      await deleteUserAssignment(selectedAssignment.id as number, {
        permission: UserManagementPermissions.REMOVE_ASSIGNMENT,
      })
      setQuery(prev => ({ ...prev, page: getPage() }))
      setSelectedAssignment({ ...selectedAssignment, id: null })
      toast('Success - Assignment successfully deleted', successToastOptions)
    } catch (e) {
      handleError(e)
    }
  }, [data.page, selectedAssignment.id])

  const onOverride = useCallback(
    async (notes: string) => {
      try {
        await overrideUserAssignmentScore(selectedAssignment.id as number, {
          notes,
        })
        const updatedAssignments = data.assignments.map(assignment =>
          assignment.id === selectedAssignment.id
            ? {
                ...assignment,
                competencyTestScore: passingScore,
                status: ASSIGNMENT_STATUS.COMPLETED,
              }
            : assignment,
        )
        setData({ ...data, assignments: updatedAssignments })
        setSelectedAssignment({ ...selectedAssignment, id: null })
        toast(
          'Success - Test score successfully overridden',
          successToastOptions,
        )
      } catch (e) {
        handleError(e)
      }
    },
    [data.page, selectedAssignment.id],
  )

  const onCourseComplete = useCallback(
    async (date: Date) => {
      try {
        await overrideUserAssignmentScore(selectedAssignment.id as number, {
          notes: 'Course Completed',
          date,
        })
        const updatedAssignments = data.assignments.map(assignment =>
          assignment.id === selectedAssignment.id
            ? {
                ...assignment,
                progress: 100,
                competencyTestScore: passingScore,
                status: ASSIGNMENT_STATUS.COMPLETED,
              }
            : assignment,
        )
        setData({ ...data, assignments: updatedAssignments })
        setSelectedAssignment({ ...selectedAssignment, id: null })
        toast('Success - Course successfully completed', successToastOptions)
      } catch (e) {
        handleError(e)
      }
    },
    [data.page, selectedAssignment.id],
  )

  const onUpdate = useCallback(
    async (
      values: UpdateAssignmentDTO.Request,
      form: FormikHelpers<UpdateAssignmentDTO.Request>,
    ) => {
      try {
        if (selectedAssignment.id) {
          await updateUserAssignment(selectedAssignment.id, {
            ...values,
            dueDate: moment(values.dueDate).format('YYYY-MM-DD'),
          })
          const updatedAssignments = data.assignments.map(assignment =>
            assignment.id === selectedAssignment.id ||
            assignment.parentId === selectedAssignment.id
              ? {
                  ...assignment,
                  dueDate: moment(values.dueDate).toISOString(),
                  dateAvailable: moment(values.dateAvailable).toISOString(),
                  scheduleId: values.scheduleId ?? assignment.scheduleId,
                }
              : assignment,
          )
          form.resetForm()
          setData({ ...data, assignments: updatedAssignments })
          setSelectedAssignment({ ...selectedAssignment, id: null })
          toast('Assignment successfully updated', successToastOptions)
        }
      } catch (e) {
        handleError(e)
      }
    },
    [data.page, selectedAssignment.id],
  )

  const getDueDateBadgeColor = (assignment: TAssignment) =>
    moment(assignment.dueDate).isBefore(moment()) &&
    assignment.status !== ASSIGNMENT_STATUS.COMPLETED
      ? 'red'
      : 'soft-grey'

  const getTestScoreBadgeColor = (assignment: TAssignment) =>
    assignment.competencyTestScore < passingScore ? 'red' : 'soft-grey'

  const currentAssignment = useMemo(
    () =>
      data?.assignments?.find(
        assignment => assignment.id === selectedAssignment.id,
      ),
    [data, selectedAssignment.id],
  )

  const selectedItem = useMemo(() => {
    return data.assignments.find(
      a => a.id === selectedAssignment.id,
    ) as TAssignment
  }, [selectedAssignment.id, data])

  const onAddTestFile = (res: UploadWrittenTestDTO.Response) => {
    const { assignment: changedAssignment, testFile } = res
    const updatedAssignments = data.assignments.map(assignment =>
      assignment.id === selectedAssignment.id
        ? {
            ...assignment,
            competencyTestScore: changedAssignment.competencyTestScore,
            status: changedAssignment.status,
            endDate: changedAssignment.endDate,
            certificate: changedAssignment.certificate,
            testFile,
            testFileId: changedAssignment.testFileId,
          }
        : assignment,
    )
    setData({ ...data, assignments: updatedAssignments })

    setSelectedAssignment({ ...selectedAssignment, id: null })
  }

  const tabsToFilter = useMemo(() => {
    if (activeTab === COURSE_HISTORY_TABS.CONTINUING_EDUCATION) {
      return tabs.filter(tab => tab.value !== ASSIGNMENT_STATUS.TEST_PENDING)
    }

    return tabs
  }, [activeTab])

  return (
    <Col className='col'>
      <Card>
        <CardHeader>
          <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
            {permissions.viewCourseHistory ? (
              <>
                <SearchInput
                  style={{ maxWidth: 400 }}
                  onChange={key => {
                    setQuery(prev => ({ ...prev, key, page: 1 }))
                  }}
                  value={query.key}
                />
                <FilterTabs
                  tabs={tabsToFilter}
                  navTab={query.status}
                  navToggle={status => {
                    setQuery(prev => ({ ...prev, status, page: 1 }))
                  }}
                />
              </>
            ) : (
              <div></div>
            )}

            <div className='d-flex flex-shrink-0 gap-3'>
              {permissions.printTranscript && (
                <Button
                  color={'primary'}
                  className='btn-primary align-middle'
                  onClick={handlePrintTranscript}
                >
                  <i className='ri-printer-line me-1 fs-16' />
                  Print Transcript
                </Button>
              )}
              {permissions.assign &&
                !user.deactivated &&
                user.status !== USER_STATUSES.INACTIVE && (
                  <Button
                    color={'primary'}
                    className='btn btn-primary align-middle'
                    onClick={() => setShowAssign(true)}
                  >
                    <i className='ri-edit-box-line me-1 fs-16' />
                    Assign Course
                  </Button>
                )}
            </div>
          </div>
        </CardHeader>

        <Card className='m-0 p-0 table-card'>
          <Nav
            className='nav nav-tabs nav-tabs-custom rounded bg-white course-package-tabs'
            role='tablist'
          >
            {courseHistoryTabs.map((tab, index) => (
              <NavItem key={index}>
                <NavLink
                  to='#'
                  className={`${classnames({
                    active: activeTab === tab.value,
                  })} cursor-pointer`}
                  onClick={() => {
                    tabChange(tab.value)
                  }}
                >
                  {tab.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <TabContent activeTab={activeTab}>
            {Object.values(COURSE_HISTORY_TABS).map(tabId => (
              <TabPane tabId={tabId} key={tabId}>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={false}
                  pages={data.pages}
                  className={'py-0'}
                >
                  <div
                    className='table-card mt-0 courses-table'
                    id='user-course-history'
                  >
                    <Table className='align-middle mb-0'>
                      <thead className='table-light'>
                        <tr className='text-muted fs-14'>
                          {columns.map(column => (
                            <th
                              scope='col'
                              className='align-middle'
                              style={column.style ?? {}}
                              key={column.id}
                            >
                              {!!column.tooltip && (
                                <UncontrolledTooltip
                                  placement='top'
                                  target={
                                    'user-course-history-col-' + column.id
                                  }
                                  autohide={false}
                                >
                                  {column.tooltip}
                                </UncontrolledTooltip>
                              )}
                              <div
                                className='d-flex align-items-center'
                                id={'user-course-history-col-' + column.id}
                              >
                                <span>{column.name}</span>
                                {!!column.sortBy && (
                                  <ColumnSortIcon<AssignmentsSortBy>
                                    sortOrder={query.orderBy}
                                    sortedColumn={query.sortBy}
                                    column={column.sortBy}
                                    handleSort={handleSort}
                                  />
                                )}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.assignments?.map((assignment, i) => (
                          <tr key={i} className='fs-14'>
                            {!isCETabActive && (
                              <>
                                <td>
                                  <Link
                                    to={
                                      assignment.course
                                        ? '/courses/general/' +
                                          assignment.course?.id
                                        : '/courses/packages/manage/' +
                                          assignment.package?.id
                                    }
                                    className='fw-normal'
                                  >
                                    {assignment.code}
                                  </Link>
                                </td>
                                <td className='package-col'>
                                  {assignment.package && assignment.courseId ? (
                                    <Link
                                      to={
                                        '/courses/packages/manage/' +
                                        assignment.package.id
                                      }
                                      className='fw-normal'
                                    >
                                      {assignment.package.code}
                                    </Link>
                                  ) : (
                                    '-'
                                  )}
                                </td>
                              </>
                            )}
                            <td>
                              <span
                                className={`fw-normal ${
                                  isCETabActive ? '' : 'two-line-limit'
                                }`}
                              >
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={
                                    assignment.course?.translations[0].content
                                      .name ||
                                    assignment.package?.name ||
                                    '-'
                                  }
                                />
                              </span>
                            </td>
                            {!isCETabActive && (
                              <>
                                <td>
                                  <span
                                    className={`badge badge-${
                                      BadgeColorByAllFormats[assignment.format]
                                    } fs-12 fw-normal text-capitalize`}
                                  >
                                    {assignment.format}
                                  </span>
                                </td>
                                <td>
                                  {assignment.courseId && (
                                    <div className='course-progress d-flex gap-1 align-items-center'>
                                      <span className='fw-light text-muted fs-13'>
                                        {assignment.progress + '%'}
                                      </span>
                                      <Progress
                                        style={{ height: 5, width: 60 }}
                                        color='primary'
                                        value={assignment.progress}
                                      />
                                    </div>
                                  )}
                                </td>
                              </>
                            )}
                            <td>
                              <span
                                className={`badge badge-${getTestScoreBadgeColor(
                                  assignment,
                                )} fs-12 fw-normal text-capitalize`}
                              >
                                {assignment.competencyTestScore
                                  ? assignment.competencyTestScore + '%'
                                  : null}
                              </span>
                              {assignment.testFileId && (
                                <i
                                  className='ri-edit-box-line ms-1 fs-12 text-primary cursor-pointer'
                                  onClick={() => {
                                    setSelectedAssignment({
                                      type: ASSIGNMENT_ACTIONS.TEST_PREVIEW,
                                      id: assignment.id,
                                    })
                                  }}
                                ></i>
                              )}
                            </td>
                            {!isCETabActive && (
                              <td>
                                <span
                                  className={`badge badge-${getDueDateBadgeColor(
                                    assignment,
                                  )} fs-12 fw-normal text-capitalize`}
                                >
                                  {moment(assignment.dueDate).format(
                                    'MM/DD/YYYY',
                                  )}
                                </span>
                              </td>
                            )}

                            <td>
                              <span
                                className={`badge badge-${
                                  BadgeColorByStatus[assignment.status]
                                } fs-12 fw-normal text-capitalize`}
                              >
                                {getAssignmentStatus(assignment.status)}
                              </span>
                            </td>
                            {!isCETabActive && (
                              <td>
                                <div className='text-center'>
                                  {assignment.skipToTest &&
                                  assignment.status ===
                                    ASSIGNMENT_STATUS.COMPLETED ? (
                                    <i className='ri-check-line text-green-500'></i>
                                  ) : (
                                    <i className='ri-close-line text-danger'></i>
                                  )}
                                </div>
                              </td>
                            )}
                            <td>
                              <div className='d-flex justify-content-center'>
                                <ActionsDropdownMenu
                                  assignment={assignment}
                                  permissions={permissions}
                                  passingScore={passingScore}
                                  setSelectedAssignment={setSelectedAssignment}
                                  onViewCourse={setCourseToPreview}
                                  activeTab={activeTab}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className='my-3 pt-3'>
                    <Pagination
                      currentPage={data.page - 1}
                      totalPages={data.pages}
                      totalRecords={data.count}
                      limit={query.limit}
                      setPage={page => {
                        setQuery(prev => ({ ...prev, page: ++page }))
                      }}
                    />
                  </div>
                </NoResultTableWrapper>
              </TabPane>
            ))}
          </TabContent>
        </Card>
      </Card>
      {currentAssignment && (
        <EditAssignmentModal
          isOpen={
            !!(
              selectedAssignment.id &&
              selectedAssignment.type === ASSIGNMENT_ACTIONS.EDIT
            )
          }
          onClose={() => {
            setSelectedAssignment({ ...selectedAssignment, id: null })
          }}
          onSubmit={onUpdate}
          user={user}
          assignment={currentAssignment}
        />
      )}
      <OverrideConfirmationModal
        isOpen={
          !!(
            selectedAssignment.id &&
            selectedAssignment.type === ASSIGNMENT_ACTIONS.OVERRIDE
          )
        }
        title='Override Test Score'
        message={`Please explain the need to override ${firstName} ${lastName}'s test score to a pass?`}
        onOverride={onOverride}
        onClose={() => {
          setSelectedAssignment({ ...selectedAssignment, id: null })
        }}
      />
      <CourseOverrideConfirmationModal
        isOpen={
          !!(
            selectedAssignment.id &&
            selectedAssignment.type === ASSIGNMENT_ACTIONS.COURSE_OVERRIDE
          )
        }
        title='Complete Course'
        message={`Are you sure you want to complete ${
          data.assignments.find(
            assignment => assignment.id === selectedAssignment.id,
          )?.course?.translations[0].content.name ?? ''
        } for user ${firstName} ${lastName}?`}
        onOverride={onCourseComplete}
        onClose={() => {
          setSelectedAssignment({ ...selectedAssignment, id: null })
        }}
      />
      <DeleteConfirmation
        isOpen={
          !!(
            selectedAssignment.id &&
            selectedAssignment.type === ASSIGNMENT_ACTIONS.DELETE
          )
        }
        title='Delete assignment?'
        message={`Are you sure you want to delete ${
          data.assignments.find(
            assignment => assignment.id === selectedAssignment.id,
          )?.course?.translations[0].content.name ?? ''
        } assignment?`}
        onDelete={onDelete}
        onClose={() => {
          setSelectedAssignment({ ...selectedAssignment, id: null })
        }}
      />
      {permissions.assign && (
        <AssignCourseModal
          isOpen={showAssign}
          onClose={() => setShowAssign(false)}
          onSubmit={() => setQuery(prev => ({ ...prev, page: data.page }))}
          user={user}
        />
      )}
      {permissions.printTranscript && (
        <PrintTranscriptModal
          isOpen={showPrintTranscript}
          onClose={() => setShowPrintTranscript(false)}
          onSubmit={printTranscript}
        />
      )}

      {courseToPreview && (
        <CoursePreviewModal
          courseId={courseToPreview.id}
          isOpen={!!courseToPreview}
          onClose={() => {
            setCourseToPreview(null)
          }}
        />
      )}

      {selectedAssignment.id && (
        <>
          <UploadWrittenTest
            isOpen={
              !!(
                selectedAssignment.id &&
                selectedAssignment.type === ASSIGNMENT_ACTIONS.UPLOAD_TEST
              )
            }
            assignmentId={selectedAssignment.id}
            onSubmit={onAddTestFile}
            onClose={() => {
              setSelectedAssignment({ ...selectedAssignment, id: null })
            }}
          />
          {selectedAssignment.type === ASSIGNMENT_ACTIONS.TEST_PREVIEW && (
            <TestPreview
              isOpen={
                !!(
                  selectedAssignment.id &&
                  selectedAssignment.type === ASSIGNMENT_ACTIONS.TEST_PREVIEW
                )
              }
              testFile={
                data.assignments.find(a => a.id === selectedAssignment.id)
                  ?.testFile as TAttachment
              }
              onClose={() => {
                setSelectedAssignment({ ...selectedAssignment, id: null })
              }}
            />
          )}

          {selectedAssignment.type === ASSIGNMENT_ACTIONS.GENERATE_TEST &&
            permissions.overrideScore && (
              <CompetencyTestPreviewModal
                isOpen={
                  !!(
                    selectedAssignment.id &&
                    selectedAssignment.type === ASSIGNMENT_ACTIONS.GENERATE_TEST
                  )
                }
                onClose={() => {
                  setSelectedAssignment({ ...selectedAssignment, id: null })
                }}
                courseName={_.get(
                  selectedItem.course,
                  'translations[0].content.name',
                  '-',
                )}
                assignment={selectedItem}
                languages={selectedItem.course.translations.map(
                  t => t.language,
                )}
                permission={UserManagementPermissions.VIEW_USER_COURSE_HISTORY}
              />
            )}
        </>
      )}
    </Col>
  )
}

export default CourseHistory
