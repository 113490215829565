import React from 'react'
import { Spinner } from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css'

const Loader = () => {
  return (
    <React.Fragment>
      <div className='d-flex justify-content-center mx-2 mt-2'>
        <Spinner color='primary'> Loading... </Spinner>
      </div>
    </React.Fragment>
  )
}

export default Loader
