import React, { useEffect, useCallback, Fragment } from 'react'
import { Link } from 'react-router-dom'
import withRouter from '../../Components/Common/withRouter'
import { Collapse } from 'reactstrap'

// Import Data
import useNavData from '../LayoutMenuData'
//i18n
import { withTranslation } from 'react-i18next'
import {
  usePermissions,
  usePermissionsToDisplay,
} from '../../hooks/usePermissions'
import {
  ItemLInkProps,
  NavChildItemProps,
  NavItemWithSubItemsProps,
  VerticalLayoutProps,
} from './types'

const VerticalLayout = (props: VerticalLayoutProps) => {
  const navData = useNavData()

  //vertical and semibox resize events
  const resizeSidebarMenu = useCallback(() => {
    const windowSize = document.documentElement.clientWidth
    if (windowSize >= 1025) {
      if (document.documentElement.getAttribute('data-layout') === 'vertical') {
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
      if (document.documentElement.getAttribute('data-layout') === 'semibox') {
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
      if (document.querySelector('.hamburger-icon')) {
        document.querySelector('.hamburger-icon')?.classList.remove('open')
      } else {
        document.querySelector('.hamburger-icon')?.classList.add('open')
      }
    } else if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove('twocolumn-panel')
      if (document.documentElement.getAttribute('data-layout') === 'vertical') {
        document.documentElement.setAttribute('data-sidebar-size', 'sm')
      }
      if (document.documentElement.getAttribute('data-layout') === 'semibox') {
        document.documentElement.setAttribute('data-sidebar-size', 'sm')
      }
      if (document.querySelector('.hamburger-icon')) {
        document.querySelector('.hamburger-icon')?.classList.add('open')
      }
    } else if (windowSize <= 767) {
      document.body.classList.remove('vertical-sidebar-enable')
      if (
        document.documentElement.getAttribute('data-layout') !== 'horizontal'
      ) {
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
      if (document.querySelector('.hamburger-icon')) {
        document.querySelector('.hamburger-icon')?.classList.add('open')
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resizeSidebarMenu, true)
  }, [resizeSidebarMenu])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + props.router.location.pathname
      const ul = document.getElementById('navbar-nav')
      const items = ul?.getElementsByTagName('a')
      const itemsArray = items ? Array.from(items) : [] // converts NodeList to Array
      removeActivation(itemsArray)
      const matchingMenuItem = itemsArray.find(x => {
        return x.pathname === pathName
      })
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }

    initMenu()
  }, [props.router.location.pathname])

  function activateParentDropdown(item: HTMLAnchorElement) {
    item.classList.add('active')
    const parentCollapseDiv = item.closest('.collapse.menu-dropdown')

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add('show')
      parentCollapseDiv.parentElement?.children[0].classList.add('active')
      parentCollapseDiv.parentElement?.children[0].setAttribute(
        'aria-expanded',
        'true',
      )
      parentCollapseDiv.parentElement?.children[0].setAttribute(
        'data-open-mode',
        'opened',
      )
      if (parentCollapseDiv.parentElement?.closest('.collapse.menu-dropdown')) {
        parentCollapseDiv.parentElement
          ?.closest('.collapse')
          ?.classList.add('show')
        if (
          parentCollapseDiv.parentElement?.closest('.collapse')
            ?.previousElementSibling
        ) {
          parentCollapseDiv.parentElement
            ?.closest('.collapse')
            ?.previousElementSibling?.classList.add('active')
        }
        if (
          parentCollapseDiv.parentElement
            ?.closest('.collapse')
            ?.previousElementSibling?.closest('.collapse')
        ) {
          parentCollapseDiv.parentElement
            ?.closest('.collapse')
            ?.previousElementSibling?.closest('.collapse')
            ?.classList.add('show')
          parentCollapseDiv.parentElement
            ?.closest('.collapse')
            ?.previousElementSibling?.closest('.collapse')
            ?.previousElementSibling?.classList.add('active')
        }
      }
      return false
    }
    return false
  }

  const removeActivation = (items: HTMLAnchorElement[]) => {
    items.forEach(item => {
      if (item.classList.contains('menu-link')) {
        if (!item.classList.contains('active')) {
          item.setAttribute('aria-expanded', 'false')
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove('show')
        }
      }
      if (item.classList.contains('nav-link')) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove('show')
        }
        item.setAttribute('aria-expanded', 'false')
        item.setAttribute('data-open-mode', 'closed')
      }
      item.classList.remove('active')
    })
  }

  return (
    <Fragment>
      {/* menu Items */}
      {(navData || []).map((item, key) => {
        return (
          <Fragment key={key}>
            {/* Main Header */}
            {item['isHeader'] ? (
              <li className='menu-title'>
                <span data-key='t-menu'>{props.t(item.label)} </span>
              </li>
            ) : item.subItems && item.subItems.length ? (
              <NavItemWithSubItems item={item} translation={props} />
            ) : (
              <li className='nav-item'>
                <ParentNavItem item={item} label={props.t(item.label)} />
              </li>
            )}
          </Fragment>
        )
      })}
    </Fragment>
  )
}

const ParentNavItem = ({ item, label }: ItemLInkProps) => {
  const hasPermissionToDisplay = usePermissions(item.id ?? '')
  let allPermissionsToDisplay: boolean[] = []

  if (item.ids) {
    allPermissionsToDisplay = item.ids.map(id => usePermissions(id))
  }

  return (
    <>
      {(hasPermissionToDisplay ||
        Object.values(allPermissionsToDisplay).some(Boolean)) && (
        <Link className='nav-link menu-link' to={item.link ? item.link : '/#'}>
          <i className={item.icon}></i> <span>{label}</span>
          {item.badgeName ? (
            <span
              className={'badge badge-pill bg-' + item.badgeColor}
              data-key='t-new'
            >
              {item.badgeName}
            </span>
          ) : null}
        </Link>
      )}
    </>
  )
}

const NavItemWithSubItems = ({
  item,
  translation,
}: NavItemWithSubItemsProps) => {
  const hasPermissionToDisplay = usePermissionsToDisplay(item)

  return (
    <>
      {hasPermissionToDisplay && (
        <li className='nav-item'>
          <Link
            onClick={item.click}
            className='nav-link menu-link'
            data-open-mode={`${item.stateVariables ? 'opened' : 'closed'}`}
            to={item.link ? item.link : '/#'}
            data-bs-toggle='collapse'
          >
            <i className={item.icon}></i>
            <span data-key='t-apps'>{translation.t(item.label)}</span>
            {item.badgeName ? (
              <span
                className={'badge badge-pill bg-' + item.badgeColor}
                data-key='t-new'
              >
                {item.badgeName}
              </span>
            ) : null}
          </Link>
          <Collapse
            className='menu-dropdown'
            isOpen={item.stateVariables}
            id='sidebarApps'
          >
            <ul className='nav nav-sm flex-column test'>
              {item.subItems &&
                (item.subItems || []).map((subItem, key) => (
                  <Fragment key={key}>
                    {!subItem.isChildItem ? (
                      <NavChildItem
                        item={item}
                        subItem={subItem}
                        subItemLabel={translation.t(subItem.label)}
                      />
                    ) : (
                      <li className='nav-item'>
                        <Link
                          onClick={subItem.click}
                          className='nav-link'
                          to='/#'
                          data-bs-toggle='collapse'
                        >
                          {' '}
                          {translation.t(subItem.label)}
                          {subItem.badgeName ? (
                            <span
                              className={
                                'badge badge-pill bg-' + subItem.badgeColor
                              }
                              data-key='t-new'
                            >
                              {subItem.badgeName}
                            </span>
                          ) : null}
                        </Link>
                        <Collapse
                          className='menu-dropdown'
                          isOpen={subItem.stateVariables}
                          id='sidebarEcommerce'
                        >
                          <ul className='nav nav-sm flex-column'>
                            {subItem.childItems &&
                              (subItem.childItems || []).map(
                                (childItem, key) => (
                                  <Fragment key={key}>
                                    {!childItem.childItems ? (
                                      <li className='nav-item'>
                                        <Link
                                          to={
                                            childItem.link
                                              ? childItem.link
                                              : '/#'
                                          }
                                          className='nav-link'
                                        >
                                          {translation.t(childItem.label)}
                                        </Link>
                                      </li>
                                    ) : (
                                      <li className='nav-item'>
                                        <Link
                                          to='/#'
                                          className='nav-link'
                                          onClick={childItem.click}
                                          data-bs-toggle='collapse'
                                        >
                                          {translation.t(childItem.label)}
                                        </Link>
                                        <Collapse
                                          className='menu-dropdown'
                                          isOpen={childItem.stateVariables}
                                          id='sidebaremailTemplates'
                                        >
                                          <ul className='nav nav-sm flex-column'>
                                            {childItem.childItems.map(
                                              (subChildItem, key) => (
                                                <li
                                                  className='nav-item'
                                                  key={key}
                                                >
                                                  <Link
                                                    to={subChildItem.link}
                                                    className='nav-link'
                                                    data-key='t-basic-action'
                                                  >
                                                    {translation.t(
                                                      subChildItem.label,
                                                    )}{' '}
                                                  </Link>
                                                </li>
                                              ),
                                            )}
                                          </ul>
                                        </Collapse>
                                      </li>
                                    )}
                                  </Fragment>
                                ),
                              )}
                          </ul>
                        </Collapse>
                      </li>
                    )}
                  </Fragment>
                ))}
            </ul>
          </Collapse>
        </li>
      )}
    </>
  )
}

const NavChildItem = ({ item, subItem, subItemLabel }: NavChildItemProps) => {
  const hasPermissionToDisplay = usePermissions(
    item.id ?? '',
    subItem.id.toString(),
  )

  return (
    <li className='nav-item'>
      {hasPermissionToDisplay && (
        <Link
          to={subItem.link ? subItem.link : '/#'}
          className='nav-link'
          target={subItem.openInNewWindow ? '_blank' : undefined}
          rel={subItem.openInNewWindow ? 'noopener noreferrer' : undefined}
        >
          {subItemLabel}
          {subItem.badgeName ? (
            <span
              className={'badge badge-pill bg-' + subItem.badgeColor}
              data-key='t-new'
            >
              {subItem.badgeName}
            </span>
          ) : null}
        </Link>
      )}
    </li>
  )
}
export default withRouter(withTranslation()(VerticalLayout))
