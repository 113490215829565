import React, { useEffect, useState } from 'react'
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import {
  GetCourseQuestionsDTO,
  GetQuestionsPermissions,
  ImageAnswerItem,
  MultiChoiceAnswerItem,
  OnlineCourseItem,
  QuestionableTypes,
  QuestionTypes,
  TAssignment,
  TLanguage,
} from '../../../sharedTypes'
import { getQuestions } from '../../../helpers/api_helper'
import _ from 'lodash'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { calculateAspectRatioFit } from '../../../pages/Calendar'
import { handleError } from '../../../helpers/toast_helper'
import { renderToString } from 'react-dom/server'
import ExportCourseTestQuestions from '../../Course/Questions/ExportCourseTestQuestions'
import { NotCorrectAnswerIcon } from '../../Course/Questions/AnswerIcons'
import { answerOptions } from '../../Course/Questions/types'
import { ENGLISH_LANGUAGE_CODE } from '../../../helpers/common'

interface PreviewModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  languages: TLanguage[]
  assignment?: TAssignment
  permission: GetQuestionsPermissions
  course?: OnlineCourseItem
  courseName?: string
  displayAllQuestions?: boolean
}

const ROWS_PER_PAGE = 5

const PreviewModal = ({
  onClose,
  isOpen,
  languages,
  assignment,
  course,
  courseName,
  displayAllQuestions = false,
  permission,
}: PreviewModalProps) => {
  const [activeTab, setActiveTab] = useState(languages[0].id)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [data, setData] = useState<GetCourseQuestionsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    questions: [],
  })

  useEffect(() => {
    if (isOpen) {
      const questionsToDisplay = assignment
        ? assignment.course.competencyTest.quizQuestionsNumber
        : undefined
      getQuestions({
        entityId: assignment
          ? assignment.courseId
          : (course as OnlineCourseItem).id,
        questionableType: QuestionableTypes.COMPETENCY_ONLY,
        languageId: activeTab,
        permission,
      })
        .then(res => {
          setData({
            ...res,
            questions: !displayAllQuestions
              ? _.sampleSize(res.questions, questionsToDisplay)
              : res.questions,
            count: !displayAllQuestions
              ? res.count
              : (questionsToDisplay as number),
          })
        })
        .catch(e => {
          handleError(e)
        })
    }
  }, [activeTab])

  const addContentToPDF = async (
    currentPageIndex: number,
    pdf: jsPDF,
    totalPages: number,
    pdfWithAnswerKeys = false,
  ) => {
    const pdfContent = document.createElement('div')

    const startIndex = currentPageIndex * ROWS_PER_PAGE
    const endIndex = Math.min(startIndex + ROWS_PER_PAGE, data.questions.length)
    const chunk = data.questions.slice(startIndex, endIndex)

    const courseTitle =
      courseName ??
      ((course as OnlineCourseItem)?.translations?.find(
        item => item.language.code === ENGLISH_LANGUAGE_CODE,
      )?.content?.name ||
        '')

    pdfContent.innerHTML = renderToString(
      <ExportCourseTestQuestions
        courseCode={
          assignment ? assignment.code : (course as OnlineCourseItem).code
        }
        courseName={courseTitle}
        userName={`${
          assignment ? assignment.user.firstName + assignment.user.lastName : ''
        }`}
        questions={chunk}
        page={currentPageIndex + 1}
        withAnswerKeys={pdfWithAnswerKeys}
        displayLabel={!displayAllQuestions}
      />,
    )

    document.body.appendChild(pdfContent)

    const images: NodeListOf<HTMLImageElement> =
      pdfContent.querySelectorAll('img')

    for (const img of Array.from(images)) {
      await fetch(img.getAttribute('src')!, { cache: 'no-cache' })
    }

    html2canvas(pdfContent, {
      useCORS: true,
    }).then(function (canvas) {
      const imgData = canvas.toDataURL('image/jpeg')

      const { width, height } = calculateAspectRatioFit(
        canvas.width,
        canvas.height,
        pdf.internal.pageSize.getWidth() - 30,
        pdf.internal.pageSize.getHeight() - 30,
      )
      if (currentPageIndex > 0) {
        pdf.addPage()
      }

      const imageWidth = pdf.internal.pageSize.getWidth() - 30
      const x = (pdf.internal.pageSize.getWidth() - imageWidth) / 2

      pdf.addImage({
        imageData: imgData,
        x,
        y: 20,
        width: imageWidth,
        height: (height * imageWidth) / width,
        compression: 'NONE',
      })

      if (totalPages > 1) {
        currentPageIndex = currentPageIndex + 1
        totalPages = totalPages - 1
        addContentToPDF(currentPageIndex, pdf, totalPages, pdfWithAnswerKeys)
      } else {
        const pdfBlob = new Blob([pdf.output('blob')], {
          type: 'application/pdf',
        })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        const pdfWindow = window.open(pdfUrl, '_blank')
        if (pdfWindow) {
          setIsLoading(false)
          pdfWindow.onload = () => {
            pdfWindow.print()
          }
        }
      }
    })

    if (document.body.contains(pdfContent)) {
      document.body.removeChild(pdfContent)
    }
  }

  const generatePDFWithOptions = async ({
    includeAnswerKeys,
    totalPages,
  }: {
    includeAnswerKeys: boolean
    totalPages: number
  }) => {
    const pdf = new jsPDF({
      unit: 'px',
      format: 'a4',
      orientation: 'portrait',
    })

    await addContentToPDF(0, pdf, totalPages, includeAnswerKeys)
    return pdf
  }

  const generatePDF = async () => {
    setIsLoading(true)
    const totalPages = Math.ceil(data.questions.length / ROWS_PER_PAGE)

    if (!displayAllQuestions) {
      generatePDFWithOptions({
        includeAnswerKeys: false,
        totalPages: totalPages,
      })
    }

    generatePDFWithOptions({
      includeAnswerKeys: true,
      totalPages: totalPages,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        <h5 className='fw-light text-muted'>Test Preview</h5>
      </ModalHeader>
      <ModalBody
        className='px-0 preview-course-test'
        style={{ overflowX: 'hidden' }}
      >
        {!_.isEmpty(data.questions) ? (
          <div className='text-center'>
            <TabContent activeTab={activeTab} className='px-3'>
              {languages.map((language, langIndex: number) => (
                <TabPane tabId={language.id} key={langIndex}>
                  <div
                    className='vstack gap-4'
                    id={`questions-preview-${activeTab}`}
                  >
                    {data.questions.map((questionData, qIndex) => (
                      <div key={qIndex}>
                        <div className='border-bottom'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='text-muted fs-14'>
                              Question # {qIndex + 1}
                            </span>
                          </div>
                          <div className='hstack mb-2'>
                            <span className='fw-medium fs-16 text-start'>
                              {questionData.question || '-'}
                            </span>
                          </div>
                          {questionData.type ===
                            QuestionTypes.MULTIPLE_CHOICE &&
                            (
                              (questionData.answers as MultiChoiceAnswerItem[]) ||
                              []
                            ).map((answerData, aIndex) => (
                              <div key={aIndex}>
                                <div className='form-check my-2 fs-14 mb-2 px-0 d-flex align-items-center'>
                                  <NotCorrectAnswerIcon />
                                  <Label className='form-check-label fw-medium text-muted'>
                                    {'answer' in answerData &&
                                      answerData?.answer}
                                  </Label>
                                </div>
                              </div>
                            ))}

                          {questionData.type === QuestionTypes.IMAGE_CHOICE &&
                            (
                              (questionData.answers as ImageAnswerItem[]) || []
                            ).map((answerData, aIndex) => (
                              <div key={aIndex}>
                                <div className='form-check my-2 fs-14 mb-2 px-0 d-flex align-items-center'>
                                  <NotCorrectAnswerIcon />
                                  <div
                                    className='d-flex justify-content-center align-items-center
                                      rounded test-question-image-answer'
                                  >
                                    {_.get(answerData, 'path') ? (
                                      <img
                                        src={_.get(answerData, 'path')}
                                        className='w-100 h-100 rounded'
                                      />
                                    ) : (
                                      <i className='display-7 text-muted ri-upload-cloud-2-fill fs-5'></i>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}

                          {questionData.type === QuestionTypes.TRUE_FALSE && (
                            <div>
                              {Object.values(answerOptions).map(
                                (option, index) => (
                                  <div
                                    key={index}
                                    className='form-check mb-2 px-0 d-flex align-items-center'
                                  >
                                    <NotCorrectAnswerIcon />
                                    <Label className='form-check-label text-muted'>
                                      {option}
                                    </Label>
                                  </div>
                                ),
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPane>
              ))}
            </TabContent>
          </div>
        ) : (
          <p className='fs-20 text-center'>No Questions Yet</p>
        )}

        <div className='hstack gap-2 justify-content-end mt-4 px-4'>
          <Button className='btn-soft-primary' onClick={onClose}>
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={generatePDF}
            disabled={isLoading || _.isEmpty(data.questions)}
          >
            <i className='ri-printer-line me-2 fs-16'></i>
            {isLoading ? <Spinner size={'sm'} /> : 'Print'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PreviewModal
