export enum ASSIGNMENT_FILTER_TYPES {
  OVERDUE = 'overdue',
  NO_MORE_ATTEMPTS = 'noAttempts',
}

export enum EXPORT_TYPES {
  CSV = 'CSV',
  XLS = 'XLS',
  PDF = 'PDF',
}

export enum EXPORT_RANGE {
  ALL_TIME = 'all',
  DATE_RANGE = 'range',
}

export interface DateRangeData {
  startDate?: Date
  endDate?: Date
}

export interface SelectedFilters {
  facilities?: string[]
  agencies?: string[]
  groups?: string[]
  positions: string[]
  departments: string[]
  courses: string[]
}

export enum FILTERS {
  AGENCY = 'agency',
  FACILITY = 'facility',
  GROUP = 'group',
  POSITION = 'position',
  DEPARTMENT = 'department',
  COURSE = 'courseStatus',
  COMPLETED_DATE = 'completedDate',
  DUE_DATE = 'dueDate',
  EXPIRE_DATE = 'expireDate',
  STATUS = 'status',
  TAB = 'tab',
  SORT_BY = 'sortBy',
  ORDER_BY = 'orderBy',
  TYPE = 'type',
  SKIP_TO_TEST = 'skipToTest',
}
