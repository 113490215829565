import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Row,
  Table,
} from 'reactstrap'
import {
  CreateFacilityDTO,
  DocumentFile,
  FacilityManagementPermissions,
  GetCompanyFacilitiesPermissions,
  StatesEnum,
  StatesOptions,
  TAttachment,
  TCompany,
  TFacility,
  TFacilityStatus,
  TUser,
} from '../../../sharedTypes'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import {
  getCompanyGroupOptions,
  getParentOption,
  Option,
} from '../../../helpers/facility'
import {
  deleteFacilityLogo,
  getCompaniesHierarchy,
  getFacilitySettings,
  patchFacility,
  uploadFacilityLogo,
} from '../../../helpers/api_helper'
import Select, { SingleValue } from 'react-select'
import { GroupSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import { usePermissions } from '../../../hooks/usePermissions'
import { ReactComponent as FlashLightOff } from '../../../assets/images/svg/flashOff.svg'
import SignatoryModal from '../../../Components/Modals/SignatoryModal'
import moment from 'moment/moment'
import LandingPageInfo from './LandingPageInfo'
import { getUserDisplayName } from '../../../helpers/user'
import formatBytes from '../../../utils/formatBytes'
import LogoUploader from '../../../Components/Common/LogoUploader'
import FacilitySettings from './FacilitySettings'

interface UserInfoProps {
  facility: TFacility
  onToggleActivation: () => void
  onEditSuccess: (id: number) => void
}

export interface IForm {
  name: string
  address: string
  phone: string
  state?: { value: StatesEnum; label: StatesEnum }
  masteredManager?: { value: TUser | undefined; label: string }
  HRManager?: { value: TUser | undefined; label: string }
  clinicalScheduler?: { value: TUser | undefined; label: string }
  parent: Option
  allowSkipToTest: boolean
}

const FacilityInfo = ({
  facility: facilityProp,
  onToggleActivation,
  onEditSuccess,
}: UserInfoProps) => {
  const [facility, setFacility] = useState(facilityProp)
  const [logo, setLogo] = useState<DocumentFile | TAttachment | null>()
  const [isEdit, setEdit] = useState(false)
  const [companies, setCompanies] = useState<TCompany[]>([])

  const hasPermissionToEdit = usePermissions(
    FacilityManagementPermissions.EDIT_FACILITY,
  )
  const hasPermissionToActivate = usePermissions(
    FacilityManagementPermissions.ACTIVATE_FACILITY,
  )
  const hasPermissionToDeactivate = usePermissions(
    FacilityManagementPermissions.DEACTIVATE_FACILITY,
  )

  const groupOptions = useMemo(() => {
    return getCompanyGroupOptions(companies)
  }, [companies])
  //Fetch data
  useEffect(() => {
    fetchGroups()
  }, [])

  useEffect(() => {
    if (facility) {
      getFacilitySettings(facility.id).then(res => {
        setLogo(res.data.logo)
      })
    }
  }, [facility])

  useEffect(() => {
    setFacility(facilityProp)
  }, [facilityProp])

  const onSubmit = ({
    name,
    parent,
    phone,
    address,
    state,
    HRManager,
    masteredManager,
    clinicalScheduler,
    allowSkipToTest,
  }: IForm) => {
    const facilityData: CreateFacilityDTO.Request = {
      name,
      companyId: parent.companyId,
      phone,
      address,
      allowSkipToTest,
      state: state?.value,
      masteredManagerId: masteredManager?.value
        ? masteredManager.value.id
        : undefined,
      HRManagerId: HRManager?.value ? HRManager.value.id : undefined,
      clinicalSchedulerId: clinicalScheduler?.value
        ? clinicalScheduler.value.id
        : undefined,
    }
    const [type, id] = parent.value.split(':')
    if (type === 'group' && id) {
      facilityData.groupId = +id
    } else {
      facilityData.groupId = null
    }

    patchFacility(facility.id, facilityData)
      .then(() => {
        toast('Success - Facility has been updated', successToastOptions)
        onEditSuccess(facility.id)
      })
      .catch(handleError)
      .finally(() => {
        form.setSubmitting(false)
        onCancel()
      })
  }

  const fetchGroups = () => {
    getCompaniesHierarchy({
      permission: GetCompanyFacilitiesPermissions.VIEW_FACILITY_DETAILS,
    })
      .then(res => {
        setCompanies(res.data)
      })
      .catch(() => {})
  }

  const onDrop = useCallback(async (files: any) => {
    try {
      const parsedFiles = files.map((file: File) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        }),
      )
      setLogo(parsedFiles[0])
      if (parsedFiles[0]) {
        await uploadFacilityLogo({
          file: parsedFiles[0],
          facilityId: facility.id,
        })
      }
    } catch (e) {}
  }, [])

  const onDelete = async () => {
    try {
      if ((logo as TAttachment).id) {
        setLogo(null)
        form.setFieldValue('logo', null)
        await deleteFacilityLogo((logo as TAttachment).id, facility.id)
        toast('File deleted successfully.', successToastOptions)
      }
    } catch (e) {
      handleError(e)
    }
  }

  const info = useMemo(() => {
    const list = [
      {
        label: 'Name',
        value: facility.name,
        isEditable: true,
        formKey: 'name',
      },
      {
        label: 'Company',
        value: facility.company?.name,
      },
      {
        label: 'Group',
        value: facility.group?.name,
        isEditable: true,
        formKey: 'parent',
      },
      {
        label: 'State',
        value: facility.state,
        isEditable: true,
        formKey: 'state',
      },
      {
        label: 'Address',
        value: facility.address,
        isEditable: true,
        formKey: 'address',
      },
      {
        label: 'Phone',
        value: facility.phone,
        isEditable: true,
        formKey: 'phone',
      },
      {
        label: 'Status',
        value:
          facility.status === 'active' ? (
            <span className='badge badge-soft-primary fs-12 fw-normal'>
              Active
            </span>
          ) : (
            <span className='badge badge-soft-warning fs-12 fw-normal'>
              Inactive
            </span>
          ),
      },
    ]

    if (facility.status === TFacilityStatus.ACTIVE && facility.activationDate) {
      list.push({
        label: 'Activation Date',
        value: moment(facility.activationDate).format('MM/DD/YYYY'),
        formKey: 'activationDate',
        isEditable: false,
      })
    }

    if (facility?.signatory) {
      list.push({
        label: 'Signatory',
        value: `${facility.signatory.firstName} ${facility.signatory.lastName}`,
        isEditable: false,
        formKey: 'signatory',
      })
    }

    return list
  }, [facility, logo])

  const form = useFormik<IForm>({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: facility.name,
      address: facility.address,
      phone: facility.phone,
      state: {
        label: facility.state,
        value: facility.state,
      },
      masteredManager: {
        label: getUserDisplayName(facility.masteredManager),
        value: facility.masteredManager,
      },
      HRManager: {
        label: getUserDisplayName(facility.HRManager),
        value: facility.HRManager,
      },
      clinicalScheduler: {
        label: getUserDisplayName(facility.clinicalScheduler),
        value: facility.clinicalScheduler,
      },
      parent: getParentOption(groupOptions, facility),
      allowSkipToTest: facility.allowSkipToTest,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Facility Name'),
      parent: Yup.object().required('Please Select Group'),
    }),
    onSubmit: onSubmit,
  })

  const [showSignatoryModal, setShowSignatoryModal] = useState(false)
  const onCancel = () => {
    setEdit(false)
    form.resetForm()
  }

  const signatoryActionTitle = useMemo(
    () => (facility?.signatory ? 'Edit Signatory' : '+ Add Signatory'),
    [facility?.signatory],
  )

  return (
    <Row xl={4} lg={3} xs={2} className='gap-1 justify-content-evenly'>
      <Col>
        <Card>
          <Form
            onSubmit={e => {
              e.preventDefault()
              form.handleSubmit()
              return false
            }}
            action='FacilityManagement#'
          >
            <SignatoryModal
              isOpen={showSignatoryModal}
              title={signatoryActionTitle}
              facility={facility}
              onSubmit={facility => {
                setFacility(facility)
                setShowSignatoryModal(false)
              }}
              onClose={() => {
                setShowSignatoryModal(false)
              }}
            />
            <CardBody className='d-flex gap-4 flex-column'>
              <div className='d-flex align-items-center'>
                <div className='flex-grow-1'>
                  <p
                    className='fs-16 mb-0 fw-light'
                    style={{ color: '#7C8193' }}
                  >
                    General Info
                  </p>
                </div>
                <div className='flex-shrink-0 fs-12'>
                  {isEdit ? (
                    <>
                      <Button
                        color='soft-danger'
                        className='me-2 fs-12'
                        onClick={onCancel}
                      >
                        <i className='ri-close-line me-1'></i>Cancel
                      </Button>
                      <Button
                        color='soft-success'
                        className='fs-12 border-0'
                        type='submit'
                        disabled={
                          !(form.isValid && form.dirty) || form.isSubmitting
                        }
                      >
                        <i className='ri-save-line me-1 fs-12'></i>Save
                      </Button>
                    </>
                  ) : (
                    <>
                      {hasPermissionToDeactivate &&
                        facility.status === TFacilityStatus.ACTIVE && (
                          <Button
                            color='soft-danger'
                            className='me-2 fs-12'
                            onClick={onToggleActivation}
                          >
                            <div className='d-flex align-items-center gap-1'>
                              <FlashLightOff
                                style={{ width: 14, height: 14 }}
                                className='flash-light-off-danger'
                              />
                              Deactivate
                            </div>
                          </Button>
                        )}
                      {hasPermissionToActivate &&
                        facility.status === TFacilityStatus.INACTIVE && (
                          <Button
                            color='soft-primary'
                            className='me-2 fs-12'
                            onClick={onToggleActivation}
                          >
                            <div className='d-flex align-items-center gap-1'>
                              <i className='ri-flashlight-line text-primary'></i>
                              Activate
                            </div>
                          </Button>
                        )}
                      {hasPermissionToEdit && (
                        <Button
                          color='soft-primary'
                          className='fs-12'
                          onClick={() => setEdit(true)}
                        >
                          <i className='ri-edit-box-line me-1 fs-12'></i>Edit
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='table-responsive overflow-visible'>
                <div className='d-flex w-100 justify-content-center'>
                  <LogoUploader
                    file={logo as TAttachment}
                    onChange={file => onDrop([file])}
                  />
                </div>
                <Table
                  className='table-borderless mb-0'
                  style={{ tableLayout: 'fixed' }}
                >
                  <tbody>
                    {info.map((item, index) => (
                      <tr key={index} style={{ height: 39 }}>
                        <td
                          className={`ps-0 fs-14 fw-normal ${
                            item.isEditable ? 'text-nowrap' : ''
                          }`}
                          scope='row'
                          style={{
                            width: 100,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                          title={item.label}
                        >
                          {item.label} :
                        </td>
                        <td
                          className={
                            'text-muted fw-light pe-0' +
                            (isEdit && item.isEditable
                              ? ' p-0 align-middle'
                              : '')
                          }
                        >
                          {isEdit && item.isEditable ? (
                            item.formKey === 'parent' ? (
                              <GroupSelectWithSearch<Option>
                                name='parent'
                                id='parent'
                                onChange={option => {
                                  const singleOption =
                                    option as SingleValue<Option>
                                  form.setFieldValue('parent', singleOption)
                                }}
                                onBlur={form.handleBlur}
                                value={form.values.parent}
                                isMulti={false}
                                isClearable={false}
                                isSearchable={true}
                                placeholder={''}
                                options={groupOptions}
                              />
                            ) : item.formKey === 'state' ? (
                              <Select
                                isSearchable={false}
                                onChange={option => {
                                  form.setFieldValue('state', option)
                                }}
                                value={form.values.state}
                                options={StatesOptions}
                                name='state'
                                id='state'
                                placeholder='Select state'
                                onBlur={form.handleBlur}
                                styles={{
                                  control: baseStyles => ({
                                    ...baseStyles,
                                    borderRadius: '0px 4px 4px 0px',
                                    minHeight: 39,
                                  }),
                                }}
                                className='select2-container w-100'
                                classNamePrefix='select2-selection form-select'
                              />
                            ) : (
                              <Input
                                name={item.formKey}
                                className='form-control fw-light'
                                type='text'
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                value={
                                  form.values[
                                    item.formKey as keyof IForm
                                  ] as any
                                }
                                invalid={
                                  !!(
                                    form.touched[item.formKey as keyof IForm] &&
                                    form.errors[item.formKey as keyof IForm]
                                  )
                                }
                              />
                            )
                          ) : (
                            item.value
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button
                  size='md'
                  className={`${'btn-soft-primary'}
                align-middle fs-14 w-100 fw-medium release-test`}
                  onClick={() => {
                    setShowSignatoryModal(true)
                  }}
                >
                  {signatoryActionTitle}
                </Button>
              </div>
            </CardBody>
          </Form>
        </Card>
      </Col>
      <Col>
        <FacilitySettings facility={facility} form={form} />
      </Col>
      <Col>
        <LandingPageInfo facility={facility} />
      </Col>
    </Row>
  )
}

export default FacilityInfo
