import React, { Fragment, useMemo, useRef } from 'react'
import { Button, Col, Input, Table } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import moment from 'moment/moment'
import _ from 'lodash'
import AsyncSelect from 'react-select/async'
import {
  formattedDuration,
  getAssignedCode,
  loadScheduleOptions,
} from '../../../helpers/course'
import {
  AdditionalSettings,
  DigitalAssign,
  LiveAssign,
  Step3Props,
} from './types'
import { COURSE_FILTER_FORMATS, OnlineCourseItem } from '../../../sharedTypes'
import { IColumn } from '../../Common/UsersTable'
import { TCoursePackage } from '../../../sharedTypes/models/coursePackage'
import { ENGLISH_LANGUAGE_CODE } from '../../../helpers/common'

export enum SelectedCoursesSortBy {
  CODE = 'courseCode',
  COURSE = 'name',
  PACKAGE = 'package',
  CREDIT_HOURS = 'duration',
}

interface ICourseColumn extends Omit<IColumn, 'sortBy'> {
  sortBy?: SelectedCoursesSortBy
}

const Columns: ICourseColumn[] = [
  {
    title: 'Code',
    id: 'code',
    sortBy: SelectedCoursesSortBy.CODE,
    style: { width: '7%' },
  },
  {
    title: 'Package',
    id: 'package',
    sortBy: SelectedCoursesSortBy.PACKAGE,
    style: { width: '10%' },
  },
  {
    title: 'Course',
    id: 'course',
    sortBy: SelectedCoursesSortBy.COURSE,
    style: { width: '20%' },
  },
  {
    title: 'Duration',
    id: 'duration',
    sortBy: SelectedCoursesSortBy.CREDIT_HOURS,
    style: { width: '5%' },
  },
]

interface SelectedCourseList {
  type: 'digital' | 'live'
  courses: DigitalAssign[] | LiveAssign[]
  title: string
  onDelete: Step3Props['onDelete']
  onSetDate: Step3Props['onSetDate']
  facilityId?: number
  onUpdateAllDigitalCourses?: (
    property: keyof DigitalAssign,
    value: string | boolean,
  ) => void
  settings?: AdditionalSettings
  showSkipToTest?: boolean
}

interface SkipToTestHeaderProps {
  allowSkipToTest?: boolean
  onUpdateAllDigitalCourses?: (
    property: keyof DigitalAssign,
    value: string | boolean,
  ) => void
}

const SkipToTestHeader = ({
  allowSkipToTest,
  onUpdateAllDigitalCourses,
}: SkipToTestHeaderProps) => {
  return (
    <div className='d-flex gap-2'>
      <span>Allow Skip to Test</span>
      <div className='form-check'>
        <Input
          className='form-check-input'
          type='checkbox'
          id={`allow-skip-to-test-header`}
          checked={allowSkipToTest}
          onChange={e => {
            if (onUpdateAllDigitalCourses) {
              onUpdateAllDigitalCourses('allowSkipToTest', e.target.checked)
            }
          }}
        />
      </div>
    </div>
  )
}

const DateHeader = ({
  settings,
  onUpdateAllDigitalCourses,
  label,
  propertyKey,
}: any) => {
  const datePickerRef = useRef<Flatpickr>(null)

  return (
    <div className='d-flex align-items-center justify-content-between'>
      <span>{label}*</span>
      <div className='form-check'>
        <Flatpickr
          style={{ width: 0, height: 0, padding: 0 }}
          className={`form-control form-control-icon border-0`}
          id={`${propertyKey}Header`}
          name='deadline'
          onChange={option => {
            onUpdateAllDigitalCourses(propertyKey, option[0])
          }}
          ref={datePickerRef}
          value={_.get(settings, propertyKey, undefined)}
          options={{
            dateFormat: 'd-m-Y',
            formatDate: date => moment(date).format('MM/DD/YYYY'),
            minDate: Date.now(),
            allowInvalidPreload: false,
          }}
        />
        <i
          className='ri-calendar-2-line fs-20 text-primary cursor-pointer'
          onClick={() => {
            datePickerRef.current?.flatpickr.open()
          }}
        ></i>
      </div>
    </div>
  )
}

const SelectedCourseList = ({
  type,
  courses,
  title,
  onDelete,
  onSetDate,
  facilityId,
  onUpdateAllDigitalCourses,
  settings,
  showSkipToTest,
}: SelectedCourseList) => {
  const dateAvailableRefs = useRef<{ [key: string]: Flatpickr | null }>({})
  const dueDatePickerRefs = useRef<{ [key: string]: Flatpickr | null }>({})

  const isValidColumn = (columnName: 'dateAvailable' | 'dueDate') => {
    return courses.some(item => !item.isValid && !item[columnName])
  }

  const columns = useMemo(() => {
    let changedColumns = [...Columns]
    if (type === 'digital') {
      changedColumns = [
        ...changedColumns,
        {
          title: (
            <DateHeader
              onUpdateAllDigitalCourses={onUpdateAllDigitalCourses}
              settings={settings}
              label='Date Available'
              propertyKey='dateAvailable'
            />
          ),
          id: 'date-available',
          style: {
            width: showSkipToTest ? '13%' : '15%',
            paddingLeft: '2%',
            color: isValidColumn('dateAvailable') ? '#f06548' : '',
          },
        },
        {
          title: (
            <DateHeader
              onUpdateAllDigitalCourses={onUpdateAllDigitalCourses}
              settings={settings}
              label='Due Date'
              propertyKey='dueDate'
            />
          ),
          id: 'due-date',
          style: {
            width: showSkipToTest ? '11%' : '15%',
            paddingLeft: '2%',
            color: isValidColumn('dueDate') ? '#f06548' : '',
          },
        },
        ...(showSkipToTest
          ? [
              {
                title: (
                  <SkipToTestHeader
                    onUpdateAllDigitalCourses={onUpdateAllDigitalCourses}
                    allowSkipToTest={settings?.allowSkipToTest}
                  />
                ),
                id: 'allowSkipToTest',
                style: { width: '10%' },
              },
            ]
          : []),
      ]
    } else {
      changedColumns = [
        ...changedColumns,
        {
          title: 'Date and Time*',
          id: 'date-and-time',
          style: {
            width: '30%',
            paddingLeft: '2%',
            color: isValidColumn('dueDate') ? '#f06548' : '',
          },
        },
      ]
    }

    return [
      ...changedColumns,
      {
        title: '',
        id: 'actions',
        style: { width: '5%' },
      },
    ]
  }, [type, isValidColumn, showSkipToTest])

  if (courses.length === 0) {
    return null
  }

  return (
    <div>
      <div
        className={`d-flex justify-content-between align-items-center ${
          type === 'live' ? 'pt-3' : ''
        }`}
      >
        <p className={'fw-medium fs-14'}>{title}</p>
        <span className='text-muted fw-medium'>Total: {_.size(courses)}</span>
      </div>
      <div className='courses-table-container bg-white'>
        <Table
          className='align-middle table-nowrap mb-0'
          style={{ tableLayout: showSkipToTest ? 'fixed' : 'auto' }}
        >
          <thead className='table-light'>
            <tr className='text-muted fs-14'>
              {columns.map(column => (
                <th
                  scope='col'
                  className='fw-normal align-middle bg-white'
                  style={column?.style}
                  key={column.id}
                >
                  {column.title}
                  {/*{!!column.sortBy && (
                    <ColumnSortIcon<SelectedCoursesSortBy>
                      sortOrder={query.orderBy}
                      sortedColumn={query.sortBy}
                      column={column.sortBy}
                      handleSort={handleSort}
                    />
                  )}*/}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {courses.map((item, i: number) => (
              <Fragment key={i}>
                <tr className={'fs-14 fw-light my-1'}>
                  <td className='text-primary fw-normal'>
                    {getAssignedCode(item)}
                  </td>
                  <td className='text-primary fw-normal'>
                    {(item.course as OnlineCourseItem).package?.code
                      ? _.get(item.course, 'package.code', '')
                      : '-'}
                  </td>
                  <td className={'text-truncate course-name pe-5'}>
                    {(item.course as TCoursePackage).name ??
                      _.get(
                        (item.course as OnlineCourseItem)?.translations?.find(
                          translation =>
                            translation?.language?.code ===
                            ENGLISH_LANGUAGE_CODE,
                        ),
                        'content.name',
                        '-',
                      )}
                  </td>
                  <td>
                    <span
                      className={`badge badge-soft-grey fs-12 fw-normal mt-1`}
                    >
                      {'duration' in item.course &&
                        !item.course.available_formats.includes(
                          COURSE_FILTER_FORMATS.COMPETENCY_ONLY,
                        ) &&
                        formattedDuration(item.course.duration)}
                    </span>
                  </td>
                  {type === 'digital' && (
                    <>
                      <td>
                        <div className='form-icon right'>
                          <Flatpickr
                            className={`form-control form-control-icon border-0 ${
                              !item.isValid && !item.dateAvailable
                                ? 'is-invalid'
                                : ''
                            }`}
                            ref={ref =>
                              (dateAvailableRefs.current[
                                `date-available-${i}`
                              ] = ref)
                            }
                            id={`date-available-${i}`}
                            name='deadline'
                            placeholder='--/--/----'
                            onChange={option => {
                              onSetDate(type, {
                                ...item,
                                dateAvailable: option[0],
                                isValid: true,
                              })
                            }}
                            value={item.dateAvailable}
                            options={{
                              dateFormat: 'm-d-Y',
                              formatDate: date =>
                                moment(date).format('MM/DD/YYYY'),
                              minDate: Date.now(),
                              allowInvalidPreload: true,
                              allowInput: true,
                            }}
                          />
                          <i
                            className='ri-calendar-2-line fs-20 text-primary cursor-pointer'
                            style={{ right: 0 }}
                            onClick={() => {
                              dateAvailableRefs.current[
                                `date-available-${i}`
                              ]?.flatpickr.open()
                            }}
                          ></i>
                        </div>
                      </td>
                      <td>
                        <div className='form-icon right'>
                          <Flatpickr
                            className={`form-control form-control-icon border-0 ${
                              !item.isValid &&
                              'dueDate' in item &&
                              (!item.dueDate ||
                                !moment(item.dueDate).isAfter(
                                  moment(item.dateAvailable),
                                ))
                                ? 'is-invalid'
                                : ''
                            }`}
                            id={`due-date-${i}`}
                            ref={ref =>
                              (dueDatePickerRefs.current[`due-date-${i}`] = ref)
                            }
                            name='deadline'
                            placeholder='--/--/----'
                            onChange={option => {
                              onSetDate(type, {
                                ...item,
                                dueDate: option[0],
                                isValid: true,
                              })
                            }}
                            value={'dueDate' in item ? item.dueDate : undefined}
                            options={{
                              dateFormat: 'd-m-Y',
                              formatDate: date =>
                                moment(date).format('MM/DD/YYYY'),
                              minDate: item.dateAvailable || Date.now(),
                              allowInvalidPreload: true,
                            }}
                          />
                          <i
                            className='ri-calendar-2-line fs-20 text-primary cursor-pointer'
                            style={{ right: 0 }}
                            onClick={() => {
                              dueDatePickerRefs.current[
                                `due-date-${i}`
                              ]?.flatpickr.open()
                            }}
                          ></i>
                        </div>
                      </td>
                      {showSkipToTest && (
                        <td>
                          {[
                            COURSE_FILTER_FORMATS.VIDEO,
                            COURSE_FILTER_FORMATS.DIGITAL,
                          ].includes(item.format) && (
                            <div className='form-check text-center'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                id={`allow-skip-to-test-${i}`}
                                checked={item.allowSkipToTest}
                                onChange={e => {
                                  onSetDate(type, {
                                    ...item,
                                    allowSkipToTest: e.target.checked,
                                    isValid: true,
                                  })
                                }}
                              />
                            </div>
                          )}
                        </td>
                      )}
                    </>
                  )}
                  {type === 'live' && (
                    <>
                      <td>
                        <AsyncSelect
                          className='select2-container'
                          key={courses.length}
                          classNamePrefix={`select2-selection ${
                            !item.isValid ? 'is-invalid' : ''
                          } form-select`}
                          isSearchable={false}
                          placeholder={'Select Date & Time'}
                          defaultOptions
                          loadOptions={() =>
                            loadScheduleOptions(item, facilityId)
                          }
                          onChange={option => {
                            onSetDate(type, {
                              ...item,
                              dateAvailable: moment().toDate(),
                              dueDate: option
                                ? moment(option.label).toDate()
                                : undefined,
                              isValid: true,
                              scheduleId: option?.value,
                            })
                          }}
                          value={
                            'scheduleId' in item
                              ? {
                                  value: item.scheduleId,
                                  label: moment(item.dueDate).format(
                                    'MM/DD/YYYY, hh:mm A',
                                  ),
                                }
                              : undefined
                          }
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                        />
                      </td>
                    </>
                  )}
                  <td className='text-center'>
                    <i
                      className='ri-delete-bin-2-line text-danger cursor-pointer'
                      onClick={() => onDelete(item)}
                    ></i>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

const ChooseDateTime = ({
  onCancel,
  onNext,
  assignOptions,
  onDelete,
  onSetDate,
  onUpdateAllDigitalCourses,
  showSkipToTest,
}: Step3Props) => {
  return (
    <Fragment>
      <div className='px-3 py-2 choose-courses-date-time'>
        <SelectedCourseList
          type='digital'
          courses={assignOptions.digital}
          title={'Digital Courses and Competency Only'}
          onDelete={onDelete}
          onSetDate={onSetDate}
          onUpdateAllDigitalCourses={onUpdateAllDigitalCourses}
          settings={assignOptions.settings}
          showSkipToTest={showSkipToTest}
        />
        <SelectedCourseList
          type='live'
          courses={assignOptions.live}
          title={'In-Person and Virtual Conference Courses'}
          onDelete={onDelete}
          onSetDate={onSetDate}
          facilityId={assignOptions.facilities[0]}
        />
      </div>
      <div className='px-3 py-2 row g-3'>
        <Col lg={12}>
          <div className='hstack gap-2 justify-content-between'>
            <Button
              className='btn-soft-primary align-middle'
              color='secondary'
              onClick={onCancel}
            >
              Back
            </Button>
            <Button className='align-middle' color='primary' onClick={onNext}>
              Next
            </Button>
          </div>
        </Col>
      </div>
    </Fragment>
  )
}

export default ChooseDateTime
