import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Card, Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap'
import BreadCrumb from '../../../../Components/Common/BreadCrumb'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import {
  getOnlineCourse,
  getTranslatableLanguages,
} from '../../../../helpers/api_helper'
import { handleError } from '../../../../helpers/toast_helper'
import {
  CoursesPermissions,
  OnlineCourseItem,
  TLanguage,
} from '../../../../sharedTypes'
import classnames from 'classnames'
import LanguageTabContent from './LanguageTabContent'
import {
  initialTranslationTabs,
  knowledgeCheckTab,
  LanguageTranslationPermissions,
  TRANSLATION_TABS,
  TranslationTabs,
} from './types'
import { usePermissions } from '../../../../hooks/usePermissions'
import {
  CREOLE_LANGUAGE_CODE,
  ENGLISH_LANGUAGE_CODE,
  SPANISH_LANGUAGE_CODE,
} from '../../../../helpers/common'

interface CourseTranslationsProps extends WithRouterProps {}

const CourseTranslations = ({ router }: CourseTranslationsProps) => {
  document.title = 'Course Translations | Mastered - Admin & Dashboard'

  const [fetchData, setFetchData] = useState<boolean>(false)
  const [knowledgeCheckTabStatus, setKnowledgeCheckTabStatus] =
    useState<boolean>(false)
  const [languages, setLanguages] = useState<TLanguage[]>([])
  const [activeLanguageTab, setActiveLanguageTab] = useState<string>()

  const permissions: LanguageTranslationPermissions = {
    editSpanishTranslation: usePermissions(
      CoursesPermissions.EDIT_SPANISH_TRANSLATION,
    ),
    editCreoleTranslation: usePermissions(
      CoursesPermissions.EDIT_CREOLE_TRANSLATION,
    ),
  }

  const [activeTab, setActiveTab] = useState<TRANSLATION_TABS>(
    TRANSLATION_TABS.GENERAL_INFORMATION,
  )
  const [tabs, setTabs] = useState<TranslationTabs[]>([])

  const [course, setCourse] = useState<OnlineCourseItem | null>(null)

  const title = useMemo(() => {
    const name =
      course?.translations?.find(
        item => item?.language?.code === ENGLISH_LANGUAGE_CODE,
      )?.content?.name ?? ''

    return `${name}  Course ${course?.code}`
  }, [course])

  const activeLanguage = useMemo(
    () => languages.find(lang => lang.code === activeLanguageTab),
    [activeLanguageTab],
  )

  const initialTabs = useMemo(() => {
    let defaultTabs: TranslationTabs[] = []

    if (
      permissions.editSpanishTranslation &&
      activeLanguageTab === SPANISH_LANGUAGE_CODE
    ) {
      defaultTabs = [...defaultTabs, ...initialTranslationTabs]
      if (course?.hasLessons) {
        defaultTabs = [...defaultTabs, knowledgeCheckTab]
      }
    }

    if (
      permissions.editCreoleTranslation &&
      activeLanguageTab === CREOLE_LANGUAGE_CODE
    ) {
      defaultTabs = [...defaultTabs, ...initialTranslationTabs]
      if (course?.hasLessons) {
        defaultTabs = [...defaultTabs, knowledgeCheckTab]
      }
    }

    return defaultTabs
  }, [permissions, course?.hasLessons])

  const changeTabs = (tabsWithError: TRANSLATION_TABS[]) => {
    const updatedTabs = tabs.map(tab => ({
      ...tab,
      error: tabsWithError.includes(tab.value),
    }))

    setTabs(updatedTabs)
  }

  useEffect(() => {
    if (
      !router.params.courseId ||
      Number.isNaN(+router.params.courseId) ||
      typeof +router.params.courseId !== 'number'
    ) {
      return router.navigate('/404')
    }

    getOnlineCourse(Number(router.params.courseId), {
      permission: CoursesPermissions.VIEW_TRANSLATIONS,
    })
      .then(c => {
        setCourse(c)
      })
      .catch(e => {
        if (e.response.status === 404) {
          router.navigate('/404')
        }
      })
  }, [router.params.courseId, fetchData, activeLanguage])

  useEffect(() => {
    getTranslatableLanguages()
      .then(data => {
        setLanguages(data)
        setActiveLanguageTab(data[0].code)
      })
      .catch(handleError)
  }, [])

  useEffect(() => {
    setTabs(initialTabs)
    if (initialTabs.length > 1) {
      setActiveTab(TRANSLATION_TABS.GENERAL_INFORMATION)
    }
  }, [activeLanguageTab, course?.id])

  const languageTabChange = (tab: string) => {
    if (activeLanguageTab !== tab) {
      setActiveLanguageTab(tab)
    }

    setTabs(
      tabs.map(tab => ({
        ...tab,
        error: false,
      })),
    )
  }

  const tabChange = (tab: TRANSLATION_TABS) => {
    if (activeLanguageTab !== tab) {
      setActiveTab(tab)
    }
  }

  const isLive = useMemo(() => {
    if (activeTab !== TRANSLATION_TABS.KNOWLEDGE_CHECK) {
      return !!course?.translations.find(
        translation => translation.language.code === activeLanguageTab,
      )
    }

    return knowledgeCheckTabStatus
  }, [
    course?.translations,
    activeTab,
    activeLanguageTab,
    knowledgeCheckTabStatus,
  ])

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={title}
            items={[
              {
                title: 'Courses',
                linkTo: '/courses',
              },
              {
                title: 'Course Translations',
                active: true,
              },
            ]}
          />
          <Row>
            <Col md={12}>
              <Card>
                <div className='d-flex justify-content-between align-items-center'>
                  <Nav
                    className='nav-tabs-custom rounded text-uppercase translations-tabs'
                    role='tablist'
                  >
                    {languages.map((language, index) => (
                      <NavItem key={index}>
                        <NavLink
                          to='#'
                          className={`${classnames({
                            active: activeLanguageTab === language.code,
                          })} cursor-pointer p-3`}
                          onClick={() => {
                            languageTabChange(language.code)
                          }}
                        >
                          {language.name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <span
                    className={`badge ${
                      isLive ? 'bg-danger' : 'bg-dark-gray'
                    } text-white fs-12 fw-normal me-3`}
                  >
                    {isLive ? 'Live' : 'Draft'}
                  </span>
                </div>

                <Nav
                  className='nav-tabs-custom rounded text-capitalize translation-inner-tabs'
                  role='tablist'
                >
                  {tabs.map((tab, index) => (
                    <NavItem key={index}>
                      <NavLink
                        to='#'
                        className={`${classnames({
                          active: activeTab === tab.value,
                        })} cursor-pointer p-3 ${
                          tab.error ? 'tab-with-error' : ''
                        }`}
                        onClick={() => {
                          tabChange(tab.value)
                        }}
                      >
                        {tab.label}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Card>
            </Col>
            {course && activeLanguage && (
              <LanguageTabContent
                course={course}
                activeTab={activeTab}
                activeLanguage={activeLanguage}
                isLive={isLive}
                fetchData={fetchData}
                setFetchData={setFetchData}
                changeTabs={changeTabs}
                setKnowledgeCheckTabStatus={setKnowledgeCheckTabStatus}
                permissions={permissions}
              />
            )}
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default withRouter(CourseTranslations)
