import React, { useEffect, useState } from 'react'
import { Col } from 'reactstrap'
import MultiSelect from '../../../Components/Common/MultiSelect'
import {
  CE_PROVIDERS_STATUSES,
  FilterOption,
  StatesEnum,
} from '../../../sharedTypes'
import { CoursePosition } from '../../../sharedTypes/api/coursePositions'
import _ from 'lodash'

export interface IFilters {
  discipline?: string[]
  state?: StatesEnum[]
  status?: CE_PROVIDERS_STATUSES[]
}

interface FiltersProps {
  setFilters: (filter: IFilters) => void
  filterBy?: {
    discipline?: boolean
    state?: boolean
  }
  initialValues?: Partial<IFilters>
  setSelectedFilters?: (filters: IFilters) => void
  visible: boolean
  disciplines: CoursePosition[]
}

const Filters = ({
  visible,
  setFilters,
  initialValues,
  disciplines,
}: FiltersProps) => {
  const [filters, setRawFilters] = useState<IFilters>({
    discipline: initialValues?.discipline || [],
    state: initialValues?.state || [],
    status: initialValues?.status || [],
  })
  const [getFilters, setGetFilters] = useState(false)

  if (!visible) {
    return null
  }

  useEffect(() => {
    if (getFilters) {
      setFilters({ ...filters })
    }
  }, [filters])

  useEffect(() => {
    setGetFilters(true)
  }, [])

  return (
    <>
      <Col className='mt-0 p-0'>
        <div style={{ minWidth: 220 }}>
          <MultiSelect
            name='providerStatus'
            id='providerStatus'
            isMulti
            isSearchable={true}
            placeholder='Filter by status'
            isClearable={false}
            options={Object.values(CE_PROVIDERS_STATUSES).map(value => ({
              value: value,
              label: value,
            }))}
            onChange={(option: FilterOption[]) => {
              setRawFilters(prev => ({
                ...prev,
                status: _.isEmpty(option)
                  ? []
                  : (_.map(option, 'value') as CE_PROVIDERS_STATUSES[]),
                page: 1,
              }))
            }}
            defaultValue={(initialValues?.status || []).map(status => ({
              value: status,
              label: _.capitalize(status),
            }))}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
          />
        </div>
      </Col>
      <Col className='mt-0 p-0'>
        <div style={{ minWidth: 220 }}>
          <MultiSelect
            name='discipline'
            id='discipline'
            isMulti
            isSearchable={true}
            placeholder='Filter by discipline'
            isClearable={false}
            options={disciplines.map(item => {
              return { value: item.name, label: item.name }
            })}
            onChange={(selectedOptions: FilterOption[]) => {
              setRawFilters(filters => ({
                ...filters,
                discipline: selectedOptions.map(
                  option => option.value,
                ) as string[],
              }))
            }}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
          />
        </div>
      </Col>
      <Col className='mt-0 p-0'>
        <div style={{ minWidth: 220 }}>
          <MultiSelect
            name='state'
            id='state'
            isMulti
            isSearchable={true}
            placeholder='Filter by state'
            isClearable={false}
            onChange={(selectedOptions: FilterOption[]) => {
              setRawFilters(filters => ({
                ...filters,
                state: selectedOptions.map(
                  option => option.value,
                ) as StatesEnum[],
              }))
            }}
            options={Object.values(StatesEnum).map(value => ({
              value,
              label: value,
            }))}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
          />
        </div>
      </Col>
    </>
  )
}

export default Filters
