import {
  ASSIGNMENT_STATUS,
  COURSE_FILTER_FORMATS,
  CoursesTabs,
  OnlineCourseItem,
  TAssignment,
  TAttachment,
} from '../models'
import { TCoursePackage } from '../models/coursePackage'
import { PaginatedBaseResponse, PaginationRequest } from './pagination'
import {
  CalendarPermissions,
  CoursesPermissions,
  UserManagementPermissions,
} from '../lib/types'
import { ConfirmationScheduleData } from './users'

export namespace ValidateAssignmentDTO {
  export interface Request {
    facilityIds?: number[]
    departmentIds?: number[]
    positionIds?: number[]
    userIds?: number[]
    [CoursesTabs.COURSES]?: {
      courseId: number
      format: COURSE_FILTER_FORMATS
    }[]
    [CoursesTabs.PACKAGES]?: number[]
    [CoursesTabs.TRAINING_DAYS]?: number[]
  }
  export interface Response {
    digital: OnlineCourseItem[]
    live: OnlineCourseItem[] | TCoursePackage[]
    facilities: number[]
    assignTo: number[]
    allowSkipToTest: boolean
    scheduleDetails: boolean
  }
}

export type AssignmentUserPermissions =
  | UserManagementPermissions.ASSIGN_COURSE_TO_USER
  | CoursesPermissions.ASSIGN_COURSE
  | CalendarPermissions.ADD_PARTICIPANTS

export namespace CreateAssignmentDTO {
  export interface Request {
    userIds: number[]
    itemsToAssign: {
      courseId: number | null
      format: COURSE_FILTER_FORMATS
      packageId?: number
      dateAvailable: Date
      dueDate: Date | string
      scheduleId?: number
      code: string
      allowSkipToTest?: boolean
    }[]
    allowDuplicates?: boolean
    permission: AssignmentUserPermissions
    confirmation?: ConfirmationScheduleData
  }
}

export namespace OverrideTestScoreDTO {
  export interface Request {
    notes: string
    date?: Date
  }
}

export namespace UpdateAssignmentDTO {
  export interface Request {
    dateAvailable: Date
    dueDate: Date | string
    scheduleId?: number
  }
}

export namespace DeleteUserAssignmentDTO {
  export interface Request {
    permission:
      | CalendarPermissions.REMOVE_PARTICIPANTS
      | UserManagementPermissions.REMOVE_ASSIGNMENT
  }
}

export namespace GetTranscriptDTO {
  export interface Request {
    dateFrom?: Date
    dateTo?: Date
  }
}

export enum ScheduleAssignmentsSortBy {
  ID = '"Assignments"."id"',
  USER = 'user',
  FACILITY = '"user->facility"."name"',
  POSITION = '"user->position"."name"',
  SIGN_IN_TIME = '"Assignments"."startDate"',
  TEST_SCORE = '"Assignments"."competencyTestScore"',
  STATUS = '"Assignments"."status"',
  COMPLETED = '"Assignments"."completedCoursesCount"',
}

export namespace GetScheduleAssignmentsDTO {
  export interface Request extends PaginationRequest {
    sortBy?: ScheduleAssignmentsSortBy
    status?: ASSIGNMENT_STATUS
    scheduleId: number
    startDate: string
  }

  export interface Response extends PaginatedBaseResponse {
    assignments: TAssignment[]
  }
}

export namespace ReleaseTestDTO {
  export interface Request {
    startDate: string
    scheduleId: number
    courseId: number
  }
}

export namespace UploadWrittenTestDTO {
  export interface Request {
    questionsCount: number
    correctAnswersCount: number
    file: File
  }

  export interface Response {
    assignment: TAssignment
    testFile: TAttachment
  }
}

export enum AnnualEducationOverdueSortBy {
  ID = 'userId',
  USER = 'user',
  OVERDUE = 'daysOverdue',
}

export namespace GetAnnualEducationOverdueDTO {
  export interface Request extends PaginationRequest {
    sortBy?: AnnualEducationOverdueSortBy
    facilityId?: number
    groupId?: number
    includeAgency?: boolean
  }
  export interface Response extends PaginatedBaseResponse {
    users: {
      daysOverdue: number
      id: number
      firstName: string
      lastName: string
      position: string | null
      facilityName: string | null
      departments: string[] | null
    }[]
    count: number
  }
}
