import {
  CREDENTIALS_TYPE,
  TDepartment,
  TUser,
  TUserCredentials,
} from '../models'
import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import {
  CalendarPermissions,
  CommunicationPermissions,
  CoursesPermissions,
  FacilityManagementPermissions,
  ReportingPermissions,
  USER_COURSE_STATUSES,
  USER_REGISTERED_STATUSES,
  USER_STATUSES,
  USER_VERIFICATION_STATUS,
  UserManagementPermissions,
} from '../lib/types'
import { NewUsersStatuses } from '../../pages/DashboardAnalytics/types'

const CommonSortBy = {
  FACILITY_NAME: '"User->facility"."name"',
  AGENCY_NAME: '"User->agency"."name"',
  POSITION_NAME: '"User->position"."name"',
  ID: '"User"."id"',
  USER: 'user',
  REGISTERED: '"User"."isSignedUp"',
  STATUS: '"User"."status"',
}

export const UsersSortBy = {
  ...CommonSortBy,
  EMPLOYEE_ID: '"User"."employeeId"',
  COURSE_STATUS: '"User"."courseStatus"',
  HIRE_DATE: '"User"."hireDate"',
  REGISTRATION_DATE: '"User"."signupDate"',
  LASTNAME: '"User"."lastName"',
  COURSES_NOT_STARTED: '"User->reportData"."coursesNotStarted"',
  COURSES_IN_PROGRESS: '"User->reportData"."coursesInProgress"',
  COURSES_COMPLETED_IN_PAST_12_MONTHS:
    '"User->reportData"."coursesCompletedInPast12Months"',
  COURSES_OVERDUE: '"User->reportData"."coursesOverdue"',
  VERIFICATION_DATE: '"User"."verificationDate"',
  VERIFICATION_STATUS: '"User"."verificationStatus"',
  CREATION_DATE: '"User"."createdAt"',
  ONBOARDING_DATE: '"User"."onboardingDate"',
  FACILITY_TOUR_DATE: '"User"."facilityTourDate"',
}

export const UsersSearchSortBy = {
  ...CommonSortBy,
  COMPANY_NAME: '"User->company"."name"',
}

export type UsersSortBy = (typeof UsersSortBy)[keyof typeof UsersSortBy]
export type UsersSearchSortBy =
  (typeof UsersSearchSortBy)[keyof typeof UsersSearchSortBy]

export type UserPermissions =
  | UserManagementPermissions.VIEW_USER_LISTING
  | UserManagementPermissions.VIEW_USER_CREATION_LOG
  | FacilityManagementPermissions.VIEW_FACILITY_EMPLOYEES
  | CommunicationPermissions.EDIT_NOTIFICATIONS
  | CommunicationPermissions.CREATE_CLONE_NOTIFICATIONS
  | CommunicationPermissions.CREATE_CLONE_SURVEY
  | CommunicationPermissions.EDIT_SURVEY
  | UserManagementPermissions.ASSIGN_COURSE_TO_USER
  | CoursesPermissions.ASSIGN_COURSE
  | CalendarPermissions.ADD_PARTICIPANTS
  | ReportingPermissions.VIEW_USER_REPORT
  | ReportingPermissions.VIEW_NEW_USERS_REPORT

export namespace GetUsersDTO {
  export interface Request extends PaginationRequest {
    groupIds?: number[]
    facilityIds?: number[]
    departmentIds?: number[]
    positionIds?: number[]
    sortBy?: UsersSortBy
    key?: string
    status?: USER_STATUSES[]
    registeredStatuses?: USER_REGISTERED_STATUSES[]
    courseStatus?: USER_COURSE_STATUSES
    permission?: UserPermissions
    includeAgencyEmployees?: boolean
    userCreationLog?: boolean
    verificationStatus?: USER_VERIFICATION_STATUS
    creationDate?: Date[]
    verificationDate?: Date[]
    hireDate?: Date[]
  }
  export interface Response extends PaginatedBaseResponse {
    users: TUser[]
  }
}

export type TempUserData = {
  id: number
  userId: number
  employeeId?: number
  firstName: string
  lastName: string
  facilityId?: number
  facility?: {
    id: number
    name: string
  }
  positionId?: number
  rawPosition?: string
  position?: {
    id: number
    title: string
    name: string
  }
  department?: TDepartment
  status: USER_STATUSES
  dob?: Date
  hireDate?: Date
  gender?: string
  employeeType?: string
  state?: string
  country: string
  departmentId?: number
  emails?: string[]
  phones?: string[]
}

export namespace GetHostedDTO {
  export type Response = TempUserData
}

export enum MATCH_USER_ACCOUNT_ACTION {
  APPROVE = 'approve',
  REJECT = 'reject',
}

export namespace MatchUserAccountDTO {
  export interface Request {
    action: MATCH_USER_ACCOUNT_ACTION
  }
  export type Response = TUser
}

export namespace EditUserDTO {
  export interface Request {
    firstName?: string
    lastName?: string
    positionId?: number
    facilityId?: number
    departmentId?: number
    dob?: string
    email?: string
    phone?: string
  }
  export type Response = TUser
}

export namespace SearchUsersDTO {
  export interface Request extends PaginationRequest {
    sortBy?: UsersSearchSortBy
    key: string
  }
  export interface Response extends PaginatedBaseResponse {
    users: TUser[]
  }
}

export interface ConfirmationScheduleData {
  onboardingDate?: Date
  facilityTourScheduleId?: number
  facilityTourDate?: Date
  facilityTourStartTime?: Date
  facilityTourEndTime?: Date
}

export namespace CreateUserDto {
  export interface Request {
    user: {
      firstName: string
      lastName: string
      positionId: number
      facilityId: number
      departmentId: number
      dob: string
      email: string
      phone: string
    }
    packages?: {
      data?: number[]
      expirationDate?: string
    }
    confirmation?: ConfirmationScheduleData
  }
  export interface Response {
    user: TUser
  }
}

export namespace CheckUserCredentialDTO {
  export interface Request {
    email?: string
    phone?: string
    credentialType: CREDENTIALS_TYPE
  }
}

export namespace GetEditRequestFieldsDto {
  export interface Response {
    fields: { field_name: string; current_value: string | null }[]
  }
}

export namespace PostEditRequestFieldsDto {
  export interface Request {
    field_name: string
    field_value: string | number
  }
}

export namespace UpdateUserCredentialDto {
  export interface Request {
    credentialType: CREDENTIALS_TYPE
    phone?: string
    email?: string
    id?: number
  }

  export interface Response {
    credential: TUserCredentials
  }
}

export namespace ChangeUserPasswordDTO {
  export interface Request {
    currentPassword: string
    newPassword: string
    confirmPassword: string
  }
  export interface Response extends TUser {}
}

export namespace PatchUserRoleUpdateDTO {
  export interface Request {
    roleId: number
  }
  export interface Response extends TUser {}
}

export namespace GetUsersCourseStatusAnalyticsDTO {
  export interface Request {
    facilityId?: number
    groupId?: number
    includeAgency?: boolean
  }
  export interface Response {
    data: {
      [courseStatus in USER_COURSE_STATUSES]: number
    }
  }
}

export namespace GetAnalyticsForLast30DaysDTO {
  export interface Request {
    facilityId?: number
    groupId?: number
    includeAgency?: boolean
  }
  export interface Response {
    usersPendingCompletion: number
    coursesCompleted: number
  }
}

export namespace GetUserStatusAnalyticsDTO {
  export interface Request {
    facilityId?: number
    groupId?: number
    includeAgency?: boolean
  }
  export interface Response {
    [NewUsersStatuses.NOT_REGISTERED]: {
      count: number
      percent: number
    }
    [NewUsersStatuses.NOT_STARTED_ONBOARDING]: {
      count: number
      percent: number
    }
    [NewUsersStatuses.ONBOARDING_IN_PROGRESS]: {
      count: number
      percent: number
    }
    [NewUsersStatuses.ONBOARDING_COMPLETE]: {
      count: number
      percent: number
    }
    [NewUsersStatuses.ONBOARDING_EXPIRED]: {
      count: number
      percent: number
    }
    totalUsersCount: number
  }
}
