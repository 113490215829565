import React, { useCallback, useState } from 'react'
import {
  MediaModalDetails,
  AttachmentsTypesEnum,
  TAttachment,
  AttachmentsMimeTypeEnum,
} from '../../sharedTypes'
import { Col, Row, Card, Button } from 'reactstrap'
import SelectFromMediaLibraryModal from '../Modals/MediaModal'
import formatBytes from '../../utils/formatBytes'
import { Link } from 'react-router-dom'

const Preview = ({
  attachment,
  onDelete,
}: {
  attachment: TAttachment
  onDelete: () => void
}) => {
  const getIcon = (type: any) => {
    if (type === AttachmentsMimeTypeEnum.AUDIO_MP3) {
      return 'ri-play-line'
    } else if (type === AttachmentsMimeTypeEnum.VIDEO_MP4) {
      return 'ri-film-line'
    } else {
      return 'ri-file-list-3-lin'
    }
  }

  return (
    <Row>
      <Col sm='12'>
        <ul className='list-unstyled mb-0' id='dropzone-preview'>
          <Card
            className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
            key={'-file'}
          >
            <div className='p-2'>
              <Row className='align-items-center'>
                <Col className='col-auto'>
                  <div className='avatar-sm flex-shrink-0'>
                    <span className='avatar-title rounded fs-3 image-container'>
                      <i className={getIcon(attachment.mimetype)}></i>
                    </span>
                  </div>
                </Col>
                <Col>
                  <Link to='#' className='text-muted font-weight-bold'>
                    {attachment?.name}
                  </Link>
                  <p className='mb-0'>
                    <strong>{formatBytes(attachment.size)}</strong>
                  </p>
                </Col>
                <Col className='d-flex justify-content-end align-items-end'>
                  <Button color='danger' onClick={() => onDelete()}>
                    {' '}
                    Delete{' '}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </ul>
      </Col>

      {attachment.mimetype === AttachmentsMimeTypeEnum.VIDEO_MP4 && (
        <Col sm='12'>
          <Card
            className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
            key={'-file'}
          >
            <div className='p-2'>
              <Row className='align-items-center'>
                <video
                  controls
                  className='w-100'
                  onLoadedMetadata={event => {}}
                  src={attachment.path}
                />
              </Row>
            </div>
          </Card>
        </Col>
      )}
    </Row>
  )
}

const MediaLiveCourse = ({
  attachments,
  onAdd,
  onDelete,
  viewOnly,
}: {
  attachments: TAttachment[]
  viewOnly?: boolean
  onAdd: (attachmentId: number) => void
  onDelete: (attachmentId: number) => void
}) => {
  const [mediaModalDetails, setMediaModalDetails] = useState<MediaModalDetails>(
    {
      isOpen: false,
      mediaProperty: 'cover',
      type: [],
    },
  )

  const onAddMedia = useCallback(() => {
    setMediaModalDetails({
      isOpen: true,
      mediaProperty: 'cover',
      type: [],
      mimetype: [
        AttachmentsMimeTypeEnum.PPT,
        AttachmentsMimeTypeEnum.PDF,
        AttachmentsMimeTypeEnum.VIDEO_MP4,
        AttachmentsMimeTypeEnum.AUDIO_MP3,
      ],
    })
  }, [])
  return (
    <Row className='d-flex align-items-center'>
      {attachments.map((a, i) => (
        <Col key={i} sm='6'>
          <Preview attachment={a} onDelete={() => onDelete(a.id)} />
        </Col>
      ))}
      <Col sm='6'>
        <Button
          color='ghost-primary'
          className='text-light-purple'
          onClick={onAddMedia}
          disabled={viewOnly}
        >
          Select Media
        </Button>
        <SelectFromMediaLibraryModal
          isOpen={mediaModalDetails.isOpen}
          type={mediaModalDetails.type}
          mimetype={mediaModalDetails.mimetype}
          handleUploadSelectedMedia={({ id }) => {
            onAdd(id)
          }}
          onClose={() => {
            setMediaModalDetails(prev => ({
              ...prev,
              isOpen: false,
            }))
          }}
        />
      </Col>
    </Row>
  )
}

export default MediaLiveCourse
