import React, { useCallback, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  Col,
  Card,
  Row,
  FormFeedback,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  DocumentModalInitialValues,
  DocumentModalSubmitedValues,
  StatesEnum,
  StatesOptionsWithKeys,
} from '../../../sharedTypes'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import { FormikHelpers } from 'formik'
import Select from 'react-select'
import { Spinner } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import handleAcceptedFile from '../../../utils/handleAcceptedFile'
import { getCoursePositions } from '../../../helpers/api/coursePositions'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import { CoursePosition } from '../../../sharedTypes/api/coursePositions'
import _ from 'lodash'
import {
  extractDataFromDocument,
  getLicenseTypes,
} from '../../../helpers/api_helper'
import { handleError } from '../../../helpers/toast_helper'
import Loader from '../../../Components/Common/Loader'

export enum DOCUMENT_TYPES {
  CPR_CARD = 'CPR Card',
  LICENSE = 'License',
  OTHER = 'Other',
}

interface DocumentModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (
    values: DocumentModalSubmitedValues,
    formikHelpers: FormikHelpers<DocumentModalSubmitedValues>,
  ) => void
  title: string
  initialValues: DocumentModalInitialValues | null
}

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB

type ModalOpenTypes = 'manual' | 'extractData' | null

const DocumentModal = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  initialValues,
}: DocumentModalProps) => {
  const [disciplineList, setDisciplineList] = useState<CoursePosition[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [modalOpenType, setModalOpenType] = useState<ModalOpenTypes>(
    initialValues?.id ? 'extractData' : null,
  )

  const fetchDisciplines = () => {
    return getCoursePositions()
      .then(res => {
        setDisciplineList(res.coursePositions)
        return res.coursePositions
          .filter(position => position.name !== 'All Staff')
          .map(position => ({
            value: position.id,
            label: position.name,
          }))
      })
      .catch(() => [])
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: 0,
      type: '',
      licenseType: '',
      documentId: '',
      disciplineIds: [],
      state: undefined,
      organization: undefined,
      validDate: undefined,
      expiryDate: undefined,
      document: undefined,
    },
    validationSchema: Yup.object({
      type: Yup.string().required('Document Type is required'),
      licenseType: Yup.object()
        .nullable()
        .when('type', {
          is: (val: DOCUMENT_TYPES) => {
            return val == DOCUMENT_TYPES.LICENSE
          },
          then: schema => schema.required('License Type is required'),
          otherwise: schema => schema.optional(),
        }),
      state: Yup.object(),
      documentId: Yup.string().nullable(),
      organization: Yup.string().nullable(),
      document: Yup.mixed().nullable(),
      validDate: Yup.date().nullable().required('Valid Date is required'),
      expiryDate: Yup.date().nullable().required('Expiry Date is required'),
    }),
    onSubmit: (values, formik) => {
      onSubmit(
        values as DocumentModalSubmitedValues,
        formik as FormikHelpers<DocumentModalSubmitedValues>,
      )
    },
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  const fetchLicenseTypes = useCallback(() => {
    return getLicenseTypes()
      .then(res => {
        return res.licenseTypes.map(licenseType => {
          return {
            label: licenseType,
            value: licenseType,
          }
        })
      })
      .catch(() => [])
  }, [form.values.licenseType])

  const onDocumentUpload = (acceptedFiles: File[]) => {
    setIsLoading(true)
    const modalOpenTypeValue = !modalOpenType ? 'extractData' : modalOpenType
    if (!modalOpenType) {
      setModalOpenType(modalOpenTypeValue)
    }

    if (modalOpenTypeValue === 'manual') {
      handleAcceptedFile(acceptedFiles, 'document', form.setFieldValue)
      setIsLoading(false)
    } else {
      const formData = new FormData()
      formData.append('document', acceptedFiles[0])

      extractDataFromDocument(formData)
        .then(res => {
          form.setFieldValue('documentId', res.documentId || '')
          form.setFieldValue('type', res.documentType)
          form.setFieldValue(
            'expiryDate',
            res.expirationDate
              ? moment(res.expirationDate, 'YYYY-MM-DD').toDate()
              : undefined,
          )
          form.setFieldValue(
            'validDate',
            res.issueDate
              ? moment(res.issueDate, 'YYYY-MM-DD').toDate()
              : undefined,
          )
          form.setFieldValue('organization', res.organization || '')
          if (res.state && res.state !== '') {
            form.setFieldValue('state', {
              label: _.get(StatesEnum, res.state, res.state),
              value: res.state,
            })
          } else {
            form.setFieldValue('state', undefined)
          }

          handleAcceptedFile(acceptedFiles, 'document', form.setFieldValue)
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>

        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <div>
              <label htmlFor='title' className='form-label'>
                Document
              </label>
              {(!form.values.document || form.values.id) && !isLoading && (
                <div className='cursor-pointer'>
                  <Dropzone
                    maxFiles={1}
                    disabled={form.isSubmitting}
                    multiple={false}
                    accept={{
                      'image/jpeg': [],
                      'image/jpg': [],
                      'image/png': [],
                      'application/pdf': [],
                    }}
                    maxSize={MAX_FILE_SIZE}
                    onDrop={acceptedFiles => {
                      onDocumentUpload(acceptedFiles)
                    }}
                  >
                    {({ getRootProps }) => (
                      <div className='dropzone dz-clickable'>
                        <div
                          className='dz-message needsclick'
                          {...getRootProps()}
                        >
                          <div className='mt-2'>
                            <i className='display-7 text-muted ri-upload-cloud-2-fill' />
                          </div>
                          <p className='m-0 text-muted fs-16'>
                            Drop files here or click to upload.<br></br>
                            (pdf, jpg, png)
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>{' '}
                </div>
              )}
              {isLoading && <Loader />}
              {form.errors.document ? (
                <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                  {(form.errors.document as string) || ''}
                </p>
              ) : null}
              {form.values.document && (
                <ul className='list-unstyled mb-0' id='dropzone-preview'>
                  <Card
                    className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                    key={'-file'}
                  >
                    <div className='p-2'>
                      <Row className='align-items-center'>
                        <Col>
                          <Link to='#' className='text-muted font-weight-bold'>
                            {form.values.document.name}
                          </Link>
                          <p className='mb-0'>
                            <strong>
                              {form.values.document.formattedSize}
                            </strong>
                          </p>
                        </Col>
                        <Col className='d-flex justify-content-end align-items-end gap-3'>
                          <Button
                            color='soft-primary'
                            disabled={isLoading}
                            onClick={() => {
                              window.open(
                                form.values.document?.preview,
                                '_blank',
                              )
                            }}
                          >
                            Preview
                          </Button>
                          {!form.values.id && (
                            <Button
                              color='danger'
                              disabled={isLoading}
                              onClick={() => {
                                form.setFieldValue('document', null)
                              }}
                            >
                              {' '}
                              Delete{' '}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </ul>
              )}
            </div>

            {!modalOpenType && (
              <Button
                color='soft-primary'
                onClick={() => setModalOpenType('manual')}
              >
                Enter document details manually
              </Button>
            )}

            {(modalOpenType === 'manual' ||
              (modalOpenType === 'extractData' && !isLoading)) && (
              <>
                <div>
                  <label htmlFor='title' className='form-label'>
                    Document Type*
                  </label>
                  <Select
                    name='type'
                    id='type'
                    isSearchable={false}
                    placeholder='Select a type'
                    onChange={option => {
                      if (option) {
                        form.setFieldValue('type', option.label)
                      }
                    }}
                    onBlur={form.handleBlur}
                    value={Object.values(DOCUMENT_TYPES)
                      .map(item => ({
                        value: item,
                        label: item,
                      }))
                      .find(o => o.value === form.values.type)}
                    options={Object.values(DOCUMENT_TYPES).map(item => ({
                      value: item,
                      label: item,
                    }))}
                    className='select2-container is-invalid'
                    classNamePrefix='select2-selection form-select'
                  />
                  {form.touched.type && form.errors.type ? (
                    <FormFeedback type='invalid'>
                      {form.errors.type}
                    </FormFeedback>
                  ) : null}
                </div>
                {form.values.type === DOCUMENT_TYPES.LICENSE && (
                  <div>
                    <label htmlFor='licenseType' className='form-label'>
                      License Type*
                    </label>
                    <AsyncSelectWithSearch
                      name='licenseType'
                      id='licenseType'
                      onChange={option => {
                        form.setFieldValue('licenseType', option)
                      }}
                      onBlur={form.handleBlur}
                      value={form.values.licenseType}
                      isMulti={false}
                      isClearable={false}
                      isSearchable={false}
                      placeholder={'Select license type'}
                      defaultOptions
                      loadOptions={fetchLicenseTypes}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                    />
                    {form.touched.licenseType && form.errors.licenseType ? (
                      <FormFeedback type='invalid' className='d-block'>
                        {form.errors.licenseType}
                      </FormFeedback>
                    ) : null}
                  </div>
                )}

                <div className='row g-3'>
                  <Col xxl={6}>
                    <label htmlFor='documentId' className='form-label'>
                      Document ID
                    </label>
                    <Input
                      name='documentId'
                      className='form-control'
                      id='documentId'
                      disabled={form.isSubmitting}
                      placeholder='ID 000000'
                      type='text'
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      value={form.values.documentId || ''}
                      invalid={
                        !!(form.touched.documentId && form.errors.documentId)
                      }
                    />
                    {form.touched.documentId && form.errors.documentId ? (
                      <FormFeedback type='invalid'>
                        {form.errors.documentId as string}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col xxl={6}>
                    <label htmlFor='organization' className='form-label'>
                      Organization
                    </label>
                    <Input
                      name='organization'
                      className='form-control'
                      id='organization'
                      disabled={form.isSubmitting}
                      placeholder=''
                      type='text'
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      value={form.values.organization || ''}
                      invalid={
                        !!(
                          form.touched.organization && form.errors.organization
                        )
                      }
                    />
                    {form.touched.organization && form.errors.organization ? (
                      <FormFeedback type='invalid'>
                        {form.errors.organization as string}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </div>

                <div className='row g-3'>
                  <Col xxl={6}>
                    <label htmlFor='startDate'>Valid Date*</label>
                    <div className='input-group is-invalid'>
                      <div className='form-icon right'>
                        <Flatpickr
                          className='form-control form-control-icon'
                          id='validDate'
                          name='validDate'
                          placeholder='mm/dd/yyyy'
                          onChange={option => {
                            form.setFieldValue('validDate', option[0])
                          }}
                          value={form.values.validDate}
                          options={{
                            dateFormat: 'd-m-Y',
                            formatDate: date =>
                              moment(date).format('MM/DD/YYYY'),
                            maxDate: form.values.expiryDate
                              ? form.values.expiryDate
                              : undefined,
                          }}
                        />
                        <i className='ri-calendar-2-line fs-20 text-primary'></i>
                      </div>
                    </div>
                    {form.touched.validDate && form.errors.validDate ? (
                      <FormFeedback type='invalid'>
                        {form.errors.validDate as string}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col xxl={6}>
                    <label htmlFor='endDate'>Expiry Date*</label>
                    <div className='input-group is-invalid'>
                      <div className='form-icon right'>
                        <Flatpickr
                          className='form-control form-control-icon'
                          id='expiryDate'
                          name='expiryDate'
                          placeholder='mm/dd/yyyy'
                          onChange={option => {
                            form.setFieldValue('expiryDate', option[0])
                          }}
                          value={form.values.expiryDate}
                          options={{
                            dateFormat: 'd-m-Y',
                            formatDate: date =>
                              moment(date).format('MM/DD/YYYY'),
                            minDate: form.values.validDate
                              ? form.values.validDate
                              : undefined,
                          }}
                        />
                        <i className='ri-calendar-2-line fs-20 text-primary'></i>
                      </div>
                    </div>
                    {form.touched.expiryDate && form.errors.expiryDate ? (
                      <FormFeedback type='invalid'>
                        {form.errors.expiryDate as string}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </div>

                <Col>
                  <label htmlFor='state' className='form-label'>
                    State
                  </label>
                  <Select
                    isSearchable={false}
                    onChange={option => {
                      form.setFieldValue('state', option)
                    }}
                    value={form.values.state}
                    options={StatesOptionsWithKeys}
                    name='state'
                    id='state'
                    placeholder='Select state'
                    onBlur={form.handleBlur}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: '0px 4px 4px 0px',
                        minHeight: 39,
                      }),
                    }}
                    className='select2-container w-100'
                    classNamePrefix='select2-selection form-select'
                    isDisabled={form.isSubmitting}
                  />
                  {form.touched.state && form.errors.state ? (
                    <FormFeedback type='invalid'>
                      {form.errors.state as string}
                    </FormFeedback>
                  ) : null}
                </Col>

                <Col>
                  <div>
                    <label htmlFor='tutorials' className='form-label'>
                      Disciplines
                    </label>
                    <AsyncSelectWithSearch
                      name='disciplines'
                      id='disciplines'
                      isMulti={true}
                      isClearable={false}
                      loadOptions={fetchDisciplines}
                      isSearchable={false}
                      placeholder='Select disciplines'
                      defaultOptions
                      onChange={option => {
                        form.setFieldValue(
                          'disciplineIds',
                          _.isEmpty(option) ? [] : _.map(option, 'value'),
                        )
                      }}
                      onBlur={form.handleBlur}
                      value={form.values.disciplineIds.map(id => ({
                        value: id,
                        label:
                          disciplineList.find(p => p.id === id)?.name || '',
                      }))}
                      className='select2-container is-invalid'
                      classNamePrefix='select2-selection form-select'
                    />
                  </div>
                </Col>
              </>
            )}

            <div className='hstack gap-2 justify-content-end mt-4'>
              <Button
                className='btn-soft-primary'
                onClick={beforeClose}
                active={false}
                disabled={form.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                color='success'
                type='submit'
                disabled={!form.dirty || form.isSubmitting}
              >
                {form.isSubmitting ? (
                  <Spinner size={'sm'} />
                ) : form.values.id ? (
                  'Update'
                ) : (
                  'Upload'
                )}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default DocumentModal
