import React, { Fragment, useMemo } from 'react'
import masteredLogo from '../../../assets/images/logo.png'
import { Table } from 'reactstrap'
import {
  TUser,
  USER_REGISTERED_STATUSES,
  USER_STATUSES,
} from '../../../sharedTypes'
import {
  getUserDisplayName,
  truncateTextToFitWidth,
} from '../../../helpers/user'
import moment from 'moment'
import {
  COURSES_COMPLETED,
  COURSES_IN_PROGRESS,
  COURSES_NOT_STARTED,
  COURSES_OVERDUE,
  SelectedFilters,
} from './types'

export interface IColumn {
  name: string
  style?: React.HTMLProps<HTMLTableColElement>['style']
}

const Columns: IColumn[] = [
  {
    name: 'ID',
    style: { width: 80 },
  },
  {
    name: 'User',
    style: { width: 220 },
  },
  {
    name: 'Department',
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    name: 'Registration Date',
    style: { width: 50 },
  },
  {
    name: 'Courses \n Not Started',
    style: { width: 140, whiteSpace: 'pre-line' },
  },
  {
    name: `Courses \n In Progress`,
    style: { width: 140, whiteSpace: 'pre-line' },
  },
  {
    name: 'Courses Completed',
    style: { width: 140, whiteSpace: 'pre-line' },
  },
  {
    name: 'Courses \n Overdue',
    style: { width: 100, whiteSpace: 'pre-line' },
  },
  {
    name: 'Status',
    style: { width: 150 },
  },
  {
    name: 'Registered',
    style: { width: 70 },
  },
]

interface UserReportSelectedFilters extends SelectedFilters {
  hireDate?: Date[]
  signupDate?: Date[]
  status?: USER_STATUSES[]
  coursesNotStarted?: COURSES_NOT_STARTED[]
  coursesInProgress?: COURSES_IN_PROGRESS[]
  coursesCompleted?: COURSES_COMPLETED[]
  coursesOverdue?: COURSES_OVERDUE[]
  registeredStatuses?: USER_REGISTERED_STATUSES[]
}

interface ExportUserReportProps {
  userReport: TUser[]
  page: number
  selectedFilters: UserReportSelectedFilters
}

const ExportUserReportPdf = ({
  userReport,
  page,
  selectedFilters,
}: ExportUserReportProps) => {
  const shouldRenderFilters = useMemo(() => {
    return Object.values(selectedFilters).some(filter => {
      if (Array.isArray(filter)) {
        return filter.length > 0
      }
      return true
    })
  }, [selectedFilters])

  const filtersData = useMemo(() => {
    return [
      {
        label: 'Position',
        data: selectedFilters.positions,
        key: 'position',
      },
      {
        label: 'Department',
        data: selectedFilters.departments,
        key: 'department',
      },
      {
        label: 'Hire Date',
        data: selectedFilters.hireDate || [],
        key: 'hireDate',
      },
      {
        label: 'Registration Date',
        data: selectedFilters.signupDate || [],
        key: 'signupDate',
      },
      {
        label: 'Active Status',
        data: selectedFilters.status || [],
        key: 'status',
      },
      {
        label: 'Courses Not Started',
        data: selectedFilters.coursesNotStarted || [],
        key: 'coursesNotStarted',
      },
      {
        label: 'Courses In Progress',
        data: selectedFilters.coursesInProgress || [],
        key: 'coursesInProgress',
      },
      {
        label: 'Courses Completed',
        data: selectedFilters.coursesCompleted || [],
        key: 'coursesCompleted',
      },
      {
        label: 'Courses Overdue',
        data: selectedFilters.coursesOverdue || [],
        key: 'coursesOverdue',
      },
      {
        label: 'RegisteredStatus',
        data: selectedFilters.registeredStatuses || [],
        key: 'registered',
      },
    ]
  }, [selectedFilters])

  return (
    <div id='user-report-pdf w-100'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex gap-3 align-items-center'>
          <h1 className='text-body'>User Report</h1>
          <h2 className='text-body'>(Page {page})</h2>
        </div>
        <div>
          <img
            className='card-img-top img-fluid w-100'
            src={masteredLogo}
            alt='Mastered'
          />
        </div>
      </div>
      <div className='mt-2'>
        <h2>{(selectedFilters.facilities || ['All']).join(', ')}</h2>
      </div>
      {page === 1 && (
        <>
          {shouldRenderFilters ? (
            <div className='mt-3'>
              <h2 className='fw-normal text-muted'>Filters Selected</h2>
            </div>
          ) : (
            ''
          )}

          <div className='d-flex flex-wrap gap-2 mb-2 mt-1'>
            {filtersData.map(filterItem => (
              <Fragment key={filterItem.key}>
                {filterItem.data.length > 0 && (
                  <>
                    {['hireDate', 'signupDate'].includes(filterItem.key) &&
                    filterItem.data.length > 1 ? (
                      <>
                        <h3 className='fw-normal text-muted'>
                          {filterItem.key === 'hireDate'
                            ? 'Hire '
                            : 'Registration '}{' '}
                          Date:
                        </h3>
                        <h3 className='text-body'>
                          {moment(filterItem.data[0]).format('MM/DD/YYYY')}
                        </h3>
                        {filterItem.data[1] && (
                          <h3 className='text-body'>
                            {' '}
                            - {moment(filterItem.data[1]).format('MM/DD/YYYY')}
                          </h3>
                        )}
                      </>
                    ) : (
                      <>
                        <h3 className='fw-normal text-muted text-capitalize'>
                          {filterItem.label}:
                        </h3>
                        <h3 className='text-body'>
                          {filterItem.data.join(', ')}
                        </h3>
                      </>
                    )}
                    <h3 className='text-muted filter-separator'>|</h3>
                  </>
                )}
              </Fragment>
            ))}
          </div>
        </>
      )}
      <div className='table-card mt-5'>
        <Table className='align-middle mb-0' id={'user-report'}>
          <thead className='table-light'>
            <tr className='text-muted fs-3'>
              {Columns.map(column => (
                <th
                  scope='col'
                  className='fw-light align-middle'
                  style={column.style}
                  key={column.name}
                >
                  <div className='d-flex align-items-center gap-1'>
                    <span>{column.name}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {userReport.map(user => (
              <tr key={user.id} className='fs-14 fw-light'>
                <td>
                  <span className='fs-4 text-muted'>{user.id}</span>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='flex-grow-1 ms-2'>
                      <p
                        className='card-title mb-0 fs-4'
                        style={{ overflow: 'hidden' }}
                      >
                        <span>{getUserDisplayName(user, true)}</span>
                        <p className='text-truncate user-info fs-4 text-muted'>
                          {truncateTextToFitWidth(
                            user && user?.position
                              ? user.position?.name ?? ''
                              : '',
                            100,
                          )}
                        </p>
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {user.departments?.[0]?.name || ''}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {user.signupDate
                      ? moment(user.signupDate).format('MM/DD/YYYY')
                      : ''}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {user.reportData?.coursesNotStarted}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {user.reportData?.coursesInProgress}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {user.reportData?.coursesCompletedInPast12Months}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {user.reportData?.coursesOverdue}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted text-capitalize'>
                    {user.courseStatus}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted text-capitalize'>
                    {user.isSignedUp ? 'Yes' : 'No'}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ExportUserReportPdf
