import React, { Fragment, useMemo } from 'react'
import { SelectedFilters } from './types'
import moment from 'moment'
import masteredLogo from '../../../assets/images/logo.png'
import { Table } from 'reactstrap'
import { TAssignment } from '../../../sharedTypes'
import {
  getUserDisplayName,
  truncateTextToFitWidth,
} from '../../../helpers/user'

interface AssignmentsSelectedFilters extends SelectedFilters {
  expiredDate?: Date[]
  dueDate?: Date[]
}

interface CourseHistoryReportProps {
  assignments: TAssignment[]
  page: number
  selectedFilters: AssignmentsSelectedFilters
}

const Columns = [
  {
    name: 'User',
    style: { width: 300 },
  },
  {
    name: 'Department',
  },
  {
    name: 'Agency',
  },
  {
    name: 'Code',
  },
  {
    name: 'Course',
  },
  {
    name: 'Due Date',
    style: { width: 180 },
  },
  {
    name: 'Date Expired',
    style: { width: 180 },
  },
]

const ExportExpiredCourses = ({
  assignments,
  page,
  selectedFilters,
}: CourseHistoryReportProps) => {
  const filtersData = useMemo(() => {
    const data = [
      {
        label: 'Position',
        data: selectedFilters.positions,
        key: 'position',
        render: true,
      },
      {
        label: 'Course',
        data: selectedFilters.courses,
        key: 'course',
        render: true,
      },
      {
        label: 'Department',
        data: selectedFilters.departments,
        key: 'department',
        render: true,
      },
      {
        label: 'Agency',
        data: selectedFilters.agencies || [],
        key: 'agency',
        render: true,
      },
      {
        label: 'Due Date',
        data: selectedFilters.dueDate || [],
        key: 'dueDate',
        render: true,
      },
      {
        label: 'Date Expired',
        data: selectedFilters.expiredDate || [],
        key: 'expiredDate',
        render: true,
      },
    ]

    return data.filter(d => d.render)
  }, [selectedFilters])

  const shouldRenderFilters = useMemo(() => {
    return (
      selectedFilters.positions.length ||
      selectedFilters.courses.length ||
      selectedFilters.departments.length ||
      selectedFilters.expiredDate?.length ||
      selectedFilters.dueDate?.length
    )
  }, [selectedFilters])

  return (
    <div id='expired-courses-report-pdf w-100'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex gap-3 align-items-center'>
          <h1 className='text-body'>Expired Courses Report</h1>
          <h2 className='text-body'>(Page {page})</h2>
        </div>
        <div>
          <img
            className='card-img-top img-fluid w-100'
            src={masteredLogo}
            alt='Mastered'
          />
        </div>
      </div>
      {page === 1 && (
        <>
          {shouldRenderFilters ? (
            <div className='mt-3'>
              <h2 className='fw-normal text-muted'>Filters Selected</h2>
            </div>
          ) : (
            ''
          )}

          <div className='d-flex flex-wrap gap-2 mb-2 mt-1'>
            {filtersData.map(filterItem => (
              <Fragment key={filterItem.key}>
                {filterItem.data.length > 0 && (
                  <>
                    {filterItem.key === 'completedDate' &&
                    filterItem.data.length > 1 ? (
                      <>
                        <h3 className='fw-normal text-muted'>
                          Date Completed:
                        </h3>
                        <h3 className='text-body'>
                          {moment(filterItem.data[0]).format('MM/DD/YYYY')}
                        </h3>
                        {filterItem.data[1] && (
                          <h3 className='text-body'>
                            {' '}
                            - {moment(filterItem.data[1]).format('MM/DD/YYYY')}
                          </h3>
                        )}
                      </>
                    ) : (
                      <>
                        <h3 className='fw-normal text-muted'>
                          {filterItem.label}:
                        </h3>
                        <h3 className='text-body'>
                          {filterItem.data.join(', ')}
                        </h3>
                      </>
                    )}
                    <h3 className='text-muted filter-separator'>|</h3>
                  </>
                )}
              </Fragment>
            ))}
          </div>
        </>
      )}
      <div className='table-card mt-5 courses-table'>
        <Table className='align-middle mb-0' id='expired-courses-section'>
          <thead className='table-light'>
            <tr className='text-muted fs-3'>
              {Columns.map(column => (
                <th
                  scope='col'
                  className='fw-light align-middle'
                  style={column.style}
                  key={column.name}
                >
                  <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='header-name'>{column.name}</span>
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {assignments.map((assignment, i) => (
              <tr key={i} className='fs-14 fw-light'>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='flex-grow-1 ms-2'>
                      <p
                        className='card-title mb-0 fs-4'
                        style={{ overflow: 'hidden' }}
                      >
                        <span>{getUserDisplayName(assignment.user)}</span>
                        <p className='text-truncate user-info fs-4 text-muted'>
                          {assignment.user &&
                            truncateTextToFitWidth(
                              assignment.user.position?.name ?? '',
                              500,
                            )}
                        </p>
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {assignment.user.departments?.[0]?.name || ''}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>
                    {assignment.user.agency ? assignment.user.agency.name : ''}
                  </span>
                </td>
                <td>
                  <span className='fs-4 text-muted'>{assignment.code}</span>
                </td>
                <td>
                  <span className='fw-normal text-muted fs-4'>
                    {assignment.course?.translations[0].content.name || '-'}
                  </span>
                </td>

                <td>
                  <div className='course-progress d-flex gap-1 align-items-center'>
                    <span className='fw-light text-muted fs-4'>
                      {moment(assignment.dueDate).format('MM/DD/YYYY')}
                    </span>
                  </div>
                </td>

                <td>
                  <div className='course-progress d-flex gap-1 align-items-center'>
                    <span className='text-muted fs-4'>
                      {assignment.expiredDate
                        ? moment(assignment.expiredDate).format('MM/DD/YYYY')
                        : ''}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ExportExpiredCourses
