import {
  TUserEmployeeType,
  TUserGender,
  USER_STATUSES,
  USER_COURSE_STATUSES,
  TUserReportData,
  USER_VERIFICATION_STATUS,
} from '../lib/types'
import { TFacility } from './facility'
import { TPosition } from './position'
import { TDepartment } from './department'
import { TRole } from './role'
import { CountriesEnum, StatesEnum } from '../lib/locations'
import { TGroup } from './groups'
import { TCompany } from './company'
import { TAttachment } from './attachments'
import { TAgency } from './agency'
import { TempUserData } from '../api'

export type AdminNotificationSettings = {
  userCourseOverdue: boolean
  courseFeedbackReceived: boolean
  surveyResponseReceived: boolean
  userCourseCompleted: boolean
  courseCreated: boolean
  userCourseRetaking: boolean
  userOnboardedWithoutCourses: boolean
  userScheduleMissed: boolean
}

export enum CREDENTIALS_TYPE {
  EMAIL = 'email',
  PHONE = 'phone',
}

export type TUserCredentials = {
  id: number
  userId: number
  credential: string
  credentialType: CREDENTIALS_TYPE
  isDeleted: boolean
  isVerified: boolean
  lastUsageDate: string
}

export type TUser = {
  id: number
  employeeId: number
  agencyId: number
  agency: null | TAgency
  firstName: string
  lastName: string
  photo: string
  signatureId?: number | null
  signature?: TAttachment | null
  departments?: TDepartment[]
  adminDepartments?: TDepartment[]
  adminFacilities?: TFacility[]
  credentials?: TUserCredentials[]
  facilityId: null | number
  facility: null | TFacility
  groupId: null | number
  group: null | TGroup
  companyId: null | number
  company: null | TCompany
  positionId: null | number
  position: null | TPosition
  rawPosition: null | string
  roleId: null | number
  role: null | TRole
  status: USER_STATUSES
  courseStatus: USER_COURSE_STATUSES
  dob: string
  hireDate: string
  signupDate: Date
  verificationDate: Date | null
  verificationStatus: USER_VERIFICATION_STATUS
  gender: TUserGender | null
  employeeType: TUserEmployeeType
  address: string | null
  country: CountriesEnum
  state: StatesEnum | null
  language: string
  bio: string
  qualification: string
  isInstructor: boolean
  isSignedUp: boolean
  deactivated: boolean
  reportData?: TUserReportData
  hasAccessToMultipleFacilities?: boolean
  isFacilityAdmin?: boolean
  createdAt: string
  updatedAt: string
  onboardingDate: Date
  facilityTourDate: Date
  tempUserData?: TempUserData
}
