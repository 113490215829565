import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  CoursesPermissions,
  GetCourseQuestionsDTO,
  OrderType,
  QuestionableTypes,
  QuestionsSortBy,
} from '../../sharedTypes'
import {
  deletetOnlineCourseQuestion,
  getQuestions,
  updateOnlineCourseQuestionOrder,
} from '../../helpers/api_helper'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../helpers/toast_helper'
import { DropResult, Responders } from 'react-beautiful-dnd'
import { map } from 'lodash'

export interface IUseQuestions {
  setQuery: Dispatch<SetStateAction<GetCourseQuestionsDTO.Request>>
  query: GetCourseQuestionsDTO.Request
  data: GetCourseQuestionsDTO.Response
  handleSort: (column: QuestionsSortBy) => void
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  onReOrder: Responders['onDragEnd']
}

export const useQuestions = (
  courseId: number,
  questionableType: QuestionableTypes,
  shouldFetch: boolean,
  languageId = 1,
  limit = 10,
  permission?: CoursesPermissions.VIEW_TRANSLATIONS,
): IUseQuestions => {
  const [isLoading, setIsLoading] = useState(false)
  const [deleteId, setDeleteId] = useState<null | string>()
  const [data, setData] = useState<GetCourseQuestionsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    questions: [],
  })

  const [query, setQuery] = useState<GetCourseQuestionsDTO.Request>({
    page: 1,
    limit: limit > 0 ? limit : undefined,
    key: null,
    questionableType,
    entityId: courseId,
    languageId,
    sortBy: QuestionsSortBy.ID,
    orderBy: OrderType.ASC,
    permission: permission ?? CoursesPermissions.ADD_EDIT_COURSE,
  })

  useEffect(() => {
    setQuery(q => ({ ...q, entityId: courseId }))
  }, [courseId])

  const onReOrder = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return
      }

      const questions = Array.from(data.questions)

      const [reorderedItem] = questions.splice(result.source.index, 1)

      questions.splice(result.destination.index, 0, reorderedItem)

      const groupIdsOrder = map(questions, 'groupId')

      setData({ ...data, questions })

      try {
        await updateOnlineCourseQuestionOrder({
          groupIdsOrder,
          page: query.page || 1,
          limit: query.limit || 10,
          questionableType,
          entityId: courseId,
          permission: CoursesPermissions.ADD_EDIT_COURSE,
        })
      } catch (e) {}
    },
    [data, questionableType, query],
  )

  const fetchList = useCallback(() => {
    // setIsLoading(true)
    getQuestions(query)
      .then(response => {
        setData(response)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  const handleSort = useCallback((column: QuestionsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onDelete = useCallback(async () => {
    try {
      await deletetOnlineCourseQuestion(courseId, deleteId as string)

      setQuery(prev => ({ ...prev, page: 1 }))
      setDeleteId(null)
      toast(`Successfully deleted`, successToastOptions)
    } catch (e) {}
  }, [courseId, deleteId])

  useEffect(() => {
    if (query.entityId && shouldFetch) {
      fetchList()
    }
  }, [query, courseId, shouldFetch])

  return {
    setQuery,
    query,
    data,
    handleSort,
    setIsLoading,
    isLoading,
    onReOrder,
  }
}
