import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import {
  COURSE_FILTER_FORMATS,
  COURSE_TRANSLATIONS_STATUS,
  COURSE_TYPES,
  CoursesPermissions,
  CoursesTabs,
  StatesEnum,
  TAttachment,
  UserManagementPermissions,
  ViewResultReportEnum,
} from '../../sharedTypes'
import {
  ONLINE_COURSE_STATUS,
  OnlineCourseItem,
  DigitalCourse,
  OnlineCourseFormatsEnum,
} from '../models'
import {
  CoursePackageFormatsEnum,
  CoursePackageStatus,
  TCoursePackage,
} from '../models/coursePackage'
import { PackagesSortBy } from './coursePackages'
import { OnlineProviderItem } from '../models/ceProvider'

export enum OnlineCategoriesSortBy {
  ID = 'ID',
  NAME = 'name',
  COURSES_COUNT = 'coursesCount',
  CODE = 'code',
}

export enum CoursesSortBy {
  CODE = 'code',
  NAME = 'name',
  TYPE = 'type',
  DESCRIPTION = 'description',
  CREDIT_HOURS = 'duration',
  DATE_PUBLISHED = 'publishedDate',
  OWNER = 'owner',
  CATEGORY = 'category.name',
  STATUS = 'status',
  TRANSLATION_STATUS = 'translationStatus',
  UPDATED_AT = 'updatedAt',
}

export enum CourseCodesSortBy {
  BOARD = 'board',
  COURSE_CODE = 'courseCode',
  DISCIPLINES = 'disciplines',
}

export interface OnlineCategoryRequest {
  name: string
  code: string
  description: string
}

export interface CourseCodesItem {
  providerId: number
  provider: OnlineProviderItem
  courseCode: string
  courseId: number
  disciplines: string[]
  id: number
}

export interface OnlineCategoryItem extends OnlineCategoryRequest {
  id: number
  coursesCount: number
}

export namespace GetOnlineCoursesDTO {
  export interface Request extends PaginationRequest {
    sortBy?: CoursesSortBy
    statuses?: ONLINE_COURSE_STATUS[]
    states?: StatesEnum[]
    instructorIds?: number[]
    categoryIds?: number[]
    formats?: COURSE_FILTER_FORMATS[]
    courseFormats?: OnlineCourseFormatsEnum[]
    types?: COURSE_TYPES[]
    notExpired?: boolean
    translationStatus?: COURSE_TRANSLATIONS_STATUS[]
    ftags?: number
    excludeCE?: boolean
  }

  export interface Response extends PaginatedBaseResponse {
    courses: OnlineCourseItem[]
  }
}

export namespace GetCoursesDTO {
  export interface Request extends PaginationRequest {
    activeTab: CoursesTabs
    sortBy?: CoursesSortBy | PackagesSortBy
    states?: StatesEnum[]
    types?: COURSE_TYPES[]
    statuses?: ONLINE_COURSE_STATUS[] | CoursePackageStatus[]
    format?: CoursePackageFormatsEnum
    publishedDate?: Date[]
    positionIds?: number[]
    additionalProps?: string[]
    excludeCE?: boolean
  }
  export interface Response extends PaginatedBaseResponse {
    data: OnlineCourseItem[] | TCoursePackage[]
  }
}

export namespace GetOnlineCategoriesDTO {
  export interface Request extends PaginationRequest {
    sortBy?: OnlineCategoriesSortBy
  }

  export interface Response extends PaginatedBaseResponse {
    online_categories: OnlineCategoryItem[]
  }
}

export namespace CreateOnlineCategoryDTO {
  export interface Request extends OnlineCategoryRequest {}
  export type Response = any
}

export namespace PatchOnlineCourseDTO {
  export type Response = OnlineCourseItem
}

export namespace PutDigitalCourseDTO {
  export type Response = DigitalCourse
  export type Request = {
    completeDays: number
    quizAttempts: number
  }
}

export namespace GetCourseCodeDTO {
  export interface Request extends PaginationRequest {
    sortBy?: CourseCodesSortBy
  }
  export interface Response extends PaginatedBaseResponse {
    courseCodes: CourseCodesItem[]
  }
}
export namespace GetCEDetails {
  export interface Response {
    courseId: number
    audioFileId: number | null
    pdfFileId: number | null
    audioFile?: TAttachment
    pdfFile?: TAttachment
  }
}

export namespace DeleteCEFiles {
  export interface Request {
    audioFileId?: boolean
    pdfFileId?: boolean
  }
}

export namespace PostCourseCodeDTO {
  export interface Request {
    providerId: number
    courseCode: string
    disciplines: string[]
  }
}

export namespace PutCourseCodeDTO {
  export interface Request extends PostCourseCodeDTO.Request {}
}

interface CompetencyMessage {
  languageId: number
  content: {
    success: string
    fail: string
    retake: string
  }
}

export namespace PutCompetencyGeneralCourseDTO {
  export type Response = DigitalCourse
  export type Request = {
    quizQuestionsNumber: number
    randomizeQuestions: boolean
    viewResultReport: ViewResultReportEnum
    messages: CompetencyMessage[]
  }
}

export namespace PostDefaultOnlineCourseDTO {
  export type Response = OnlineCourseItem
  export type Request = {
    name: string
    formats: OnlineCourseFormatsEnum[]
    type: COURSE_TYPES[]
  }
}

export namespace RemovetOnlineCourseFormatDTO {
  export type Response = OnlineCourseItem
  export type Request = {
    format: OnlineCourseFormatsEnum
  }
}
export namespace PublishOnlineCourseDTO {
  export type Response = OnlineCourseItem
}

export namespace PostOnlineCourseFormatDTO {
  export type Response = OnlineCourseItem
  export type Request = {
    format: OnlineCourseFormatsEnum
  }
}

export namespace GetOnlineCourseDTO {
  export type Request = {
    permission:
      | CoursesPermissions.ADD_EDIT_COURSE
      | CoursesPermissions.VIEW_TRANSLATIONS
      | UserManagementPermissions.VIEW_USER_COURSE_HISTORY
  }
  export type Response = OnlineCourseItem
}

export namespace EditOnlineCategoryDTO {
  export interface Request extends OnlineCategoryRequest {}
  export type Response = any
}

export type OnlineCategoryInitialValues = {
  name: string
  code: string
  description: string
}

export type OnlineCategoryModalSubmitedValues = {
  name: string
  code: string
  description: string
}

export namespace CreateCourseRevisionDTO {
  export interface Request {
    expirationDate: string
  }
}
