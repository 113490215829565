import React, { Dispatch, SetStateAction } from 'react'
import { Col } from 'reactstrap'
import _ from 'lodash'

import MultiSelect from '../Common/MultiSelect'
import {
  COURSE_FILTER_FORMATS,
  CourseFiltersFormatsOptions,
  CourseAssignTypesOptions,
} from '../../sharedTypes'
import { FilterOption } from '../../sharedTypes'

export interface IFilters {
  formats?: FilterOption['value'][]
  types?: FilterOption['value'][]
}

interface FilterSelectBoxesProps<FilterObject> {
  onFilterChange: Dispatch<SetStateAction<FilterObject & IFilters>>
  availableFilters: ('format' | 'type' | 'category')[]
  filterOptions?: {
    value: COURSE_FILTER_FORMATS
    label: COURSE_FILTER_FORMATS
  }[]
}
const Filters: <FilterObject>(
  props: FilterSelectBoxesProps<FilterObject>,
) => React.JSX.Element = ({
  onFilterChange,
  availableFilters,
  filterOptions = CourseFiltersFormatsOptions,
}) => {
  return (
    <>
      {!availableFilters ||
        (availableFilters.includes('format') && (
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='format' className='form-label'>
                Filter by format
              </label>

              <MultiSelect
                name='format'
                id='format'
                isMulti
                options={filterOptions}
                isSearchable={true}
                placeholder='Select format'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(option: FilterOption[]) => {
                  onFilterChange(prev => ({
                    ...prev,
                    formats: _.isEmpty(option) ? [] : _.map(option, 'value'),
                  }))
                }}
              />
            </div>
          </Col>
        ))}
      {!availableFilters ||
        (availableFilters.includes('type') && (
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='type' className='form-label'>
                Filter by type
              </label>

              <MultiSelect
                name='type'
                id='type'
                isMulti
                options={CourseAssignTypesOptions}
                isSearchable={true}
                placeholder='Select type'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(option: FilterOption[]) => {
                  onFilterChange(prev => ({
                    ...prev,
                    types: _.isEmpty(option) ? [] : _.map(option, 'value'),
                  }))
                }}
              />
            </div>
          </Col>
        ))}
    </>
  )
}

export default Filters
