import React, { useCallback } from 'react'
import { Button, Col } from 'reactstrap'

import { AsyncSelectWithSearch } from '../../Common/SelectWithSearch'

import {
  getDepartments,
  getFacilities,
  getPositions,
  getUsers,
} from '../../../helpers/api_helper'
import { getUserDisplayName } from '../../../helpers/user'
import {
  GetDepartmentsDTO,
  GetPositionsDTO,
  GetUsersDTO,
  TDepartment,
  TPosition,
  USER_STATUSES,
} from '../../../sharedTypes'
import _ from 'lodash'
import { AllValue, SelectGroup, Step1Props } from './types'

const checkIfNotAll = (array: any) => {
  return (
    !_.isEmpty(array) &&
    (!_.isEqual(array, AllValue) || !_.isEqual(array, [AllValue])) &&
    Array.isArray(array)
  )
}

const AssignCourseStep1 = ({
  handleBlur,
  setFieldValue,
  values,
  onCancel,
  onNext,
  permission,
}: Step1Props) => {
  const fetchFacilities = useCallback(
    (inputValue?: string) => {
      return getFacilities({ key: inputValue })
        .then(res => {
          return [
            AllValue,
            ...res.data.facilities.map(facility => {
              return {
                label: facility.name,
                value: facility.id,
              }
            }),
          ]
        })
        .catch(() => [])
    },
    [values.facilities],
  )

  const fetchDepartments = useCallback(
    (inputValue?: string) => {
      const query: GetDepartmentsDTO.Request = {
        key: inputValue,
      }
      if (
        !_.isEmpty(values.facilities) &&
        (!_.isEqual(values.facilities, AllValue) ||
          !_.isEqual(values.facilities, [AllValue])) &&
        Array.isArray(values.facilities)
      ) {
        query.facilityIds = values.facilities.map(f => f.value)
      }
      return getDepartments(query)
        .then(res => {
          const selectedDepartments = Array.isArray(values.departments)
            ? values.departments.map(position => position.label)
            : []

          const grouped: SelectGroup[] = _.map(
            _.groupBy(res.data.departments, 'name'),
            (group: TDepartment[], key) => {
              return {
                label: key,
                value: group.map(g => g.id),
              }
            },
          ).filter(group => !selectedDepartments.includes(group.label))
          return [AllValue, ...grouped]
        })
        .catch(() => [])
    },
    [values.facilities],
  )

  const fetchPositions = useCallback(
    (inputValue?: string) => {
      const query: GetPositionsDTO.Request = {
        key: inputValue,
      }
      if (
        !_.isEmpty(values.facilities) &&
        (!_.isEqual(values.facilities, AllValue) ||
          !_.isEqual(values.facilities, [AllValue])) &&
        Array.isArray(values.facilities)
      ) {
        query.facilityIds = values.facilities.map(f => f.value)
      }
      return getPositions({ key: inputValue }).then(res => {
        const selectedPositions = Array.isArray(values.positions)
          ? values.positions.map(position => position.label)
          : []

        const grouped: SelectGroup[] = _.map(
          _.groupBy(res.data.positions, 'name'),
          (group: TPosition[], key) => {
            return {
              label: key,
              value: group.map(g => g.id),
            }
          },
        ).filter(group => !selectedPositions.includes(group.label))
        return [AllValue, ...grouped]
      })
    },
    [values.facilities],
  )

  const fetchUsers = useCallback(
    (inputValue?: string) => {
      const params: GetUsersDTO.Request = {
        page: 1,
        limit: 20,
        key: inputValue,
        permission,
        status: [USER_STATUSES.ACTIVE, USER_STATUSES.UNVERIFIED],
      }
      if (
        checkIfNotAll(values.facilities) &&
        Array.isArray(values.facilities)
      ) {
        params.facilityIds = values.facilities.map(f => f.value)
      }
      if (
        checkIfNotAll(values.departments) &&
        Array.isArray(values.departments)
      ) {
        params.departmentIds = _.flattenDeep(
          values.departments.map(f => f.value),
        )
      }
      if (checkIfNotAll(values.positions) && Array.isArray(values.positions)) {
        params.positionIds = _.flattenDeep(values.positions.map(f => f.value))
      }

      return getUsers(params)
        .then(res => {
          const nextPage = res.data.users.map(user => ({
            value: user.id,
            label: getUserDisplayName(user),
          }))
          return [AllValue, ...nextPage]
        })
        .catch(() => [])
    },
    [values.facilities, values.positions, values.departments],
  )

  return (
    <>
      <div className='px-3 py-2'>
        <div className='row gap-4 mb-3 mt-0 mx-0'>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='facilities' className='form-label'>
                Facilities
              </label>
              <AsyncSelectWithSearch
                name='facilities'
                id='facilities'
                onChange={option => {
                  if (_.isEqual(values.facilities, option)) {
                    return
                  }
                  if (_.includes(option, AllValue)) {
                    setFieldValue('assignTo.facilities', AllValue)
                  } else {
                    setFieldValue(
                      'assignTo.facilities',
                      Array.isArray(option) ? option : [option],
                    )
                  }
                  setFieldValue('assignTo.departments', [])
                  setFieldValue('assignTo.positions', [])
                  setFieldValue('assignTo.users', [])
                }}
                onBlur={handleBlur}
                value={values.facilities}
                isMulti={!_.isEqual(values.facilities, AllValue)}
                isClearable={false}
                isSearchable={true}
                placeholder={'Select facilities'}
                defaultOptions
                loadOptions={fetchFacilities}
                closeMenuOnSelect={false}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='departments' className='form-label'>
                Departments
              </label>
              <AsyncSelectWithSearch
                name='departments'
                id='departments'
                onChange={option => {
                  if (_.includes(option, AllValue)) {
                    setFieldValue('assignTo.departments', AllValue)
                  } else {
                    setFieldValue(
                      'assignTo.departments',
                      Array.isArray(option) ? option : [option],
                    )
                  }
                  setFieldValue('assignTo.users', [])
                }}
                onBlur={handleBlur}
                value={values.departments}
                isMulti={!_.isEqual(values.departments, AllValue)}
                isClearable={false}
                isSearchable={true}
                placeholder={'Select departments'}
                defaultOptions
                loadOptions={fetchDepartments}
                key={JSON.stringify(values.facilities)}
                closeMenuOnSelect={false}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </Col>
        </div>
        <div className='row gap-4 mt-3 mt-0 mx-0'>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='facilities' className='form-label'>
                Positions
              </label>
              <AsyncSelectWithSearch
                name='positions'
                id='positions'
                onChange={option => {
                  if (_.includes(option, AllValue)) {
                    setFieldValue('assignTo.positions', AllValue)
                  } else {
                    setFieldValue(
                      'assignTo.positions',
                      Array.isArray(option) ? option : [option],
                    )
                  }
                  setFieldValue('assignTo.users', [])
                }}
                onBlur={handleBlur}
                value={values.positions}
                isMulti={!_.isEqual(values.positions, AllValue)}
                isClearable={false}
                isSearchable={true}
                placeholder={'Select positions'}
                defaultOptions
                loadOptions={fetchPositions}
                key={JSON.stringify(values.facilities)}
                closeMenuOnSelect={false}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='departments' className='form-label'>
                Users
              </label>
              <AsyncSelectWithSearch
                name='users'
                id='users'
                onChange={option => {
                  if (_.includes(option, AllValue)) {
                    setFieldValue('assignTo.users', AllValue)
                  } else {
                    setFieldValue(
                      'assignTo.users',
                      Array.isArray(option) ? option : [option],
                    )
                  }
                }}
                onBlur={handleBlur}
                value={values.users}
                isMulti={!_.isEqual(values.users, AllValue)}
                isClearable={false}
                isSearchable={true}
                placeholder={'Select users'}
                defaultOptions
                loadOptions={fetchUsers}
                closeMenuOnSelect={false}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                /*onMenuScrollToBottom={() => {
                if (users.pages >= users.page) {
                  setUsers({ ...users, page: ++users.page })
                }
              }}*/
                key={JSON.stringify([
                  values.facilities,
                  values.departments,
                  values.positions,
                ])}
              />
            </div>
          </Col>
        </div>
      </div>
      <div className='px-3 py-2 row g-3'>
        <Col lg={12}>
          <div className='hstack gap-2 justify-content-between'>
            <Button
              className='btn-soft-primary align-middle'
              color='secondary'
              onClick={onCancel}
            >
              Back
            </Button>
            <Button className='align-middle' color='primary' onClick={onNext}>
              Next
            </Button>
          </div>
        </Col>
      </div>
    </>
  )
}

export default AssignCourseStep1
