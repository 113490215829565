import ExportConfirmation from '../../../Components/Modals/PrimaryConfirmationModal'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { handleError } from '../../../helpers/toast_helper'
import { EXPORT_TYPES } from '../CourseHistory/types'
import { jsPDF } from 'jspdf'
import { renderToString } from 'react-dom/server'
import html2canvas from 'html2canvas'
import { addImageToPdf, generateXLSOrCSV } from '../../../helpers/export'
import moment from 'moment'
import { GetCompaniesDTO } from '../../../sharedTypes'
import { getCompanies } from '../../../helpers/api/company'
import ExportCompanyReportPdf from './ExportCompanyReportPdf'
import { generateFileName } from '../../../helpers/getExportFileName'

interface ExportCompanyReportProps {
  exportType: EXPORT_TYPES
  setExportType: Dispatch<SetStateAction<EXPORT_TYPES | null>>
  count: number
  query: GetCompaniesDTO.Request
}

const ROWS_PER_PAGE = 15

const fileHeaders = [
  'Company ID',
  'Company Name',
  'Status (Active/Inactive)',
  'Activation Date',
  'Number of Active Facilities',
  'Number of Active Users',
  'Number of Registered Users',
  'Number of UnRegistered Users',
]

const ExportCompanyReport = ({
  exportType,
  setExportType,
  count,
  query,
}: ExportCompanyReportProps) => {
  const [data, setData] = useState<GetCompaniesDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    companies: [],
  })

  const [dataGetSuccess, setDataGetSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const dataToExport = useMemo(() => {
    return data.companies.map(row => ({
      id: row.id,
      name: row.name,
      status: row.status,
      activationDate: row.activationDate
        ? moment(row.activationDate).format('MM/DD/YYYY')
        : '-',
      facilitiesCount: row.facilitiesCount,
      usersCount: row.usersCount,
      registeredUsersCount: row.registeredUsersCount,
      notRegisteredUsersCount: row.notRegisteredUsersCount,
    }))
  }, [data.companies])

  const getFilteredCompanyReport = () => {
    setIsLoading(true)
    const { limit, ...companyReportQuery } = query
    getCompanies(companyReportQuery)
      .then(res => {
        setData(res)
        setDataGetSuccess(true)
      })
      .catch(e => {
        handleError(e)
      })
  }

  useEffect(() => {
    if (dataGetSuccess) {
      exportData()
    }
  }, [data, dataGetSuccess])

  useEffect(() => {
    if (exportType && count <= 100) {
      getFilteredCompanyReport()
    }
  }, [exportType, count])

  const exportData = () => {
    if (exportType === EXPORT_TYPES.PDF) {
      generatePDF()
    } else {
      // XLS or CSV
      generateXLSOrCSV(exportType, fileHeaders, dataToExport, 'Company_Report')
      setIsLoading(false)
      setExportType(null)
    }
  }

  const generatePDF = () => {
    const pdf = new jsPDF({
      unit: 'px',
      format: 'a4',
      orientation: 'landscape',
    })

    const totalPages = Math.ceil(data.companies.length / ROWS_PER_PAGE)

    addContentToPDF(0, pdf, totalPages)
  }

  const addContentToPDF = (
    currentPageIndex: number,
    pdf: jsPDF,
    totalPages: number,
  ) => {
    const pdfContent = document.createElement('div')

    const startIndex = currentPageIndex * ROWS_PER_PAGE
    const endIndex = Math.min(startIndex + ROWS_PER_PAGE, data.companies.length)
    const chunk = data.companies.slice(startIndex, endIndex)
    pdfContent.innerHTML = renderToString(
      <ExportCompanyReportPdf
        companyReport={chunk}
        page={currentPageIndex + 1}
        selectedFilters={{
          activationDate: query.activationDate,
          status: query.status,
        }}
      />,
    )

    document.body.appendChild(pdfContent)
    html2canvas(pdfContent).then(function (canvas) {
      addImageToPdf(canvas, pdf, currentPageIndex)

      if (totalPages > 1) {
        currentPageIndex = currentPageIndex + 1
        totalPages = totalPages - 1
        addContentToPDF(currentPageIndex, pdf, totalPages)
      } else {
        const pdfBlob = new Blob([pdf.output('blob')], {
          type: 'application/pdf',
        })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        const link = document.createElement('a')
        link.href = pdfUrl
        link.download = `${generateFileName('Company_Report')}.pdf`
        link.click()
        setIsLoading(false)
        setExportType(null)
      }
    })

    if (document.body.contains(pdfContent)) {
      document.body.removeChild(pdfContent)
    }
  }

  return (
    <>
      {exportType && count > 100 && (
        <ExportConfirmation
          onConfirm={getFilteredCompanyReport}
          onClose={() => setExportType(null)}
          isOpen={!!exportType}
          message={`You have ${count} results in this export. Go back to filter or continue to export?`}
          title={'Warning'}
          confirmButtonLabel={'Export'}
          cancelLabel={'Go Back'}
          icon={'ri-error-warning-line'}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default ExportCompanyReport
