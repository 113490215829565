import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import Highlighter from 'react-highlight-words'
import {
  CE_PROVIDERS_STATUSES,
  OrderType,
  SettingsPermissions,
  TAttachment,
} from '../../../sharedTypes'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { Pagination } from '../../../Components/Common/Pagination'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import {
  createProvider,
  getProviders,
  putProvider,
} from '../../../helpers/api_helper'
import { FormikHelpers } from 'formik'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import Filters from './Filters'
import CEProviderModal from './CEProviderModal'
import {
  CEProviderSortBy,
  GetProvidersDTO,
  ProvidersInitialValues,
  ProvidersModalSubmitedValues,
} from '../../../sharedTypes/api/providers'
import { OnlineProviderItem } from '../../../sharedTypes/models/ceProvider'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import { getCoursePositions } from '../../../helpers/api/coursePositions'
import { CoursePosition } from '../../../sharedTypes/api/coursePositions'
import ItemsPerPageDropdown from '../../../Components/Common/ItemsPerPageDropdown'
import { usePermissions } from '../../../hooks/usePermissions'

const Columns = [
  {
    name: 'Provider Number',
    sortBy: CEProviderSortBy.PROVIDER_NUMBER,
    style: { minWidth: 155 },
  },
  {
    name: 'Board',
    sortBy: CEProviderSortBy.BOARD,
    style: { minWidth: 125 },
  },
  {
    name: 'Discipline',
    style: { width: '32%' },
    sortBy: CEProviderSortBy.DISCIPLINE,
  },
  {
    name: 'States',
    sortBy: CEProviderSortBy.STATES,
    style: { width: 100, minWidth: 70 },
  },
  {
    name: 'Signatory Name',
    sortBy: CEProviderSortBy.SIGNATORY_NAME,
    style: { width: 200, minWidth: 120 },
  },
  {
    name: 'Signature',
    sortBy: CEProviderSortBy.SIGNATURE,
    style: { width: 120, minWidth: 95 },
  },
  {
    name: 'Status',
    sortBy: CEProviderSortBy.STATUS,
    style: { width: 120 },
  },
  {
    name: 'Actions',
    style: { width: 80 },
  },
]

export const BadgeColorByStatus = {
  [CE_PROVIDERS_STATUSES.ACTIVE]: 'success',
  [CE_PROVIDERS_STATUSES.INACTIVE]: 'danger',
}

const CEProvider = () => {
  document.title =
    'Continuing Education Providers| Mastered - Admin & Dashboard'
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<GetProvidersDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    ceProviders: [],
  })

  const [disciplines, setDisciplines] = useState<CoursePosition[]>([])
  const [query, setQuery] = useState<GetProvidersDTO.Request>({
    page: 1,
    limit: 10,
    orderBy: OrderType.ASC,
    sortBy: CEProviderSortBy.PROVIDER_NUMBER,
    status: [CE_PROVIDERS_STATUSES.ACTIVE],
  })

  const initialValues = {
    id: undefined,
    providerNumber: 0,
    board: '',
    disciplines: [],
    states: [],
    signatoryName: '',
    credentials: '',
    signatoryTitle: '',
    signature: undefined,
    status: CE_PROVIDERS_STATUSES.ACTIVE,
  }

  const [openModal, setOpenModal] = useState<
    ProvidersInitialValues | OnlineProviderItem | null
  >(null)

  const permissions = {
    add: usePermissions(SettingsPermissions.ADD_CE_PROVIDER),
    edit: usePermissions(SettingsPermissions.EDIT_CE_PROVIDER),
  }

  const columns = useMemo(() => {
    if (!permissions.edit) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [permissions])

  const handleSort = useCallback((column: CEProviderSortBy) => {
    setQuery(prev => ({
      ...prev,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
      sortBy: column,
    }))
  }, [])

  const onCreate = useCallback(
    async (
      values: ProvidersModalSubmitedValues,
      form: FormikHelpers<ProvidersModalSubmitedValues>,
    ) => {
      try {
        form.setSubmitting(true)
        const formData = new FormData()
        const signature = values.signature as Blob | TAttachment
        formData.append('providerNumber', values.providerNumber.toString())
        formData.append('board', values.board)
        formData.append('signatoryName', values.signatoryName)
        formData.append('signatoryTitle', values.signatoryTitle)
        formData.append('credentials', values.credentials)
        formData.append('status', values.status)
        values.disciplines.forEach((discipline, index) =>
          formData.append(`disciplines[${index}]`, discipline),
        )
        values.states.forEach((state, index) =>
          formData.append(`states[${index}]`, state),
        )
        if (signature instanceof Blob) {
          formData.append('signature', signature)
        }
        await createProvider(formData)
        form.setSubmitting(false)
        form.resetForm()
        toast('Success - CE provider added successfully', successToastOptions)
        setQuery(prev => ({ ...prev, page: 1 }))
        setOpenModal(null)
      } catch (e) {
        form.setSubmitting(false)
        handleError(e)
      }
    },
    [data.page],
  )

  const onEdit = useCallback(
    async (
      { id, ...values }: ProvidersModalSubmitedValues,
      form: FormikHelpers<ProvidersModalSubmitedValues>,
    ) => {
      try {
        form.setSubmitting(true)
        const formData = new FormData()
        const signature = values.signature as Blob | TAttachment
        formData.append('providerNumber', values.providerNumber.toString())
        formData.append('board', values.board)
        formData.append('signatoryName', values.signatoryName)
        formData.append('signatoryTitle', values.signatoryTitle)
        formData.append('credentials', values.credentials)
        formData.append('status', values.status)
        values.disciplines.forEach((discipline, index) =>
          formData.append(`disciplines[${index}]`, discipline),
        )
        values.states.forEach((state, index) =>
          formData.append(`states[${index}]`, state),
        )
        if (signature instanceof Blob) {
          formData.append('signature', signature)
        }
        if (!signature) {
          formData.append('deleteSignature', 'true')
        }
        if (id) {
          await putProvider(id, formData)
        }
        form.setSubmitting(false)
        form.resetForm()
        toast('Success - CE provider edited successfully', successToastOptions)
        setQuery(prev => ({ ...prev, page: 1 }))
        setOpenModal(null)
      } catch (e) {
        form.setSubmitting(false)
        handleError(e)
      }
    },
    [],
  )

  useEffect(() => {
    setIsLoading(true)
    getProviders(query)
      .then(response => {
        setData(response)
      })
      .catch(handleError)
      .finally(() => {
        setIsLoading(false)
      })
  }, [query])

  useEffect(() => {
    getCoursePositions()
      .then(response => setDisciplines(response.coursePositions))
      .catch(handleError)
  }, [])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='CE Providers Settings'
            items={[
              {
                title: 'CE Providers Settings',
                active: true,
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key || ''}
                    />
                    <div className='d-flex flex-wrap gap-3 ps-2'>
                      <Filters
                        visible
                        disciplines={disciplines}
                        initialValues={{ status: query.status }}
                        setFilters={data => {
                          setQuery(prev => ({
                            ...prev,
                            disciplines: data.discipline,
                            states: data.state,
                            status: data.status,
                            page: 1,
                          }))
                        }}
                      />
                    </div>
                    <div className='d-flex flex-shrink-0 gap-3'>
                      {permissions.add && (
                        <Button
                          color={'primary'}
                          onClick={() => {
                            setOpenModal(initialValues)
                          }}
                          className='btn btn-primary align-middle'
                        >
                          <i className='ri-add-line me-1 fs-16'></i>Add Provider
                        </Button>
                      )}
                    </div>
                  </div>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  {' '}
                  <div className='table-card'>
                    <div className='overflow-x-auto'>
                      <Table className='align-middle mb-0'>
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {columns.map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.name}
                              >
                                {column.name}
                                {!!column.sortBy && (
                                  <ColumnSortIcon<CEProviderSortBy>
                                    sortOrder={query.orderBy}
                                    sortedColumn={query.sortBy}
                                    column={column.sortBy}
                                    handleSort={handleSort}
                                  />
                                )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.ceProviders.map(
                            (item: OnlineProviderItem, i) => (
                              <tr key={i} className='fs-14 fw-light'>
                                <td>
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={item.providerNumber}
                                    className='two-line-limit'
                                  />
                                </td>
                                <td>
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={item.board}
                                    className='two-line-limit'
                                  />
                                </td>
                                <td>
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={item.disciplines.join(
                                      ', ',
                                    )}
                                  />
                                </td>
                                <td>
                                  <span
                                    className={`badge badge-soft-grey fs-12 fw-normal text-capitalize mx-2`}
                                  >
                                    {item.states.length}
                                  </span>
                                </td>
                                <td>
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={item.signatoryName}
                                  />
                                </td>
                                <td>
                                  {item.signatureId ? (
                                    <i className='ri-check-fill fs-22 cursor-pointer text-green-500 mx-4' />
                                  ) : (
                                    ''
                                  )}
                                </td>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      BadgeColorByStatus[item.status]
                                    } fs-12 fw-normal text-capitalize`}
                                  >
                                    {item.status}
                                  </span>
                                </td>
                                {permissions.edit && (
                                  <td>
                                    <span className='d-flex justify-content-center'>
                                      <i
                                        onClick={() => {
                                          setOpenModal(item)
                                        }}
                                        className='ri-pencil-line cursor-pointer'
                                      ></i>
                                    </span>
                                  </td>
                                )}
                              </tr>
                            ),
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <div className='mx-3 mt-3'>
                      <ItemsPerPageDropdown
                        limit={query.limit ?? 10}
                        onChange={limit =>
                          setQuery(prev => ({ ...prev, limit: +limit.label }))
                        }
                      />
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>

        {openModal && (
          <CEProviderModal
            isOpen={!!openModal}
            title={openModal.id ? 'Edit Provider' : 'Add New Provider'}
            initialValues={openModal}
            onClose={() => {
              setOpenModal(null)
            }}
            onSubmit={openModal.id ? onEdit : onCreate}
            disciplines={disciplines}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default CEProvider
