import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import Chart from './Chart'
import { getUserCourseStatusAnalytics } from '../../../helpers/api/users'
import { v4 as uuidv4 } from 'uuid'

import {
  GetUsersCourseStatusAnalyticsDTO,
  USER_COURSE_STATUSES,
} from '../../../sharedTypes'
import { handleError } from '../../../helpers/toast_helper'
import { useAppSelector } from '../../../hooks/redux'
import _ from 'lodash'
import DetailsInfoModal from '../../../Components/Modals/DetailsInfoModal'

const courseStatusesData = [
  {
    label: USER_COURSE_STATUSES.NO_COURSES,
    color: '#B859F2',
    info: 'User is registered but has no courses assigned.',
  },
  {
    label: USER_COURSE_STATUSES.ON_SCHEDULE,
    color: '#0039C7',
    info: 'User has no overdue courses.',
  },
  {
    label: USER_COURSE_STATUSES.PENDING_COMPLETION,
    color: '#ccc',
    info: 'User has overdue courses',
  },
  {
    label: USER_COURSE_STATUSES.COMPLETE,
    color: '#1AC1BD',
    info: 'User has completed all of the courses assigned',
  },
]

const ScheduleBreakDown = ({ includeAgency }: { includeAgency: boolean }) => {
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )

  const [isLoading, setIsLoading] = useState(true)

  const [chartData, setChartData] = useState<number[]>([])
  const [courseStatuses, setCourseStatuses] =
    useState<GetUsersCourseStatusAnalyticsDTO.Response>({
      data: {
        [USER_COURSE_STATUSES.NO_COURSES]: 0,
        [USER_COURSE_STATUSES.ON_SCHEDULE]: 0,
        [USER_COURSE_STATUSES.PENDING_COMPLETION]: 0,
        [USER_COURSE_STATUSES.COMPLETE]: 0,
      },
    })

  const dataColors = useMemo(() => {
    return JSON.stringify(courseStatusesData.map(course => course.color))
  }, [courseStatusesData])

  useEffect(() => {
    const facilityId = selectedFacilityId ?? undefined
    const groupId = selectedGroupId ?? undefined

    getUserCourseStatusAnalytics({ facilityId, groupId, includeAgency })
      .then(res => {
        setCourseStatuses(res)
        setIsLoading(false)
        setChartData(Object.values(res.data))
      })
      .catch(handleError)
  }, [selectedFacilityId, selectedGroupId, includeAgency])

  const getAnalytics = (status: USER_COURSE_STATUSES) => {
    const userCount = courseStatuses.data[status]
    if (userCount >= 1000) {
      return userCount / 1000 + 'k'
    }

    return userCount
  }

  const emptyChart = useMemo(() => {
    return Object.values(courseStatuses.data).every(value => value === 0)
  }, [courseStatuses])

  const memoizedCourseStatusesData = useMemo(() => {
    return courseStatusesData.map(item => ({
      ...item,
      id: uuidv4(),
    }))
  }, [])

  return (
    <Card className='schedule-breakdown-container'>
      <CardHeader className='align-items-center d-flex'>
        <h4 className='card-title mb-0 flex-grow-1'>User Breakdown</h4>
        <div>{_.sum(Object.values(courseStatuses.data))}</div>
      </CardHeader>

      <CardBody>
        <div className='d-flex flex-column flex-wrap'>
          <div className='position-relative'>
            <Chart series={chartData} dataColors={dataColors} />
            {emptyChart && !isLoading && (
              <span className='no-chart-data'>No Users</span>
            )}
          </div>
          <Row className={'justify-content-between'}>
            {memoizedCourseStatusesData.map((courseStatusItem, index) => (
              <Col
                md={6}
                xl={index % 2 != 0 ? 5 : 7}
                key={courseStatusItem.color}
                className='mt-3'
              >
                <h4 className='text-truncate fs-14 fs-medium mb-0 d-flex'>
                  <i
                    className='ri-stop-fill align-middle fs-18 me-1'
                    style={{ color: courseStatusItem.color }}
                  ></i>
                  <div className='vstack gap-2'>
                    <span className='text-capitalize'>
                      {courseStatusItem.label}
                      <DetailsInfoModal
                        id={courseStatusItem.id}
                        name={'user-break-down'}
                        info={courseStatusItem.info}
                      />
                    </span>
                    <p className='text-muted mb-0'>
                      <i className='ri-user-3-line me-2 fs-16'></i>
                      {getAnalytics(courseStatusItem.label)}
                    </p>
                  </div>
                </h4>
              </Col>
            ))}
          </Row>
        </div>
      </CardBody>
    </Card>
  )
}

export default ScheduleBreakDown
