import {
  CommunicationPermissions,
  CompanyManagementPermissions,
  FacilityManagementPermissions,
  ReportingPermissions,
  UserManagementPermissions,
} from '../lib/types'
import { COMPANY_STATUSES, TCompany, TFacilityStatus } from '../models'
import {
  OrderType,
  PaginatedBaseResponse,
  PaginationRequest,
} from './pagination'

export enum CompaniesFacilitiesSortBy {
  FACILITY_NAME = 'facilities.name',
  GROUP_NAME = 'groups.name',
  STATUS = 'facilities.status',
  ACTIVATION_DATE = 'facilities.activationDate',
}

export enum CompaniesSortBy {
  ID = 'id',
  NAME = 'name',
  LOGO = 'logo',
  STATUS = 'status',
  ACTIVATION_DATE = 'activationDate',
  NUM_OF_USERS = 'usersCount',
  NUM_OF_REGISTERED_USERS = 'registeredUsersCount',
  NUM_OF_NOT_REGISTERED_USERS = 'notRegisteredUsersCount',
  NUM_OF_ADMINS = 'adminsCount',
  NUM_OF_FACILITIES = 'facilitiesCount',
}

export enum GetCompanyFacilitiesPermissions {
  VIEW_FACILITY_MANAGEMENT = FacilityManagementPermissions.VIEW_FACILITY_MANAGEMENT,
  VIEW_FACILITY_DETAILS = FacilityManagementPermissions.VIEW_FACILITY_DETAILS,
  ADD_NEW_ADMIN = UserManagementPermissions.ADD_NEW_ADMIN,
  EDIT_ADMIN = UserManagementPermissions.EDIT_ADMIN,
}

export namespace GetCompaniesFacilitiesDTO {
  export interface Request {
    key?: string
    status?: TFacilityStatus
    orderBy?: OrderType
    sortBy?: CompaniesFacilitiesSortBy
    permission?: GetCompanyFacilitiesPermissions
    accessible?: boolean
    adminFacilities?: boolean
  }
  export type Response = TCompany[]
}

export namespace GetCompaniesDTO {
  export interface Request extends PaginationRequest {
    orderBy?: OrderType
    sortBy?: CompaniesSortBy
    status?: COMPANY_STATUSES[]
    activationDate?: Date[]
    permission:
      | ReportingPermissions.VIEW_COMPANY_REPORT
      | CompanyManagementPermissions.VIEW_COMPANY_MANAGEMENT
      | CommunicationPermissions.VIEW_NOTIFICATION_LISTING
      | CommunicationPermissions.VIEW_COMPANY_NEWS
      | CommunicationPermissions.VIEW_SURVEY_LISTING
  }

  export interface Response extends PaginatedBaseResponse {
    companies: TCompany[]
  }
}

export namespace CreateCompanyDTO {
  export interface Request extends Omit<TCompany, 'logo'> {}
  export interface Response extends TCompany {}
}
