import React, { useCallback, useState } from 'react'
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalProps,
  Row,
  Spinner,
} from 'reactstrap'
import { TextAnswer } from '../../sharedTypes'
import Select from 'react-select'
import {
  BooleanQuestion,
  IKnowledgeCheck,
  KnowledgeCheckTypes,
  MultipleChoice,
  options,
  Pause,
  ReflectionPoint,
} from '../../pages/Courses/OnlineCourses/Lessons/types'
import { FieldArray, FormikHelpers, FormikProvider, useFormik } from 'formik'
import { TextAnswers } from '../Course/Questions/TextAnswers'
import _ from 'lodash'
import { knowledgeCheckSchema } from '../../schemas'
import { DropResult } from 'react-beautiful-dnd'
import TypeChangeWarningModal from '../../Components/Modals/DeleteConfirmation'
import { BoolAnswer } from '../Course/Questions'

interface KnowledgeCheckModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  data: IKnowledgeCheck
  onSubmit: (
    values: IKnowledgeCheck,
    form: FormikHelpers<IKnowledgeCheck>,
  ) => void
}

const MAX_ANSWERS_LIMIT = 4

const KnowledgeCheckModal = ({
  onClose,
  onSubmit,
  isOpen,
  data,
}: KnowledgeCheckModalProps) => {
  const [knowledgeCheckType, setKnowledgeCheckType] =
    useState<KnowledgeCheckTypes | null>(null)

  const getValuesByType = (type: KnowledgeCheckTypes) => {
    if (type === KnowledgeCheckTypes.MULTIPLE_CHOICE) {
      return {
        question: '',
        explanation: '',
        answers: [{ answer: '', correct: false }],
      }
    }

    if (type === KnowledgeCheckTypes.TRUE_FALSE) {
      return {
        question: '',
        explanation: '',
        answer: { correct: false },
      }
    }

    if (type === KnowledgeCheckTypes.REFLECTION_POINT) {
      return {
        description: '',
        buttonText: '',
      }
    }

    return {
      buttonText: '',
    }
  }

  const form = useFormik<IKnowledgeCheck>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: knowledgeCheckSchema,
    onSubmit,
  })

  const onReOrder = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return
      }

      const answers = Array.from(
        (form.values.data as MultipleChoice).answers || [],
      )

      const [reorderedItem] = answers.splice(result.source.index, 1)

      answers.splice(result.destination.index, 0, reorderedItem)

      form.setFieldValue('data.answers', [...answers])
    },
    [form.values],
  )

  const onChangeType = () => {
    if (knowledgeCheckType) {
      form.setFieldValue('type', knowledgeCheckType)
      form.setFieldValue('data', getValuesByType(knowledgeCheckType))
      setKnowledgeCheckType(null)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>
            {form.values.id ? 'Edit ' : 'Add '} Knowledge Check
          </h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onClose}
          ></i>
        </div>
        <div className='vstack gap-3'>
          <Row>
            <Col>
              <div>
                <label htmlFor='type' className='form-label'>
                  Type*
                </label>
                <Select
                  className='w-100'
                  name='type'
                  id='knowledgeCheckType'
                  value={options.find(o => o.value === form.values.type)}
                  onChange={option => {
                    if (option) {
                      if (form.values.id && option.value !== form.values.type) {
                        setKnowledgeCheckType(option.value)
                      } else {
                        form.setFieldValue('type', option.value)
                      }
                    }
                  }}
                  onBlur={form.handleBlur}
                  options={options}
                />
              </div>
            </Col>
            <Col>
              <div>
                <label htmlFor='timestamp' className='form-label'>
                  Timestamp*
                </label>
                <Input
                  className='form-control'
                  id='timestamp'
                  name='timestamp'
                  placeholder='Enter timestamp'
                  type='text'
                  invalid={!!form.errors.timestamp}
                  value={form.values.timestamp}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                {form.touched.timestamp && form.errors.timestamp ? (
                  <FormFeedback type='invalid'>
                    {form.errors.timestamp}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            {form.values.type === KnowledgeCheckTypes.REFLECTION_POINT && (
              <div className='mb-3'>
                <label htmlFor='explanation' className='form-label'>
                  Description*
                </label>
                <Input
                  className='form-control'
                  id='description'
                  name={'data.description'}
                  placeholder='Enter description'
                  type='textarea'
                  rows={3}
                  invalid={!!(form.errors.data as ReflectionPoint)?.description}
                  value={
                    (form.values.data as ReflectionPoint)?.description || ''
                  }
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
                {(form.touched.data as ReflectionPoint)?.description &&
                (form.errors.data as ReflectionPoint)?.description ? (
                  <FormFeedback type='invalid'>
                    {(form.errors.data as ReflectionPoint).description}
                  </FormFeedback>
                ) : null}
              </div>
            )}
            {[
              KnowledgeCheckTypes.PAUSE,
              KnowledgeCheckTypes.REFLECTION_POINT,
            ].includes(form.values.type) && (
              <Col>
                <div>
                  <label htmlFor='buttonText' className='form-label'>
                    Button Text
                  </label>
                  <Input
                    className='form-control'
                    id='buttonText'
                    name={'data.buttonText'}
                    placeholder='Enter a button text'
                    type='text'
                    invalid={!!(form.errors.data as Pause)?.buttonText}
                    value={(form.values.data as Pause)?.buttonText || ''}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </div>
              </Col>
            )}

            {[
              KnowledgeCheckTypes.MULTIPLE_CHOICE,
              KnowledgeCheckTypes.TRUE_FALSE,
            ].includes(form.values.type) && (
              <Col className='vstack gap-3'>
                <div>
                  <label htmlFor='buttonText' className='form-label'>
                    Question*
                  </label>
                  <Input
                    className='form-control'
                    id='question'
                    name={'data.question'}
                    placeholder='Enter question'
                    type='textarea'
                    rows={3}
                    invalid={!!(form.errors.data as MultipleChoice)?.question}
                    value={(form.values.data as MultipleChoice)?.question || ''}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                  {(form.touched.data as MultipleChoice)?.question &&
                  (form.errors.data as MultipleChoice)?.question ? (
                    <FormFeedback type='invalid'>
                      {(form.errors.data as MultipleChoice).question}
                    </FormFeedback>
                  ) : null}
                </div>
                {form.values.type === KnowledgeCheckTypes.MULTIPLE_CHOICE ? (
                  <FormikProvider value={form}>
                    <FieldArray
                      name={`data.answers`}
                      render={({ push, remove }) => (
                        <div>
                          <TextAnswers
                            errors={_.get(form.errors, `data.answers`, [])}
                            touched={_.get(form.touched, `data.answers`, [])}
                            index={0}
                            maxMultiChoiceQuestions={MAX_ANSWERS_LIMIT}
                            hideCorrectAnswer={false}
                            onReOrder={result => onReOrder(result)}
                            handleAnswerChange={(
                              aIndex: number,
                              value: string | boolean,
                            ) => {
                              form.setFieldValue(
                                `data.answers[${aIndex}].answer`,
                                value,
                              )
                            }}
                            handleCorrectChange={correctIndex => {
                              ;(
                                (form.values.data as MultipleChoice).answers ||
                                []
                              ).forEach((_, i: number) => {
                                form.setFieldValue(
                                  `data.answers[${i}].correct`,
                                  i === correctIndex,
                                )
                              })
                            }}
                            handleBlur={e => {
                              const index = Number(e.target.id)
                              form.setFieldTouched(
                                `data.answers[${index}].answer`,
                                true,
                              )
                            }}
                            onDelete={i => {
                              remove(i)
                            }}
                            answers={
                              ((form.values.data as MultipleChoice)?.answers ||
                                []) as TextAnswer[]
                            }
                          />

                          {_.get(form.touched.data, 'answers') &&
                          _.get(form.errors.data, 'answers') &&
                          _.isString(_.get(form.errors.data, 'answers')) ? (
                            <FormFeedback
                              type='invalid'
                              className='d-block pb-1'
                            >
                              {_.get(form.errors.data, 'answers')}
                            </FormFeedback>
                          ) : null}
                          {_.get(form.values.data, 'answers', []).length <
                            MAX_ANSWERS_LIMIT && (
                            <Button
                              style={{ width: 80 }}
                              color='light'
                              className='btn-label px-1'
                              size='sm'
                              onClick={() => {
                                push({
                                  answer: '',
                                  correct: false,
                                })
                              }}
                            >
                              <i className='ri-add-line me-1 fs-16'></i>
                              Add
                            </Button>
                          )}
                        </div>
                      )}
                    />
                  </FormikProvider>
                ) : (
                  <BoolAnswer
                    onChange={correct => {
                      form.setFieldValue('data.answer', {
                        correct,
                      })
                    }}
                    index={0}
                    value={
                      !!(form.values.data as BooleanQuestion).answer?.correct
                    }
                  />
                )}

                <div>
                  <label htmlFor='explanation' className='form-label'>
                    Explanation
                  </label>
                  <Input
                    className='form-control'
                    id='explanation'
                    name={'data.explanation'}
                    placeholder='Enter explanation'
                    type='textarea'
                    rows={3}
                    invalid={
                      !!(form.errors.data as MultipleChoice)?.explanation
                    }
                    value={
                      (form.values.data as MultipleChoice)?.explanation || ''
                    }
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <div className='d-flex justify-content-end gap-3'>
              <Button
                className='btn-soft-primary'
                onClick={onClose}
                disabled={form.isSubmitting}
              >
                Cancel
              </Button>
              <Button color='primary' onClick={() => form.handleSubmit()}>
                {form.isSubmitting ? <Spinner size={'sm'} /> : 'Save'}
              </Button>
            </div>
          </Row>
        </div>
      </ModalBody>

      <TypeChangeWarningModal
        isOpen={!!knowledgeCheckType}
        onClose={() => {
          setKnowledgeCheckType(null)
        }}
        title='Warning'
        confirmIcon='ri-error-warning-line'
        confirmLabel='Continue'
        confirmBtnType='primary'
        message={`If you change the type of knowledge check, all previous data will be deleted.`}
        onDelete={onChangeType}
      />
    </Modal>
  )
}

export default KnowledgeCheckModal
