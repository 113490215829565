import React from 'react'

interface ToggleSwitchProps {
  enabled: boolean
  setEnabled: (enabled: boolean) => void
}
const ToggleSwitch = ({ enabled, setEnabled }: ToggleSwitchProps) => {
  return (
    <div
      className={`toggle ${enabled ? 'enabled' : ''}`}
      onClick={() => setEnabled(!enabled)}
    >
      <div className='toggle-circle' />
    </div>
  )
}

export default ToggleSwitch
