import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {
  getCompanyNewsById,
  getLanguages,
  patchCompanyNews,
  postCompanyNews,
  putCompanyNewsImages,
} from '../../../helpers/api_helper'
import {
  TLanguage,
  NewsStatusTypes,
  TCompanyNewsTranslationItem,
  AttachmentsTypesEnum,
  DocumentFile,
  TAttachment,
  CoursesPermissions,
  AttachmentsMimeTypeEnum,
  CommunicationPermissions,
  COMPANY_STATUSES,
} from '../../../sharedTypes'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import WarningModal from '../../../Components/Modals/WarningModal'
import SelectFromMediaLibraryModal from '../../../Components/Modals/MediaModal'
import ConfirmationModal from '../../../Components/Modals/ConfirmationModal'
import ImageCropperModal from '../../../Components/Modals/ImageCropperModalv2'
import { usePermissions } from '../../../hooks/usePermissions'
import { ENGLISH_LANGUAGE_CODE, getMediaDetails } from '../../../helpers/common'
import { useAppSelector } from '../../../hooks/redux'
import FacilitiesMultiSelectDropdown from '../../../Components/Common/FacilitiesMultiSelectDropdown'
import { Option } from '../../../helpers/facility'
import _ from 'lodash'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import { AllValue } from '../../../Components/Modals/AssignCourseModal/types'
import { getCompanies } from '../../../helpers/api/company'

interface IForm {
  isDraft: boolean
  status?: string
  id?: number
  facilityIds?: number[]
  groupIds?: number[]
  companyIds?: number[]
  sendDate?: Date | undefined
  publishedDate?: Date | undefined
  newsCover: DocumentFile | null
  newsMedia: DocumentFile | null
  newsMediaId: number | null
  newsCoverId: number | null
  translations: TCompanyNewsTranslationItem[]
}

type IModelPropertyType = 'newsCover' | 'newsMedia'

const ManageCompanyNews = () => {
  document.title = 'Manage Company News | Mastered - Admin & Dashboard'
  const { selectedFacilityId, groupOptions, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )
  const user = useAppSelector(state => state.User.user)

  const selectedValue = useMemo(() => {
    if (user?.groupId && !selectedFacilityId && !selectedGroupId) {
      return groupOptions.filter(
        option => option.value === `group:${user?.groupId}`,
      )
    }

    if (!selectedFacilityId && !selectedGroupId) {
      return groupOptions.filter(option => option.level === 0)
    }

    if (selectedFacilityId) {
      return groupOptions.filter(
        option => option.value === `facility:${selectedFacilityId}`,
      )
    }

    return groupOptions.filter(
      option => option.value === `group:${selectedGroupId}`,
    )
  }, [selectedFacilityId, selectedGroupId, groupOptions, user])

  const navigate = useNavigate()

  const { id } = useParams()
  const datePickerRef = useRef<Flatpickr>(null)
  const [croperAttachment, setCroperAttachment] = useState<TAttachment | null>(
    null,
  )
  const [languages, setLanguages] = useState<TLanguage[] | []>([])
  const [activeTab, setActiveTab] = useState('en')
  const [status, setStatus] = useState(NewsStatusTypes.DRAFT)
  const [isOpenPopup, setOpenPopup] = useState<boolean>(false)
  const [isOpenConfirmPopup, setOpenConfirmPopup] = useState<boolean>(false)
  const [disableFacilities, setDisableFacilities] = useState<boolean>(false)
  const [selectedOptions, setSelectedOptions] =
    useState<Option[]>(selectedValue)
  const [companiesOptions, setCompaniesOptions] = useState<
    {
      label: string
      value: string | number
    }[]
  >([])
  const [mediaModalDetails, setMediaModalDetails] = useState<{
    isOpen: boolean
    property?: IModelPropertyType
    type: AttachmentsTypesEnum[]
    mimetype?: AttachmentsMimeTypeEnum[] | undefined
  }>({
    isOpen: false,
    type: [],
  })
  const [imagesData, setImagesData] = useState<any>({})

  const companyNewsPermissions = {
    add: usePermissions(CommunicationPermissions.ADD_COMPANY_NEWS),
    edit: usePermissions(CommunicationPermissions.EDIT_COMPANY_NEWS),
    publish: usePermissions(CommunicationPermissions.PUBLISH_COMPANY_NEWS),
  }

  const handleUploadSelectedMedia = (attachment: TAttachment) => {
    if (attachment.type === AttachmentsTypesEnum.VIDEO) {
      form.setFieldValue('newsMedia', getMediaDetails(attachment))
    } else {
      setCroperAttachment(attachment)
    }
  }

  const prepareCroppedPromise = useCallback(
    (id: number, prop: 'cover' | 'media') => {
      const formData = new FormData()
      const attachment = imagesData[prop].attachment
      formData.append('attachmentId', attachment.id)
      Object.keys(imagesData[prop]['cropped']).forEach(key => {
        formData.append(key, imagesData[prop]['cropped'][key])
      })
      return putCompanyNewsImages(Number(id), attachment.id, prop, formData)
    },
    [id, imagesData],
  )

  const onSubmit = useCallback(
    (formValues: IForm, formikHelpers: FormikHelpers<IForm>) => {
      const {
        sendDate,
        translations,
        isDraft,
        facilityIds,
        groupIds,
        companyIds,
        newsMediaId,
        newsCoverId,
      } = formValues

      const data: any = {
        translations,
        isDraft,
        sendDate: sendDate ?? null,
        newsMediaId,
        newsCoverId,
        facilityIds: !_.isEmpty(facilityIds) ? facilityIds : undefined,
        groupIds: !_.isEmpty(groupIds) ? groupIds : undefined,
        companyIds: !_.isEmpty(companyIds) ? companyIds : [],
      }

      formikHelpers.setSubmitting(true)

      const action = id
        ? patchCompanyNews(+id, data as any)
        : postCompanyNews(data as any)

      action
        .then(news => {
          const promises = []
          const newsId = (news as any).id || id
          if (imagesData['cover']) {
            promises.push(prepareCroppedPromise(newsId, 'cover'))
          }
          if (imagesData['media']) {
            promises.push(prepareCroppedPromise(newsId, 'media'))
          }
          return Promise.all(promises)
        })
        .then(() => {
          setImagesData({})
          const message = id
            ? 'Success - News successfully edited.'
            : `Success - News successfully ${
                isDraft
                  ? 'saved as draft'
                  : sendDate
                  ? 'scheduled'
                  : 'published'
              }`
          toast(message, successToastOptions)
          navigate('/company-news')
        })
        .catch(handleError)
        .finally(() => {
          formikHelpers.setSubmitting(false)
        })
    },
    [imagesData],
  )

  const fetchCompanies = useCallback(() => {
    return getCompanies({
      permission: CommunicationPermissions.VIEW_COMPANY_NEWS,
      status: [COMPANY_STATUSES.ACTIVE],
    })
      .then(res => {
        const data = [
          AllValue,
          ...res.companies.map(company => {
            return {
              label: company.name,
              value: company.id,
            }
          }),
        ]
        setCompaniesOptions(data)

        return data
      })
      .catch(() => [])
  }, [])

  const form = useFormik<IForm>({
    enableReinitialize: true,

    initialValues: {
      isDraft: false,
      sendDate: undefined,
      publishedDate: undefined,
      status: '',
      newsMedia: null,
      newsMediaId: null,
      newsCoverId: null,
      newsCover: null,
      translations: [],
      facilityIds: [],
      groupIds: [],
      companyIds: [],
    },
    validationSchema: Yup.object({
      translations: Yup.array(
        Yup.object({
          languageId: Yup.number().required(),
          title: Yup.string().when('languageId', {
            is: 1,
            then: Yup.string()
              .required('Please Enter Title')
              .max(100, 'Title should be under 100 characters'),
          }),
          content: Yup.string().when('languageId', {
            is: 1,
            then: Yup.string().required('Please Enter Body'),
          }),
        }),
      ),
      sendDate: Yup.date()
        .nullable()
        .when([], {
          is: () => status === NewsStatusTypes.SCHEDULED,
          then: Yup.date()
            .required('Please select Schedule Date and Time')
            .min(new Date(), 'Schedule Date and Time must be in the future')
            .typeError('Please select Schedule Date and Time'),
        }),
    }),
    onSubmit,
  })

  useEffect(() => {
    getLanguages()
      .then(data => {
        const _data = data.filter(item => item.code === ENGLISH_LANGUAGE_CODE)
        setLanguages(_data)
        if (!id) {
          const all: TCompanyNewsTranslationItem[] = []
          _data.map(item => {
            all.push({ languageId: item.id, title: '', content: '' })
          })
          form.setFieldValue('translations', all)
        }
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    if (!_.isEmpty(selectedOptions)) {
      const _selectedOptions = selectedOptions.map(option => {
        const [type, id] = option.value.split(':')
        return { type, id: +id }
      })

      if (_selectedOptions.every(option => option.type === 'company')) {
        form.setFieldValue('facilityIds', [])
        form.setFieldValue('groupIds', [])
      } else {
        form.setFieldValue(
          'facilityIds',
          _selectedOptions
            .filter(option => option.type === 'facility')
            .map(option => option.id),
        )
        form.setFieldValue(
          'groupIds',
          _selectedOptions
            .filter(option => option.type === 'group')
            .map(option => option.id),
        )
      }
    } else {
      form.setFieldValue('facilityIds', [])
      form.setFieldValue('groupIds', [])
    }
  }, [selectedOptions])

  useEffect(() => {
    if (id) {
      getCompanyNewsById(+id).then(res => {
        const data: any = res.data
        form.setValues({
          ...data,
          newsCover: data.newsCover && getMediaDetails(data.newsCover),
          newsMedia: data.newsMedia && getMediaDetails(data.newsMedia),
        })
        manageFacilityOptions(data.facilityIds || [], data.groupIds || [])
      })
    }
  }, [id])

  const manageFacilityOptions = (facilityIds: number[], groupIds: number[]) => {
    const selectedFacilityOptions = filterOptions(facilityIds, groupIds)
    setSelectedOptions(selectedFacilityOptions)
    const hasPermission = checkOptions(facilityIds, groupIds)
    setDisableFacilities(!hasPermission)
  }

  const filterOptions = (facilityIds: number[], groupIds: number[]) => {
    if (_.isEmpty(facilityIds) && _.isEmpty(groupIds)) {
      return groupOptions.filter(option => option.level === 0)
    }

    return groupOptions.filter(option => {
      if (facilityIds.length > 0 && option.value.startsWith('facility:')) {
        const facilityId = parseInt(option.value.split(':')[1])

        return facilityIds.includes(facilityId)
      }
      if (groupIds.length > 0 && option.value.startsWith('group:')) {
        const groupId = parseInt(option.value.split(':')[1])

        return groupIds.includes(groupId)
      }
      return false
    })
  }

  const checkOptions = (facilityIds: number[], groupIds: number[]) => {
    if (
      _.isEmpty(facilityIds) &&
      _.isEmpty(groupIds) &&
      (user?.groupId || user?.facilityId)
    ) {
      return false
    }

    const existingFacilityIds = groupOptions
      .filter(option => option.value.startsWith('facility:'))
      .map(option => parseInt(option.value.split(':')[1]))

    const existingGroupIds = groupOptions
      .filter(option => option.value.startsWith('group:'))
      .map(option => parseInt(option.value.split(':')[1]))

    const allFacilityIdsExist = facilityIds.every(id =>
      existingFacilityIds.includes(id),
    )
    const allGroupIdsExist = groupIds.every(id => existingGroupIds.includes(id))

    return allFacilityIdsExist && allGroupIdsExist
  }

  const mediaModalMimeTypes = useMemo(() => {
    let mimetypes: AttachmentsMimeTypeEnum[] = []
    if (mediaModalDetails.type.includes(AttachmentsTypesEnum.IMAGE)) {
      mimetypes = [
        AttachmentsMimeTypeEnum.IMAGE_JPG,
        AttachmentsMimeTypeEnum.IMAGE_PNG,
      ]
    }
    if (mediaModalDetails.type.includes(AttachmentsTypesEnum.VIDEO)) {
      mimetypes.push(AttachmentsMimeTypeEnum.VIDEO_MP4)
    }
    return mimetypes
  }, [mediaModalDetails.type])

  const dynamicInputsChange = (
    id: number,
    key: 'title' | 'content',
    value: string,
  ) => {
    const translations = form.values.translations

    const translationsData = translations?.find(
      translation => translation.languageId === id,
    )
    if (translationsData) {
      translationsData[key] = value
    } else {
      if (translations.length) {
        const translation = { languageId: id, title: '', content: '' }
        translations.push({ ...translation, [key]: value })
      }
    }
    if (translations.length) {
      form.setFieldValue('translations', [...translations])
    }
  }

  const tabChange = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleUnpublish = () => {
    form.setFieldValue('isDraft', true)
    form.setFieldValue('sendDate', null)
    setOpenConfirmPopup(false)
    form.validateForm().finally(() => {
      return form.submitForm()
    })
  }

  const handleCropSave = useCallback(
    async (attachment: TAttachment, images: any) => {
      try {
        const property = mediaModalDetails.property as IModelPropertyType
        const cropped: any = {}

        Object.keys(images).forEach((key: string) => {
          cropped[key] = images[key]
        })
        const test = {
          [property === 'newsCover' ? 'cover' : 'media']: {
            attachment,
            cropped,
          },
        }

        setImagesData((prev: any) => ({ ...prev, ...test }))

        form.setFieldValue(property, getMediaDetails(attachment))
      } catch (e) {
        handleError(e)
      }
    },
    [id, mediaModalDetails],
  )

  const openMediaModal = (type: 'newsCover' | 'newsMedia') => {
    if (type === 'newsCover') {
      setMediaModalDetails({
        isOpen: true,
        property: 'newsCover',
        type: [AttachmentsTypesEnum.IMAGE],
      })
    } else {
      setMediaModalDetails({
        isOpen: true,
        property: 'newsMedia',
        type: [AttachmentsTypesEnum.IMAGE, AttachmentsTypesEnum.VIDEO],
      })
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={id ? 'Edit' : 'Add'}
            items={[
              {
                title: 'Settings',
                linkTo: '/',
              },
              {
                title: 'Company News',
                linkTo: '/company-news',
              },
              {
                title: `${id ? 'Edit' : 'Add'} News`,
                active: true,
              },
            ]}
          />
          <Form
            onSubmit={e => {
              e.preventDefault()
              form.handleSubmit()
              return false
            }}
          >
            <Card className='news-manage-page'>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <Nav className='nav-tabs-custom rounded' role='tablist'>
                      {languages.map((language, index) => (
                        <NavItem key={index}>
                          <NavLink
                            to='#'
                            className={classnames({
                              active: activeTab === language.code,
                            })}
                            onClick={() => {
                              tabChange(language.code)
                            }}
                          >
                            {language.name}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      {languages.map((language, index: number) => (
                        <TabPane tabId={language.code} key={index}>
                          <div className='vstack gap-4 mt-3'>
                            <div>
                              <label htmlFor='groupName' className='form-label'>
                                Title*
                              </label>
                              <Input
                                name='name'
                                className='form-control'
                                id='groupName'
                                placeholder='Enter title'
                                type='text'
                                onChange={e =>
                                  dynamicInputsChange(
                                    language.id,
                                    'title',
                                    e.target.value,
                                  )
                                }
                                onBlur={form.handleBlur}
                                value={
                                  form.values.translations[index]?.title || ''
                                }
                                invalid={
                                  !!(
                                    (form.errors?.translations as any)?.[index]
                                      ?.title &&
                                    (form.touched?.translations as any)?.[index]
                                      ?.title
                                  )
                                }
                              />
                              {(form.errors?.translations as any)?.[index]
                                ?.title &&
                              (form.touched?.translations as any)?.[index]
                                ?.title ? (
                                <FormFeedback type='invalid'>
                                  {
                                    (form.errors?.translations as any)?.[index]
                                      ?.title
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div>
                              <label
                                htmlFor='description'
                                className='form-label'
                              >
                                Body*
                              </label>
                              <CKEditor
                                editor={ClassicEditor}
                                config={{
                                  toolbar: [
                                    'undo',
                                    'redo',
                                    'bold',
                                    'italic',
                                    'link',
                                    'bulletedList',
                                    'numberedList',
                                    'outdent',
                                    'indent',
                                  ],
                                }}
                                data={
                                  form.values.translations[index]?.content || ''
                                }
                                onChange={(
                                  event: unknown,
                                  editor: { getData: () => string },
                                ) =>
                                  dynamicInputsChange(
                                    language.id,
                                    'content',
                                    editor.getData(),
                                  )
                                }
                              />
                              {(form.errors?.translations as any)?.[index]
                                ?.content &&
                              (form.touched?.translations as any)?.[index]
                                ?.content ? (
                                <FormFeedback
                                  type='invalid'
                                  className='d-block'
                                >
                                  {
                                    (form.errors?.translations as any)?.[index]
                                      ?.content
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </TabPane>
                      ))}
                    </TabContent>
                    {id && form.values.status === NewsStatusTypes.PUBLISHED && (
                      <div className='mt-3'>
                        <label className='form-label'>
                          <span>Published Date:</span>
                        </label>
                        <span>
                          {' '}
                          {moment(form.values.publishedDate).format(
                            'MM/DD/YYYY',
                          )}{' '}
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col md={6} className='vstack gap-4'>
                    {(!user?.isFacilityAdmin ||
                      user?.hasAccessToMultipleFacilities) &&
                      user?.companyId && (
                        <FacilitiesMultiSelectDropdown
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          isDisabled={disableFacilities}
                          label={'Select Facilities'}
                        />
                      )}

                    {!user?.companyId && (
                      <div>
                        <label htmlFor='facilities' className='form-label'>
                          Select Companies
                        </label>
                        <AsyncSelectWithSearch
                          name='companies'
                          id='companies'
                          onChange={option => {
                            if (_.includes(option, AllValue)) {
                              form.setFieldValue('companyIds', [])
                            } else {
                              const selectedValues = _.isArray(option)
                                ? _.map(option, 'value')
                                : [option.value]
                              form.setFieldValue('companyIds', selectedValues)
                            }
                          }}
                          value={
                            form.values.companyIds &&
                            form.values.companyIds?.length
                              ? (form.values.companyIds || []).map(id => ({
                                  value: id,
                                  label:
                                    companiesOptions.find(p => p.value === id)
                                      ?.label || '',
                                }))
                              : AllValue
                          }
                          isMulti={form.values.companyIds?.length}
                          isClearable={false}
                          isSearchable={false}
                          placeholder={'Select companies'}
                          defaultOptions
                          loadOptions={fetchCompanies}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                        />
                      </div>
                    )}

                    <div>
                      <label htmlFor='title' className='form-label'>
                        News Media
                      </label>
                      {form.errors.newsMedia ? (
                        <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                          {(form.errors.newsMedia as string) || ''}
                        </p>
                      ) : null}
                      {form.values.newsMedia ? (
                        <>
                          <ul
                            className='list-unstyled mb-0'
                            id='dropzone-preview'
                          >
                            <Card
                              className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                              key={'-file'}
                            >
                              <div className='p-2'>
                                <Row className='align-items-center'>
                                  {form.values.newsMedia.type?.startsWith(
                                    'image',
                                  ) ? (
                                    <Col className='col-auto'>
                                      <img
                                        data-dz-thumbnail=''
                                        height='80'
                                        className='avatar-sm rounded bg-light'
                                        alt={'NO IMAGE'}
                                        src={
                                          'preview' in form.values.newsMedia
                                            ? form.values.newsMedia.preview
                                            : ''
                                        }
                                      />
                                    </Col>
                                  ) : null}

                                  <Col>
                                    <Link
                                      to='#'
                                      className='text-muted font-weight-bold'
                                    >
                                      {form.values.newsMedia.name}
                                    </Link>
                                    <p className='mb-0'>
                                      <strong>
                                        {form.values.newsMedia.formattedSize}
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col className='d-flex justify-content-end align-items-end gap-3'>
                                    <Button
                                      color='soft-primary'
                                      onClick={() => {
                                        openMediaModal('newsMedia')
                                      }}
                                    >
                                      Replace
                                    </Button>
                                    <Button
                                      color='danger'
                                      onClick={() => {
                                        form.setFieldValue('newsMedia', null)
                                        form.setFieldValue('newsMediaId', null)
                                      }}
                                    >
                                      {' '}
                                      Delete{' '}
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </ul>
                          {form.values.newsMedia.type?.startsWith('video') ? (
                            <div className='file-preview mt-3'>
                              <div>
                                <label htmlFor='title' className='form-label'>
                                  Preview
                                </label>
                              </div>
                              <video
                                controls
                                className='w-100'
                                style={{ height: 300 }}
                              >
                                <source
                                  src={form.values.newsMedia.preview}
                                  type='video/mp4'
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div>
                          <Button
                            color='ghost-primary'
                            className='text-light-purple'
                            onClick={() => {
                              openMediaModal('newsMedia')
                            }}
                          >
                            Select Media
                          </Button>
                        </div>
                      )}
                    </div>

                    <div>
                      <label htmlFor='title' className='form-label'>
                        News Cover
                      </label>

                      {form.errors.newsCover ? (
                        <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                          {(form.errors.newsCover as string) || ''}
                        </p>
                      ) : null}
                      {form.values.newsCover ? (
                        <>
                          <ul
                            className='list-unstyled mb-0'
                            id='dropzone-preview'
                          >
                            <Card
                              className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                              key={'-file'}
                            >
                              <div className='p-2'>
                                <Row className='align-items-center'>
                                  <Col className='col-auto'>
                                    <img
                                      data-dz-thumbnail=''
                                      height='80'
                                      className='avatar-sm rounded bg-light'
                                      alt={'NO IMAGE'}
                                      src={
                                        'preview' in form.values.newsCover
                                          ? form.values.newsCover.preview
                                          : ''
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to='#'
                                      className='text-muted font-weight-bold'
                                    >
                                      {form.values.newsCover.name}
                                    </Link>
                                    <p className='mb-0'>
                                      <strong>
                                        {form.values.newsCover.formattedSize}
                                      </strong>
                                    </p>
                                  </Col>
                                  <Col className='d-flex justify-content-end align-items-end gap-3'>
                                    <Button
                                      color='soft-primary'
                                      onClick={() => {
                                        openMediaModal('newsCover')
                                      }}
                                    >
                                      Replace
                                    </Button>
                                    <Button
                                      color='danger'
                                      onClick={() => {
                                        form.setFieldValue('newsCoverId', null)
                                        form.setFieldValue('newsCover', null)
                                      }}
                                    >
                                      {' '}
                                      Delete{' '}
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </ul>
                        </>
                      ) : (
                        <div>
                          <Button
                            color='ghost-primary'
                            className='text-light-purple'
                            onClick={() => {
                              openMediaModal('newsCover')
                            }}
                          >
                            Select Media
                          </Button>
                        </div>
                      )}
                    </div>
                    {!(
                      id && form.values.status === NewsStatusTypes.PUBLISHED
                    ) && (
                      <Row className='d-flex align-items-end'>
                        <Col md={6}>
                          <div>
                            <label htmlFor='date' className='form-label'>
                              Scheduled Date and Time
                            </label>
                            <div className='form-icon right'>
                              <Flatpickr
                                data-enable-time
                                className={`form-control form-control-icon ${
                                  form.touched.sendDate && form.errors.sendDate
                                    ? ' is-invalid'
                                    : ''
                                }`}
                                ref={datePickerRef}
                                id='date'
                                name='sendDate'
                                placeholder='mm-dd-yyyy, --:--'
                                onChange={option => {
                                  form.setFieldValue('sendDate', option[0])
                                }}
                                onBlur={() => {
                                  form.setFieldTouched('sendDate', true)
                                }}
                                value={form.values.sendDate}
                                options={{
                                  dateFormat: 'MM-DD-YYYY HH:mm',
                                  formatDate: date =>
                                    moment(date).format('MM/DD/YYYY HH:mm'),
                                  minDate: Date.now(),
                                  allowInvalidPreload: true,
                                }}
                              />
                              {form.values.sendDate && (
                                <i
                                  className='ri-close-line fs-16 text-danger'
                                  onClick={() => {
                                    datePickerRef.current?.flatpickr.clear()
                                  }}
                                ></i>
                              )}
                              <i className='ri-calendar-event-line fs-20'></i>
                            </div>
                          </div>
                        </Col>
                        <Col
                          md={6}
                          className={`${form.values.sendDate && 'mb-2'}`}
                        >
                          <span className='fs-14 text-muted'>
                            {form.values.sendDate
                              ? 'To Send Now, leave the Scheduled Date and Time blank'
                              : 'If you do not enter a scheduled date/time, the news item will be sent now'}
                          </span>
                        </Col>
                        {form.errors.sendDate ? (
                          <div className='invalid-feedback d-block'>
                            {form.errors.sendDate}
                          </div>
                        ) : null}
                      </Row>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className='d-flex justify-content-between navigation-container sticky-row'>
              <Button
                color='light'
                className='btn-label'
                onClick={() => navigate('/company-news')}
              >
                <i className='ri-arrow-left-line label-icon align-middle me-2'></i>
                Cancel
              </Button>
              <div className='hstack gap-3'>
                {((!id && companyNewsPermissions.add) ||
                  (id &&
                    form.values.status !== NewsStatusTypes.PUBLISHED &&
                    companyNewsPermissions.edit)) && (
                  <Button
                    color='ghost-primary'
                    className='text-light-purple'
                    disabled={!form.dirty || form.isSubmitting}
                    onClick={() => {
                      form.setFieldValue('isDraft', true)
                      setStatus(NewsStatusTypes.DRAFT)
                      form.validateForm().finally(() => {
                        return form.submitForm()
                      })
                    }}
                  >
                    Save as Draft
                  </Button>
                )}
                {id &&
                  form.values.status === NewsStatusTypes.PUBLISHED &&
                  companyNewsPermissions.edit(
                    <Button
                      color='danger'
                      className='right'
                      disabled={!form.dirty || form.isSubmitting}
                      onClick={() => {
                        setOpenConfirmPopup(true)
                      }}
                    >
                      Unpublish
                    </Button>,
                  )}

                {form.values.status !== NewsStatusTypes.PUBLISHED &&
                  companyNewsPermissions.publish && (
                    <Button
                      color='success'
                      className='right'
                      disabled={!form.dirty || form.isSubmitting}
                      onClick={() => {
                        form.setFieldValue('isDraft', false)
                        setStatus(NewsStatusTypes.SCHEDULED)
                        form.validateForm().finally(() => {
                          return form.submitForm()
                        })
                      }}
                    >
                      Schedule
                    </Button>
                  )}
                {((id &&
                  form.values.status === NewsStatusTypes.PUBLISHED &&
                  companyNewsPermissions.edit) ||
                  (form.values.status !== NewsStatusTypes.PUBLISHED &&
                    companyNewsPermissions.publish)) && (
                  <Button
                    color='primary'
                    className='btn-label right'
                    disabled={!form.dirty || form.isSubmitting}
                    onClick={() => {
                      form.setFieldValue('isDraft', false)
                      setStatus(NewsStatusTypes.PUBLISHED)
                      form.setFieldValue('sendDate', null)
                      form.validateForm().finally(() => {
                        return form.submitForm()
                      })
                    }}
                  >
                    <i className='ri-arrow-right-line label-icon align-middle ms-2'></i>
                    {id && form.values.status === NewsStatusTypes.PUBLISHED
                      ? 'Save'
                      : 'Publish'}
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </Container>

        <WarningModal
          isOpen={isOpenPopup}
          title=''
          message={'Please fill Spanish translations before saving the News.'}
          onClose={() => {
            setOpenPopup(false)
            form.setSubmitting(false)
          }}
        />

        <ConfirmationModal
          isOpen={isOpenConfirmPopup}
          title={'Are you sure...?'}
          message={''}
          onClose={() => {
            setOpenConfirmPopup(false)
            form.setSubmitting(false)
          }}
          confirmationBtnText={'Unpublish'}
          onConfirm={handleUnpublish}
        />
        <SelectFromMediaLibraryModal
          isOpen={mediaModalDetails.isOpen}
          mimetype={mediaModalMimeTypes}
          type={mediaModalDetails.type}
          handleUploadSelectedMedia={handleUploadSelectedMedia}
          onClose={() => {
            setMediaModalDetails(prev => ({
              ...prev,
              isOpen: false,
            }))
            form.setSubmitting(false)
          }}
        />
        <ImageCropperModal
          show={!!croperAttachment}
          onClose={() => setCroperAttachment(null)}
          attachment={croperAttachment}
          aspectRatios={[1, 1.1]}
          onSave={(attachment, images) => {
            handleCropSave(attachment, images)
            setCroperAttachment(null)
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default ManageCompanyNews
