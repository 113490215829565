import { GroupMultiSelectWithSearch } from './SelectWithSearch'
import { Option } from '../../helpers/facility'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FILTERS } from '../../pages/Reporting/CourseHistory/types'
import _ from 'lodash'
import { MultiValue, SingleValue } from 'react-select'

interface FacilitiesMultiSelectDropdownProps {
  selectedOptions: Option[]
  setSelectedOptions: Dispatch<SetStateAction<Option[]>>
  isDisabled?: boolean
  label?: string
  updateUrlParams?: (key: string, value?: string) => string
  updateParamsInUrl?: boolean
}

const FacilitiesMultiSelectDropdown = ({
  setSelectedOptions,
  selectedOptions,
  isDisabled = false,
  label = 'Filter by Facility',
  updateUrlParams,
}: FacilitiesMultiSelectDropdownProps) => {
  const { groupOptions } = useAppSelector(state => state.FacilityOptions)
  const user = useAppSelector(state => state.User.user)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const facilitiesParam = searchParams.get(FILTERS.FACILITY)
  const facilities = facilitiesParam ? JSON.parse(facilitiesParam) : ''

  const [options, setOptions] = useState<Option[]>(groupOptions)
  const [selectedFacilities, setSelectedFacilities] =
    useState<Option[]>(selectedOptions)

  useEffect(() => {
    const { options } = disableChildren(selectedOptions)

    setOptions(options)
  }, [selectedOptions])

  useEffect(() => {
    setSelectedFacilities(selectedOptions)
  }, [selectedOptions])

  useEffect(() => {
    if (facilities.length) {
      const filteredOptions = options.filter(item =>
        facilities.includes(item.label),
      )
      setSelectedFacilities(prev =>
        _.isEqual(prev, filteredOptions) ? prev : filteredOptions,
      )
    }
  }, [])

  const collectDisabledIds = (
    option: Option,
    selectedValues: Set<string>,
    disabledIds: Set<string>,
  ) => {
    if (selectedValues.has(option.parentValue || '')) {
      disabledIds.add(option.value)
    }
  }

  const disableRecursive = (
    option: Option,
    selectedValues: Set<string>,
    disabledIds: Set<string>,
  ): Option => {
    const updatedOption = { ...option }
    collectDisabledIds(option, selectedValues, disabledIds)

    if (option.parentValue && selectedValues.has(option.parentValue)) {
      updatedOption.disabled = true
    }

    if (disabledIds.has(option.parentValue || '')) {
      updatedOption.disabled = true
    }

    return updatedOption
  }

  const disableChildren = (selectedOptions: Option[]) => {
    const selectedValues = new Set(
      (selectedOptions || []).map(option => option.value),
    )
    const disabledIds = new Set<string>()
    let _options: Option[] = options.map(option => ({
      ...option,
      disabled: false,
    }))

    if (user?.groupId) {
      _options = _options.filter(option => option.level !== 0)
    }

    const allSelected = selectedOptions.some(option => option.level === 0)

    if (allSelected) {
      return {
        options: _options.map(option => ({
          ...option,
          disabled: option.level !== 0,
        })),
        disabledIds,
      }
    }
    return {
      options: _options.map(option =>
        disableRecursive(option, selectedValues, disabledIds),
      ),
      disabledIds,
    }
  }

  const handleChange = (option: MultiValue<Option> | SingleValue<Option>) => {
    if (option) {
      const { disabledIds } = disableChildren(option as Option[])
      const allSelected = (option as Option[]).find(item => item.level === 0)
      if (allSelected) {
        setSelectedOptions([allSelected])
        if (updateUrlParams) {
          navigate(
            `?${updateUrlParams(
              FILTERS.FACILITY,
              JSON.stringify([allSelected].map(item => item.label)),
            )}`,
          )
        }
      } else {
        const filteredSelectedOptions = (option as Option[]).filter(
          item => !disabledIds.has(item.value),
        )

        setSelectedOptions(filteredSelectedOptions)

        if (updateUrlParams) {
          const queryString = filteredSelectedOptions.length
            ? `?${updateUrlParams(
                FILTERS.FACILITY,
                JSON.stringify(
                  filteredSelectedOptions.map(item => item.label.toString()),
                ),
              )}`
            : `?${updateUrlParams(FILTERS.FACILITY)}`

          navigate(queryString)
        }
      }
    }
  }

  return (
    <div>
      <label htmlFor='facility' className='form-label'>
        {label}
      </label>
      <GroupMultiSelectWithSearch<Option>
        name='group'
        id='group'
        onChange={option => handleChange(option)}
        placeholder={'Select facilities'}
        hideSelectedOptions={false}
        isDisabled={isDisabled}
        isMulti
        value={selectedFacilities}
        isClearable={true}
        isSearchable={true}
        options={options}
      />
    </div>
  )
}

export default FacilitiesMultiSelectDropdown
