import React, { useCallback } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import { postInstructor, putInstructor } from '../../helpers/api_helper'
import { InstructorModalForm, TInstructor } from '../../sharedTypes'
import { handleError, successToastOptions } from '../../helpers/toast_helper'
import AvatarUploader from '../Common/AvaterUploader'

interface InstructorModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (instructor: TInstructor) => void
  title: string
  initialValues: InstructorModalForm | null
}

const InstructorModal = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  initialValues,
}: InstructorModalProps) => {
  const onCreate = useCallback(async (values: InstructorModalForm) => {
    const { name, qualification, bio, photo } = values
    try {
      const [firstName, lastName] = name.split(' ')
      const formData = new FormData()
      formData.append('firstName', firstName)
      formData.append('lastName', lastName)
      formData.append('bio', bio)

      if (photo) {
        formData.append('avatar', photo)
      }
      formData.append('qualification', qualification)

      const instructor = await postInstructor(formData)
      form.resetForm()
      toast('Success - Instructor added successfully', successToastOptions)
      onSubmit(instructor)
    } catch (e) {
      handleError(e)
    }
  }, [])

  const onEdit = useCallback(async (values: InstructorModalForm) => {
    const { id, name, qualification, bio, photo: avatar } = values
    try {
      const [firstName, lastName] = name.split(' ')

      const formData = new FormData()
      formData.append('firstName', firstName)
      formData.append('lastName', lastName)
      formData.append('bio', bio)

      if (avatar && typeof avatar === 'object') {
        formData.append('avatar', avatar)
      }
      formData.append('qualification', qualification)

      const instructor = await putInstructor(id, formData)
      form.resetForm()
      onSubmit(instructor)
    } catch (e) {
      handleError(e)
    }
  }, [])

  const _onSubmit = async (values: InstructorModalForm) => {
    if (values.id > 0) {
      await onEdit(values)
    } else {
      await onCreate(values)
    }
  }

  const form = useFormik<InstructorModalForm>({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: -1,
      name: '',
      bio: '',
      photo: undefined,
      qualification: '',
    },
    validationSchema: Yup.object({
      photo: Yup.mixed()
        .nullable()
        .test('is-valid-size', 'Max allowed size is 3mb', value =>
          value instanceof File ? value.size <= 3000000 : true,
        ),
      name: Yup.string()
        .required('Please enter your name')
        .matches(
          /^[a-zA-Z]{1,40}( [a-zA-Z]{1,40})+$/,
          'First Name and Last Name are required',
        ),
      bio: Yup.string()
        .max(2000, 'Can be only up to 2000 characters')
        .required('Bio is required'),
      qualification: Yup.string().required('Please enter your credentials'),
    }),
    onSubmit: _onSubmit,
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered>
      <ModalBody
        className='modal-body'
        style={{ maxHeight: '80vh', overflowY: 'auto' }}
      >
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='vstack gap-3'>
            <section className='m-auto'>
              <AvatarUploader
                file={form.values.photo}
                onChange={(f: File) => {
                  form.setFieldValue('photo', f)
                }}
              />
              {form.errors.photo ? (
                <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                  {(form.errors.photo as string) || ''}
                </p>
              ) : null}
            </section>
          </div>

          <div className='vstack gap-3'>
            <div>
              <label htmlFor='name' className='form-label'>
                Name*
              </label>
              <Input
                name='name'
                className='form-control'
                id='name'
                placeholder='Enter name'
                type='text'
                onChange={form.handleChange}
                disabled={form.isSubmitting}
                onBlur={form.handleBlur}
                value={form.values.name || ''}
                invalid={!!(form.touched.name && form.errors.name)}
              />
              {form.touched.name && form.errors.name ? (
                <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
              ) : null}
            </div>

            <div>
              <label htmlFor='bio' className='form-label'>
                Bio*
              </label>
              <Input
                name='bio'
                className='form-control'
                id='bio'
                placeholder=''
                type='textarea'
                rows='6'
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                disabled={form.isSubmitting}
                value={form.values.bio || ''}
                invalid={!!(form.touched.bio && form.errors.bio)}
              />
              {form.touched.bio && form.errors.bio ? (
                <FormFeedback type='invalid'>
                  {form.errors.bio as string}
                </FormFeedback>
              ) : null}
            </div>

            <div>
              <label htmlFor='bio' className='form-label'>
                Credentials*
              </label>
              <Input
                name='qualification'
                className='form-control'
                id='qualification'
                placeholder='Enter credentials'
                type='text'
                onChange={form.handleChange}
                disabled={form.isSubmitting}
                onBlur={form.handleBlur}
                value={form.values.qualification || ''}
                invalid={
                  !!(form.touched.qualification && form.errors.qualification)
                }
              />
              {form.touched.qualification && form.errors.qualification ? (
                <FormFeedback type='invalid'>
                  {form.errors.qualification as string}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={beforeClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={form.isSubmitting || !form.dirty}
            >
              {form.isSubmitting ? <Spinner size={'sm'} /> : 'Submit'}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default InstructorModal
