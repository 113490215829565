import React, { ReactNode } from 'react'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import { TUser } from '../../sharedTypes'
import { Col, Row, Card, Container } from 'reactstrap'
import UserInfo from '../../pages/UserManagment/UserProfile/UserInfo'
import { getUserDisplayName } from '../../helpers/user'
import {
  TabsHeader,
  UserTabItemEnum,
  UserProfileTabs,
} from '../../pages/UserManagment/UserProfile/TabsHeader'

const tabList: UserProfileTabs = {
  [UserTabItemEnum.HISTORY]: {
    path: '/user-listing/profile',
    label: 'Course History',
    active: true,
    error: false,
  },
  [UserTabItemEnum.DOCUMENT]: {
    path: '/documents',
    label: 'Documents',
    active: true,
    error: false,
  },
}

interface UserProfileLayoutProps {
  tabs?: UserProfileTabs[]
  children: ReactNode
  user: TUser
  onDeactivate: () => void
}

export const UserProfileLayout = (props: UserProfileLayoutProps) => {
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='User Profile'
            items={[
              {
                linkTo: '/',
                title: 'User Management',
              },
              {
                linkTo: '/user-listing',
                title: 'User Listing',
              },
              {
                active: true,
                title: getUserDisplayName(props.user) || 'User Profile',
              },
            ]}
          />
          <Row>
            <UserInfo
              user={props.user}
              onDeactivatePress={() => props.onDeactivate()}
            />
            <Col xl={9} md={8} sm={8}>
              <Card className='m-0' style={{ display: 'block' }}>
                <TabsHeader tabs={tabList} userId={props.user.id} />
                {props.children}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
