import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import CountUp from 'react-countup'
import { ReactComponent as CheckinIcon } from '../../../assets/images/svg/checkin-in.svg'
import { GetAnalyticsForLast30DaysDTO } from '../../../sharedTypes'
import { getAnalyticsForLast30Days } from '../../../helpers/api/users'
import _ from 'lodash'
import { handleError } from '../../../helpers/toast_helper'
import { useAppSelector } from '../../../hooks/redux'
import DetailsInfoModal from '../../../Components/Modals/DetailsInfoModal'
import { v4 as uuidv4 } from 'uuid'

interface ICourseStatusAnalytics {
  label: string
  key: 'usersPendingCompletion' | 'coursesCompleted'
  icon: JSX.Element
  dateLabel: string
  info?: string
}

const userCourseStatusAnalytics: ICourseStatusAnalytics[] = [
  {
    label: 'Users Pending Completion',
    key: 'usersPendingCompletion',
    icon: <i className='ri-group-line fs-3'></i>,
    dateLabel: '',
  },
  {
    label: 'Courses Completed',
    key: 'coursesCompleted',
    icon: <CheckinIcon style={{ width: 24.5, height: 36 }} />,
    dateLabel: 'within last 30 days',
    info: 'The total number of courses completed in the past 30 days',
  },
]

const Statistics = ({ includeAgency }: { includeAgency: boolean }) => {
  const { selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )

  const [courseStatuses, setCourseStatuses] =
    useState<GetAnalyticsForLast30DaysDTO.Response>({
      usersPendingCompletion: 0,
      coursesCompleted: 0,
    })

  useEffect(() => {
    const facilityId = selectedFacilityId ?? undefined
    const groupId = selectedGroupId ?? undefined
    getAnalyticsForLast30Days({ facilityId, groupId, includeAgency })
      .then(res => {
        setCourseStatuses(res)
      })
      .catch(handleError)
  }, [selectedFacilityId, selectedGroupId, includeAgency])

  const memoizedUserCourseStatusAnalytics = useMemo(() => {
    return userCourseStatusAnalytics.map(item => ({
      ...item,
      id: uuidv4(),
    }))
  }, [])

  return (
    <>
      <Row>
        {memoizedUserCourseStatusAnalytics.map(analytics => (
          <Col md={6} key={analytics.key}>
            <Card className='card-animate'>
              <CardBody>
                <div className='d-flex justify-content-between'>
                  <div>
                    <p className='fw-medium text-muted mb-0 gap-1'>
                      {analytics.label}
                      <DetailsInfoModal
                        id={analytics.id}
                        name={'analytics-break-down'}
                        info={analytics.info}
                      />
                    </p>
                    <h2 className='mt-4 ff-secondary'>
                      <span className='counter-value'>
                        <CountUp
                          start={0}
                          end={_.get(courseStatuses, analytics.key, 0)}
                          decimals={0}
                          duration={4}
                        />
                      </span>
                    </h2>
                    <p className='mb-0 text-muted'>{analytics.dateLabel}</p>
                  </div>
                  <div>
                    <div className='avatar-sm flex-shrink-0'>
                      <span className='avatar-title rounded-circle fs-2 text-light-purple'>
                        {analytics.icon}
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default Statistics
