import React, { useState, useCallback } from 'react'
import { Input, Button, FormFeedback } from 'reactstrap'
import { FormikErrors, FormikTouched } from 'formik'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import SelectFromMediaLibraryModal from '../../Modals/MediaModal'
import {
  MediaModalDetails,
  AttachmentsTypesEnum,
  TAttachment,
} from '../../../sharedTypes'
import { ImageAnswer } from '../../../sharedTypes'
import { Responders } from 'react-beautiful-dnd'

export const ImageAnswers = ({
  index,
  answers,
  onDelete,
  handleAnswerChange,
  handleCorrectChange,
  handleBlur,
  onReOrder,
  errors,
  touched,
}: {
  index: number
  answers: ImageAnswer[]
  errors: FormikErrors<ImageAnswer[]>
  touched: FormikTouched<ImageAnswer[]>
  onDelete: (index: number) => void
  handleCorrectChange: (index: number) => void
  handleAnswerChange: (answerIndex: number, attachment: TAttachment) => void
  handleBlur: (e: React.FocusEvent<any, Element>) => void
  onReOrder: Responders['onDragEnd']
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [mediaModalDetails, setMediaModalDetails] = useState<MediaModalDetails>(
    {
      isOpen: false,
      mediaProperty: 'cover',
      type: [AttachmentsTypesEnum.IMAGE],
    },
  )
  function getStyle(style: any, snapshot: any) {
    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      transitionDuration: `0.001s`,
    }
  }

  const onAddMedia = useCallback((index: number) => {
    setSelectedIndex(index)
    setMediaModalDetails({
      isOpen: true,
      mediaProperty: 'cover',
      type: [AttachmentsTypesEnum.IMAGE],
    })
  }, [])

  const handleUploadSelectedMedia = useCallback(
    (attachment: TAttachment) => {
      handleAnswerChange(selectedIndex as number, attachment)
    },
    [selectedIndex],
  )

  return (
    <div className='d-flex flex-column gap-2'>
      <SelectFromMediaLibraryModal
        isOpen={mediaModalDetails.isOpen}
        type={mediaModalDetails.type}
        handleUploadSelectedMedia={handleUploadSelectedMedia}
        onClose={() => {
          setMediaModalDetails(prev => ({
            ...prev,
            isOpen: false,
          }))
        }}
      />

      <section>
        <label htmlFor='date' className='form-label mb-0'>
          Answers*
        </label>
        <p className='text-muted p-0 mb-0'>
          You can enter up to 4 answer choices and select multiple answers.
        </p>
      </section>
      <DragDropContext onDragEnd={onReOrder}>
        <Droppable
          droppableId='droppable'
          type='questions'
          direction='vertical'
        >
          {(provided: any) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {provided.placeholder}
              {answers.map((a, i) => (
                <Draggable
                  draggableId={`image-${a.id}-${index}`}
                  index={a.id}
                  key={`image-${a.id}-${index}`}
                >
                  {(provided, snapshot) => (
                    <div
                      key={i}
                      ref={provided.innerRef}
                      className='mb-2'
                      {...provided.draggableProps}
                      style={getStyle(provided.draggableProps.style, snapshot)}
                    >
                      <section className='d-flex gap-1 is-invalid'>
                        <div {...provided.dragHandleProps}>
                          <div
                            style={{ width: 40, height: '100%' }}
                            className='btn-label px-1 btn btn-light btn-sm d-flex justify-content-center align-items-center'
                          >
                            <i
                              className='ri-draggable text-muted fs-5 drag-icon'
                              {...provided.draggableProps}
                            ></i>
                          </div>
                        </div>

                        <section
                          onClick={() => onAddMedia(i)}
                          className='w-100 p-3 d-flex gap-2 align-items-center cursor-pointer'
                          style={{
                            border: '1px solid #e9ebec',
                            borderColor:
                              (errors as any)[i]?.attachmentId &&
                              ((touched as any)[i]?.attachmentId as any)
                                ? '#f06548'
                                : '#e9ebec',
                            borderRadius: 4,
                          }}
                        >
                          <div
                            style={{
                              border: '1px solid #e9ebec',
                              borderRadius: 4,
                              width: 50,
                              height: 50,
                            }}
                            className='d-flex justify-content-center align-items-center'
                          >
                            {a.path ? (
                              <img src={a.path} className='w-100 h-100' />
                            ) : (
                              <i className='display-7 text-muted ri-upload-cloud-2-fill fs-5'></i>
                            )}
                          </div>
                          <div>
                            <p className='mb-1'>
                              {a.name ? a.name : 'Select Media'}
                            </p>
                            <p className='mb-0 text-muted'>(jpg, png)</p>
                          </div>
                        </section>

                        <Button
                          style={{ width: 37, minHeight: '100%' }}
                          color='light'
                          className='btn-label px-1'
                          size='sm'
                          onClick={() => onDelete(i)}
                        >
                          <i className='bx  ri-delete-bin-2-line text-muted fs-20'></i>
                        </Button>
                        <Button
                          style={{ width: 37, minHeight: '100%' }}
                          color='light'
                          className={`btn-label px-1 ${
                            a.correct ? 'bg-green-100 border-green-500' : ''
                          }`}
                          size='sm'
                          onClick={() => {
                            handleCorrectChange(i)
                          }}
                        >
                          <i
                            className={`bx ri-check-fill fs-22 ${
                              a.correct ? 'text-green-500' : ''
                            }`}
                          ></i>
                        </Button>
                      </section>
                      {(errors as any)[i]?.answer &&
                      ((touched as any)[i]?.answer as any) ? (
                        <FormFeedback type='invalid'>
                          {(errors as any)[i]?.answer}
                        </FormFeedback>
                      ) : null}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
