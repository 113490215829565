import { useEffect, useState } from 'react'
import { LinkProps, useNavigate } from 'react-router-dom'
import useIsMasteredAdmin from '../hooks/useIsMasteredAdmin'
import { useAppSelector } from '../hooks/redux'

export type ChildItem = {
  id: number | string
  label: string
  link: string
  parentId?: string
  badgeColor?: string
  badgeName?: string
  isChildItem?: boolean
  click?: LinkProps['onClick']
  stateVariables?: boolean
  childItems?: ChildItem[]
  openInNewWindow?: boolean
}

export type NavItem = {
  id?: string
  ids?: string[]
  label: string
  icon?: string
  link?: string
  stateVariables?: boolean
  isHeader?: boolean
  click?: LinkProps['onClick']
  subItems?: ChildItem[]
  badgeColor?: string
  badgeName?: string
}

const useNavData = () => {
  const history = useNavigate()
  //state data
  const [isDashboard, setIsDashboard] = useState(false)
  const [isUsers, setIsUsers] = useState(false)
  const [isUsersSearch, setIsUsersSearch] = useState(false)
  const [isCourses, setIsCourses] = useState(false)
  const [isFacilityManagement, setIsFacilityManagement] = useState(false)
  const [isCompanyManagement, setIsCompanyManagement] = useState(false)
  const [isReporting, setIsReporting] = useState(false)
  const [isSupport, setIsSupport] = useState(false)
  const [isCalendar, setIsCalendar] = useState(false)
  const [isCommunication, setIsCommunication] = useState(false)
  const [isSettings, setIsSettings] = useState(false)

  const [currentState, setCurrentState] = useState('Dashboard')

  const isMasteredAdmin = useIsMasteredAdmin()
  const user = useAppSelector(state => state.User.user)

  const hasMultipleFacilities = user?.hasAccessToMultipleFacilities
  function updateIconSidebar(e: any) {
    if (e && e.target && e.target?.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul?.querySelectorAll('.nav-icon.active')
      iconItems?.forEach(item => {
        item.classList.remove('active')
        const id = item.getAttribute('subitems')
        if (id && document.getElementById(id)) {
          document.getElementById(id)?.classList.remove('show')
        }
      })
    }
  }

  useEffect(() => {
    if (currentState !== 'dashboard') {
      setIsDashboard(false)
    }
    if (currentState !== 'users') {
      setIsUsers(false)
    }
    if (currentState !== 'courses') {
      setIsCourses(false)
    }
    if (currentState !== 'facilityManagement') {
      setIsFacilityManagement(false)
    }
    if (currentState !== 'companyManagement') {
      setIsCompanyManagement(false)
    }
    if (currentState !== 'reporting') {
      setIsReporting(false)
    }
    if (currentState !== 'support') {
      setIsSupport(false)
    }
    if (currentState !== 'calendar') {
      setIsCalendar(false)
    }
    if (currentState !== 'communication') {
      setIsCommunication(false)
    }
    if (currentState !== 'settings') {
      setIsSettings(false)
    }
  }, [
    history,
    currentState,
    isDashboard,
    isUsers,
    isCourses,
    isFacilityManagement,
    isReporting,
    isSupport,
    isCalendar,
    isSettings,
    isCompanyManagement,
  ])

  const navItems: NavItem[] = [
    {
      label: 'Menu',
      isHeader: true,
    },
    ...(isMasteredAdmin
      ? [
          {
            id: 'viewCompanyManagement',
            label: 'Company Management',
            icon: 'ri-briefcase-line',
            link: '/company-management',
            stateVariables: true,
            click: function (e: any) {
              e.preventDefault()
              setIsFacilityManagement(!isCompanyManagement)
              setCurrentState('companyManagement')
              updateIconSidebar(e)
            },
          },
        ]
      : [
          {
            id: 'dashboard',
            label: 'Dashboard',
            icon: 'ri-apps-2-line',
            link: '/',
            stateVariables: isDashboard,
            click: function (event: any) {
              event.preventDefault()
              setIsDashboard(!isDashboard)
              setCurrentState('dashboard')
              updateIconSidebar(event)
            },
          },
        ]),
    ...(isMasteredAdmin
      ? []
      : [
          {
            id: 'viewUserListing',
            label: 'User Management',
            icon: 'ri-group-line',
            click: function (e: any) {
              e.preventDefault()
              setIsUsers(!isUsers)
              setCurrentState('users')
              updateIconSidebar(e)
            },
            stateVariables: isUsers,
            subItems: [
              {
                id: 'viewUserListing',
                label: 'User Listing',
                link: '/user-listing',
                parentId: 'users',
              },
              {
                id: 'viewUserCreationLog',
                label: 'User Creation Log',
                link: '/user-creation-log',
                parentId: 'users',
              },
              {
                id: 'viewAdminListing',
                label: 'Admin Listing',
                link: '/admin-listing',
                parentId: 'users',
              },
              {
                id: 'viewImportLog',
                label: 'Import Log',
                link: '/import-log',
                parentId: 'users',
              },
            ],
          },
        ]),
    ...(!isMasteredAdmin
      ? []
      : [
          {
            id: 'viewAdminListing',
            label: 'Admin Listing',
            icon: 'ri-group-line',
            link: '/admin-listing',
            click: function (e: any) {
              e.preventDefault()
              setIsUsers(!isUsers)
              setCurrentState('users')
              updateIconSidebar(e)
            },
            stateVariables: isUsers,
          },
          {
            id: 'userManagement.usersSearch',
            label: 'User Search',
            icon: 'ri-search-2-line',
            link: '/users-search',
            click: function (e: any) {
              e.preventDefault()
              setIsUsersSearch(!isUsersSearch)
              setCurrentState('usersSearch')
              updateIconSidebar(e)
            },
            stateVariables: isUsersSearch,
          },
        ]),
    {
      id: 'courses',
      label: 'Courses',
      icon: 'ri-pages-line',
      click: function (e) {
        e.preventDefault()
        setIsCourses(!isCourses)
        setCurrentState('courses')
        updateIconSidebar(e)
      },
      stateVariables: isCourses,
      subItems: [
        {
          id: 'viewCourseListing',
          label: 'All Courses',
          link: '/courses',
          parentId: 'courses',
        },
        // {
        //   id: 'scheduledCourses',
        //   label: 'Online Course Categories',
        //   link: '/courses/online-categories',
        //   parentId: 'courses',
        // },
        ...(isMasteredAdmin
          ? [
              {
                id: 'viewCourseKeywords',
                label: 'Course Keywords',
                link: '/courses/keywords',
                parentId: 'courses',
              },
            ]
          : []),

        // {
        //   id: 'scheduledCourses',
        //   label: 'Scheduled Courses',
        //   link: '/courses/scheduled-courses',
        //   parentId: 'courses',
        // },
        // {
        //   id: 'scheduledCourses',
        //   label: 'Scheduled Course Categories',
        //   link: '/courses/scheduled-categories',
        //   parentId: 'courses',
        // },
        /*{
          id: 'coursePackages',
          label: 'Course Packages',
          link: '/courses/course-packages',
          parentId: 'courses',
        },*/
        // {
        //   id: 'reportsAndAnalytics',
        //   label: 'Reports and Analytics',
        //   link: '/courses/reports-and-analytics',
        //   parentId: 'courses',
        // },
        {
          id: 'viewMediaLibrary',
          label: 'Media Library',
          link: '/courses/media-library',
          parentId: 'courses',
        },
      ],
    },
    ...(isMasteredAdmin
      ? []
      : [
          {
            id: 'facilityManagement',
            ids: [
              'facilityManagement.viewFacilityManagement',
              'facilityManagement.viewFacilityDetails',
              'facilityManagement.viewFacilityEmployees',
            ],
            label: 'Facility Management',
            icon: 'ri-briefcase-line',
            link: '/facility-management',
            click: function (e: any) {
              e.preventDefault()
              setIsFacilityManagement(!isFacilityManagement)
              setCurrentState('facilityManagement')
              updateIconSidebar(e)
            },
            stateVariables: isFacilityManagement,
            subItems:
              user?.isFacilityAdmin && !user?.hasAccessToMultipleFacilities
                ? [
                    {
                      id: 'viewFacilityDetails',
                      label: 'Facility Details',
                      link: user?.adminFacilities
                        ? `/facility-management/facility/${user?.adminFacilities[0]?.id}`
                        : '#',
                      parentId: 'facilityManagement',
                    },

                    {
                      id: 'viewFacilityWelcomePage',
                      label: 'Facility Welcome Page',
                      link: user?.adminFacilities
                        ? `${
                            process.env.REACT_APP_WELCOME_FACILITY_PAGE_URL
                          }/${encodeURIComponent(
                            user?.company?.name as string,
                          )}/${encodeURIComponent(
                            user.adminFacilities[0].name,
                          )}`
                        : '#',
                      parentId: 'facilityManagement',
                      openInNewWindow: true,
                    },
                  ]
                : [],
          },
        ]),
    {
      id: 'reporting',
      label: 'Reporting',
      icon: 'ri-file-list-3-line',
      link: '/reporting',
      click: function (e) {
        e.preventDefault()
        setIsReporting(!isReporting)
        setCurrentState('reporting')
        updateIconSidebar(e)
      },
      stateVariables: isReporting,
      subItems: isMasteredAdmin
        ? [
            {
              id: 'viewCourseReport',
              label: 'Course Report',
              link: '/courses/report',
              parentId: 'reporting',
            },
            {
              id: 'viewCompanyReport',
              label: 'Company Report',
              link: '/company/report',
              parentId: 'reporting',
            },
          ]
        : [
            {
              id: 'viewCourseHistoryReport',
              label: 'Course History',
              link: '/courses/history',
              parentId: 'reporting',
            },
            {
              id: 'viewCourseHistorySummaryReport',
              label: 'Completed Courses',
              link: '/courses/history/summary',
              parentId: 'reporting',
            },
            {
              id: 'viewUserReport',
              label: 'User Report',
              link: '/user/report',
              parentId: 'reporting',
            },
            {
              id: 'viewNewUsersReport',
              label: 'New Users Report',
              link: '/new-users/report',
              parentId: 'reporting',
            },
            {
              id: 'viewExpiredCoursesReport',
              label: 'Expired Courses Report',
              link: '/expired-courses/report',
              parentId: 'reporting',
            },
          ],
    },
    ...(!isMasteredAdmin
      ? []
      : [
          {
            id: 'support',
            label: 'Support',
            icon: 'ri-service-line',
            click: function (e: any) {
              e.preventDefault()
              setIsSupport(!isSupport)
              setCurrentState('support')
              updateIconSidebar(e)
            },
            stateVariables: isSupport,
            subItems: [
              {
                id: 'viewTutorialsListing',
                label: 'Tutorials',
                link: '/support/tutorials',
                parentId: 'support',
              },
              {
                id: 'viewFAQListing',
                label: 'Knowledge base',
                link: 'https://app.intercom.com/a/apps/dbxgtj0g/articles/articles/list/all',
                parentId: 'support',
              },
              {
                id: 'viewSupportCategories',
                label: 'Support Categories',
                link: '/support/categories',
                parentId: 'support',
              },
            ],
          },
        ]),
    ...(isMasteredAdmin
      ? []
      : [
          {
            id: 'calendar.viewCalendar',
            label: 'Calendar',
            icon: 'ri-calendar-2-line',
            link: '/calendar',
            click: function (e: any) {
              e.preventDefault()
              setIsCalendar(!isCalendar)
              setCurrentState('calendar')
            },
            stateVariables: isCalendar,
          },
        ]),
    {
      id: 'communication',
      label: 'Communication',
      icon: 'ri-mail-open-line',
      click: function (e) {
        e.preventDefault()
        setIsCommunication(!isCommunication)
        setCurrentState('communication')
        updateIconSidebar(e)
      },
      stateVariables: isCommunication,
      subItems: [
        {
          id: 'viewSurveyListing',
          label: 'Surveys',
          link: '/surveys',
          parentId: 'communication',
        },
        {
          id: 'viewNotificationListing',
          label: 'Push Notifications',
          link: '/push-notifications',
          parentId: 'communication',
        },
        {
          id: 'viewCompanyNewsListing',
          label: 'Company News',
          link: '/company-news',
          parentId: 'communication',
        },
      ],
    },
    {
      id: 'settings',
      label: 'Settings',
      icon: 'ri-settings-3-line',
      click: function (e) {
        e.preventDefault()
        setIsSettings(!isSettings)
        setCurrentState('settings')
        updateIconSidebar(e)
      },
      stateVariables: isSettings,
      subItems: [
        {
          id: 'viewContinuingEducationProviders',
          label: 'Default Settings',
          link: '/course-settings',
          parentId: 'settings',
        },
        ...(!isMasteredAdmin
          ? []
          : [
              {
                id: 'viewInstructorListing',
                label: 'Instructor Management',
                link: '/instructor-management',
                parentId: 'settings',
              },
            ]),
        {
          id: 'viewRolesAndPermissions',
          label: 'User Roles & Permissions',
          link: '/role-permissions',
          parentId: 'settings',
        },
        ...(!isMasteredAdmin
          ? []
          : [
              {
                id: 'viewContinuingEducationProviders',
                label: 'CE Providers',
                link: '/ce-providers',
                parentId: 'settings',
              },
              {
                id: 'viewFTagsListing',
                label: 'FTags',
                link: '/ftags',
                parentId: 'settings',
              },
            ]),
        // {
        //   id: 'categories',
        //   label: 'Categories',
        //   link: '/forms-pickers',
        //   parentId: 'settings',
        //   isChildItem: true,
        // },
        /*{
          id: 'certificates',
          label: 'Certificates',
          link: '/forms-range-sliders',
          parentId: 'settings',
        },*/
        // {
        //   id: 'userSetup',
        //   label: 'User Setup Questions',
        //   link: '/forms-validation',
        //   parentId: 'settings',
        // },
      ],
    },
  ]
  return navItems
}
export default useNavData
