import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Row, Table } from 'reactstrap'
import Highlighter from 'react-highlight-words'
import {
  OrderType,
  DocumentSortBy,
  GetDocumentsDTO,
  StatesEnum,
} from '../../../sharedTypes'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  getDocumentsDTO,
  getUserDocuments,
  postDocument,
  updateDocument,
} from '../../../helpers/api_helper'
import moment from 'moment'
import DocumentModal, { DOCUMENT_TYPES } from './DocumentModal'
import {
  IDocumentItem,
  DocumentModalSubmitedValues,
  DocumentModalInitialValues,
} from '../../../sharedTypes'
import { FormikHelpers } from 'formik'
import { Pagination } from '../../../Components/Common/Pagination'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { handleError } from '../../../helpers/toast_helper'
import formatBytes from '../../../utils/formatBytes'
import _ from 'lodash'

const Columns = [
  {
    name: 'Document',
    sortBy: DocumentSortBy.TYPE,
    style: { width: 120 },
  },
  {
    name: 'License Type',
    sortBy: DocumentSortBy.LICENSE_TYPE,
  },
  {
    name: 'ID',
    sortBy: DocumentSortBy.DOCUMENT_ID,
  },
  {
    sortBy: DocumentSortBy.VALID_DATE,
    name: 'Valid Date',
  },
  {
    sortBy: DocumentSortBy.EXPIRY_DATE,
    name: 'Expiry Date',
  },
  {
    sortBy: DocumentSortBy.ORGANIZATION,
    name: 'Organization',
  },
  {
    sortBy: DocumentSortBy.STATE,
    name: 'State',
    style: { width: 50 },
  },
  {
    sortBy: DocumentSortBy.SOURCE,
    name: 'Source',
    style: { width: 70 },
  },
  {
    name: 'Actions',
    style: { width: 70 },
  },
]

const DocumentsTable = ({ userId }: { userId?: number }) => {
  const [openDocumentModal, setOpenDocumentModal] =
    useState<DocumentModalInitialValues | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<GetDocumentsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    documents: [],
  })

  const [query, setQuery] = useState<GetDocumentsDTO.Request>({
    page: 1,
    limit: 10,
    key: null,
    sortBy: DocumentSortBy.TYPE,
    orderBy: OrderType.ASC,
  })

  useEffect(() => {
    setIsLoading(true)
    if (userId) {
      getUserDocuments(userId, query)
        .then(response => {
          setData(response)
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      getDocumentsDTO(query)
        .then(response => {
          setData(response)
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query])

  const handleSort = useCallback((column: DocumentSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onFormSubmit = useCallback(
    async (
      values: DocumentModalSubmitedValues,
      form: FormikHelpers<DocumentModalSubmitedValues>,
    ) => {
      try {
        form.setSubmitting(true)

        const formData = new FormData()
        formData.append('type', values.type)
        if (values.type === DOCUMENT_TYPES.LICENSE && values.licenseType) {
          formData.append('licenseType', values.licenseType.value.toString())
        }
        formData.append('disciplineIds', JSON.stringify(values.disciplineIds))
        if (values.organization) {
          formData.append('organization', values.organization)
        }
        formData.append('validDate', new Date(values.validDate).toISOString())
        formData.append('expiryDate', new Date(values.expiryDate).toISOString())
        formData.append('document', values.document)
        formData.append('documentId', values.documentId)

        if (values.state) {
          formData.append('state', values.state.value)
        }
        formData.append('userId', values.userId.toString())

        if (values.id) {
          await updateDocument(formData, values.id)
        } else {
          await postDocument(formData)
        }

        form.setSubmitting(false)
        form.resetForm()
        setQuery(prev => ({ ...prev, page: 1 }))

        setOpenDocumentModal(null)
      } catch (e) {
        handleError(e)
        form.setSubmitting(false)
      }
    },
    [data.page],
  )

  return (
    <div className='vstack gap-3'>
      {openDocumentModal && (
        <DocumentModal
          title={`${openDocumentModal.id ? 'Update' : 'Upload'} Document`}
          initialValues={openDocumentModal}
          onClose={() => {
            setOpenDocumentModal(null)
          }}
          isOpen={!!openDocumentModal}
          onSubmit={onFormSubmit}
        />
      )}
      <Row className='px-3 pt-3'>
        <Col>
          <SearchInput
            style={{ maxWidth: 400, height: '100%' }}
            onChange={key => {
              setQuery(prev => ({ ...prev, key, page: 1 }))
            }}
            value={query.key || ''}
          />
        </Col>

        <Col>
          <div className='d-flex justify-content-end'>
            <Button
              color={'primary'}
              onClick={() => {
                setOpenDocumentModal({
                  userId: userId ? userId : 1,
                  type: '',
                  licenseType: undefined,
                  documentId: '',
                  disciplineIds: [],
                  state: undefined,
                  organization: '',
                  validDate: undefined,
                  expiryDate: undefined,
                  document: undefined,
                })
              }}
              className='btn btn-primary align-middle'
            >
              <i className='ri-upload-2-fill me-1 fs-16' />
              Add New Document
            </Button>
          </div>
        </Col>
      </Row>
      <NoResultTableWrapper
        isLoading={isLoading}
        isFiltering={!!query.key}
        pages={data.pages}
      >
        <div className='table-card'>
          <div className='overflow-x-auto'>
            <Table className='align-middle table-nowrap mb-0'>
              <thead className='table-light'>
                <tr className='text-muted fs-14'>
                  {Columns.map(column => (
                    <th
                      scope='col'
                      className='align-middle'
                      style={column.style}
                      key={column.name}
                    >
                      {column.name}
                      {!!column.sortBy && (
                        <ColumnSortIcon<DocumentSortBy>
                          sortOrder={query.orderBy}
                          sortedColumn={query.sortBy}
                          column={column.sortBy}
                          handleSort={handleSort}
                        />
                      )}{' '}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.documents.map((item: IDocumentItem, i) => (
                  <tr key={i} className='fs-14 fw-light'>
                    <td>
                      <Highlighter
                        highlightClassName='text-highlight'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={item.type}
                      />
                    </td>
                    <td>
                      <Highlighter
                        highlightClassName='text-highlight'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={item.licenseType}
                      />
                    </td>
                    <td>
                      {item.documentId && (
                        <span className='text-dark badge bg-light fs-12 fw-normal'>
                          <Highlighter
                            highlightClassName='text-highlight'
                            searchWords={[query.key || '']}
                            highlightTag={'span'}
                            autoEscape={true}
                            textToHighlight={item.documentId}
                          />
                        </span>
                      )}
                    </td>
                    <td>
                      {moment(new Date(item.validDate)).format('MM/DD/YYYY')}
                    </td>
                    <td>
                      {moment(new Date(item.expiryDate)).format('MM/DD/YYYY')}
                    </td>
                    <td>
                      <Highlighter
                        highlightClassName='text-highlight'
                        searchWords={[query.key || '']}
                        highlightTag={'span'}
                        autoEscape={true}
                        textToHighlight={item.organization}
                      />
                    </td>
                    <td>{item.state || ''}</td>
                    <td>{item.source}</td>
                    <td>
                      <span className='d-flex gap-2 mx-3'>
                        <i
                          onClick={() => {
                            if (item.url) {
                              window.open(
                                item.url,
                                '_blank',
                                'noopener,noreferrer',
                              )
                            }
                          }}
                          className={`ri-eye-line ${
                            item.url ? 'cursor-pointer' : 'text-muted'
                          }`}
                        />
                        <i
                          className='ri-pencil-line cursor-pointer'
                          onClick={() => {
                            setOpenDocumentModal({
                              ...item,
                              licenseType: {
                                label: item.licenseType,
                                value: item.licenseType,
                              },
                              disciplineIds: item.disciplineIds || [],
                              state: {
                                label: StatesEnum[item.state],
                                value: item.state,
                              },
                              document: item.url
                                ? {
                                    name: _.get(item, 'info.originalname', ''),
                                    preview: item.url,
                                    formattedSize: formatBytes(
                                      _.get(item, 'info.size', 0),
                                    ),
                                  }
                                : undefined,
                            })
                          }}
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className='mx-3 my-3'>
            <Pagination
              currentPage={data.page - 1}
              totalPages={data.pages}
              totalRecords={data.count}
              setPage={page => {
                setQuery(prev => ({ ...prev, page: ++page }))
              }}
            />
          </div>
        </div>
      </NoResultTableWrapper>
    </div>
  )
}

export default DocumentsTable
