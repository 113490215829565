import BreadCrumb from '../../../Components/Common/BreadCrumb'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  OrderType,
  ReportingPermissions,
  USER_STATUSES,
  UsersSearchSortBy,
  UsersSortBy,
} from '../../../sharedTypes'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { handleError } from '../../../helpers/toast_helper'
import { getUserReport } from '../../../helpers/api/users'
import { onPrint } from '../../../helpers/common'
import { GetUserReportDTO } from '../../../sharedTypes/api/userReport'
import UsersTable from '../../../Components/Common/UsersTable'
import { EXPORT_TYPES } from '../CourseHistory/types'
import Filters from './Filters'
import { Columns, SelectedFilters } from './types'
import ExportUserReport from './ExportUserReport'
import _ from 'lodash'
import moment from 'moment/moment'
import FilterTabs from './FilterTabs'

const NewUsersReport = () => {
  document.title = 'New Users Report | Mastered - Admin & Dashboard'

  const [showFilters, setShowFilters] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState(false)

  const [exportDropdownOpen, setExportDropdownOpen] = useState(false)
  const [exportType, setExportType] = useState<EXPORT_TYPES | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    positions: [],
    departments: [],
    facilities: [],
    agencies: [],
  })

  const [query, setQuery] = useState<GetUserReportDTO.Request>({
    page: 1,
    limit: 10,
    facilityIds: [],
    departmentIds: [],
    agencyIds: [],
    positionIds: [],
    sortBy: UsersSortBy.CREATION_DATE,
    orderBy: OrderType.DESC,
    status: [USER_STATUSES.ACTIVE, USER_STATUSES.UNVERIFIED],
    permission: ReportingPermissions.VIEW_NEW_USERS_REPORT,
  })

  const [data, setData] = useState<GetUserReportDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    usersReport: [],
  })

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      const _query = _.omitBy(query, _.isNil)
      if (!_.isEmpty(_query.hireDate)) {
        _query.hireDate = [
          moment(_query.hireDate[0]).format('YYYY-MM-DD'),
          moment(_query.hireDate[1]).format('YYYY-MM-DD'),
        ]
      }
      if (!_.isEmpty(_query.signupDate)) {
        _query.signupDate = [
          moment(_query.signupDate[0]).format('YYYY-MM-DD'),
          moment(_query.signupDate[1]).format('YYYY-MM-DD'),
        ]
      }
      if (!_.isEmpty(_query.creationDate)) {
        _query.creationDate = [
          moment(_query.creationDate[0]).format('YYYY-MM-DD'),
          moment(_query.creationDate[1]).format('YYYY-MM-DD'),
        ]
      }

      getUserReport(_query as GetUserReportDTO.Request)
        .then(res => {
          setData(res.data)
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  const handleSort = useCallback((column: UsersSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={'New Users Report'}
            items={[
              {
                active: true,
                title: 'Reporting',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <FilterTabs
                      navTab={query.creationMethod}
                      navToggle={creationMethod => {
                        setQuery(prev => ({ ...prev, creationMethod, page: 1 }))
                      }}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      <Dropdown
                        isOpen={exportDropdownOpen}
                        toggle={() => {
                          setExportDropdownOpen(prevState => !prevState)
                        }}
                      >
                        <DropdownToggle
                          className=' btn btn-secondary align-middle'
                          tag='button'
                        >
                          <i className='mdi mdi-file-export-outline me-1 fs-16'></i>
                          Export
                        </DropdownToggle>
                        <DropdownMenu end>
                          {Object.values(EXPORT_TYPES).map(type => (
                            <DropdownItem key={type}>
                              <span
                                className={`mx-2 ${
                                  type === EXPORT_TYPES.PDF
                                    ? 'text-green-500'
                                    : ''
                                }`}
                                onClick={() => setExportType(type)}
                              >
                                Export as {type}
                              </span>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => onPrint('new-users-report-section')}
                      >
                        <i className='ri-printer-line me-1 fs-16'></i>
                      </Button>
                    </div>
                  </div>

                  <Filters
                    showFilters={showFilters}
                    setQuery={setQuery}
                    query={query}
                    setSelectedFilters={setSelectedFilters}
                    setFetchData={setFetchData}
                    filterBy={{
                      facilities: true,
                      departments: true,
                      positions: true,
                      agencies: false,
                      groups: false,
                      status: false,
                      registered: false,
                      hireDate: false,
                      signupDate: false,
                      creationDate: true,
                    }}
                  />
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={false}
                  pages={data.pages}
                >
                  <UsersTable
                    columns={Columns}
                    users={data.usersReport}
                    sortedColumn={query.sortBy}
                    page={data.page - 1}
                    sortOrder={query.orderBy}
                    totalPages={data.pages}
                    onPageChanged={page => {
                      setQuery(prev => ({ ...prev, page: ++page }))
                    }}
                    onLimitChange={limit => {
                      setQuery(prev => ({ ...prev, limit }))
                    }}
                    limit={query.limit}
                    itemsPerPage={query.limit ? query.limit : 10}
                    handleSort={
                      handleSort as (
                        column: UsersSortBy | UsersSearchSortBy,
                      ) => void
                    }
                    totalUsers={data.count}
                    globalSearch={query.key || ''}
                    id='new-users-report-section'
                  />
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
        {exportType && (
          <ExportUserReport
            exportType={exportType}
            setExportType={setExportType}
            count={data.count}
            query={query}
            selectedFilters={selectedFilters}
          />
        )}
      </div>
    </Fragment>
  )
}

export default NewUsersReport
