import { Col, Row } from 'reactstrap'
import React, { Dispatch, SetStateAction, useRef } from 'react'
import {
  COMPANY_STATUSES,
  FilterOption,
  GetCompaniesDTO,
} from '../../../sharedTypes'
import _ from 'lodash'
import MultiSelect from '../../../Components/Common/MultiSelect'
import Flatpickr from 'react-flatpickr'

interface ReportFiltersProps {
  showFilters: boolean
  setFilters: Dispatch<SetStateAction<GetCompaniesDTO.Request>>
  query: GetCompaniesDTO.Request
}

const ReportFilters = ({
  showFilters,
  setFilters,
  query,
}: ReportFiltersProps) => {
  const datePickerRef = useRef<Flatpickr>(null)

  return (
    <>
      {showFilters && (
        <Row className='mx-0 gap-4 mt-2'>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='group' className='form-label'>
                Filter by Active Status
              </label>

              <MultiSelect
                name='status'
                id='userStatus'
                isMulti
                options={Object.values(COMPANY_STATUSES).map(status => ({
                  value: status,
                  label: _.capitalize(status),
                }))}
                isSearchable={false}
                placeholder='Select status'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                defaultValue={(query.status || []).map(status => ({
                  value: status,
                  label: _.capitalize(status),
                }))}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    status: _.isEmpty(option)
                      ? []
                      : (_.map(option, 'value') as COMPANY_STATUSES[]),
                    page: 1,
                  }))
                }}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0'>
            <div className='w-100 form-icon right filter-by-date'>
              <label htmlFor='type' className='form-label'>
                Filter by Activation Date
              </label>
              <div className='form-icon right'>
                <Flatpickr
                  className='form-control fs-12'
                  placeholder='--/--/---- - --/--/---'
                  onChange={dates => {
                    if (dates.length === 2) {
                      setFilters(prev => ({
                        ...prev,
                        activationDate: dates,
                      }))
                    }
                  }}
                  ref={datePickerRef}
                  value={query.activationDate}
                  options={{
                    mode: 'range',
                    dateFormat: 'm/d/Y',
                    locale: {
                      rangeSeparator: ' - ',
                    },
                  }}
                />
                {query.activationDate ? (
                  <i
                    className='ri-close-line fs-16 text-danger'
                    onClick={() => {
                      datePickerRef.current?.flatpickr.clear()
                      setFilters(prev => ({
                        ...prev,
                        activationDate: [],
                        page: 1,
                      }))
                    }}
                  ></i>
                ) : null}
                <i
                  className='ri-calendar-2-line fs-20'
                  onClick={() => {
                    datePickerRef.current?.flatpickr.open()
                  }}
                ></i>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ReportFilters
